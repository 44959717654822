import { useEffect, useCallback } from 'react';
import { useAtom, atom } from 'jotai';
import axios from 'axios';
import { currentUserAtom } from '../../../../utils/PrivateRoute';

export const userListDataAtom = atom({
    init: true,
    userList: [],
    allUsers: [],
    usersById: {},
});

export const compareStrings = (a, b, locale = 'en') => {
    if (!isNaN(a) && !isNaN(parseFloat(a))) {
        return a.localeCompare(b, locale, {
            numeric: true,
            sensitivity: 'base',
        });
    }

    return a.localeCompare(b, locale, { sensitivity: 'base' });
};

export const useFetchAllUsers = () => {
    const [userListData, setUserListData] = useAtom(userListDataAtom);
    const [currentUser] = useAtom(currentUserAtom);

    const fetchActiveUsers = useCallback(async () => {
        try {
            const { data } = await axios.request({
                method: 'get',
                url: '/api/getActiveUsers',
            });
            const userDict = {};
            const mappedData = data.userList.map((user) => {
                userDict[user.UserID] = { ...user };

                return {
                    id: user.UserID,
                    name: user.name,
                    email: user.email,
                };
            });

            mappedData.sort((a, b) => {
                const splitA = a.name.trim().split(' ');
                const splitB = b.name.trim().split(' ');
                const result = compareStrings(splitA[splitA.length - 1] ?? '', splitB[splitB.length - 1] ?? '');
                if (result === 0) {
                    return compareStrings(a.name, b.name);
                }
                return result;
            });

            setUserListData({
                init: false,
                userList: mappedData.filter((user) => user.id !== currentUser?.userId),
                allUsers: mappedData,
                usersById: userDict,
            });
            return mappedData;
        } catch (error) {
            console.error(error);
            return false;
        }
    }, [setUserListData, currentUser]);

    useEffect(() => {
        if (userListData.init) {
            fetchActiveUsers();
        }
    }, [fetchActiveUsers, userListData.init]);

    return {
        ...userListData,
        fetchActiveUsers,
    };
};
