import { useState, useMemo } from 'react';

import { useFocusAtom } from '../../../../../hooks';
import { gsFiltersAtom } from '../../../steps/generalSettings/GeneralSettingsAtoms';

/**
 * @typedef {('military' | 'full-time')} fullRequire
 */
/**
 *
 * @param {fullRequire} initialValue
 * @returns {{
 *  isDisabled: boolean,
 *  handleToggleRqmt: ({ name: fullRequire }) => void,
 *  fullRequire: fullRequire
 * }}
 */
export const useMilitaryFullTimeRequirment = (initialValue = 'full-time') => {
    const [fullRequire, setFullRequire] = useState(initialValue);

    const [cmd] = useFocusAtom(gsFiltersAtom, 'CMD');
    // const cmd = 'AFR - HQ AIR FORCE RESERVE COMMAND';

    const isDisabled = useMemo(() => {
        if (typeof cmd === 'string') {
            const shortenedCmd = cmd.split(' - ')[0] ?? '';
            const CMD = shortenedCmd.toUpperCase();
            // Only these 2 commands can have full time
            if (CMD === 'AFR' || CMD === 'ANG') {
                return false;
            }
        }
        // All other commands are this requirement
        setFullRequire('full-time');
        return true;
    }, [cmd]);

    /**
     *
     * @param {{ name: fullRequire }} CarbonEvent
     */
    const handleToggleRqmt = ({ name }) => {
        setFullRequire(name === 'military' ? 'military' : 'full-time');
    };

    return {
        isDisabled,
        handleToggleRqmt,
        fullRequire,
    };
};
