// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.issues-reported-tab tr.cds--parent-row.cds--expandable-row + tr[data-child-row] td {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/reportIssue/IssuesTable.scss"],"names":[],"mappings":"AACI;EACI,oBAAA;EACA,qBAAA;AAAR","sourcesContent":[".issues-reported-tab {\n    tr.cds--parent-row.cds--expandable-row+tr[data-child-row] td {\n        padding-left: 3.5rem;\n        padding-right: 3.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
