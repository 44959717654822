import React, { useState, useEffect } from 'react';
import { PostResponseNotification } from '../../utils';
import {
    Download,
    CopyFile,
    Printer,
    NextOutline,
    Warning,
} from '@carbon/icons-react';
import { Button, Tile } from '@carbon/react';
import './Styles/DownloadRecoveryCodePage.scss';
import axios from 'axios';

export const DownloadRecoveryCodePage = () => {
    const [codes, setCodes] = useState('');
    const [textURL, setTextURL] = useState('');
    const [notificationData, setNotificationData] = useState({});

    const handleCopyClick = () => {
        //workaround for <br/> tags
        const codesString = codes.toString().replaceAll(',[object Object]', '');
        navigator.clipboard.writeText(codesString);
    };
    const handleDownloadClick = () => {};
    const handlePrintClick = () => {};
    const handleProceedClick = () => {};

    useEffect(() => {
        (async () => {
            try {
                const resGet = await axios.post(
                    '/api/getRecoveryCodes',
                    {
                        method: 'get',
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );

                //convert comma-separated string of codes to an array with <br/> between each code
                setCodes(
                    resGet.data.codes
                        .split(',')
                        .map((code, index) => [
                            code,
                            <br key={`code-${index}`} />,
                        ])
                        .flat()
                );
            } catch (err) {
                setNotificationData(err.response);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const resDownload = await axios.post(
                    '/api/getRecoveryCodes',
                    {
                        method: 'download',
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                setTextURL(
                    window.URL.createObjectURL(new Blob([resDownload.data]))
                );
            } catch (err) {
                setNotificationData(err.response);
            }
        })();
    }, []);

    return (
        <div className="mx-5 my-4">
            <h3 style={{ fontWeight: 'bold' }}>Account Recovery codes</h3>
            <div
                style={{
                    width: '100%',
                    height: '1px',
                    margin: '20px 0',
                    backgroundColor: 'var(--cds-border-subtle)',
                }}
            ></div>{' '}
            {/* Line Divider*/}
            <div className="download-warning-container">
                <p>
                    <Warning className="download-warning-icon" />
                    Please copy, download, or print your recovery codes before
                    proceeding.
                </p>
            </div>
            <p className="my-3">
                Should you ever lose your phone or access to your one time
                password secret, each of these recovery codes can be used one
                time each to regain access to your account. Please save them in
                a safe place, or you <strong>will</strong> lose access to your
                account.
            </p>
            <Tile className="download-page-recovery-codes-container">
                <p id="download-page-recovery-codes">{codes}</p>
            </Tile>
            <a
                href={textURL}
                download={`${process.env.REACT_APP_PROJECT_NAME}_recovery_codes.txt`}
                style={{ textDecoration: 'none' }}
            >
                <Button
                    className="download-button-styles"
                    renderIcon={Download}
                >
                    Download
                </Button>
            </a>
            <Button
                onClick={handleCopyClick}
                className="download-button-styles"
                renderIcon={CopyFile}
            >
                Copy Codes
            </Button>
            <Button
                onClick={handlePrintClick}
                className="download-button-styles"
                renderIcon={Printer}
            >
                Print Codes
            </Button>
            <Button
                onClick={handleProceedClick}
                className="download-button-styles-proceed download-button-styles"
                renderIcon={NextOutline}
            >
                Proceed
            </Button>
            <PostResponseNotification data={notificationData} />
        </div>
    );
};
