import { ListItem, Modal, UnorderedList } from '@carbon/react';
import './ManageScenarios.scss';
import PropTypes from 'prop-types';

export const ShareScenarioModal = ({ scenarios, users, open, onClose, onSubmit }) => {
    const handleSubmit = () => {
        onSubmit();
        onClose();
    };

    return (
        <Modal
            className="share-scenario-modal"
            open={open}
            modalHeading="Share Scenarios"
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
            onRequestClose={onClose}
            onRequestSubmit={handleSubmit}
            size="sm"
            data-testid="share-scenario-modal"
        >
            <div className="mb-2">Are you sure you want to share the following scenarios with the following users?</div>
            <div className="d-flex justify-content-around">
                <div>
                    <p className="d-flex justify-content-center" style={{ fontWeight: 'bold' }}>
                        Scenario
                    </p>
                    <UnorderedList>
                        {scenarios.map((scenario) => {
                            return <ListItem key={scenario.id}>{scenario.scenario_name}</ListItem>;
                        })}
                    </UnorderedList>
                </div>
                <div>
                    <p className="d-flex justify-content-center" style={{ fontWeight: 'bold' }}>
                        User
                    </p>
                    <UnorderedList>
                        {users.map((user) => {
                            return <ListItem key={user.id}>{user.name}</ListItem>;
                        })}
                    </UnorderedList>
                </div>
                <div>
                    <p className="d-flex justify-content-center" style={{ fontWeight: 'bold' }}>
                        Email
                    </p>
                    <UnorderedList>
                        {users.map((user) => {
                            return <ListItem key={user.id}>{user.email}</ListItem>;
                        })}
                    </UnorderedList>
                </div>
            </div>
        </Modal>
    );
};

ShareScenarioModal.propTypes = {
    scenarios: PropTypes.arrayOf(PropTypes.object),
    users: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};
