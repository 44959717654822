import { useState, useMemo } from 'react';

const calculateStartAndEnd = (page, pageSize) => {
    const mult = page - 1;
    const start = mult * pageSize;
    const end = start + pageSize;
    return { start, end };
};

/**
 * In order for pagination to work use the rowPositions start and end to slice the rows to render
 * @param options used for initial values such as initial page and page size
 * @returns
 */
export const usePagination = (options = {}) => {
    const { initialPage = 1, initialPageSize = 10 } = options;

    const [page, setPage] = useState(initialPage);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [rowPositions, setRowPositions] = useState(calculateStartAndEnd(initialPage, initialPageSize));

    const handlePageChange = (change) => {
        setPage(change.page);
        setPageSize(change.pageSize);
        setRowPositions(calculateStartAndEnd(change.page, change.pageSize));
    };

    const jumpToFirstPage = () => {
        setPage(initialPage);
        setRowPositions(calculateStartAndEnd(initialPage, pageSize));
    };

    return {
        page,
        pageSize,
        rowPositions,
        handlePageChange,
        jumpToFirstPage,
    };
};

/**
 * Filter data hook
 * @param data a list of obejcts that should be filtered
 * @returns
 */
export const useFilteredData = (data) => {
    // A filter object of key value pairs that will be matched against the data
    const [filters, setFilters] = useState({});

    const filteredData = useMemo(() => {
        let fData = data || [];
        for (const key in filters) {
            if (key === 'all' && filters[key] !== '') {
                // if the key 'all' is set run a search across all properties
                fData = fData.filter((d) => {
                    return Object.values(d).join('').toLowerCase().search(filters[key].toLowerCase()) !== -1;
                });
            } else if (filters[key] !== '') {
                // otherwise check if any key value pairs match the data
                fData = fData.filter((d) => {
                    let match = false;
                    if (filters[key] === d[key]) {
                        match = true;
                    }
                    return match;
                });
            }
        }
        return fData;
    }, [data, filters]);

    return {
        filteredData,
        filters,
        setFilters,
    };
};
