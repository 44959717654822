import React, { useState } from 'react';
import { WatsonHealthDna } from '@carbon/react/icons';
import { IconButton } from '@carbon/react';

import { UmpFlow } from './UmpFlow';

export const OpenUmpFlowButton = ({ kind }) => {
    const [isUmpFlowOpen, setIsUmpFlowOpen] = useState(false);

    return (
        <React.Fragment>
            <IconButton
                data-testid="ump-flow-button"
                label="Show UMP flow"
                align="left"
                enterDelayMs={1000}
                onClick={() => setIsUmpFlowOpen(true)}
                kind={kind}
                className="me-2"
                size="md"
            >
                <WatsonHealthDna />
            </IconButton>
            {isUmpFlowOpen ? <UmpFlow onClose={() => setIsUmpFlowOpen(false)} /> : null}
        </React.Fragment>
    );
};
