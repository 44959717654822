// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ops-modal .cds--data-table.level-0 thead tr th {
  width: 6rem;
  min-width: 6rem;
}
.ops-modal .cds--data-table.level-0 tbody .cds--expandable-row td .cds--child-row-inner-container .cds--data-table-content table.cds--data-table.level-1 tbody tr td {
  min-width: 5rem;
  max-width: 5rem;
  padding: 0 0.5rem !important;
}
.ops-modal tr.cds--parent-row.cds--expandable-row + tr[data-child-row] td {
  padding: 0 !important;
}
.ops-modal .action-buttons .cds--btn--ghost svg {
  fill: var(--cds-link-primary, #0f62fe);
}
.ops-modal .action-buttons .cds--btn--disabled svg {
  fill: var(--cds-text-on-color-disabled, #8d8d8d);
}`, "",{"version":3,"sources":["webpack://./src/pages/wizard/modals/opsEquations/opsSelectionTable/OpsSelectionTable.scss"],"names":[],"mappings":"AAIgB;EACI,WAAA;EACA,eAAA;AAHpB;AAgBwC;EACI,eAAA;EACA,eAAA;EACA,4BAAA;AAd5C;AA2BI;EACI,qBAAA;AAzBR;AA8BY;EACI,sCAAA;AA5BhB;AAiCY;EACI,gDAAA;AA/BhB","sourcesContent":[".ops-modal {\n    .cds--data-table.level-0 {\n        thead {\n            tr {\n                th {\n                    width: 6rem;\n                    min-width: 6rem;\n                }\n            }\n        }\n\n        tbody {\n            .cds--expandable-row {\n                td {\n                    .cds--child-row-inner-container {\n                        .cds--data-table-content {\n                            table.cds--data-table.level-1 {\n                                tbody {\n                                    tr {\n                                        td {\n                                            min-width: 5rem;\n                                            max-width: 5rem;\n                                            padding: 0 0.5rem !important;\n                                        }\n                                    }\n                                }\n                            }\n\n                        }\n                    }\n                }\n            }\n        }\n    }\n\n    tr.cds--parent-row.cds--expandable-row+tr[data-child-row] td {\n        padding: 0 !important;\n    }\n\n    .action-buttons {\n        .cds--btn--ghost {\n            svg {\n                fill: var(--cds-link-primary, #0f62fe)\n            }\n        }\n\n        .cds--btn--disabled {\n            svg {\n                fill: var(--cds-text-on-color-disabled, #8d8d8d);\n            }\n\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
