import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Loading, Button } from '@carbon/react';

import { JsonToExcel } from '../../../../helpers/jsonToExcel';
import { getAllScenarios, getScenarioReview } from '../../scenarioApiCalls';

/**
 *
 * @param {object} row
 * @returns
 */
const filterOutIds = ({ id, EXCLUDE, ...details }) => {
    return {
        ...details,
    };
};

export const ReviewDownload = () => {
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const { pathname, search } = location;
    const scenarioId = search.split('q=')[1];

    useEffect(() => {
        async function fetchReviewData() {
            setIsLoading(true);
            const scenarioResponse = await getAllScenarios();
            const scenario = scenarioResponse.scenarios.find(
                ({ scenario_id }) => Number(scenario_id) === Number(scenarioId)
            );
            const scenarioName = scenario?.scenario_name ?? 'ump_scenario';
            const xlsx = new JsonToExcel();
            const { isOk, data } = await getScenarioReview(scenarioId);
            setIsLoading(false);
            const { generalSettings, addUnitDetails, unitDetails, opsTobe, mxTobe, opsAsis, mxAsis } = data;
            if (isOk) {
                xlsx.addWorksheet('General Settings', generalSettings.map(filterOutIds));
                xlsx.addWorksheet(
                    'Unit Details - OPS',
                    unitDetails.OPS_DETAILS.map((element) => {
                        return {
                            'OG/WING PAS': unitDetails.OPS_GP,
                            ...filterOutIds(element),
                        };
                    })
                );
                xlsx.addWorksheet(
                    'Unit Details - MX',
                    unitDetails.MX_DETAILS.map((element) => {
                        return {
                            'MXG/WING PAS': unitDetails.MX_GP,
                            ...filterOutIds(element),
                        };
                    })
                );
                xlsx.addWorksheet(
                    'Additional Unit Details',
                    addUnitDetails.CMD.map((CMD) => ({ CMD })),
                    addUnitDetails.ILC.map((ILC) => ({ ILC })),
                    'AIRCREW: ',
                    addUnitDetails.OPS.map((element) => ({
                        UNIT: element.UNIT,
                        HOST: element.HOST,
                        PEC: element.PEC,
                        PEC_TITLE: element.PEC_TITLE,
                    })),
                    'MX: ',
                    addUnitDetails.MX.map((element) => ({
                        UNIT: element.UNIT,
                        HOST: element.HOST,
                        PEC: element.PEC,
                        PEC_TITLE: element.PEC_TITLE,
                    }))
                );
                xlsx.addWorksheet('OPS ASIS', opsAsis.map(filterOutIds));
                xlsx.addWorksheet('OPS TOBE', opsTobe.map(filterOutIds));
                xlsx.addWorksheet('MX ASIS', mxAsis.map(filterOutIds));
                xlsx.addWorksheet('MX TOBE', mxTobe.map(filterOutIds));

                xlsx.exportFile(scenarioName);
            }
        }
        if (scenarioId && Number(scenarioId) > -1) {
            fetchReviewData();
        }
    }, [pathname, scenarioId]);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="align-self-center">
            <div
                className="mb-3"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '30vh',
                }}
            >
                <h1 className="fw-bold mb-5" style={{ marginTop: 'auto' }}>
                    Guardian Download Page
                </h1>
                <p className="d-block pt-3 mb-4">Download completed</p>
                <Button
                    onClick={() => {
                        window.open('', '_parent', '');
                        window.close();
                    }}
                >
                    Close window
                </Button>
            </div>
        </div>
    );
};
