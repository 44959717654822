import { Button } from '@carbon/react';
import { useCallback, useEffect, useState } from 'react';
import './TimeoutAlert.scss';
import { refreshJWT, useHandleLogout } from '../helpers';
import { useWindowIsVisible } from '../hooks/useWindowIsVisible';

export const TimeoutAlert = ({ initialTime = Infinity }) => {
    const tokenExp = document.cookie
        .split('; ')
        .find((row) => row.startsWith('exp='))
        ?.split('=')[1];
    const maxAgeSecs = process.env.REACT_APP_JWT_MAX_AGE;
    const sessionTime =
        maxAgeSecs > 59
            ? `${Math.floor(maxAgeSecs / 60)} minute${Math.floor(maxAgeSecs / 60) > 1 ? 's' : ''}.`
            : `${maxAgeSecs} second${maxAgeSecs > 1 ? 's' : ''}.`;

    const [timeoutOpen, setTimeoutOpen] = useState(false);
    const [timeleft, setTimeleft] = useState(initialTime);

    const timeleftSecs = timeleft / 1000;

    const windowIsVisible = useWindowIsVisible();

    const handleLogout = useHandleLogout();

    const handleRefreshToken = useCallback(async () => {
        const currentTime = Math.floor(new Date().getTime() / 1000);
        const res = await refreshJWT();
        if (res === false) {
            window.location.reload();
        }
        const newTokenExp = Number(res.exp);
        setTimeoutOpen(false);
        setTimeleft((newTokenExp - currentTime) * 1000);
    }, []);

    const refreshPage = () => {
        window.location.reload();
    };

    useEffect(() => {
        const currentTime = Math.floor(new Date().getTime() / 1000);
        setTimeleft((Number(tokenExp) - currentTime) * 1000);
    }, [tokenExp, windowIsVisible]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeleft((prevTimeleft) => prevTimeleft - 1000);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [windowIsVisible]);

    const lessThanOneMinute = timeleft < 60000;

    useEffect(() => {
        if (lessThanOneMinute) {
            if (windowIsVisible) {
                handleRefreshToken();
            } else {
                setTimeoutOpen(true);
            }
        }
    }, [lessThanOneMinute, windowIsVisible, handleRefreshToken]);

    return (
        <div className={`timeout-alert cds--modal ${timeoutOpen ? 'is-visible' : ''}`}>
            <div className="cds--modal-container">
                <div className="main-content">
                    <h3 className="mb-2">Timeout Alert!</h3>
                    <p>{`The maximum session time is ${sessionTime}`}</p>
                    {timeleft < 0 ? (
                        <>
                            <p>Session Expired! Please log in again.</p>
                            <div className="mt-3">
                                <Button onClick={refreshPage} className="me-3">
                                    Reload
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>
                                Timeout in: {timeleftSecs > 0 ? timeleftSecs : 0} sec
                                {timeleftSecs > 1 ? 's' : ''}
                            </p>
                            <p>Would you like to continue?</p>
                            <div className="mt-3">
                                <Button onClick={handleLogout} kind="secondary" className="me-3">
                                    No, Logout
                                </Button>
                                <Button onClick={handleRefreshToken}>Yes, Keep me logged in</Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
