// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.guardian-panel {
  height: 100vh;
  width: 100vw;
  margin-left: 0;
}
.guardian-panel .logo-panel {
  padding-left: 7vw;
}
.guardian-panel img.logo-image {
  align-self: center;
  height: 20vh;
}
.guardian-panel .logo_style {
  max-height: 10em;
}
.guardian-panel .recoveryLink {
  text-decoration: none;
}
.guardian-panel .tile-container {
  padding: 0.5rem 0;
}
.guardian-panel .registration-form textarea {
  height: 10rem;
  max-height: 15rem;
}
.guardian-panel .registration-form .cds--tabs {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/utils/GuardianPanel.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,cAAA;AADF;AAGE;EACE,iBAAA;AADJ;AAQE;EACE,kBAAA;EACA,YAAA;AANJ;AASE;EACE,gBAAA;AAPJ;AAUE;EACE,qBAAA;AARJ;AAWE;EACE,iBAAA;AATJ;AAaI;EACE,aAAA;EACA,iBAAA;AAXN;AAcI;EACE,uBAAA;AAZN","sourcesContent":["@import '../scss/styles';\n\n.guardian-panel {\n  height: 100vh;\n  width: 100vw;\n  margin-left: 0;\n\n  .logo-panel {\n    padding-left: 7vw;\n  }\n\n  .content-panel {\n    // background-color:#EEEEEE;\n  }\n\n  img.logo-image {\n    align-self: center;\n    height: 20vh;\n  }\n\n  .logo_style {\n    max-height: 10em;\n  }\n\n  .recoveryLink {\n    text-decoration: none;\n  }\n\n  .tile-container {\n    padding: .5rem 0;\n  }\n\n  .registration-form {\n    textarea {\n      height: 10rem;\n      max-height: 15rem;\n    }\n\n    .cds--tabs {\n      justify-content: center;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
