import { Modal } from '@carbon/react';
import './modal.scss';
import PropTypes from 'prop-types';

export const ApplyFiltersModal = ({ open, onClose, onSubmit }) => {
    const handleSubmit = () => {
        onSubmit();
        onClose();
    };

    return (
        <Modal
            className="apply-filters-modal"
            open={open}
            modalHeading="Apply Filters"
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
            onRequestClose={onClose}
            onRequestSubmit={handleSubmit}
            size="xs"
            data-testid="apply-filters-modal"
        >
            The changes made are not saved until the "Calculate TOBE" is run.
        </Modal>
    );
};

ApplyFiltersModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};
