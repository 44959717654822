import React from 'react';
import { UnorderedList, ListItem, ToastNotification } from '@carbon/react';

export const FailedPublishScenario = ({ scenarioName, onClose, reason }) => {
    return (
        <div className="error_notification--container">
            <ToastNotification
                data-testid="failed-publish-scenario-notification"
                aria-label="closes notification"
                kind="error"
                onClose={onClose}
                onCloseButtonClick={onClose}
                statusIconDescription="Error notification"
                timeout={40000}
                title="Error"
                subtitle={`Could not publish scenario: ${scenarioName}`}
            >
                <div className="failed-scenarios-list">
                    <UnorderedList>
                        <ListItem key={reason}>{reason}</ListItem>
                    </UnorderedList>
                </div>
            </ToastNotification>
        </div>
    );
};
