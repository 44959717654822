import React from 'react';
import { DataTable, Table, TableBody } from '@carbon/react';
import { TableHeaderContent } from './EquationsTableChildren';
import PropTypes from 'prop-types';

export const EmptyTable = ({ headers }) => {
    return (
        <DataTable rows={[]} headers={headers} isSortable id={'Empty-table'}>
            {({ rows, headers, getHeaderProps, getSelectionProps, getTableProps }) => (
                <Table {...getTableProps()}>
                    <TableHeaderContent
                        headers={headers}
                        getHeaderProps={getHeaderProps}
                        getSelectionProps={getSelectionProps}
                    />
                    <TableBody />
                </Table>
            )}
        </DataTable>
    );
};

EmptyTable.propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string,
            key: PropTypes.string.isRequired,
        })
    ),
};
