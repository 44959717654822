import React, { useEffect, useState } from 'react';
import { Email, Password } from '@carbon/react/icons';
import {
    EmailField,
    TextField,
    ButtonUtil,
    PostResponseNotification,
    LinkUtil,
} from '../../utils';
import { Form } from '@carbon/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const RecoveryCodeForm = () => {
    const [email, setEmail] = useState({ value: '' });
    const [recoveryCode, setRecoveryCode] = useState({ value: '' });
    const [notificationData, setNotificationData] = useState({});
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
        })();
    }, []);

    useEffect(() => {
        if (email.invalid === false || email.value.length > 0) {
            setButtonDisabled(false);
        }
    }, [email.value, email.invalid]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        (async () => {
            try {
                const res = await axios.post(
                    '/api/useRecoveryCode',
                    {
                        email: email.value,
                        recoveryCode: recoveryCode.value,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                setNotificationData(res);
                if (res.data.status === 'success') {
                    navigate('/account-profile');
                }
            } catch (err) {
                setNotificationData(err.response);
            } finally {
                setTimeout(() => {
                    setButtonDisabled(false);
                }, '1000');
            }
        })();
    };

    return (
        <Form>
            <h2 className="mb-2"> Use Recovery Code </h2>
            <LinkUtil
                pathKey="accountServices"
                link={{
                    children: 'Go back to login',
                    className: 'me-auto small',
                }}
            />
            <div
                style={{
                    display: 'flex',
                    marginTop: 32,
                }}
            >
                <Email
                    style={{
                        width: 30,
                    }}
                />
                <EmailField
                    textInput={{ ...email, className: '' }}
                    type="emailDomain"
                    validation={true}
                    setState={setEmail}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    marginTop: 32,
                }}
            >
                <Password style={{ width: 30 }} />
                <TextField
                    textInput={{
                        ...recoveryCode,
                        labelText: (
                            <>
                                Recovery Code{' '}
                                <span style={{ color: 'red' }}>* </span>
                            </>
                        ),
                    }}
                    type="token"
                    validation={false}
                    setState={setRecoveryCode}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row-reverse',
                }}
                className="mt-4"
            >
                <ButtonUtil
                    button={{
                        children: 'Log in',
                        disabled:
                            email.invalid === true ||
                            email.value.length === 0 ||
                            buttonDisabled,
                        onClick: handleSubmit,
                        type: 'submit',
                    }}
                />
            </div>
            <PostResponseNotification response={notificationData} />
        </Form>
    );
};
