import React from 'react';
import { Modal, Button, TextInput, Dropdown } from '@carbon/react';
import { Add, TrashCan } from '@carbon/react/icons';
import { useAtom } from 'jotai';
import {
    useConditionalEqns,
    isConditionalEqnsOpenAtom,
    checkConditionalEqnState,
    validVariables,
    validateVariable,
} from './useConditionalEqns';
import { useFormulaTable } from './useFormulaTable';
import { isApprovedScenarioAtom } from '../../../../../../hooks/wizardAtoms';

export const ConditionalEqns = () => {
    const [isConditionalEqnsOpen, setIsConditionalEqnsOpen] = useAtom(isConditionalEqnsOpenAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const [showErrors, setShowErrors] = React.useState(false);
    const {
        state,
        handleTopLevelChange,
        handleConditionChange,
        handleAddCondition,
        handleDeleteCondition,
        handleAddVariable,
        handleReset,
    } = useConditionalEqns({ open: true });
    const { handleUpdateRow } = useFormulaTable(isConditionalEqnsOpen?.activeType);

    return (
        <Modal
            data-testid="conditional-eqns-modal"
            open
            modalHeading="Conditional Equations"
            className="conditional-eqns"
            primaryButtonText="Apply Equations"
            primaryButtonDisabled={isApprovedScenario}
            secondaryButtonText="Cancel"
            size="md"
            preventCloseOnClickOutside
            onRequestClose={() => {
                handleReset();
                setIsConditionalEqnsOpen(null);
            }}
            onRequestSubmit={() => {
                if (!checkConditionalEqnState(state)) {
                    setShowErrors(true);
                    return;
                }
                handleUpdateRow(state.length > 0 ? state : null, isConditionalEqnsOpen?.rowIdx, 'conditionalEqns');
                handleReset();
                setIsConditionalEqnsOpen(null);
            }}
        >
            <div>
                <Button
                    kind="tertiary"
                    size="md"
                    renderIcon={Add}
                    iconDescription="plus sign"
                    disabled={isApprovedScenario}
                    onClick={() => {
                        handleAddVariable();
                        setShowErrors(false);
                    }}
                >
                    Add equation
                </Button>
                {state.map((eqn, eqnIdx) => (
                    <div key={`conditional_eqn_${eqn.id}`} className="d-flex mt-3 mb-3">
                        <div>
                            <TextInput
                                placeholder="X1"
                                labelText="Variable"
                                id={`variable_${eqn.id}`}
                                disabled={isApprovedScenario}
                                value={eqn.variable}
                                invalid={showErrors && !validateVariable(eqn.variable)}
                                invalidText={
                                    eqn.variable.length === 0 ? (
                                        'Required field'
                                    ) : (
                                        <span>
                                            <div>Must be a valid variable</div>
                                            <div>{Array.from(validVariables).join(', ')}</div>
                                        </span>
                                    )
                                }
                                onChange={(e) => {
                                    handleTopLevelChange({ variable: e.target.value.toUpperCase() }, { eqnIdx });
                                }}
                            />
                        </div>
                        <div style={{ width: '100%' }}>
                            {eqn.conditions.map((condition, conditionIdx) => (
                                <div
                                    key={`dropdown_${eqn.id}_${condition.id}`}
                                    className="d-flex ms-2 mb-2"
                                    data-testid="conditional-eqn-dropdown"
                                >
                                    <Dropdown
                                        id={`dropdown_${eqn.id}_${condition.id}`}
                                        className="me-2"
                                        style={{ flex: '1 0 33%' }}
                                        titleText="Comparison"
                                        label=""
                                        items={['>', '<', '==', '>=', '<=']}
                                        disabled={isApprovedScenario}
                                        selectedItem={condition.comparison ?? ''}
                                        placeholder=">"
                                        itemToString={(item) => {
                                            switch (item) {
                                                case '>':
                                                    return '>';
                                                case '<':
                                                    return '<';
                                                case '==':
                                                    return '=';
                                                case '>=':
                                                    return '≥';
                                                case '<=':
                                                    return '≤';
                                                default:
                                                    return item;
                                            }
                                        }}
                                        onChange={({ selectedItem }) => {
                                            const update = { comparison: selectedItem };
                                            handleConditionChange(update, {
                                                conditionIdx,
                                                eqnIdx,
                                            });
                                        }}
                                    />
                                    <TextInput
                                        placeholder="8"
                                        labelText="Right hand side"
                                        value={condition.rhs}
                                        disabled={isApprovedScenario}
                                        id={`rhs_${eqn.id}`}
                                        invalid={showErrors && condition.rhs.length === 0}
                                        invalidText="Required field"
                                        onChange={(e) => {
                                            const update = { rhs: e.target.value };
                                            handleConditionChange(update, {
                                                conditionIdx,
                                                eqnIdx,
                                            });
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                        <TextInput
                            className="ms-2"
                            placeholder="9*X1"
                            labelText="Y="
                            id={`result_${eqn.id}`}
                            disabled={isApprovedScenario}
                            value={eqn.y}
                            invalid={showErrors && eqn.y.length === 0}
                            invalidText="Required field"
                            onChange={(e) => {
                                handleTopLevelChange({ y: e.target.value }, { eqnIdx });
                            }}
                        />
                        <div>
                            {eqn.conditions.map((condition, conditionIdx) => (
                                <div key={`delete_${eqn.id}_${condition.id}`} className="mb-2">
                                    <Button
                                        kind="ghost"
                                        size="md"
                                        disabled={isApprovedScenario}
                                        renderIcon={TrashCan}
                                        iconDescription="trash"
                                        aria-label={
                                            eqn.conditions.length === 1 ? 'remove equation' : 'remove condition'
                                        }
                                        style={{ height: 'max-content' }}
                                        className="mt-4"
                                        onClick={() => {
                                            handleDeleteCondition({ eqnIdx, conditionIdx });
                                        }}
                                    />
                                </div>
                            ))}
                        </div>
                        <Button
                            kind="ghost"
                            size="md"
                            renderIcon={Add}
                            disabled={isApprovedScenario}
                            className="mt-4"
                            iconDescription="plus sign"
                            aria-label="add condition"
                            style={{ float: 'right', height: 'max-content' }}
                            onClick={() => {
                                handleAddCondition({ eqnIdx });
                                setShowErrors(false);
                            }}
                        />
                    </div>
                ))}
            </div>
        </Modal>
    );
};
