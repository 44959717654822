import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import sankey from 'highcharts/modules/sankey';
import organization from 'highcharts/modules/organization';
import { TextAreaSkeleton, useTheme } from '@carbon/react';
import PropTypes from 'prop-types';

sankey(Highcharts);
organization(Highcharts);

/**
 * Window resize listener that returns ref for highcharts
 * @returns {React.MutableRefObject<Highcharts.Chart>}
 */
const useReflowChart = () => {
    const chartRef = useRef(null);

    useEffect(() => {
        const resize = () => {
            chartRef.current?.chart.reflow();
        };

        window.addEventListener('resize', resize);

        return () => window.removeEventListener('resize', resize);
    }, []);

    return chartRef;
};

export const GraphView = function ({ graphData }) {
    const { theme } = useTheme();

    const chartRef = useReflowChart();

    if (!graphData) {
        return (
            <section id="graph-view" className="graph-view">
                No Graph data
            </section>
        );
    }

    if (graphData.length <= 0) {
        return <TextAreaSkeleton />;
    }

    const themeLevels = {
        white: [
            {
                level: 0,
                color: 'silver',
                dataLabels: {
                    color: 'black',
                },
                height: 25,
            },
            {
                level: 1,
                color: '#8f8f8f',
                dataLabels: {
                    color: 'white',
                },
                height: 25,
            },
            {
                level: 2,
                color: '#454545',
            },
        ],
        g90: [
            {
                level: 0,
                color: 'silver',
                dataLabels: {
                    color: 'black',
                },
                height: 25,
            },
            {
                level: 1,
                color: '#8f8f8f',
                dataLabels: {
                    color: 'black',
                },
                height: 25,
            },
            {
                level: 2,
                color: '#454545',
                dataLabels: {
                    color: 'white',
                },
            },
        ],
    };

    const themeBackgroundColor = {
        white: '#ffffff',
        g90: '#262626',
    };

    const graphSeries = graphData.at(0);
    const { nodes } = graphSeries;
    graphSeries.nodes = nodes.map((node) => {
        return {
            ...node,
            height: 80,
        };
    });
    graphSeries.nodeWidth = 200;
    graphSeries.levels = themeLevels[theme];

    const orgChartOptions = {
        title: {
            text: '',
        },
        chart: {
            height: 3 * 250,
            inverted: true,
            backgroundColor: themeBackgroundColor[theme],
        },
        accessibility: {
            enabled: false,
        },
        series: [graphSeries],
        credits: {
            enabled: false,
        },
    };

    return (
        <div>
            <section className="graph-view">
                <HighchartsReact ref={chartRef} highcharts={Highcharts} options={orgChartOptions} />
            </section>
        </div>
    );
};

GraphView.propTypes = {
    graphData: PropTypes.arrayOf(
        PropTypes.shape({
            borderColor: PropTypes.string,
            color: PropTypes.string,
            colorByPoint: PropTypes.bool,
            data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
            dataLabels: PropTypes.shape({
                color: PropTypes.string,
            }),
            keys: PropTypes.arrayOf(PropTypes.string),
            levels: PropTypes.arrayOf(
                PropTypes.shape({
                    level: PropTypes.number,
                    color: PropTypes.string,
                    dataLabels: PropTypes.shape({
                        color: PropTypes.string,
                    }),
                    height: PropTypes.number,
                })
            ),
            name: PropTypes.string,
            nodeWidth: PropTypes.number,
            nodes: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    name: PropTypes.string,
                    height: PropTypes.number,
                })
            ),
            type: PropTypes.string,
        })
    ),
};
