import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ContentSwitcher, InlineNotification, Switch } from '@carbon/react';
import { useAtom } from 'jotai';

import {
    hasPaiReqAtom,
    mxEquationsAtom,
    mxGetZoomedPropertyAtom,
    mxWorkcentersInvalidInputAtom,
    optionBTabs,
} from '../../../../../hooks/mxAtoms';

import { MxTextInput } from '../mxFormGroups/mxInputs/MxTextInput';
import { MxDropdown } from '../mxFormGroups/mxInputs/MxDropdown';
import { MDSFiltersAtom, gsFiltersAtom } from '../../../steps/generalSettings/GeneralSettingsAtoms';
import { OptionA } from './OptionA';
import { AdvancedMxEquations } from './advancedMxEquations/AdvancedMxEquations';
import { useFocusAtom, useScrollIntoViewOnMount } from '../../../../../hooks';
import { createMxModel, saveMxModel } from '../../../maintenanceApiCalls';
import { isApprovedScenarioAtom, scenarioAtom } from '../../../../../hooks/wizardAtoms';

function validateInputFields(...fields) {
    try {
        return fields.every((field) => field?.length > 0);
    } catch (error) {
        console.error(error);
        return false;
    }
}

const mxEquationsTypeEnum = Object.freeze({
    A: 'A',
    B: 'B',
});

const EditMxEquationsPropTypes = {
    open: PropTypes.bool,
    handleCloseEdit: PropTypes.func.isRequired,
    mxModels: PropTypes.arrayOf(
        PropTypes.shape({
            MX_ID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            MODEL_NAME: PropTypes.string,
        })
    ),
    editOption: PropTypes.oneOf(['A', 'B']),
    baselinePai: PropTypes.number,
    modelType: PropTypes.oneOf(['full-time', 'military']).isRequired,
    action: PropTypes.oneOf(['add', 'edit', 'copy']),
    mxId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setModelsUpdated: PropTypes.func.isRequired,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof EditMxEquationsPropTypes>>}
 * @returns {React.ReactElement}
 */
export const EditMxEquations = ({
    open,
    handleCloseEdit,
    mxModels,
    editOption = null,
    baselinePai,
    modelType,
    action = 'add',
    mxId = null,
    setModelsUpdated,
}) => {
    const [gsFilters] = useAtom(gsFiltersAtom);
    const [showExpandedView, setShowExpandedView] = useState(editOption ?? mxEquationsTypeEnum.A);
    const [scenarioId] = useFocusAtom(scenarioAtom, 'scenario_id');
    const [failedSubmit, setFailedSubmit] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [mxEquations] = useAtom(mxEquationsAtom);
    const scrollRef = useScrollIntoViewOnMount();
    const { MDS, CMD, ILC } = gsFilters;
    const [hasPaiReq] = useAtom(hasPaiReqAtom);
    const [invalidInput] = useAtom(mxWorkcentersInvalidInputAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const [MDSFilters] = useAtom(MDSFiltersAtom);
    const [selectedMDS, setSelectedMDS] = useState(MDS);

    const [takenNameError, setTakenNameError] = useState(false);
    const [showRequiredFields, setShowRequiredFields] = useState(false);

    const applyModelChangesButtonText = {
        edit: 'Update Existing Model',
        copy: 'Save as New Model',
        add: 'Create New Model',
    };

    const checkModelNameCollision = () => {
        const { modelName } = mxEquations;
        let filteredModels = action === 'edit' ? mxModels.filter((model) => `${mxId}` !== `${model.MX_ID}`) : mxModels;
        const modelNames = filteredModels.map((model) => model.MODEL_NAME?.toLowerCase?.().trim());
        const modelNamesSet = new Set(modelNames);
        const hasCollision = modelNamesSet.has(modelName.toLowerCase().trim());
        return hasCollision;
    };

    const handleToggleOptionView = ({ name }) => {
        if (editOption === null) {
            setShowExpandedView(name === mxEquationsTypeEnum.A ? mxEquationsTypeEnum.A : mxEquationsTypeEnum.B);
        }
    };

    const scrollRefAfterLoading = React.useCallback(() => {
        // Uses a callback for passing into on mounts for child components
        try {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        } catch (error) {
            console.error(error);
        }
    }, [scrollRef]);

    const filledOutInputFields = validateInputFields(
        MDS,
        CMD,
        mxEquations.modelName,
        mxEquations.source,
        mxEquations.poc
    );
    useEffect(() => {
        if (filledOutInputFields) {
            setFailedSubmit(false);
        }
    }, [filledOutInputFields]);

    const handleSubmit = async (option) => {
        if (!filledOutInputFields) {
            setFailedSubmit(true);
            scrollRef.current?.scrollIntoView?.({ behavior: 'smooth' });
            return;
        }
        const hasCollisions = checkModelNameCollision();
        if (hasCollisions) {
            setTakenNameError(true);
            scrollRef.current?.scrollIntoView?.({ behavior: 'smooth' });
            return;
        }
        try {
            const body = {
                mds: selectedMDS,
                cmd: CMD,
                ilc: ILC,
                model_name: mxEquations.modelName,
                notes: mxEquations.notes,
                source: mxEquations.source,
                poc: mxEquations.poc,
                pai_type: mxEquations.paiAssociation,
                status: 'modified',
                model_type: modelType === 'full-time' ? 'FULLTIME' : 'MILITARY',
                option: {
                    option_a: option.option_a,
                    option_b: option.option_b,
                },
            };
            if (action === 'edit' && mxId) {
                body.mx_id = mxId;
                const { data } = await saveMxModel(body, scenarioId);
                if (data) {
                    setModelsUpdated({ type: 'update', modelName: mxEquations.modelName });
                }
            } else {
                const { data } = await createMxModel(body, scenarioId);
                if (data) {
                    setModelsUpdated({ type: 'create', modelName: mxEquations.modelName });
                }
            }
            await handleCloseEdit(true);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div data-testid="edit-mx-equations">
            <div ref={scrollRef} className="section">
                <div className="input-row">
                    <div className="input-col">
                        <MxDropdown
                            labelText="MDS"
                            items={MDSFilters}
                            value={selectedMDS}
                            onValueChange={setSelectedMDS}
                        />
                    </div>
                    <div className="input-col">
                        <MxDropdown labelText="CMD" items={[CMD]} disabled value={CMD} />
                    </div>
                    <div className="input-col">
                        <MxTextInput
                            type="text"
                            labelText="Model Name"
                            disabled={isApprovedScenario}
                            valueAtom={mxGetZoomedPropertyAtom('modelName')}
                            onChange={() => setTakenNameError(false)}
                            invalidText="Model name must be unique"
                            invalid={takenNameError}
                        />
                    </div>
                    <div className="input-col">
                        <MxTextInput
                            type="text"
                            labelText="Source"
                            disabled={isApprovedScenario}
                            valueAtom={mxGetZoomedPropertyAtom('source')}
                            invalidText="Field required"
                            invalid={failedSubmit && !mxEquations.source?.length > 0}
                        />
                    </div>
                    <div className="input-col">
                        <MxTextInput
                            type="text"
                            labelText="POC"
                            disabled={isApprovedScenario}
                            valueAtom={mxGetZoomedPropertyAtom('poc')}
                            invalidText="Field required"
                            invalid={failedSubmit && !mxEquations.poc?.length > 0}
                        />
                    </div>
                </div>
                <div className="d-flex">
                    <div className="input-col align-self-end" style={{ flexGrow: 1 }}>
                        <MxTextInput
                            type="text"
                            labelText="Notes"
                            disabled={isApprovedScenario}
                            valueAtom={mxGetZoomedPropertyAtom('notes')}
                        />
                    </div>
                    <div className="align-self-end d-flex ms-3">
                        <ContentSwitcher
                            style={{
                                minWidth: 'fit-content',
                                width: 'fit-content',
                                marginRight: 'auto',
                            }}
                            selectedIndex={showExpandedView === mxEquationsTypeEnum.A ? 0 : 1}
                            onChange={handleToggleOptionView}
                        >
                            <Switch
                                name={mxEquationsTypeEnum.A}
                                text="Option A"
                                disabled={editOption === mxEquationsTypeEnum.B}
                                style={{
                                    minWidth: 'fit-content',
                                    width: 'fit-content',
                                }}
                            />
                            <Switch
                                name={mxEquationsTypeEnum.B}
                                text="Option B"
                                disabled={editOption === mxEquationsTypeEnum.A}
                                style={{
                                    minWidth: 'fit-content',
                                    width: 'fit-content',
                                }}
                            />
                        </ContentSwitcher>
                    </div>
                </div>
            </div>
            <div className="divider" />
            {showExpandedView === 'A' ? (
                <OptionA open={open} mxId={mxId} scrollRefAfterLoading={scrollRefAfterLoading} modelType={modelType}>
                    {({ activeWorkcenters }) => (
                        <div className="d-flex flex-column ms-auto">
                            {activeWorkcenters.length > 0 && !hasPaiReq && (
                                <InlineNotification
                                    title=""
                                    subtitle="Please Add Pai Requirement."
                                    hideCloseButton
                                    kind="error"
                                    className="mb-2"
                                />
                            )}
                            <div className="d-flex">
                                <Button className="me-2" kind="tertiary" size="md" onClick={handleCloseEdit}>
                                    Cancel
                                </Button>
                                <Button
                                    kind="primary"
                                    size="md"
                                    disabled={
                                        submitting ||
                                        (activeWorkcenters.length > 0 && !hasPaiReq) ||
                                        invalidInput ||
                                        activeWorkcenters.length === 0 ||
                                        isApprovedScenario
                                    }
                                    onClick={async () => {
                                        const mapWorkcenter = (obj) => {
                                            const result = {
                                                type: '',
                                                mission_type: '',
                                                fac: '',
                                                afsc: '',
                                                osc: '',
                                                wc_title: '',
                                                pai_req: {},
                                            };
                                            for (const property in obj) {
                                                const resultKey = property.toLowerCase();
                                                if (resultKey.includes('pai')) {
                                                    result.pai_req[resultKey] = obj[property] || 0;
                                                } else {
                                                    result[resultKey] = obj[property];
                                                }
                                            }
                                            return result;
                                        };

                                        setSubmitting(true);
                                        await handleSubmit({
                                            option_b: null,
                                            option_a: activeWorkcenters.map((obj) => mapWorkcenter(obj)),
                                        });
                                        setSubmitting(false);
                                    }}
                                >
                                    {applyModelChangesButtonText[action]}
                                </Button>
                            </div>
                        </div>
                    )}
                </OptionA>
            ) : (
                <AdvancedMxEquations
                    scrollRefAfterLoading={scrollRefAfterLoading}
                    mxId={mxId}
                    baselinePai={baselinePai}
                    showRequiredFields={showRequiredFields}
                    disabled={isApprovedScenario}
                >
                    {({ maf, coefficients, tableType, canSubmit = true }) => (
                        <div className="d-flex justify-content-end mt-3">
                            <Button className="me-2" kind="tertiary" size="lg" onClick={handleCloseEdit}>
                                Cancel
                            </Button>
                            <Button
                                kind="primary"
                                size="md"
                                onClick={async () => {
                                    function checkRequiredFields(arr) {
                                        return arr.every(
                                            (row) =>
                                                row.fac &&
                                                row.afsc &&
                                                row.wc_title &&
                                                !isNaN(Number(row.x4)) &&
                                                Number(row.x4) > 0
                                        );
                                    }
                                    function filterRequiredFields(row) {
                                        let conditional_eqs = null;
                                        try {
                                            conditional_eqs = Array.isArray(row.conditionalEqns)
                                                ? row.conditionalEqns.map((eqn) => ({
                                                      y: eqn.y,
                                                      variable: eqn.variable,
                                                      conditions: eqn.conditions.map(
                                                          ({ comparison, rhs }) => `${comparison}${rhs}`
                                                      ),
                                                  }))
                                                : null;
                                        } catch (error) {
                                            console.error(error);
                                            conditional_eqs = null;
                                        }
                                        return {
                                            a: row.A,
                                            b1: row.B1,
                                            b2: row.B2,
                                            b3: row.B3,
                                            b4: row.B4,
                                            x3: row.X3,
                                            x4: row.X4,
                                            min_req: row.MIN_REQ,
                                            fac: row.fac?.toUpperCase(),
                                            afsc: row.afsc?.toUpperCase(),
                                            osc: row.osc?.toUpperCase(),
                                            wc_title: row.wc_title,
                                            conditional_eqs,
                                        };
                                    }
                                    const formattedCoefficients = coefficients.map(filterRequiredFields);
                                    const formattedMaf = isNaN(Number(maf)) ? 0 : Number(maf);
                                    if (!checkRequiredFields(formattedCoefficients) || formattedMaf <= 0) {
                                        setShowRequiredFields(true);
                                        scrollRefAfterLoading();
                                        return;
                                    }
                                    setSubmitting(true);
                                    await handleSubmit({
                                        option_a: null,
                                        option_b: {
                                            //
                                            mission_type: optionBTabs[tableType].text,
                                            maf: formattedMaf,
                                            coefficients: formattedCoefficients,
                                        },
                                    });
                                    setSubmitting(false);
                                }}
                                disabled={!canSubmit || !filledOutInputFields || submitting || isApprovedScenario}
                            >
                                Apply Changes
                            </Button>
                        </div>
                    )}
                </AdvancedMxEquations>
            )}
        </div>
    );
};

EditMxEquations.propTypes = EditMxEquationsPropTypes;
