// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-sections .cds--data-table .cds--popover-container {
  --cds-popover-offset: 0px;
}
.review-sections .cds--data-table .cds--popover-container .cds--btn--ghost {
  border-width: 0;
  color: inherit;
  font-weight: inherit;
  padding: 0;
  cursor: default;
}
.review-sections .cds--data-table .cds--popover-container .cds--btn--ghost:focus {
  box-shadow: none;
  border: none;
}
.review-sections .cds--data-table .cds--popover-container .cds--btn--ghost:hover {
  background: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/wizard/steps/reviewStep/ReviewSections.scss"],"names":[],"mappings":"AAIQ;EACI,yBAAA;AAHZ;AAKY;EACI,eAAA;EACA,cAAA;EACA,oBAAA;EACA,UAAA;EACA,eAAA;AAHhB;AAMY;EACI,gBAAA;EACA,YAAA;AAJhB;AAOY;EACI,gBAAA;AALhB","sourcesContent":[".review-sections {\n\n    .cds--data-table {\n\n        .cds--popover-container {\n            --cds-popover-offset: 0px;\n\n            .cds--btn--ghost {\n                border-width: 0;\n                color: inherit;\n                font-weight: inherit;\n                padding: 0;\n                cursor: default;\n            }\n\n            .cds--btn--ghost:focus {\n                box-shadow: none;\n                border: none;\n            }\n\n            .cds--btn--ghost:hover {\n                background: none;\n            }\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
