import React from 'react';
import PropTypes from 'prop-types';
import {
    DataTable,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
} from '@carbon/react';

const missingUnitsTableProps = {
    units: PropTypes.arrayOf(PropTypes.object).isRequired,
    tableDescription: PropTypes.string.isRequired,
    tableHeader: PropTypes.string.isRequired,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
        }).isRequired
    ),
    ariaLabel: PropTypes.string,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof TableProps>>}
 * @returns {React.ReactElement}
 */
export const MissingUnitsTable = ({ tableDescription, units, tableHeader, ariaLabel, headers }) => {
    return (
        <div className="mt-4 mb-4">
            <DataTable rows={units} headers={headers} isSortable>
                {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getToolbarProps,
                    onInputChange,
                    getTableProps,
                    getTableContainerProps,
                }) => {
                    return (
                        <TableContainer title={tableHeader} {...getTableContainerProps()}>
                            <div className="publish-table-description">
                                <p className="me-4">{tableDescription}</p>
                                <TableToolbar {...getToolbarProps()}>
                                    <TableToolbarContent>
                                        <TableToolbarSearch onChange={onInputChange} />
                                    </TableToolbarContent>
                                </TableToolbar>
                            </div>
                            <Table {...getTableProps()} className="sticky-table-header" aria-label={ariaLabel}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header, i) => (
                                            <TableHeader
                                                key={i}
                                                isSortable
                                                {...getHeaderProps({
                                                    header,
                                                })}
                                            >
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow
                                            key={row.id}
                                            {...getRowProps({
                                                row,
                                            })}
                                        >
                                            {row.cells.map((cell, cellIndex) => (
                                                <TableCell
                                                    style={{ width: headers[cellIndex]?.width ?? 'initial' }}
                                                    key={cell.id}
                                                >
                                                    <strong>{cell.value}</strong>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );
                }}
            </DataTable>
        </div>
    );
};

MissingUnitsTable.propTypes = missingUnitsTableProps;
