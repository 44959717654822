import { atom } from 'jotai';
import { useReducerAtom } from 'jotai/utils';

export const isConditionalEqnsOpenAtom = atom(null);

export const actionTypes = Object.freeze({
    INSERT: 'INSERT',
    UPDATE: 'UPDATE',
    ADD_VARIABLE: 'ADD_VARIABLE',
    DELETE_VARIABLE: 'DELETE_VARIABLE',
    ADD_CONDITION: 'ADD_CONDITION',
    DELETE_CONDITION: 'DELETE_CONDITION',
    RESET: 'RESET',
});

/**
 * @typedef {Object} conditionalEqn
 * @property {string} id
 * @property {string} variable
 * @property {string} y
 * @property {condition[]} conditions
 */

/**
 * @typedef {Object} condition
 * @property {'>' | '<' | '>=' | '<=' | '=='} comparison
 * @property {string} rhs
 */

export const emptyCondition = {
    comparison: '>',
    rhs: '',
};

export const emptyEqn = {
    variable: '',
    conditions: [{ ...emptyCondition }],
    y: '',
};

export const conditionalEqnsAtom = atom(/** @type {conditionalEqn[]} */ ([]));

export const validVariables = new Set(['X1', 'X2', 'X3', 'X4', 'B1', 'B2', 'B3', 'B4', 'A', 'MAF', 'AMU']);

export const validateVariable = (variable) => {
    const parsedVariables = variable.split(/\+|\*|\-|\//);
    return parsedVariables.every((el) => {
        const trimmedEl = el.trim();
        if (trimmedEl === '') return false;
        return validVariables.has(trimmedEl) || !isNaN(Number(trimmedEl));
    });
};

/**
 *
 * @param {conditionalEqn[]} state
 * @returns {boolean}
 */
export function checkConditionalEqnState(state) {
    for (let i = 0; i < state.length; i++) {
        if (validateVariable(state[i].variable) && state[i].y?.length > 0 && state[i].conditions.length > 0) {
            if (state[i].conditions.every((condition) => condition.rhs?.length > 0)) {
                continue;
            }
        }
        return false;
    }
    return true;
}

/**
 *
 * @param {conditionalEqn[]} state
 * @param {any} action
 * @returns
 */
const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.INSERT:
            return [
                ...action.payload.map((eqn) => ({
                    id: Math.random().toString().replace('.', '_'),
                    ...eqn,
                    conditions: eqn.conditions.map((condition) => ({
                        id: Math.random().toString(),
                        ...condition,
                    })),
                })),
            ];
        case actionTypes.UPDATE:
            try {
                const { idx } = action.meta;
                const newState = [...state];
                newState[idx] = {
                    ...emptyEqn,
                    ...state[idx],
                    ...action.payload,
                };
                return [...newState];
            } catch (error) {
                return [...state];
            }
        case actionTypes.ADD_VARIABLE:
            return [
                ...state,
                {
                    ...emptyEqn,
                    id: '' + state.length,
                },
            ];
        case actionTypes.ADD_CONDITION:
            const { idx } = action.meta;
            const newState = [...state];
            newState[idx] = {
                ...emptyEqn,
                ...state[idx],
                conditions: [
                    ...state[idx].conditions,
                    {
                        ...emptyCondition,
                        id: Math.random().toString().replace('.', '_'),
                    },
                ],
            };
            return [...newState];
        case actionTypes.DELETE_CONDITION:
            try {
                const { idx } = action.meta;
                const newState = [...state];
                const list = [...newState[idx].conditions];
                list.splice(action.payload, 1);
                if (list.length === 0) {
                    newState.splice(idx, 1);
                    return [...newState];
                }
                newState[idx] = {
                    ...newState[idx],
                    conditions: list,
                };
                return [...newState];
            } catch (error) {
                console.error(error);
                return [...state];
            }
        case actionTypes.DELETE_VARIABLE:
            try {
                const list = [...state];
                list.splice(action.payload, 1);
                return [...list];
            } catch (error) {
                console.error(error);
                return [...state];
            }
        case actionTypes.RESET:
            return [];
        default:
            return [...state];
    }
};

export const useConditionalEqns = (options = {}) => {
    const [state, dispatch] = useReducerAtom(conditionalEqnsAtom, reducer);

    const insertConditionalEqn = (eqn) => {
        dispatch({
            type: actionTypes.INSERT,
            payload: eqn,
        });
    };
    const handleTopLevelChange = (update, { eqnIdx }) => {
        const eqn = {
            ...state[eqnIdx],
            ...update,
        };
        dispatch({
            type: actionTypes.UPDATE,
            payload: eqn,
            meta: { idx: eqnIdx },
        });
    };

    const handleConditionChange = (update, { conditionIdx, eqnIdx }) => {
        const eqn = state[eqnIdx];
        const updatedConditions = [...eqn.conditions];
        updatedConditions[conditionIdx] = {
            ...updatedConditions[conditionIdx],
            ...update,
        };
        eqn.conditions = updatedConditions;
        dispatch({
            type: actionTypes.UPDATE,
            payload: eqn,
            meta: { idx: eqnIdx },
        });
    };

    const handleAddCondition = ({ eqnIdx }) => {
        dispatch({
            type: actionTypes.ADD_CONDITION,
            meta: { idx: eqnIdx },
        });
    };

    const handleDeleteCondition = ({ eqnIdx, conditionIdx }) => {
        dispatch({
            type: actionTypes.DELETE_CONDITION,
            payload: conditionIdx,
            meta: { idx: eqnIdx },
        });
    };

    const handleAddVariable = () => {
        dispatch({ type: actionTypes.ADD_VARIABLE });
    };

    const handleReset = () => {
        dispatch({ type: actionTypes.RESET });
    };
    return {
        state,
        dispatch,
        insertConditionalEqn,
        handleConditionChange,
        handleAddCondition,
        handleTopLevelChange,
        handleDeleteCondition,
        handleAddVariable,
        handleReset,
    };
};
