import React from 'react';
import { ComboBox } from '@carbon/react';
import { useAtom, useSetAtom } from 'jotai';

import {
    writeUnitDetailsAtom,
    unitDetailsOptionsAtom,
    getUnitDetailsOptionType,
    unitDetailsAtom,
} from './UnitDetailsAtoms';

import { useFocusAtom } from '../../../../hooks';
import { comboBoxShouldFilterItem } from '../../../../helpers';
import PropTypes from 'prop-types';
import { isApprovedScenarioAtom } from '../../../../hooks/wizardAtoms';

export const UDDropdownSelect = ({
    titleText,
    labelText,
    className = '',
    disableMXOF_PAS = false,
    resetUnitDetailsPas = () => {},
}) => {
    const [{ options }] = useFocusAtom(unitDetailsOptionsAtom, getUnitDetailsOptionType(labelText));
    const [selected] = useFocusAtom(unitDetailsAtom, labelText);
    const setUnitDetailsSelection = useSetAtom(writeUnitDetailsAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);

    const handleSelect = ({ selectedItem }) => {
        if (selectedItem === null) {
            resetUnitDetailsPas(labelText);
        }
        setUnitDetailsSelection({
            [labelText]: selectedItem ? selectedItem : '',
        });
    };

    const hasOptions = Array.isArray(options) && options.length > 0;

    const disableMxofPasBox = disableMXOF_PAS && labelText === 'MXOF_PAS';

    return (
        <div
            className={`pb-3 col ${className}`}
            id={`container_${labelText}`}
            data-qa="ud-dropdown-select"
            data-testid={`container_${labelText}`}
        >
            <ComboBox
                items={hasOptions ? options : []}
                id={`uddropdown_${labelText}`}
                titleText={titleText ? titleText : labelText.replaceAll('_', ' ')}
                placeholder={hasOptions ? 'Please Select ...' : 'None'}
                selectedItem={selected ?? ''}
                onChange={handleSelect}
                shouldFilterItem={comboBoxShouldFilterItem}
                disabled={(!hasOptions && !selected) || disableMxofPasBox || isApprovedScenario}
            />
        </div>
    );
};

UDDropdownSelect.propTypes = {
    titleText: PropTypes.string,
    labelText: PropTypes.string,
    className: PropTypes.string,
    disableMXOF_PAS: PropTypes.bool,
    resetUnitDetailsPas: PropTypes.func,
};
