import { useCallback, useEffect, useState } from 'react';
import { IssuesTable } from './IssuesTable';
import { fetchIssues } from './issuesApiCalls';

export const IssuesReportedTab = () => {
    const [issues, setIssues] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const populateIssues = useCallback(async function () {
        setIsLoading(true);
        const { success, data } = await fetchIssues();
        if (success) {
            setIssues(data);
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (issues.length < 1) {
            populateIssues();
        }
    }, [issues.length, populateIssues]);

    return (
        <div className="issues-reported-tab" data-testid="issues-table">
            <p>Issues Reported on UMP</p>
            <IssuesTable issues={issues} onSetIssues={setIssues} />
        </div>
    );
};
