// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-tile {
  height: 40vh;
  min-width: 130px;
  cursor: pointer;
  background: linear-gradient(-225deg, rgb(56, 96, 168), rgb(32, 146, 128));
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  text-decoration: none !important;
  margin: 0.4em;
  width: 300px;
}

.sticky-table-header > thead {
  position: sticky;
  top: 0;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/Admin.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,eAAA;EACA,yEAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;EACA,gCAAA;EACA,aAAA;EACA,YAAA;AACJ;;AAEA;EACI,gBAAA;EACA,MAAA;EACA,UAAA;AACJ","sourcesContent":[".admin-tile {\n    height: 40vh;\n    min-width: 130px;\n    cursor: pointer;\n    background: linear-gradient(-225deg, rgb(56, 96, 168), rgb(32, 146, 128));\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white !important;\n    text-decoration: none !important;\n    margin: .4em;\n    width: 300px;\n}\n\n.sticky-table-header>thead {\n    position: sticky;\n    top: 0;\n    z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
