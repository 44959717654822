import React, { useRef, useEffect } from 'react';

/**
 * Creates a mutable ref to be passed.
 * Calls on the element scrollIntoView after the component mounts.
 * @returns {React.MutableRefObject<HTMLElement>} React mutable ref
 */
export const useScrollIntoViewOnMount = () => {
    const ref = useRef(null);

    useEffect(() => {
        try {
            if (ref.current) {
                ref.current.scrollIntoView?.({ behavior: 'smooth' });
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    return ref;
};
