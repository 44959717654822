import { atom } from 'jotai';

// ops Atoms
export const opsAsisRowsAtom = atom([]);
export const opsTobeRowsAtom = atom([]);

// opsEquations atoms
export const opsEquationsModalAtom = atom({
    selectedCalculation: null,
    models: [],
});

export const opsEquationsModelsAtom = atom({
    api: [],
    deletions: {},
    new: [],
    selections: {},
    modelId: 0,
});

// opsPaginationAtom

export const opsAsisPaginationAtom = atom(
    localStorage.getItem('pagination-opsAsis')
        ? JSON.parse(localStorage.getItem('pagination-opsAsis'))
        : { page: 1, pageSize: 10 }
);
export const opsTobePaginationAtom = atom(
    localStorage.getItem('pagination-opsTobe')
        ? JSON.parse(localStorage.getItem('pagination-opsTobe'))
        : { page: 1, pageSize: 10 }
);

export const opsScenarioAtom = atom([]);

// opsAsis selection atom to save selected state
export const opsAsisSelectionsAtom = atom({});

export const opsTooltipsAtom = atom({
    facTitles: {},
    pecTitles: {},
    ricTitles: {},
});

export const insertOpsTooltipsAtom = atom(null, (get, set, update) => {
    const opsTooltips = get(opsTooltipsAtom);
    set(opsTooltipsAtom, {
        facTitles: {
            ...opsTooltips.facTitles,
            ...(update.facTitles ?? {}),
        },
        pecTitles: {
            ...opsTooltips.pecTitles,
            ...(update.pecTitles ?? {}),
        },
        ricTitles: {
            ...opsTooltips.ricTitles,
            ...(update.ricTitles ?? {}),
        },
    });
});

export const opsSelectedDropdownDataAtom = atom({ API: [], FAC: [], MSI: [] });
