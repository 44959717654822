import { useState } from 'react';
import { Button, ComboBox, Modal } from '@carbon/react';
import { useAtom } from 'jotai';
import { selectedMPESAtom, MPESList, MPESDefault } from '../../../../hooks/wizardAtoms';
import './SelectMPESModal.scss';

export const SelectMPESModal = ({ open, onClose }) => {
    const [selectedMPES, setSelectedMPES] = useAtom(selectedMPESAtom);
    const [localMPES, setLocalMPES] = useState(selectedMPES);

    return (
        <Modal
            open={open}
            size="xs"
            className="select-mpes-modal"
            onRequestClose={onClose}
            onRequestSubmit={() => {
                setSelectedMPES(localMPES !== MPESDefault ? localMPES : null);
                onClose();
            }}
            secondaryButtonText="Cancel"
            onSecondarySubmit={onClose}
            primaryButtonText="Submit"
            modalLabel="Select MPES Year"
            data-testid="mpes-year-modal"
        >
            <div className="py-4">
                <ComboBox
                    id="mpes-year-dropdown"
                    titleText="MPES Year"
                    label=""
                    items={MPESList}
                    selectedItem={localMPES || MPESDefault}
                    onChange={({ selectedItem }) => {
                        setLocalMPES(selectedItem || null);
                    }}
                />
                <div className="pt-2 d-flex justify-content-end">
                    <Button kind="tertiary" size="sm" onClick={() => setLocalMPES(MPESDefault)}>
                        Reset
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
