import { atom } from 'jotai';

export const initialReviewFiltersAtom = {
    MDS: '',
    ILC: '',
    CMD: '',
};

export const scenariosAtom = atom([]);

export const selectedScenariosAtom = atom('');

export const reviewFiltersAtom = atom(initialReviewFiltersAtom);

export const initialReviewTables = {
    generalSettings: [],
    unitDetailsOps: {
        pas: '',
        pec: [],
        units: [],
    },
    unitDetailsMx: {
        pas: '',
        pec: [],
        units: [],
    },
    opsAsis: [],
    opsTobe: [],
    mxAsis: [],
    mxTobe: [],
};
export const reviewTablesAtom = atom(initialReviewTables);

export const writeReviewTablesAtom = atom(null, (get, set, update) => {
    const reviewTablesState = get(reviewTablesAtom);

    set(reviewTablesAtom, {
        ...reviewTablesState,
        ...update,
    });
});

export const tooltipsAtom = atom({
    PEC: {},
    FAC: {},
    RIC: {},
});

export const updateTooltipsAtom = atom(null, (get, set, update) => {
    const tooltips = get(tooltipsAtom);

    set(tooltipsAtom, {
        PEC: {
            ...tooltips.PEC,
            ...update.PEC,
        },
        FAC: {
            ...tooltips.FAC,
            ...update.FAC,
        },
        RIC: {
            ...tooltips.RIC,
            ...update.RIC,
        },
    });
});
