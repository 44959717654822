import { Breadcrumb, BreadcrumbItem } from '@carbon/react';
import { Link } from 'react-router-dom';
import { ThemeButton } from './ThemeButton';
import { ScenarioDropdown } from './ScenarioDropdown';
import { ShareButton } from './ShareButton';
import { OpenScenarioDialogButton } from '../pages/wizard/modals/updateScenarioDialog/OpenScenarioDialogButton';
import { OpenUmpFlowButton } from '../pages/wizard/modals/umpFlow/OpenUmpFlowButton';
import { DownloadUserGuide } from '../pages/wizard/DownloadUserGuide';
import { CUSTOM_THEME } from '../pages/wizard/constants';

export const PageHeader = ({ changeTheme, customTheme }) => {
    const kind = customTheme === CUSTOM_THEME.DARK ? 'primary' : 'tertiary';

    return (
        <div className="page-header">
            <div>
                <div className="breadcrumbs">
                    <Breadcrumb data-testid="page-header-breadcrumbs">
                        <BreadcrumbItem>
                            <Link to="/">Home</Link>
                        </BreadcrumbItem>
                        <BreadcrumbItem isCurrentPage>Manpower</BreadcrumbItem>
                    </Breadcrumb>
                </div>
                <ScenarioDropdown />
            </div>
            <div className="page-actins d-flex align-items-end" data-testid="page-header-action-btns">
                <DownloadUserGuide kind={kind} />
                <OpenUmpFlowButton kind={kind} />
                <OpenScenarioDialogButton kind={kind} />
                <ShareButton kind={kind} />
                <ThemeButton changeTheme={changeTheme} customTheme={customTheme} />
            </div>
        </div>
    );
};
