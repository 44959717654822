import { ToastNotification } from '@carbon/react';
import PropTypes from 'prop-types';

import './ErrorNotification.scss';
import { useErrorNotification } from './useErrorNotification';

const errorNotificationProps = {
    onClose: PropTypes.func,
    timeout: PropTypes.number,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof errorNotificationProps>>}
 * @returns {React.ReactElement}
 */
export const ErrorNotification = ({ onClose, timeout = 8000, ...otherToastNotificationProps }) => {
    const { isOpen, errorMessage, closeError } = useErrorNotification();

    const handleCloseError = () => {
        closeError();
        onClose?.();
    };

    return (
        <div className="error_notification--container">
            {isOpen ? (
                <ToastNotification
                    aria-label="closes notification"
                    kind={errorMessage.kind}
                    onClose={handleCloseError}
                    onCloseButtonClick={handleCloseError}
                    statusIconDescription={`${errorMessage.kind} notification`}
                    timeout={timeout}
                    title={errorMessage.title}
                    subtitle={errorMessage.subtitle}
                    {...otherToastNotificationProps}
                />
            ) : null}
        </div>
    );
};

ErrorNotification.propTypes = errorNotificationProps;
