import axios from 'axios';

export const submitReport = async ({ screenshots, files, ...body }) => {
    try {
        const screenshotKeys = [];
        const fileKeys = [];
        if (screenshots.length > 0 || files.length > 0) {
            const formData = new FormData();
            Object.entries(body).forEach(([key, value]) => {
                formData.append(key, value);
            });
            screenshots.forEach((file, index) => {
                const fileTypes = file.name.split('.');
                const fileSuffix = `${fileTypes[fileTypes.length - 1]}`;
                formData.append(`screenshot_${index}.${fileSuffix}`, file);
                screenshotKeys.push(`screenshot_${index}.${fileSuffix}`);
            });
            files.forEach((file, index) => {
                const fileTypes = file.name.split('.');
                const fileSuffix = `${fileTypes[fileTypes.length - 1]}`;
                formData.append(`file_${index}.${fileSuffix}`, file);
                fileKeys.push(`file_${index}.${fileSuffix}`);
            });
            formData.append('screenshotKeys', JSON.stringify(screenshotKeys));
            formData.append('fileKeys', JSON.stringify(fileKeys));

            await axios.post('/api/fast-api/issue/report_issue', formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
        } else {
            await axios.post('/api/fast-api/issue/report_issue', body);
        }
        return {
            success: true,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
};

/**
 * Fetching issues
 * @returns {Promise<{
 *  success: boolean,
 *  data: null | any,
 *  error: null | Error
 * }>}
 */

export const fetchIssues = async () => {
    try {
        const { status, data } = await axios.post('/api/fast-api/issue/list_issues', null);

        const issues = data.issues.map((issue) => {
            return {
                ...issue,
                downloadLinks: data.downloadLinks.filter((links) => links.id === issue.ISSUE_ID),
                id: `${issue.ISSUE_ID}`,
            };
        });

        return {
            success: status === 200,
            error: null,
            data: issues,
        };
    } catch (error) {
        return {
            success: false,
            data: null,
            error,
        };
    }
};

/**
 * Update issue
 *
 * @param {{
 *  cmd: "string",
 *  type: "string",
 *  scenario_name: "string",
 *  ump_page: "string",
 *  brief_desc: "string",
 *  detailed_desc: "string",
 *  steps_to_reproduce: "string"
 * }} body
 * @param {number} issueId
 * @returns {Promise<{
 *  isOk: boolean,
 *  error: null | Error
 * }>}
 */

export const updateIssue = async ({ body, issueId }) => {
    try {
        await axios.post('/api/fast-api/issue/update_issue', body, {
            params: {
                issue_id: issueId,
            },
        });

        return {
            isOk: true,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            error,
        };
    }
};
