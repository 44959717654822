import { useParams } from 'react-router-dom';

/**
 * pass in array of url slugs
 * @param {string[]} pathOrder
 * @returns {{
 *      index: number,
 *      slug: string,
 *      previousSlug: string,
 *      nextSlug: string,
 *      isFirst: boolean,
 *      isLast: boolean,
 * }}
 */
export const useCurrentStep = (pathOrder) => {
    const urlSlug = useParams()['*']?.toLowerCase();
    const index = urlSlug ? pathOrder.indexOf(urlSlug) || 0 : 0;
    const slug = pathOrder[index];
    const previousSlug = pathOrder[index - 1];
    const nextSlug = pathOrder[index + 1];

    return {
        index,
        slug,
        previousSlug,
        nextSlug,
        isFirst: previousSlug === undefined,
        isLast: nextSlug === undefined,
    };
};
