import { useState } from 'react';
import './Styles/LoginSMS.scss';
import { Button, ComposedModal, ModalBody, ModalFooter } from '@carbon/react';
import { PostResponseNotification, PhoneField } from '../../utils';

export const LoginSMS = (props) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [notificationData, setNotificationData] = useState({});
    const sendCode = () => {
        //add text logic here
        setModalIsOpen(true);
    };
    const formatPhoneNumber = (val) => {
        if (val.length > 6) {
            return `+1 (${val.substr(0, 3)}) ${val.substr(3, 3)}-${val.substr(6)}`;
        } else if (val.length > 3) {
            return `+1 (${val.substr(0, 3)}) ${val.substr(3)}`;
        }
        return '+1 ' + val;
    };
    const handlePaste = (e) => {
        let cleanedNum = e.clipboardData.getData('text').replace(/\D/g, '').substr(0, 6);
        cleanedNum ? (cleanedNum = cleanedNum.split('')) : (cleanedNum = ['']);
        const originalLength = cleanedNum.length;
        cleanedNum.length = 6;
        cleanedNum.fill('', originalLength, 6);

        setCode(cleanedNum);
    };
    const swapInput = (e, index) => {
        const form = e.target.form;
        if (e.key !== 'Backspace') {
            if (!isNaN(e.key)) {
                setCode((code) => {
                    const newCode = [...code];
                    newCode[index] = e.key;
                    return newCode;
                });
                if (index < form.elements.length - 1) {
                    form.elements[index + 1].focus();
                }
            }
        } else {
            setCode((code) => {
                const newCode = [...code];
                newCode[index] = '';
                return newCode;
            });
            if (index > 0) {
                form.elements[index - 1].focus();
            }
        }
    };
    const validateCode = () => {
        setNotificationData({ title: '', subtitle: '' });
        let codeSent = '123456'; // dummy data
        let codeString = code.join('');
        let notification_data;
        if (codeString !== codeSent) {
            setCode(['', '', '', '', '', '']);
            notification_data = {
                title: 'Error',
                subtitle: 'Code not valid. Please resend code.',
            };
            setNotificationData({ ...notification_data });
        } else {
            setModalIsOpen(false);
        }
    };

    return (
        <>
            <div className="loginSMS">
                <h1>Log in with Your Phone</h1>
                <PhoneField
                    textInput={{ id: 'phone-number-input', className: 'my-4', 'data-testid': 'login-sms-phone-field' }}
                    isInvalid={isInvalid}
                    setIsInvalid={setIsInvalid}
                    setPhoneNumber={setPhoneNumber}
                    phoneNumber={phoneNumber}
                />
                <Button onClick={sendCode} disabled={phoneNumber.length !== 10}>
                    Send Code
                </Button>
            </div>

            <ComposedModal className="phone-code-modal" open={modalIsOpen} onClose={() => setModalIsOpen(false)}>
                <ModalBody>
                    <h4>Enter the six digit code sent to {formatPhoneNumber(phoneNumber)}</h4>
                    <div className="lds-ellipsis">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <div className="notificationHeight">
                        <PostResponseNotification response={notificationData} inlineNotification={notificationData} />
                    </div>
                    <form onPaste={handlePaste} data-testid="login-sms-form">
                        <input
                            maxLength="1"
                            onKeyUp={(e) => swapInput(e, 0)}
                            onChange={() => {}}
                            value={code[0]}
                            className="single-digit-input"
                        />
                        <input
                            maxLength="1"
                            onKeyUp={(e) => swapInput(e, 1)}
                            onChange={() => {}}
                            value={code[1]}
                            className="single-digit-input"
                        />
                        <input
                            maxLength="1"
                            onKeyUp={(e) => swapInput(e, 2)}
                            onChange={() => {}}
                            value={code[2]}
                            className="single-digit-input"
                        />
                        <input
                            maxLength="1"
                            onKeyUp={(e) => swapInput(e, 3)}
                            onChange={() => {}}
                            value={code[3]}
                            className="single-digit-input"
                        />
                        <input
                            maxLength="1"
                            onKeyUp={(e) => swapInput(e, 4)}
                            onChange={() => {}}
                            value={code[4]}
                            className="single-digit-input"
                        />
                        <input
                            maxLength="1"
                            onKeyUp={(e) => swapInput(e, 5)}
                            onChange={() => {}}
                            value={code[5]}
                            className="single-digit-input"
                        />
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button kind="secondary">Resend Code</Button>
                    <Button onClick={validateCode} kind="primary" disabled={code.join('').length !== 6}>
                        Submit
                    </Button>
                </ModalFooter>
            </ComposedModal>
        </>
    );
};
