import React, { createRef, useEffect, useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { Button, Loading, Modal, InlineNotification } from '@carbon/react';
import { useQuery } from '@tanstack/react-query';

import { UDDropdowns } from './UDDropdowns';
import { GraphView } from './GraphView';

import { fsTableMessageAtom, gsFiltersAtom } from '../generalSettings/GeneralSettingsAtoms';
import {
    checkUnitDetailsCompletedAtom,
    insertDataIntoUnitDetailsAtom,
    unitDetailsAtom,
    unitDetailsFiltersAtom,
    udColumn1,
    udColumn2,
    udColumn3,
    udColumn4,
    udColumn5,
    selectedMxgPecsAtom,
    selectedOgPecsAtom,
    unitDetailsInitialState,
} from './UnitDetailsAtoms';
import { fetchUnitDetails, fetchUnitDetailsBaseline } from '../../../../httpRequests/unitOrgChart';

import { useUpdateWizardStepValid } from '../../../../hooks/useUpdateWizardStepValid';
import { useSaveScenarioAtomOnUnmount } from '../../../../hooks/useSaveAtomOnUnmount';
import { useResetPecSelections } from './useResetPecSelections';
import { isApprovedScenarioAtom } from '../../../../hooks/wizardAtoms';
import { useSaveScenario } from '../../../../hooks/useSaveScenario';

/**
 * Sort data from fastapi
 * @param {{[key: string]: string[]}} optionsToSort
 * @returns sortedOptionsToSort
 */
function sortOptions(optionsToSort) {
    const keys = Object.keys(optionsToSort);
    const result = { ...optionsToSort };
    keys.forEach((key) => {
        result[key].sort((a, b) => {
            const aWithoutNumbers = a.slice(a.indexOf(' ') + 1) || '';
            const bWithoutNumbers = b.slice(b.indexOf(' ') + 1);
            return aWithoutNumbers.localeCompare(bWithoutNumbers);
        });
    });
    return result;
}

export const UnitDetails = () => {
    const [graphData, setGraphData] = useState([]);
    const [mxSelectItems, setMxSelectItems] = useState([]);
    const [opsSelectItems, setOpsSelectItems] = useState([]);
    const [isGraphModalOpen, setIsGraphModalOpen] = useState(false);
    const [unitDetailsError, setUnitDetailsError] = useState('');

    const [unitDetailsValidation] = useAtom(checkUnitDetailsCompletedAtom);
    const [fetchedFilters, setFetchedFilters] = useAtom(unitDetailsFiltersAtom);
    const [gsFilters, setGsFilters] = useAtom(gsFiltersAtom);
    const [selectedMxgPecs, setSelectedMxgPecs] = useAtom(selectedMxgPecsAtom);
    const [selectedOgPecs, setSelectedOgPecs] = useAtom(selectedOgPecsAtom);
    const [unitDetails, setUnitDetails] = useAtom(unitDetailsAtom);
    const [fsTableMessage] = useAtom(fsTableMessageAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const { isSaving } = useSaveScenario();

    const insertUnitDetailsData = useSetAtom(insertDataIntoUnitDetailsAtom);

    const { allFiltersSelected, hasMxgPasUnits, hasOgPasUnits, flyingSquadronsMatch } = unitDetailsValidation;

    useUpdateWizardStepValid('unitDetails', allFiltersSelected && flyingSquadronsMatch);

    const { MDS, ILC, CMD } = gsFilters;

    useSaveScenarioAtomOnUnmount({ isValid: true });

    const filledOutGSInputs = MDS !== '' && ILC !== '' && CMD !== '';

    const noResults =
        fsTableMessage === 'Please make a selection for MDS, ILC, and CMD.' || fsTableMessage === 'No results found';

    const queryFn = noResults || unitDetailsError !== '' ? fetchUnitDetailsBaseline : fetchUnitDetails;

    const { isFetched, isFetching, refetch } = useQuery({
        queryKey: ['unit_details', gsFilters, isSaving],
        queryFn: queryFn,
        onSuccess: (response) => {
            const { data } = response;
            insertUnitDetailsData(sortOptions(data.UNIT_DETAILS));
            setGraphData(data.GRAPH);
            setMxSelectItems(data.MX_PECS);
            setOpsSelectItems(data.OPS_PECS);
            setFetchedFilters({ MDS, ILC, CMD });
            setUnitDetailsError('');
        },
        retry: false,
        enabled: filledOutGSInputs && !isSaving,
        refetchOnWindowFocus: false,
        onError: (error) => {
            setUnitDetailsError(error.message);
            console.error(error);
        },
    });

    useEffect(() => {
        const hasGeneralSettings =
            MDS !== fetchedFilters.MDS || ILC !== fetchedFilters.ILC || CMD !== fetchedFilters.CMD;

        const hasMds = MDS.length > 0;

        if (hasGeneralSettings && hasMds) {
            refetch();
        }
    }, [fetchedFilters.MDS, fetchedFilters.ILC, fetchedFilters.CMD, MDS, ILC, CMD, refetch]);

    useEffect(() => {
        if (unitDetailsError !== '') {
            refetch();
        }
    }, [unitDetailsError, refetch]);

    const hasMxgPas = unitDetails.MXG_PAS !== '';
    const hasOgPas = unitDetails.OG_PAS !== '';
    const hasSelectedRequiredOgPas = hasOgPas && hasOgPasUnits;
    const hasSelectedRequiredMxgPas = hasMxgPas && hasMxgPasUnits;

    const handleReset = () => {
        setUnitDetails(unitDetailsInitialState);
        setSelectedOgPecs([]);
        setSelectedMxgPecs([]);
        refetch();
    };

    useResetPecSelections({
        hasSelectedRequiredOgPas,
        hasSelectedRequiredMxgPas,
        setSelectedOgPecs,
        setSelectedMxgPecs,
    });

    if (!filledOutGSInputs && !isFetching) {
        return (
            <InlineNotification
                title="Missing General Settings Selections"
                subtitle="Please select all fields in General Settings"
                hideCloseButton
                kind="error"
                data-qa="navigate-to-general-settings"
            />
        );
    }

    if (isFetching || !isFetched) {
        return <Loading data-qa="loading-spinner" />;
    }
    return (
        <div className="unit-details" data-qa="unit-details-page">
            <div className="position-relative">
                <p className="unit-details-source bx--label">Source: MPES / UMD 2022-Nov</p>
                <UDDropdowns
                    data-qa="unit-details-dropdowns"
                    column1={udColumn1}
                    column2={udColumn2}
                    column3={udColumn3}
                    column4={udColumn4}
                    column5={udColumn5}
                    disableMXOF_PAS={CMD !== 'ANG - AIR NATIONAL GUARD'}
                    mxSelectItems={mxSelectItems}
                    opsSelectItems={opsSelectItems}
                    setSelectedMxgPecs={setSelectedMxgPecs}
                    selectedMxgPecs={selectedMxgPecs}
                    setSelectedOgPecs={setSelectedOgPecs}
                    selectedOgPecs={selectedOgPecs}
                    setGsFilters={setGsFilters}
                    hasSelectedRequiredOgPas={hasSelectedRequiredOgPas}
                    hasSelectedRequiredMxgPas={hasSelectedRequiredMxgPas}
                />
                <div className="d-flex justify-content-between">
                    <Button
                        onClick={() => setIsGraphModalOpen(!isGraphModalOpen)}
                        data-qa="unit-details-display-graph-btn"
                    >
                        Display OrgChart
                    </Button>
                    <Button
                        onClick={() => handleReset()}
                        size="md"
                        disabled={isApprovedScenario}
                        className="reset-button"
                        kind="ghost"
                        data-qa="unit-details-reset-btn"
                    >
                        Reset
                    </Button>
                </div>

                <Modal
                    open={isGraphModalOpen}
                    isFullWidth
                    size="lg"
                    passiveModal
                    onRequestClose={() => setIsGraphModalOpen(!isGraphModalOpen)}
                    className="graphViewModal"
                    data-qa="unit-details-graph-modal"
                >
                    <GraphView graphData={graphData} />
                </Modal>
            </div>
        </div>
    );
};
