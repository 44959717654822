import { solveRegistrationChallenge, solveLoginChallenge } from '@webauthn/client';
import { useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

/**
 * Handler for Yuibkey registration button.
 * Sets TFAMethods yubikey value to true if api response is successful
 */
export const useOnClickYubikeyRegister = (setNotificationData, setTFAMethods) => {
    const navigate = useNavigate();

    const onClickYubikeyRegister = useCallback(async () => {
        try {
            const reqRegResponse = await axios.post(
                '/api/registerYubikey',
                { temp: 'temp' },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                    credentials: 'include',
                }
            );
            const credentials = await solveRegistrationChallenge(reqRegResponse.data);
            const confirmRegRes = await axios.post('/api/registerYubikeyConfirm', credentials, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                credentials: 'include',
            });
            console.log(confirmRegRes);
            if (confirmRegRes.data == 'yubikey success')
                setTFAMethods((state) => {
                    let newState = state;
                    newState.yubiKey.value = 'true';
                    return newState;
                });
        } catch (err) {
            console.log(err.response.data);
            if (err.response.data.status === 'unauthorized') navigate('/');
            else setNotificationData(err.response);
        }
    }, [navigate, setNotificationData, setTFAMethods]);

    return onClickYubikeyRegister;
};

/**
 * Handler for yubikey authentication button
 * Naivgates to account profile on successful api response
 */

export const useOnClickYubikeyAuth = (setNotificationData) => {
    const navigate = useNavigate();
    const onClickYubikeyAuth = useCallback(async () => {
        try {
            const yubikeyAuthResponse = await axios.post(
                '/api/yubikeyAuth',
                { temp: 'temp' },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                    credentials: 'include',
                }
            );
            console.log(yubikeyAuthResponse.data);
            const credentials = await solveLoginChallenge(yubikeyAuthResponse.data);
            const confirmAuthRes = await axios.post('/api/yubikeyAuthConfirm', credentials, {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                credentials: 'include',
            });
            console.log(confirmAuthRes.data);
            if (confirmAuthRes.data.verified) {
                navigate(confirmAuthRes.data.redirectURL || '/account-profile');
            }
        } catch (err) {
            console.log(err.response);
            if (err.response === undefined) setNotificationData({ status: 'authFail' });
            else if (err.response.data.status === 'unauthorized') navigate('/');
            else setNotificationData(err.response);
        }
    }, [navigate, setNotificationData]);

    return onClickYubikeyAuth;
};
