import React, { useCallback } from 'react';
import { ColumnsLayout } from '../utils';
import './GuardianPanelDark.scss';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import { particleOptions } from '../config/particleOptions';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
export function GuardianPanelDark({ element }) {
    const particlesInit = useCallback(async (engine) => {
        await loadFull(engine);
    }, []);

    return (
        <div id="main-guardian-dark-container" className="h-100">
            <div className="container h-100 d-flex">
                <Particles id="ts-particles" init={particlesInit} options={particleOptions} />
                <div className="col-sm-12 align-self-center">
                    <div className="card card-block shadow-lg">
                        <div id="login_card">
                            <ColumnsLayout
                                row={{
                                    className: 'pt-md-5 px-3',
                                }}
                                cols={[
                                    {
                                        className: 'logo-panel d-flex flex-column justify-content-center',
                                        children: (
                                            <>
                                                <img
                                                    className="d-none d-md-block col-sm-10 offset-sm-1 col-md-6 offset-md-0 col-lg-4 offset-lg-1 align-self-center"
                                                    src="/assets/Logos/guardian_logo.png"
                                                    alt="Logo"
                                                />
                                            </>
                                        ),
                                    },
                                    {
                                        className: 'content-panel col-lg-5 col-md-5 col-sm-12',
                                        children: element ?? <></>,
                                    },
                                ]}
                            />
                            <div id="copy_rights" className="row">
                                <div className="col-md-12 no-gutters pt-md-5 mb-3 mt-3">
                                    GUARDIAN © 2011-{currentYear} Rhombus Power Inc.
                                    <span className="text-success pl-2">
                                        <em className="fab fa-product-hunt mr-2"></em>
                                        &nbsp;Manpower
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
