export const getRowExclusions = (rows) => {
    let rowIds = [];
    rows.forEach((row) => {
        const childrenRows = row.CHILDREN;
        childrenRows.forEach((child) => {
            // Exclude row when EXCLUDE = 1
            if (child.EXCLUDE === 1) {
                rowIds.push(child.id);
            }
        });
    });
    return [...new Set(rowIds)];
};
