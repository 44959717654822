import React, { useEffect } from 'react';
import axios from 'axios';
import { Loading } from '@carbon/react';
import { ButtonUtil } from '.';
import { useNavigate, useLocation } from 'react-router-dom';
import { TileGridLayout } from './layout/TileGridLayout';
import { atom, useAtom, useSetAtom } from 'jotai';
import { userListDataAtom } from '../pages/wizard/modals/shareScenario/useFetchAllUsers';

export const isLoggedInAtom = atom(null);
export const currentUserAtom = atom(null);

export function PrivateRoute(props) {
    const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom);
    const setCurrentUser = useSetAtom(currentUserAtom);
    const setUserListData = useSetAtom(userListDataAtom);
    const navigate = useNavigate();
    const location = useLocation();
    const locationPathRef = React.useRef(location.pathname + location.search);
    locationPathRef.current = location.pathname + location.search;

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
            const res = await axios.post(
                '/api/verifyJWT',
                {
                    accountTypes: props.accountTypes,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                    credentials: 'include',
                }
            );
            setIsLoggedIn(res.data.authorized);
            setCurrentUser({ isAdmin: res.data.accountType === 'admin', userId: res.data.userId });
            setUserListData({ init: true, userList: [] });
            if (!res.data.authorized) {
                if (locationPathRef.current?.length > 1) {
                    navigate('/', { state: locationPathRef.current });
                } else {
                    navigate('/');
                }
            }
        })();
    }, [props.accountTypes, setIsLoggedIn, setCurrentUser, setUserListData, navigate]);

    if (isLoggedIn === null) {
        return <Loading />;
    } else if (isLoggedIn) {
        //jwt is valid and the account type matches
        return props.element;
    } else if (!isLoggedIn) {
        //
        return (
            <TileGridLayout
                grid={{
                    style: { height: '90vh' },
                }}
                column={{
                    lg: { span: 6, offset: 5 },
                    md: { span: 6, offset: 1 },
                    sm: 4,
                    className: 'h-100 d-flex align-items-center',
                }}
                tile={{
                    className: 'rounded w-100 text-center px-5 pb-5',
                    style: {
                        // height: '50vh',
                        // backgroundColor:''
                    },
                    children: (
                        <>
                            <div className="d-flex justify-content-center border-bottom border-2">
                                <div style={{ maxWidth: '4em' }}>
                                    <img className="h-auto w-100" src="/assets/Logos/guardian_logo.png" alt="Logo" />
                                </div>
                            </div>
                            <h3 className="fw-bold mt-4">Login Required</h3>
                            <p className="mt-4">You must be logged in to access this page.</p>
                            <ButtonUtil
                                button={{
                                    children: 'Log In',
                                    className: 'mt-5 rounded',
                                    onClick: () => {
                                        navigate('/', {
                                            state: location.pathname,
                                        });
                                    },
                                    //s
                                }}
                            />
                        </>
                    ),
                }}
            />
        );
    }
}
