export const selectionList = {
    cmdOptions: {
        ACC: 'ACC',
        ANG: 'ANG',
    },
    ilcOptions: {
        'JB Langley-Eutis, VA': 'JB Langley-Eutis, VA',
    },
    pecOptions: {
        '27138A': '27138A',
        '27138M': '27138M',
    },
    unitOptions: {
        '1 OPS SQ (FQ00)': '1 OPS SQ (FQ00)',
        '1 MAI SQ (FN37)': '1 MAI SQ (FN37)',
    },
    workCenterOptions: {
        'MX-TFI': 'MX-TFI',
    },
};
