import axios from 'axios';

/**
 *
 * @returns {Promise<any[]>}
 */
export const getAccountData = async () => {
    const res = await axios.post('/api/account/getAccountData', {});
    return res.data;
};

/**
 *
 * @param {{
 *  userId: string | number,
 * }} options
 * @returns {Promise<{success: boolean, error: null | Error}>}
 */
export const blockUser = async ({ userId }) => {
    try {
        const { status } = await axios.post('/api/account/blockAccount', { userID: userId });
        return {
            success: status === 200,
            error: null,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
};

/**
 *
 * @param {{
 *  userId: string | number,
 * }} options
 * @returns {Promise<{success: boolean, error: null | Error}>}
 */
export const unblockUser = async ({ userId }) => {
    try {
        const { status } = await axios.post('/api/account/unblockAccount', { userID: userId });
        return {
            success: status === 200,
            error: null,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
};

/**
 *
 * @param {{
 *  userId: string | number,
 * }} options
 * @returns {Promise<{success: boolean, error: null | Error}>}
 */
export const renewUser = async ({ userId }) => {
    try {
        const { status } = await axios.post('/api/account/renewUser', { userID: userId });
        return {
            success: status === 200,
            error: null,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
};
