import { Tab, TabList, TabPanel, TabPanels, Tabs, Theme } from '@carbon/react';
import { Navigation } from '../../common';
import { CARBON_THEME, CUSTOM_THEME } from '../wizard/constants';
import { TimeoutAlert } from '../../common/TimeoutAlert';
import { useState } from 'react';
import { IssuesReportedTab } from './IssuesReportedTab';
import { useFetchAllUsers } from '../wizard/modals/shareScenario/useFetchAllUsers';
import { ReportIssuesForm } from './ReportIssuesForm/ReportIssuesForm';
import { ErrorNotification } from '../../common/ErrorNotification/ErrorNotification';

export const ReportIssue = () => {
    const [reportedIssue, setReportedIssue] = useState(0);

    useFetchAllUsers();

    return (
        <Theme theme={localStorage.getItem('theme') === CUSTOM_THEME.DARK ? CARBON_THEME.G90 : CARBON_THEME.WHITE}>
            <div className="wizard" style={{ minHeight: '100vh' }}>
                <Navigation />
                <Tabs>
                    <TabList aria-label="List of tabs" contained>
                        <Tab data-testid="report-tab">Report Issue</Tab>
                        <Tab data-testid="issues-reported-tab">Issues Reported</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel aria-label="report-tab">
                            <ReportIssuesForm onSubmit={() => setReportedIssue(reportedIssue + 1)} />
                        </TabPanel>
                        <TabPanel aria-label="issues-reported-tab">
                            <IssuesReportedTab key={`issue_${reportedIssue}`} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
            <TimeoutAlert />
            <ErrorNotification />
        </Theme>
    );
};
