import React from 'react';
import {
    DataTable,
    ListItem,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    UnorderedList,
} from '@carbon/react';
import PropTypes from 'prop-types';

const scenarioHeaders = [
    {
        header: 'SCENARIO_NAME',
        key: 'SCENARIO_NAME',
    },
    {
        header: 'CMD',
        key: 'CMD_TITLE',
    },
    {
        header: 'ILC',
        key: 'ILC_TITLE',
    },
    {
        header: 'MDS',
        key: 'MDS_TITLE',
    },
    {
        header: 'OPS_PECS',
        key: 'OPS_PECS',
    },
    {
        header: 'MX_PECS',
        key: 'MX_PECS',
    },
];

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof ScenarioInfoModalProps>>}
 * @returns {React.ReactElement}
 */
export const ScenarioInfoModal = ({ open, onClose, scenario }) => {
    return (
        <Modal
            passiveModal
            open={open}
            size="lg"
            className="ops-modal"
            onRequestClose={onClose}
            modalLabel={scenario.SCENARIO_NAME}
            data-testid="scenario-info-modal"
        >
            <DataTable rows={[scenario]} headers={scenarioHeaders}>
                {({ rows, headers, getHeaderProps, getRowProps, getTableProps, getTableContainerProps }) => {
                    return (
                        <TableContainer {...getTableContainerProps()}>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow {...getRowProps({ row })}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{cell.value}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );
                }}
            </DataTable>

            {scenario?.UNIT_DETAILS?.length > 0 ? (
                <>
                    <h5>Unit Details</h5>
                    <UnorderedList className="unit-details-info">
                        {scenario.UNIT_DETAILS.map((unit, index) => (
                            <React.Fragment key={`${unit.WING}_${index}`}>
                                <ListItem key={unit.WING}>
                                    {unit.WING}
                                    <UnorderedList nested>
                                        <ListItem>
                                            OPS
                                            <UnorderedList nested>
                                                {Array.isArray(unit.OPS_UNIT) ? (
                                                    unit.OPS_UNIT.map((ops) => <ListItem key={ops}>{ops}</ListItem>)
                                                ) : (
                                                    <ListItem>{unit.OPS_UNIT}</ListItem>
                                                )}
                                            </UnorderedList>
                                        </ListItem>

                                        <ListItem>
                                            MX
                                            <UnorderedList nested>
                                                {Array.isArray(unit.MX_UNIT) ? (
                                                    unit.MX_UNIT.map((mx) => <ListItem key={mx}>{mx}</ListItem>)
                                                ) : (
                                                    <ListItem>{unit.MX_UNIT}</ListItem>
                                                )}
                                            </UnorderedList>
                                        </ListItem>
                                    </UnorderedList>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </UnorderedList>
                </>
            ) : null}
        </Modal>
    );
};

const ScenarioInfoModalProps = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    scenario: PropTypes.object.isRequired,
};

ScenarioInfoModal.propTypes = ScenarioInfoModalProps;
