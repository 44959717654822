import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { paths, demoPaths, manpowerPaths } from './config';
import { PrivateRoute, Tracker, GuardianPanel, GuardianPanelDark } from './utils';
import './App.scss';

export const App = () => {
    const location = useLocation();

    return (
        <>
            <Routes>
                {Object.values({
                    ...paths,
                    ...demoPaths,
                    ...manpowerPaths,
                }).map((obj) => {
                    if (obj.path.split('/')[1] === location.pathname.split('/')[1]) {
                        document.title = obj.name;
                    }

                    let elementBody = <div> {obj.element} </div>;
                    if (obj.pathType === 'closed') {
                        elementBody = <PrivateRoute element={elementBody} accountTypes={obj.accountTypes} />;
                    }
                    if (obj.guardianPanel) {
                        elementBody = <GuardianPanel element={obj.element} form={true || obj.guardianPanelForm} />;
                    }
                    if (obj.pathType === 'closed' && obj.guardianPanel) {
                        elementBody = (
                            <PrivateRoute
                                element={<GuardianPanel element={obj.element} />}
                                accountTypes={obj.accountTypes}
                            />
                        );
                    }
                    if (obj.guardianPanelDark) {
                        elementBody = <GuardianPanelDark element={obj.element} />;
                    }
                    if (obj.pathType === 'closed' && obj.guardianPanelDark) {
                        elementBody = (
                            <PrivateRoute
                                element={<GuardianPanelDark element={obj.element} />}
                                accountTypes={obj.accountTypes}
                            />
                        );
                    }
                    return <Route key={obj.path} path={obj.path} element={elementBody} />;
                })}
            </Routes>
            <Tracker />
        </>
    );
};
