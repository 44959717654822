import { OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { Legend } from '@carbon/icons-react';
import PropTypes from 'prop-types';

export const HeaderContent = ({ title }) => {
    return (
        <div className="table-header">
            <div className="table-title">{title}</div>
            <div>
                <OverflowMenu
                    data-testid="table-headers-legend"
                    aria-label="overflow-menu"
                    flipped
                    renderIcon={Legend}
                    iconDescription="Table Headers Legend"
                    align="left"
                    menuOptionsClass="table-legend"
                >
                    <OverflowMenuItem
                        itemText="OSC = Office Symbol Code"
                        onClick={() => false}
                        className="legend-item"
                    />
                    <OverflowMenuItem
                        itemText="FAC = Functional Account Code"
                        onClick={() => false}
                        className="legend-item"
                    />
                    <OverflowMenuItem
                        itemText="AFSC = Air Force Specialty Code"
                        onClick={() => false}
                        className="legend-item"
                    />
                    <OverflowMenuItem
                        itemText="RIC = Routing Identification Code"
                        onClick={() => false}
                        className="legend-item"
                    />
                </OverflowMenu>
            </div>
        </div>
    );
};

HeaderContent.propTypes = {
    title: PropTypes.node,
};
