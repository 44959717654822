import React from 'react';

import { ReviewTableContainer } from './ReviewTableContainer';
import { ListItem, UnorderedList } from '@carbon/react';

const tableHeaders = [
    {
        header: 'UNIT',
        key: 'UNIT',
    },
    {
        header: 'HOST',
        key: 'HOST',
    },
    {
        header: 'PEC',
        key: 'PEC',
    },
];
export const ReviewAdditionalUnits = ({
    isLoading,
    cmds = [],
    ilcs = [],
    ops = [],
    mx = [],
    //
}) => {
    const showHeader = cmds.length > 0 || ilcs.length > 0 || ops.length > 0 || mx.length > 0;

    return (
        <>
            {showHeader ? <h3>Additional Unit Details</h3> : null}
            <div className="d-flex justify-content-between">
                {cmds.length > 0 ? (
                    <div className="flex-grow-1 flex-shrink-1 mt-2 mb-2 me-2" style={{ flexBasis: 0 }}>
                        <h4 style={{ width: 'fit-content' }}>CMD</h4>
                        <UnorderedList>
                            {cmds.map((cmd) => (
                                <ListItem key={cmd}>{cmd}</ListItem>
                            ))}
                        </UnorderedList>
                    </div>
                ) : null}
                {ilcs.length > 0 ? (
                    <div
                        className="flex-grow-1 flex-shrink-1 mt-2 mb-2 ms-2"
                        style={{ flexBasis: 0, marginRight: 'auto' }}
                    >
                        <h4 style={{ width: 'fit-content' }}>ILC</h4>
                        <UnorderedList>
                            {ilcs.map((ilc) => (
                                <ListItem key={ilc}>{ilc}</ListItem>
                            ))}
                        </UnorderedList>
                    </div>
                ) : null}
            </div>
            <div className="d-flex justify-content-between">
                {ops.length > 0 ? (
                    <div className="flex-grow-1 flex-shrink-1 mt-2 mb-2 me-2">
                        <ReviewTableContainer
                            header="OPS Additional Unit Details"
                            tableHeaders={tableHeaders}
                            rows={ops}
                            isLoading={isLoading}
                        />
                    </div>
                ) : null}
                {mx.length > 0 ? (
                    <div className="flex-grow-1 flex-shrink-1 mt-2 mb-2 ms-2">
                        <ReviewTableContainer
                            header="MX Additional Unit Details"
                            tableHeaders={tableHeaders}
                            rows={mx}
                            isLoading={isLoading}
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
};
