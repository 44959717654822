// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-services-form .highlight-text {
  color: #e40927;
}
.account-services-form .highlight-url {
  color: #1C9AB4;
}
.account-services-form .disclaimer-font {
  font-size: 18px;
}
.account-services-form .page-header-font {
  font-size: 30px;
}
.account-services-form .cds--tile {
  min-width: 32rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/accountServices/Styles/FirstAdminForm.scss","webpack://./src/scss/abstracts/_variables.scss"],"names":[],"mappings":"AAGI;EACI,cCFS;ADAjB;AAII;EACI,cCNG;ADIX;AAII;EACI,eAAA;AAFR;AAII;EACI,eAAA;AAFR;AAII;EACI,gBAAA;AAFR","sourcesContent":["@import '../../../scss/styles';\n\n.account-services-form {\n    .highlight-text{\n        color:$highlight-color;\n    }\n    .highlight-url{\n        color:$url-color;\n    }\n    .disclaimer-font{\n        font-size:18px;\n    }\n    .page-header-font{\n        font-size: $header-font;\n    }\n    .cds--tile {\n        min-width: 32rem;\n    }\n}","// colors\n$url-color:#1C9AB4;\n$highlight-color:#e40927;\n$gray-bg-color:#bfbfbf;\n$info-bg-color:#a2d0ff58;\n$blue-icon-bg-color:#0000ff;\n$cds-primary:#0062fe;\n$cds-border-subtle:#e0e0e0;\n$header-grey:#f4f4f4;\n$light-grey: #e0e0e0;\n$text-grey: #525252;\n\n// fonts\n$header-font:30px;\n$roboto-font:Roboto,Arial,sans-serif;\n\n// spacing\n$spacers: 5%;\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
