import { TableHead, TableRow, TableHeader, TableExpandHeader, TableSelectAll } from '@carbon/react';
import { useAtom } from 'jotai';
import { useCallback } from 'react';
import PropTypes from 'prop-types';

import { sortInfoAtom } from '../EquationsTable';

export const TableHeaderContent = ({
    headers,
    allChecked,
    getHeaderProps,
    getSelectionProps,
    disabled = false,
    onSelectAllRows = () => {},
}) => {
    const [sortInfo, setSortInfo] = useAtom(sortInfoAtom);
    const { columnId, sortDirection } = sortInfo;

    const getNextDirection = useCallback((oldDirection) => {
        const directions = ['none', 'ascending', 'descending'];
        const index = directions.findIndex((dir) => dir === oldDirection);
        const newIndex = index + 1;
        if (newIndex > 2) {
            return directions[0];
        } else {
            return directions[newIndex];
        }
    }, []);

    const handleChangeSort = useCallback(
        (event, id) => {
            event.preventDefault();
            const newDirection = getNextDirection(columnId === id ? sortDirection : 'none');
            setSortInfo({ columnId: id, sortDirection: newDirection });
        },
        [getNextDirection, setSortInfo, columnId, sortDirection]
    );

    return (
        <TableHead>
            <TableRow>
                <TableExpandHeader />
                <TableSelectAll
                    {...getSelectionProps({
                        onClick: (e) => onSelectAllRows(e.target.checked),
                    })}
                    checked={allChecked ?? false}
                    disabled={disabled}
                />
                {headers.map((header) => {
                    return (
                        <TableHeader
                            {...getHeaderProps({
                                header,
                                key: header.key,
                                className: 'table-header-' + header.key,
                                isSortable: header.isSortable,
                                onClick: (e) => handleChangeSort(e, header.key),
                            })}
                        >
                            {header.header.replaceAll('_', ' ')}
                        </TableHeader>
                    );
                })}
            </TableRow>
        </TableHead>
    );
};

TableHeaderContent.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.object),
    allChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    getHeaderProps: PropTypes.func.isRequired,
    getSelectionProps: PropTypes.func.isRequired,
    onSelectAllRows: PropTypes.func,
};
