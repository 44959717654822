import React from 'react';
import { ColumnsLayout, TileGridLayout } from '../utils';
import './GuardianPanel.scss';

export function GuardianPanel({ element, form }) {
    return (
        <ColumnsLayout
            row={{
                className: 'guardian-panel',
            }}
            cols={[
                {
                    className: 'logo-panel d-flex flex-column justify-content-center bg-white',
                    children: (
                        <>
                            <img className="logo-image" src="/assets/Logos/guardian_logo.png" alt="Logo" />
                            <span className="text-muted py-5 my-5 lead px-5">
                                We use cutting edge cross-disciplinary approaches <br /> to solve pressing problems.
                            </span>
                        </>
                    ),
                },
                {
                    className: 'content-panel',
                    children: form ? <GuardianPanelForm children={element} /> : element,
                },
            ]}
        />
    );
}

export function GuardianPanelForm({ children }) {
    return (
        <TileGridLayout
            grid={{
                style: { height: '90vh' },
            }}
            column={{
                lg: { span: 12, offset: 1 },
                md: { span: 6, offset: 1 },
                sm: 4,
                className: 'h-100 d-flex align-items-center',
            }}
            tile={{
                className: 'rounded w-100 text-center px-5 pb-5',
                style: {
                    // height:'auto%',
                    // backgroundColor:''
                },
                children: <>{children}</>,
            }}
        />
    );
}
