import React, { useState } from 'react';
import { DatePicker, DatePickerInput } from '@carbon/react';
import { InputValidator } from '../../helpers';

// Only the separator '-', '.' and '/' can be parsed
export const DateField = ({
    datePicker = {},
    datePickerInputStart = {},
    datePickerInputEnd = {},
    datePickerType = 'single',
    type = 'date',
    allowInput = true,
}) => {
    // Setup constants
    const [startDateInvalid, setStartDateInvalid] = useState(false);
    const [endDateInvalid, setEndDateInvalid] = useState(false);

    const DATE_PICKER_TYPE = ['single', 'simple', 'range'];
    const DATE_FORMATTING_TOKENS = ['m', 'd', 'y', 'Y'];
    const DATE_TYPE_DIGIT_LIST = {
        m: 2,
        d: 2,
        y: 2,
        Y: 4,
    };

    // set date format
    let dateFormat = datePicker.dateFormat ?? 'm/d/y';
    let delimiter = datePicker.dateFormat.includes('-') ? '-' : datePicker.dateFormat.includes('.') ? '.' : '/';
    let dateType = dateFormat.split(delimiter);

    // validate date format and delimiter if invalid date format
    let validDateFormat = true;
    dateType.forEach((e) => {
        if (!DATE_FORMATTING_TOKENS.includes(e)) {
            validDateFormat = false;
        }
    });

    if (dateType.length !== 3 || !validDateFormat) {
        dateFormat = 'm/d/y';
        delimiter = '/';
        dateType = dateFormat.split(delimiter);
    }

    const datePickerProps = {
        allowInput: allowInput,
        datePickerType: DATE_PICKER_TYPE.includes(datePickerType) ? datePickerType : 'single',
        dateFormat: dateFormat,
        onChange: (date) => handleDatePickerChange(date),
        ...datePicker,
    };

    const datePickerInputStartProps = {
        id: `date${Math.random().toString()}`,
        labelText: datePickerType === 'range' ? 'Start Date' : 'Date',
        placeholder: datePicker.placeholder ?? dateFormat ?? 'mm/dd/yyyy',
        invalid: startDateInvalid,
        invalidText: datePicker.invalidText ?? `Please enter a valid date ${dateFormat}.`,
        onChange: (e) => handleChange(e, true),
        onKeyDown: (e) => handleKeyDown(e),

        ...datePickerInputStart,
    };

    const datePickerInputEndProps = {
        id: `date-end${Math.random().toString()}`,
        labelText: 'End Date',
        placeholder: datePicker.placeholder ?? dateFormat ?? 'mm/dd/yyyy',
        invalid: endDateInvalid,
        invalidText: datePicker.invalidText ?? `Please enter a valid date ${dateFormat}.`,
        onChange: (e) => handleChange(e, false),
        onKeyDown: (e) => handleKeyDown(e),
        ...datePickerInputEnd,
    };

    const handleDatePickerChange = (date) => {
        if ((datePickerType === 'range' && date.length >= 2) || (datePickerType !== 'range' && date.length >= 1)) {
            setStartDateInvalid(false);
            setEndDateInvalid(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            if (e.target.value.slice(-1) === delimiter) {
                e.target.value = e.target.value.slice(0, -1);
            }
        }
    };

    const handleChange = (e, start) => {
        if (allowInput) {
            const typedDate = e.target.value;
            const typedDateSplit = typedDate.split(delimiter);
            const lastTypedDate = typedDateSplit[typedDateSplit.length - 1];
            const lastIndexOfTypedDate = typedDateSplit.lastIndexOf(lastTypedDate);
            const invalid = start ? startDateInvalid : endDateInvalid;

            if (
                typedDateSplit.length === 3 &&
                lastTypedDate.length - 1 === DATE_TYPE_DIGIT_LIST[dateType[lastIndexOfTypedDate]] &&
                !invalid
            ) {
                e.target.value = e.target.value.slice(0, -1);
                start ? setStartDateInvalid(false) : setEndDateInvalid(false);
            } else {
                const conditions = InputValidator({
                    type: type,
                    value: {
                        dateType: dateType[lastIndexOfTypedDate],
                        lastTypedDate: lastTypedDate,
                    },
                });

                if (!conditions.onlyNumbers && lastTypedDate !== '') {
                    e.target.value = e.target.value.slice(0, -1);
                }
                if (conditions.matchDateType && conditions.onlyNumbers) {
                    if (typedDateSplit.length < 3) {
                        e.target.value += delimiter;
                    } else if (typedDateSplit.length === 3) {
                        start ? setStartDateInvalid(false) : setEndDateInvalid(false);
                    }
                } else {
                    start ? setStartDateInvalid(true) : setEndDateInvalid(true);
                }
            }
        }
    };

    return (
        <DatePicker data-testid="test-container" {...datePickerProps}>
            <DatePickerInput data-testid="test-start" {...datePickerInputStartProps} />
            {datePickerType === 'range' && <DatePickerInput data-testid="test-end" {...datePickerInputEndProps} />}
        </DatePicker>
    );
};
