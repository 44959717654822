import React from 'react';
import { Accordion, AccordionItem, Modal } from '@carbon/react';
import Markdown from 'react-markdown';
import axios from 'axios';
import { atom, useAtom } from 'jotai';

export const releaseNotesOpenAtom = atom(false);

export const ReleaseNotes = () => {
    const [markdown, setMarkdown] = React.useState([]);
    const [open, setOpen] = useAtom(releaseNotesOpenAtom);

    React.useEffect(() => {
        if (open) {
            axios
                .get('/api/protected-assets/release-notes')
                .then(({ data }) => {
                    setMarkdown(data);
                })
                .catch(console.error);
        }
    }, [open]);
    const closeModal = () => setOpen(false);

    return (
        <Modal
            onRequestClose={closeModal}
            open={open}
            passiveModal
            className="release-notes-modal"
            data-testid="release-notes-modal"
        >
            <Accordion>
                {markdown.map((obj, i) => (
                    <AccordionItem key={`test_${i}`} title={obj.header} data-testid={`accordion_item_${i}`}>
                        <Markdown
                            components={{
                                h1: 'h2',
                                h2: 'h4',
                                // Force markdown to use carbon style elements
                                ol(props) {
                                    const { node, ordered, ...rest } = props;
                                    return (
                                        <ol className="cds--list--ordered ms-4 ps-2" ordered={`${ordered}`} {...rest} />
                                    );
                                },
                                li(props) {
                                    const { node, ordered, ...rest } = props;
                                    return <li className="cds--list__item" ordered={`${ordered}`} {...rest} />;
                                },
                            }}
                        >
                            {obj.markdown}
                        </Markdown>
                    </AccordionItem>
                ))}
            </Accordion>
        </Modal>
    );
};
