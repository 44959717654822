import axios from 'axios';

/**
 *
 * @param {number[]} scenarios
 * @returns {Promise<{ success: boolean, error: null | any, failedIds: string[], }>}
 */
export const approveScenario = async (scenarios) => {
    const reqBody = {
        scenario_ids: scenarios,
    };
    try {
        const { status, data } = await axios.post('/api/fast-api/admin/approve', reqBody, {});
        if (status === 200) {
            return {
                success: true,
                failedIds: data.INVALID ?? [],
                reasons: data.REASON ?? [],
            };
        }
    } catch (error) {
        console.error(error);
        return {
            success: false,
            failedIds: [],
            error,
        };
    }
};

/**
 *
 * @param {number[]} scenario_ids
 * @param {number[]} userIds
 * @returns {Promise<{
 *  success: boolean,
 *  data: null | { scenario_id: number, sceanrio_name: string, share_successful: boolean, user_id: number }[],
 *  error: null | any
 * }>}
 */
export const shareMultipleScenarios = async ({ scenarioIds, userIds }) => {
    const reqBody = {
        scenario_id: scenarioIds,
        share_user_id: userIds,
    };
    try {
        const { data } = await axios.post('/api/fast-api/admin/share-multiple-scenarios', reqBody, {});
        if (data[0] === 200) {
            return {
                success: true,
                data: data[1],
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            error,
        };
    }
};
/**
 *
 * @param {number} scenario_id
 * @returns {Promise<{ success: boolean, error: null | any }>}
 */
export const revokeApprovedScenario = async ({ scenarioId }) => {
    const scenario_id = Number(scenarioId);
    try {
        const { status } = await axios.post(`/api/fast-api/admin/revoke_approval/${scenario_id}`, null, null);
        if (status === 200) {
            return {
                success: true,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            error,
        };
    }
};

/**
 *
 * @param {Object & { SCENARIO_ID: number }} scenario
 * @param {number} index
 * @returns {Object & {id: string}}
 */
const addIdToScenario = (scenario, index) => {
    return {
        ...scenario,
        id: `${scenario.SCENARIO_ID ?? index}`,
    };
};

/**
 * Admin control for fetching scenarios for Publishing
 * @returns {Promise<{
 *  success: boolean,
 *  summary: {
 *      TOTAL: number,
 *      BY_CMD?: { [key: string]: number },
 *      BY_MDS?: { [key: string]: number }
 *      cmds?: { title: string, count: number, id: string }[]
 *      mds?: { title: string, count: number, id: string }[]
 *  },
 *  cmdList: string[],
 *  ilcList: string[],
 *  mdsList: string[],
 *  data: null | any,
 *  error: null | Error
 * }>}
 */
export const fetchPublishedScenarios = async (highlightScenarioId) => {
    try {
        const { status, data } = await axios.post('/api/fast-api/admin/list_scenarios', null);
        const byCmd = Object.entries(data.SUMMARY?.BY_CMD ?? {}).map(([title, count], index) => ({
            title,
            count,
            id: `${index}`,
        }));
        byCmd.sort((a, b) => {
            return b.count - a.count;
        });
        const byMds = Object.entries(data.SUMMARY?.BY_MDS ?? {}).map(([title, count], index) => ({
            title,
            count,
            id: `${index}`,
        }));
        byMds.sort((a, b) => {
            return b.count - a.count;
        });
        const seenCMDs = new Set();
        const seenILCs = new Set();
        const seenMDSs = new Set();
        const formatScenarioData = (scenario) => {
            return {
                ...scenario,
                ICON: scenario.MP_PUSHED,
            };
        };
        const scenarioList = data.SCENARIO_LIST.map((command) => {
            seenCMDs.add(command.CMD);
            seenILCs.add(command.ILC);
            seenMDSs.add(command.MDS);
            let startOpen = false;
            if (highlightScenarioId) {
                command.UNPUBLISHED?.forEach((scenario) => {
                    if (String(scenario.SCENARIO_ID) === String(highlightScenarioId)) {
                        startOpen = true;
                    }
                });
            }
            return {
                ...command,
                APPROVED: command.APPROVED?.map(addIdToScenario).map(formatScenarioData) ?? [],
                PUBLISHED: command.PUBLISHED?.map(addIdToScenario).map(formatScenarioData) ?? [],
                UNPUBLISHED: command.UNPUBLISHED?.map(addIdToScenario).map(formatScenarioData) ?? [],
                startOpen,
            };
        });
        const nameSort = (a, b) => {
            return a.localeCompare(b);
        };
        return {
            success: status === 200,
            error: null,
            summary: {
                TOTAL: 0,
                ...data.SUMMARY,
                cmds: byCmd,
                mds: byMds,
            },
            data: scenarioList,
            cmdList: Array.from(seenCMDs).sort(nameSort),
            ilcList: Array.from(seenILCs).sort(nameSort),
            mdsList: Array.from(seenMDSs).sort(nameSort),
        };
    } catch (error) {
        return {
            success: false,
            data: null,
            summary: {
                TOTAL: 0,
            },
            cmdList: [],
            ilcList: [],
            mdsList: [],
            error,
        };
    }
};

/**
 * Admin control for fetching missing units
 * @returns {Promise<{
 *  success: boolean,
 *  cmdList: string[],
 *  ilcList: string[],
 *  mdsList: string[],
 *  transit: [{
 *      id: string,
 *      CMD: string,
 *      CMD_TITLE: string,
 *      MDS: string,
 *      MDS_TITLE: string,
 *      ILC: string,
 *      ILC_TITLE: string,
 *      PAI_TYPE: string,
 *      OPS_UNIT: string,
 *  }],
 *  missing: [{
 *      id: string,
 *      CMD: string,
 *      CMD_TITLE: string,
 *      MDS: string,
 *      MDS_TITLE: string,
 *      ILC: string,
 *      ILC_TITLE: string,
 *      PAI_TYPE: string,
 *      OPS_UNIT: string,
 *  }],
 *  error: null | Error
 * }>}
 */
export const fetchUnits = async () => {
    try {
        const { status, data } = await axios.post('/api/fast-api/admin/list_units', null);

        const seenCMDs = new Set();
        const seenILCs = new Set();
        const seenMDSs = new Set();
        const createId = (unit, idx, i) => ({
            ...unit,
            id: `${unit.OPS_UNIT}_${idx}_${i}`,
        });
        const [transit, missing] = data.reduce(
            (arr, command, index) => {
                seenCMDs.add(command.CMD);
                seenILCs.add(command.ILC);
                seenMDSs.add(command.MDS);
                return [
                    arr[0].concat((command['IN TRANSIT'] ?? []).map((unit, i) => createId(unit, index, i))),
                    arr[1].concat((command['MISSING'] ?? []).map((unit, i) => createId(unit, index, i))),
                ];
            },
            [[], []]
        );
        const nameSort = (a, b) => {
            return a.localeCompare(b);
        };

        return {
            success: status === 200,
            error: null,
            cmdList: Array.from(seenCMDs).sort(nameSort),
            ilcList: Array.from(seenILCs).sort(nameSort),
            mdsList: Array.from(seenMDSs).sort(nameSort),
            transit,
            missing,
        };
    } catch (error) {
        return {
            success: false,
            cmdList: [],
            ilcList: [],
            mdsList: [],
            transit: [],
            missing: [],
            error,
        };
    }
};

/**
 *
 * @param {{
 *      newScenarioName: string;
 *      scenarioId: string | number;
 * }} options
 * @returns {Promise<{isOk: boolean, error: null | Error}>}
 */
export const cloneNewScenarioFromOld = async ({ newScenarioName, scenarioId }) => {
    try {
        const { data } = await axios.post('/api/fast-api/admin/create_new_scenario', null, {
            params: {
                new_scenario_name: newScenarioName,
                old_scenario_id: scenarioId,
            },
        });
        return {
            isOk: true,
            scenarioId: data[2],
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            error,
        };
    }
};

/**
 * Admin control publish scenario
 * @param {{ scenarioId: string | number, scenarioName: string }} options
 * @returns {Promise<{success: boolean, error: null | Error}>}
 */
export const publishScenario = async ({ scenarioId, scenarioName }) => {
    try {
        const scenario_id = Number(scenarioId);
        const { status } = await axios.post(
            '/api/fast-api/admin/publish_scenario',
            { scenarioName },
            {
                params: { scenario_id },
            }
        );
        return {
            success: status === 200,
            error: null,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
};

/**
 * Admin control unpublish scenario
 * @param {{ scenarioId: string | number, scenarioName: string }} options
 * @param {string} unpublishReason
 * @returns {Promise<{success: boolean, error: null | Error}>}
 */
export const unpublishScenario = async ({ scenarioId, scenarioName }, unpublishReason = '') => {
    try {
        const scenario_id = Number(scenarioId);
        const { status } = await axios.post('/api/fast-api/admin/unpublish_scenario', {
            scenarioName,
            scenario_id,
            unpublish_reason: unpublishReason,
        });
        return {
            success: status === 200,
            error: null,
        };
    } catch (error) {
        return {
            success: false,
            error,
        };
    }
};

/**
 * Admin control for fetching aircrew models
 * @returns {Promise<{
 *  success: boolean,
 *  cmdList: string[],
 *  mdsList: string[],
 *  data: null | any,
 *  error: null | Error
 * }>}
 */
export const fetchAircrewModels = async () => {
    try {
        const { status, data } = await axios.post('/api/fast-api/admin/get_aircrew_models', null);
        const seenCMDs = new Set();
        const seenMDSs = new Set();

        const modelList = data.map((model) => {
            seenCMDs.add(model.CMD);
            seenMDSs.add(model.MDS);
            return {
                ...model,
                MODELS: model.MODELS.map((model, i) => ({ ...model, id: `${i}` })),
            };
        });
        const nameSort = (a, b) => {
            return a.localeCompare(b);
        };
        return {
            success: status === 200,
            error: null,
            data: modelList,
            cmdList: Array.from(seenCMDs).sort(nameSort),
            mdsList: Array.from(seenMDSs).sort(nameSort),
        };
    } catch (error) {
        return {
            success: false,
            data: null,
            cmdList: [],
            mdsList: [],
            error,
        };
    }
};

/**
 * Admin control for fetching mx models
 * @returns {Promise<{
 *  success: boolean,
 *  cmdList: string[],
 *  mdsList: string[],
 *  data: null | any,
 *  error: null | Error
 * }>}
 */
export const fetchMxModels = async () => {
    try {
        const { status, data } = await axios.post('/api/fast-api/admin/get_mx_models', null);
        const seenCMDs = new Set();
        const seenMDSs = new Set();

        const modelList = data.map((model) => {
            seenCMDs.add(model.CMD);
            seenMDSs.add(model.MDS);
            return {
                ...model,
                MODELS: model.MODELS.map((model, i) => ({ ...model, id: `${i}` })),
            };
        });
        const nameSort = (a, b) => {
            return a.localeCompare(b);
        };
        return {
            success: status === 200,
            error: null,
            data: modelList,
            cmdList: Array.from(seenCMDs).sort(nameSort),
            mdsList: Array.from(seenMDSs).sort(nameSort),
        };
    } catch (error) {
        return {
            success: false,
            data: null,
            cmdList: [],
            mdsList: [],
            error,
        };
    }
};

/**
 * Admin control for fetching aircrew equations
 * @returns {Promise<{
 *  success: boolean,
 *  cmdList: string[],
 *  mdsList: string[],
 *  data: null | any,
 *  error: null | Error
 * }>}
 */
export const fetchAircrewEquations = async () => {
    try {
        const { status, data } = await axios.post('/api/fast-api/admin/get_aircrew_equations', null);
        const seenCMDs = new Set();
        const seenMDSs = new Set();

        const modelList = data.map((model) => {
            seenCMDs.add(model.CMD);
            seenMDSs.add(model.MDS);
            return {
                ...model,
                MODELS: model.MODELS.map((model, i) => ({ ...model, id: `${i}` })),
            };
        });
        const nameSort = (a, b) => {
            return a.localeCompare(b);
        };
        return {
            success: status === 200,
            error: null,
            data: modelList,
            cmdList: Array.from(seenCMDs).sort(nameSort),
            mdsList: Array.from(seenMDSs).sort(nameSort),
        };
    } catch (error) {
        return {
            success: false,
            data: null,
            cmdList: [],
            mdsList: [],
            error,
        };
    }
};

let vnvController;
/**
 *
 * @param {{[userId: number | string]: { name: string }} | null} users null just runs the abort if another request is pending
 * @returns {Promise<{
 *      success: boolean,
 *      issues: { SCENARIO_ID: number, SCENARIO_NAME: string }[]
 *      graphData: {
 *          name: string;
 *          data: { x: number, y: number }[];
 *      }[]
 *      mxGraphData: {
 *          name: string;
 *          data: { x: number, y: number }[];
 *      }[]
 *      filters: {
 *          cmd: string[],
 *          mds: string[],
 *          ilc: string[],
 *      }
 *      mxTobeCount: number,
 *      mxAsisCount: number,
 *      tobeCount: number,
 *      asisCount: number,
 *      error: null | Error,
 * }>}
 */
export const fetchVnv = async (users = {}) => {
    try {
        if (vnvController) {
            vnvController.abort();
        }
        if (users === null) {
            return;
        }
        vnvController = new AbortController();
        const { data } = await axios.post('/api/fast-api/admin/get_vnv_and_issues', null, {
            signal: vnvController.signal,
        });

        const cmdSet = new Set();
        const mdsSet = new Set();
        const ilcSet = new Set();
        const graphData = {};
        const mxGraphData = {};
        let tobeCount = 0;
        let asisCount = 0;
        let mxTobeCount = 0;
        let mxAsisCount = 0;
        for (const scenario of data.graph_data) {
            const id = scenario.SCENARIO_ID + '_' + scenario.MP_TYPE;
            let pointInGraph = scenario.MP_TYPE === 'AIRCREW' ? graphData[id] : mxGraphData[id] || {};
            cmdSet.add(scenario.CMD);
            ilcSet.add(scenario.ILC);
            mdsSet.add(scenario.MDS);
            pointInGraph = {
                name: scenario.SCENARIO_NAME,
                id,
                ...scenario,
                ...pointInGraph,
                [scenario.SC_TYPE === 'ASIS' ? 'x' : 'y']: scenario.QTY,
            };
            if (scenario.MP_TYPE === 'AIRCREW') {
                graphData[id] = pointInGraph;
                if (scenario.SC_TYPE === 'ASIS') {
                    asisCount += scenario.QTY;
                } else {
                    tobeCount += scenario.QTY;
                }
            } else {
                mxGraphData[id] = pointInGraph;
                if (scenario.SC_TYPE === 'ASIS') {
                    mxAsisCount += scenario.QTY;
                } else {
                    mxTobeCount += scenario.QTY;
                }
            }
        }
        vnvController = undefined;
        return {
            success: true,
            ...data,
            issues: data.issues.map((scenario, index) => ({
                ...scenario,
                USER: users[scenario.USER_ID]?.name ?? scenario.USER_ID,
                id: scenario.SCENARIO_ID + '_' + index,
            })),
            graphData: Object.values(graphData).map((point) => ({
                ...point,
                data: [{ ...point }],
            })),
            mxGraphData: Object.values(mxGraphData).map((point) => ({
                ...point,
                data: [{ ...point }],
            })),
            filters: {
                cmd: [...cmdSet].sort((a, b) => a.localeCompare(b)),
                mds: [...mdsSet].sort((a, b) => a.localeCompare(b)),
                ilc: [...ilcSet].sort((a, b) => a.localeCompare(b)),
            },
            mxTobeCount,
            mxAsisCount,
            tobeCount,
            asisCount,
            error: null,
        };
    } catch (error) {
        return {
            success: false,
            data: null,
            filters: {
                cmd: [],
                mds: [],
                ilc: [],
            },
            error,
        };
    }
};

export const getVnvDownload = async (url) => {
    try {
        const { data } = await axios.post('/api/fast-api/admin/getVnvFile', {
            s3Url: url,
        });
        return {
            success: true,
            data,
            error: null,
        };
    } catch (error) {
        return {
            success: false,
            data: null,
            error,
        };
    }
};
