export const mockData = {
    host: {
        cmd: 'ACC',
        ilc: 'JB Langley-Eutis, VA',
        op_pec: '27138A',
        mx_pec: '27138M',
    },
    association: {
        cmd: 'ANG',
        ilc: 'JB Langley-Eutis, VA',
        op_pec: '27138A',
        mx_pec: '27138M',
    },
    contribution: [
        {
            unit: '1 OPS SQ (FQ00)',
            wc: 'MX-TFI',
            off: -2,
            enl: -1,
            civ: -10,
            total: -13,
            pec: '27138A',
        },
        {
            unit: '1 MAI SQ (FN37)',
            wc: 'MX-TFI',
            off: -1,
            enl: 0,
            civ: -4,
            total: -5,
            pec: '27138M',
        },
    ],
};
