import { useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { reduceAllInputsAtom } from './wizardAtoms';

/**
 * React useEffect to update allInputs atom after user has fulfilled criteria for next step
 * @param {
 *      'generalSettings' |
 *      'unitDetails' |
 *      'additionalUnits' |
 *      'tfiAssociations'
 * } step Name of current wizard process in AllInputs
 * @param {boolean} isValid If the user has fulfilled criteria for step
 */
export const useUpdateWizardStepValid = (step, isValid) => {
    const setReduceAllInputs = useSetAtom(reduceAllInputsAtom);

    useEffect(() => {
        setReduceAllInputs({ [step]: isValid });
    }, [step, isValid, setReduceAllInputs]);
};
