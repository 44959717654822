import React from 'react';
import { UnorderedList, ListItem, ToastNotification } from '@carbon/react';
import PropTypes from 'prop-types';

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof ModelsInScenariosListProps>>}
 * @returns {React.ReactElement}
 */

export const ModelsInScenariosList = ({ scenarios, onClose }) => {
    return (
        <div className="error_notification--container">
            {scenarios.length > 0 ? (
                <ToastNotification
                    data-testid="models-in-scenarios-notification"
                    aria-label="closes notification"
                    kind="error"
                    onClose={onClose}
                    onCloseButtonClick={onClose}
                    statusIconDescription="Error notification"
                    timeout={40000}
                    title="Error"
                    subtitle="Could not archive model."
                >
                    <p className="pb-1">Model is used in the following scenarios:</p>
                    <UnorderedList>
                        {scenarios.map((scenario, i) => (
                            <ListItem key={i} style={{ color: 'white' }}>
                                {scenario}
                            </ListItem>
                        ))}
                    </UnorderedList>
                </ToastNotification>
            ) : null}
        </div>
    );
};

const ModelsInScenariosListProps = {
    onClose: PropTypes.func.isRequired,
    scenarios: PropTypes.array.isRequired,
};

ModelsInScenariosList.propTypes = ModelsInScenariosListProps;
