import React from 'react';
import { Modal } from '@carbon/react';

import './UmpFlow.scss';

export const UmpFlow = ({ onClose }) => {
    return (
        <Modal
            open
            className="ump-flow"
            // modalHeading="UMP Flow"
            modalLabel="UMP Flow"
            passiveModal
            onRequestClose={onClose}
            data-testid="ump-flow-modal"
        >
            <div className="ump-flow-content">
                <img src="/api/protected-assets/ump-flow.png" alt="workflow for UMP"></img>
            </div>
        </Modal>
    );
};
