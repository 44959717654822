import { useAtom } from 'jotai';
import { cloneDeep, set } from 'lodash';

import {
    forceStructureAdjustableAtom,
    // forceStructureStaticAtom,
} from './GeneralSettingsAtoms';
import { getAllOfScenarioStateAtom } from '../../../../hooks/wizardAtoms';

export const useCloneAdjustmentDataToScenario = () => {
    const [fullScenario] = useAtom(getAllOfScenarioStateAtom);
    // const [staticUnits] = useAtom(forceStructureStaticAtom);
    const [adjustableUnits] = useAtom(forceStructureAdjustableAtom);

    /**
     * Save user inputs in the fs status table > adjustment tab
     * Values will be saved only when they are different than baseline
     * @returns {{
     *   scenario_id: number,
     *   scenario_name: string,
     *   generalSettings: {
     *      adjustment: {
     *          PMAI: number,
     *          PTAI: number,
     *          PDAI: number,
     *          BAI: number,
     *          AR: number,
     *      }[],
     *   },
     * }}
     */
    const cloneAdjustmentDataToScenario = () => {
        const adjustment = [];
        const units = adjustableUnits;

        for (const key in units) {
            const { PMAI = 0, PTAI = 0, PDAI = 0, BAI = 0, AR = 0 } = units[key];
            adjustment.push({ UNIT: key, PMAI, PTAI, PDAI, BAI, AR });
        }

        if (adjustment.length === 0) return fullScenario;

        // save data via API
        const newScenario = cloneDeep(fullScenario);
        set(newScenario, 'generalSettings.forceStructureTables.adjustment', adjustment);
        return newScenario;
    };

    return cloneAdjustmentDataToScenario;
};
