// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.save-session-modal .cds--modal-content {
  height: 200px;
}
.save-session-modal .main-content {
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/wizard/modals/saveSession/SaveSessionModal.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAGI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;AADR","sourcesContent":[".save-session-modal {\n    .cds--modal-content {\n        height: 200px;\n    }\n\n    .main-content {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
