// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-issues-form {
  padding: 0 1.5rem;
  margin-bottom: 5rem;
}
.report-issues-form .radio-container {
  display: flex;
}
.report-issues-form .other-button-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.report-issues-form .other-button {
  width: fit-content;
  flex: 0 0 auto;
  margin-right: 0.75rem;
}
.report-issues-form .fieldset-other > fieldset {
  width: 100%;
}
.report-issues-form .required > span {
  margin: 0 0.25rem;
  color: var(--cds-button-danger-primary, red);
  font-size: 1.1rem;
  line-height: 0.25;
  height: 50%;
  display: inline-block;
}
.report-issues-form .submit-button {
  display: block;
  margin-left: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/reportIssue/ReportIssuesForm/reportIssuesForm.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,mBAAA;AACJ;AACI;EACI,aAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,WAAA;AAAR;AAGI;EACI,kBAAA;EACA,cAAA;EACA,qBAAA;AADR;AAII;EACI,WAAA;AAFR;AAKI;EACI,iBAAA;EACA,4CAAA;EACA,iBAAA;EACA,iBAAA;EACA,WAAA;EACA,qBAAA;AAHR;AAMI;EACI,cAAA;EACA,iBAAA;AAJR","sourcesContent":[".report-issues-form {\n    padding: 0 1.5rem;\n    margin-bottom: 5rem;\n\n    .radio-container {\n        display: flex;\n    }\n\n    .other-button-wrapper {\n        display: flex;\n        align-items: center;\n        width: 100%;\n    }\n\n    .other-button {\n        width: fit-content;\n        flex: 0 0 auto;\n        margin-right: 0.75rem;\n    }\n\n    .fieldset-other>fieldset {\n        width: 100%;\n    }\n\n    .required>span {\n        margin: 0 0.25rem;\n        color: var(--cds-button-danger-primary, red);\n        font-size: 1.1rem;\n        line-height: 0.25;\n        height: 50%;\n        display: inline-block;\n    }\n\n    .submit-button {\n        display: block;\n        margin-left: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
