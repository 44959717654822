import { React, useCallback, useState } from 'react';
import { ClickableTileRow } from '../../../utils';
import { Chat, Email, Mobile, Password, Purchase } from '@carbon/icons-react';

export const useTFAElements = () => {
    /**
     * State for 2FA methods
     */
    const [TFAMethods, setTFAMethods] = useState({
        phone: {
            heading: 'Text Message',
            description: `Text an authentication code to `,
            icon: <Chat />,
            target: '/login-sms',
        },
        email: {
            heading: 'Email',
            description: `Email an authentication code to `,
            icon: <Email />,
            target: '/email-authentication',
        },
        googleKey: {
            heading: 'Google Authenticator App',
            description: 'Get an authentication code from the Google Authenticator app.',
            icon: <Mobile />,
            target: '/app-authenticator',
        },
        yubiKey: {
            heading: 'Yubikey',
            description: 'Verify your login with a Yubikey.',
            icon: <Password />,
        },
        cacKey: {
            heading: 'CAC Card',
            description: 'Verify your login with a CAC Card.',
            icon: <Purchase />,
            target: '/cac-verification',
        },
    });

    const updateTFAMethods = useCallback((method, prop) => {
        setTFAMethods((prevState) => ({
            ...prevState,
            [method]: { ...prevState[method], ...prop },
        }));
    }, []);

    /**
     * HTML elements containing 2FA methods
     */
    const TFAMethodTiles = Object.keys(TFAMethods).map((tfaKey, i) => {
        const tfa = TFAMethods[tfaKey];
        const isDisabled = tfaKey === 'email' ? false : true;
        return (
            <ClickableTileRow
                clickableTile={{
                    href: tfa.target,
                    onClick: tfa.value ? tfa.onClick : tfa.onClickReg,
                    disabled: isDisabled,
                }}
                columns={[
                    { children: tfa.icon },
                    {
                        children: (
                            <>
                                <h5>
                                    {tfa.value ? '' : 'Register '}
                                    {tfa.heading}
                                </h5>
                                <div>
                                    {tfa.description} {tfa.value == 'true' ? '' : tfa.value}
                                </div>
                            </>
                        ),
                    },
                ]}
                key={i}
            />
        );
    });

    return [TFAMethodTiles, setTFAMethods, updateTFAMethods, TFAMethods];
};
