import React, { useEffect, useState } from 'react';
import { TextField, PasswordField, ButtonUtil, PostResponseNotification, LinkUtil } from '../../../utils';
import { Password, Ticket } from '@carbon/icons-react';
import { Form } from '@carbon/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSetAtom } from 'jotai';
import { isLoggedInAtom } from '../../../utils/PrivateRoute';

export const EmailTFA = () => {
    const [token, setToken] = useState({ value: '' });
    const [password, setPassword] = useState({ value: '' });
    const [notificationData, setNotificationData] = useState({});
    const navigate = useNavigate();

    const [buttonDisabled, setButtonDisabled] = useState(true);

    const setIsLoggedIn = useSetAtom(isLoggedInAtom);

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
        })();
    }, []);

    useEffect(() => {
        if (token.invalid === false && token.value.length > 0 && password.value.length > 0) {
            setButtonDisabled(false);
        }
    }, [token, password]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        (async () => {
            try {
                const res = await axios.post(
                    '/api/verifyEmailTFA',
                    {
                        emailTFAToken: token.value,
                        password: password.value,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                setNotificationData(res);
                if (res.data.status === 'success') {
                    navigate(res.data.redirectURL || '/manpower-wizard');
                    setIsLoggedIn(true);
                }
            } catch (err) {
                setNotificationData(err.response);
            }
        })();
    };

    return (
        <Form>
            <h4 className="mb-2"> Enter the token sent to your email</h4>
            <div
                style={{
                    display: 'flex',
                    marginTop: 32,
                }}
            >
                <Ticket style={{ width: 30 }} />
                <TextField
                    textInput={{
                        ...token,
                        labelText: (
                            <>
                                Token <span style={{ color: 'red' }}>* </span>
                            </>
                        ),
                    }}
                    type="token"
                    validation={true}
                    setState={setToken}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    marginBottom: 15,
                }}
            >
                <Password style={{ width: 30 }} />
                <PasswordField
                    passwordInput={{
                        ...password,
                        labelText: (
                            <>
                                Password <span style={{ color: 'red' }}>* </span>
                            </>
                        ),
                    }}
                    type="password"
                    validation={false}
                    setState={setPassword}
                />
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }} className="mt-4">
                <LinkUtil
                    pathKey="authenticationOptions"
                    link={{
                        children: 'Try another way',
                        style: { marginLeft: '2em' },
                        className: 'me-auto small',
                    }}
                />
                <ButtonUtil
                    button={{
                        children: 'Log In',
                        disabled: buttonDisabled,
                        className: 'd-flex mt-4 ms-auto',
                        onClick: handleSubmit,
                        type: 'submit',
                    }}
                />
            </div>
            <PostResponseNotification response={notificationData} />
        </Form>
    );
};
