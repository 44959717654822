import { useMemo, useCallback } from 'react';
import { useReducerAtom } from 'jotai/utils';
import { atom } from 'jotai';
import { initialOptionBTableRow, optionBTableAtom, initialOptionBTableState } from '../../../../../../hooks/mxAtoms';

const actionTypes = Object.freeze({
    INSERT: 'INSERT',
    UPDATE_ROWS: 'UPDATE_ROWS',
    ADD_SUMMARY_TEXT: 'ADD_SUMMARY_TEXT',
    ADD_ROW: 'ADD_ROW',
    DELETE_ROW: 'DELETE_ROW',
    RESET: 'RESET',
});

export const disableTableAtom = atom(false);

const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.INSERT:
            return {
                ...state,
                tableRows: action.payload.tableRows,
            };
        case actionTypes.UPDATE_ROWS:
            try {
                const { rowIdx, colName, value } = action.payload;
                const rows = [...state.tableRows];
                const row = { ...rows[rowIdx] };
                row[colName] = value;
                rows[rowIdx] = row;
                return {
                    ...state,
                    tableRows: rows,
                };
            } catch (error) {
                return { ...state };
            }
        case actionTypes.ADD_ROW:
            return {
                ...state,
                tableRows: [
                    ...state.tableRows,
                    {
                        ...initialOptionBTableRow,
                        id: `added_${Math.random().toString().replace('.', '_')}`,
                    },
                ],
            };
        case actionTypes.DELETE_ROW:
            try {
                const list = [...state.tableRows];
                list.splice(action.payload, 1);
                return {
                    ...state,
                    tableRows: list,
                };
            } catch (error) {
                console.error(error);
                return {
                    ...state,
                };
            }
        case actionTypes.RESET:
            return {
                ...initialOptionBTableState,
            };
        default:
            return {
                ...state,
            };
    }
};

/**
 *
 * @param {'peace' | 'fol1' | 'fol2' | 'fol3' | 'war'} activeType
 * @param {boolean} isEditable
 */
export const useFormulaTable = () => {
    const [optionB, dispatch] = useReducerAtom(optionBTableAtom, reducer);

    const insertData = useCallback(
        (payload) => {
            dispatch({ type: actionTypes.INSERT, payload });
        },
        [dispatch]
    );

    const handlers = useMemo(
        () => ({
            handleUpdateRow: (value, rowIdx, colName) => {
                dispatch({
                    type: actionTypes.UPDATE_ROWS,
                    payload: {
                        rowIdx,
                        colName,
                        value,
                    },
                });
            },
            handleAddRow: (rowIdx) => {
                dispatch({
                    type: actionTypes.ADD_ROW,
                    payload: rowIdx,
                });
            },
            handleDeleteRow: (rowIdx) => {
                dispatch({
                    type: actionTypes.DELETE_ROW,
                    payload: rowIdx,
                });
            },
            handleReset: () => {
                dispatch({
                    type: actionTypes.RESET,
                });
            },
        }),
        [dispatch]
    );

    return {
        insertData,
        ...handlers,
        optionB,
    };
};
