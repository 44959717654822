import React from 'react';
import { FileUploaderUtil, FileUploaderDragDrop } from '../../utils/';

export const FileUploadTestPage = () => (
    <>
        <FileUploaderDragDrop
            fileUploaderDropContainer={{
                accept: ['.pdf', '.jpg', '.txt', '.img'],
            }}
        />

        <FileUploaderUtil accept={['.pdf', '.txt']} />
    </>
);
