import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAtom, useSetAtom } from 'jotai';
import { Modal, Button, InlineNotification, ContentSwitcher, Switch } from '@carbon/react';
import { Add } from '@carbon/react/icons';

import { useConditionalEffect, useFocusAtom } from '../../../../hooks';
import { gsFiltersAtom } from '../../steps/generalSettings/GeneralSettingsAtoms';

import { OpsSelectionTable } from './opsSelectionTable/OpsSelectionTable';
import { UpdateModel } from './updateModel';
import { getChildId, getParentId } from './getOpsEquationsIds';
import { insertScenarioApiDataAtom, isApprovedScenarioAtom, scenarioAtom } from '../../../../hooks/wizardAtoms';
import {
    fulltimeEquationSelectionsAtom,
    showWlfConstants,
    useShowFulltimeEquation,
    useWlfEquationsState,
} from './opsFulltimeEquation/wlfEquationsState';
import { WlfEquations } from './opsFulltimeEquation/WlfEquations';
import {
    createAircrewModel,
    deleteAircrewModel,
    deleteOpsFulltimeEquation,
    fetchAircrewModels,
    fetchOpsFullTimeEquation,
    updateAircrewModel,
} from '../../aircrewApiCalls';
import { FulltimeEquationInputs } from './opsFulltimeEquation/FulltimeEquationInputs';
import { getScenario, updateModelId } from '../../scenarioApiCalls';
import { DeleteFullTimeEqnModal } from './DeleteFulltimeEqnModal';

const columns = [
    { header: '', key: 'radio' },
    { header: 'MDS', key: 'MDS' },
    { header: 'CMD', key: 'CMD' },
    { header: 'Model Name', key: 'MODEL_NAME' },
    { header: 'Crew Ratio', key: 'CREW_RATIO' },
    { header: 'Crew Comp Enl', key: 'CREW_COMP_ENL' },
    { header: 'Crew Comp Off', key: 'CREW_COMP_OFF' },
    { header: 'Notes', key: 'NOTES' },
    { header: 'Source', key: 'SOURCE' },
    { header: 'POC', key: 'POC' },
    { header: 'AFSC', key: 'AFSC' },
    { header: 'FAC', key: 'FAC' },
    { header: 'Status', key: 'STATUS' },
    { header: '', key: 'edit' },
];

export const OpsEquations = ({ onClose, onSubmit, open = false }) => {
    const [gsFilters] = useAtom(gsFiltersAtom);
    const { CMD, MDS, ILC } = gsFilters;

    // sessionStorage state
    const [scenario_id] = useFocusAtom(scenarioAtom, 'scenario_id');
    const [acr_id] = useFocusAtom(scenarioAtom, 'acr_id');
    const [eqn_id] = useFocusAtom(scenarioAtom, 'eqn_id');
    const insertScenarioApiData = useSetAtom(insertScenarioApiDataAtom);

    const showFulltimeEquation = useShowFulltimeEquation();
    const { fullWlfEquation, insertBulkData, submitAllUpdatedFulltimeEquations } = useWlfEquationsState();

    // local state
    const [fetchedData, setFetchedData] = useState(false);
    const [deleteOpsId, setDeleteOpsId] = useState(-1);
    const [deleteLevel, setDeleteLevel] = useState(-1);
    const [models, setModels] = useState([]);
    const [modelToUpdate, setModelToUpdate] = useState(null);
    const [action, setAction] = useState(null);
    // selectedRow = modelId
    const [selectedRow, setSelectedRow] = useState(-1);
    const [isLoading, setIsLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [openDeleteFulltimeModel, setOpenDeleteFulltimeModel] = useState(null);
    const [selections, setSelections] = useAtom(fulltimeEquationSelectionsAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const [openWlfEquationsModal, setOpenWlfEquationsModal] = useState(null);
    const [modelsUpdated, setModelsUpdated] = useState(null);
    const [aircrewType, setAircrewType] = useState('models');

    // Fetch and store modified data from api
    useEffect(() => {
        async function fetchData() {
            try {
                const { isOk, data } = await fetchAircrewModels({ mds: MDS, cmd: CMD });
                if (!isOk) {
                    throw new Error('Could not fetch model data');
                }
                const mappedData = data.map((modelData) => {
                    const modifiedData = { ...modelData };
                    const parentData = modelData.PARENT;
                    const childData = modelData.CHILDREN;
                    const parentKeys = ['ACR_ID', 'CMD', 'MDS', 'MODEL_NAME', 'POC', 'SOURCE'];

                    // add unique id to parent row
                    modifiedData.modelId = getParentId(parentData);
                    modifiedData.id = parentData.ID;

                    // add source and poc to parent row
                    modifiedData.PARENT.SOURCE = childData[0].SOURCE;
                    modifiedData.PARENT.POC = childData[0].POC;

                    // add unique id to each child row.
                    // remove common parent values from children
                    const newChildModelsData = childData.map((childModel) => {
                        const childId = getChildId(childModel, modifiedData.modelId);
                        const childProperties = Object.keys(childModel)
                            .filter((childKey) => parentKeys.includes(childKey) === false)
                            .reduce((a, b) => ({ ...a, [b]: childModel[b] }), {});
                        return {
                            ...childProperties,
                            id: childModel.ID,
                            childModelId: childId,
                            parentId: modifiedData.modelId,
                        };
                    });
                    modifiedData.CHILDREN = newChildModelsData;

                    return modifiedData;
                });
                const index = mappedData.findIndex((model) => model.PARENT.ACR_ID === acr_id);
                if (index > -1) {
                    setSelectedRow(mappedData[index].modelId);
                }
                setModels(mappedData);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        }
        if (open) {
            if (MDS && !fetchedData) {
                setFetchedData(true);
                setIsLoading(true);
                fetchData();
            }
        }
    }, [open, MDS, CMD, models.length, fetchedData, acr_id]);

    // Clean up local state because component does not unmount on close
    useEffect(() => {
        if (open === false) {
            setModels([]);
            setModelToUpdate(null);
            setAction(null);
            setSelectedRow(-1);
            setFetchedData(false);
            setModelsUpdated(null);
        }
    }, [open]);

    useEffect(() => {
        async function asyncMount(aircrewType) {
            const { isOk, data } = await fetchOpsFullTimeEquation({
                cmd: CMD,
                mds: MDS,
                ilc: ILC,
                aircrew_type: aircrewType,
            });
            const dataToInsert = isOk ? data : [];
            insertBulkData(dataToInsert, aircrewType);
        }
        const shortedCmd = CMD.split(' - ')[0] ?? '';
        if (open && (shortedCmd === 'AFR' || shortedCmd === 'ANG')) {
            asyncMount('OFF');
            asyncMount('ENL');
        }
    }, [CMD, ILC, MDS, insertBulkData, open]);

    useConditionalEffect(() => {
        const firstUnit = eqn_id ?? {};
        const enl = firstUnit.ENL ?? [];
        const off = firstUnit.OFF ?? [];

        setSelections({
            OFF: off.reduce((obj, id) => {
                return {
                    ...obj,
                    [id]: true,
                };
            }, {}),
            ENL: enl.reduce((obj, id) => {
                return {
                    ...obj,
                    [id]: true,
                };
            }, {}),
        });
    }, open);

    const handleOpenUpdateModel = (model, options = {}) => {
        const { childModel, modelId, modelAction } = options;
        if (model) {
            let MODEL_NAME = model.MODEL_NAME;
            if (modelAction === 'copy') {
                const date = new Date().toLocaleString('en-us', {
                    timeZone: 'UTC',
                });
                MODEL_NAME = MODEL_NAME + ' ' + date;
            }
            // handle edit
            setAction(modelAction);
            setModelToUpdate({
                parent: { ...model, MODEL_NAME },
                children: childModel,
                modelId: modelId,
            });
        } else {
            // handle add
            setAction('add');
            setModelToUpdate({ parent: { MDS: MDS, CMD: CMD }, children: [] });
        }
    };

    const handleCloseUpdateModel = () => {
        setAction(null);
        setModelToUpdate(null);
    };

    const handleAddUpdatedModel = async (newModel) => {
        const modelName = newModel.PARENT.MODEL_NAME;
        try {
            setSubmitting(true);
            if (action === 'edit') {
                const { isOk } = await updateAircrewModel(newModel, scenario_id);
                if (!isOk) {
                    throw new Error('Could not update model');
                } else {
                    setModelsUpdated({ type: 'update', modelName });
                }
            } else {
                const { isOk } = await createAircrewModel(newModel, scenario_id);
                if (!isOk) {
                    throw new Error('Could not create model');
                } else {
                    setModelsUpdated({ type: 'create', modelName });
                }
            }

            // Refetch data
            setFetchedData(false);
            setSubmitting(false);
            handleCloseUpdateModel();
        } catch (error) {
            console.error(error);
            setSubmitting(false);
        }
    };

    const handleSaveAndSubmit = async () => {
        const aircrewSelection = models.find((model) => model.modelId === selectedRow);

        let ftEqns = null;
        if (showFulltimeEquation !== showWlfConstants.NONE) {
            const reduceFtSelections = (arr, selectionsValue) => {
                const [id, isSelected] = selectionsValue;
                if (isSelected && fullWlfEquation[id]) {
                    return [...arr, parseInt(id)];
                }
                return arr;
            };
            const offEqns = Object.entries(selections.OFF || {}).reduce(reduceFtSelections, []);
            const enlEqns = Object.entries(selections.ENL || {}).reduce(reduceFtSelections, []);
            ftEqns = {
                OFF: offEqns,
                ENL: enlEqns,
            };

            const success = await submitAllUpdatedFulltimeEquations(scenario_id);
            if (!success) {
                return;
            }
        }

        const updateOpsBody = {
            acr_id: parseInt(aircrewSelection?.PARENT?.ACR_ID),
        };

        if (ftEqns) {
            updateOpsBody.eqn_id = ftEqns;
        }
        await updateModelId(scenario_id, updateOpsBody);
        const { isOk, data: updatedScenarioFromApi } = await getScenario(scenario_id);
        if (isOk) {
            insertScenarioApiData(updatedScenarioFromApi);
            setModelsUpdated(null);
        }
        onSubmit();
    };

    const handleSubmitWlfChanges = async (wlfEquation, editResult) => {
        if (editResult.isOk) {
            setOpenWlfEquationsModal(null);
        }
        const { isOk, data } = await fetchOpsFullTimeEquation({
            cmd: CMD,
            mds: MDS,
            ilc: ILC,
            aircrew_type: wlfEquation.aircrewType,
        });
        if (isOk) {
            insertBulkData(data, wlfEquation.aircrewType, true);
        }
    };

    const handleDelete = async (modelId) => {
        if (modelId === selectedRow) {
            setSelectedRow(-1);
        }
        const model = models.find((item) => item.modelId === modelId);
        if (model?.PARENT?.ACR_ID) {
            const { isOk } = await deleteAircrewModel(model.PARENT.ACR_ID);
            if (isOk) {
                setModelsUpdated({
                    type: 'delete',
                    modelName: model?.PARENT?.MODEL_NAME,
                });
            }
        }

        // possibly update to store model ac_id in atom
        // handles cases where deleted row is a parent
        if (deleteLevel === 0) {
            setModels(models.filter((model) => model.modelId !== modelId));
        }

        // handles where deleted row is child
        else if (deleteLevel === 1) {
            // given modelId get the parentModel
            // log models and parentModel and childModel
            const parentmodelId = modelId.split(':::')[0];
            const parentModelIndex = models.findIndex((model) => model.modelId === parentmodelId);
            const parentModel = { ...models[parentModelIndex] };
            parentModel.CHILDREN = parentModel.CHILDREN.filter((childModel) => childModel.childModelId !== modelId);

            const body = {
                PARENT: {
                    ACR_ID: parentModel.PARENT.ACR_ID,
                    CMD: parentModel.PARENT.CMD,
                    MDS: parentModel.PARENT.MDS,
                    MODEL_NAME: parentModel.PARENT.MODEL_NAME,
                },
                CHILDREN: parentModel.CHILDREN.map((childModel) => ({
                    AFSC: childModel.AFSC,
                    FAC: childModel.FAC,
                    NOTES: childModel.NOTES,
                    POC: childModel.POC,
                    SOURCE: childModel.SOURCE,
                    ARCRW_RATIO: childModel.CREW_RATIO,
                    ARCRW_COMP_ENL: childModel.CREW_COMP_ENL,
                    ARCRW_COMP_OFF: childModel.CREW_COMP_OFF,
                    CREW_POSITION: childModel.CREW_POSITION,
                    TABLE_DATE: childModel.TABLE_DATE ?? '',
                })),
            };
            await updateAircrewModel(body, scenario_id);

            // Refetch data
            setFetchedData(false);
        }
    };

    const isWlfEquationsModalVisible = openWlfEquationsModal !== null;
    const openUpdateModel = action !== null && modelToUpdate !== null;

    const inlineNotificationTitle = {
        update: `${modelsUpdated?.modelName} has been updated.`,
        delete: `${modelsUpdated?.modelName} has been deleted.`,
        create: `A new model has been created: ${modelsUpdated?.modelName}.`,
    };

    return (
        <div className="ops-equations-modal">
            <Modal
                open={open && !isWlfEquationsModalVisible}
                size="lg"
                className="ops-modal"
                onRequestClose={onClose}
                modalLabel="Update OPS Equations"
                primaryButtonDisabled={selectedRow === -1 || isApprovedScenario || openUpdateModel}
                primaryButtonText="Save & Submit"
                secondaryButtonText="Cancel"
                onRequestSubmit={handleSaveAndSubmit}
                data-testid="ops-equations-modal"
            >
                <ContentSwitcher
                    style={{
                        minWidth: 'fit-content',
                        width: 'fit-content',
                        marginRight: 'auto',
                    }}
                    className="mb-3"
                    selectedIndex={aircrewType === 'models' ? 0 : 1}
                    onChange={({ name }) => setAircrewType(name)}
                >
                    <Switch
                        name="models"
                        text="Aircrew Models"
                        disabled={isWlfEquationsModalVisible}
                        style={{
                            minWidth: 'fit-content',
                            width: 'fit-content',
                        }}
                        data-testid="aircrew-models-switch"
                    />
                    <Switch
                        name="fulltime-equations"
                        text="Fulltime Equations"
                        disabled={showFulltimeEquation === showWlfConstants.NONE || isWlfEquationsModalVisible}
                        style={{
                            minWidth: 'fit-content',
                            width: 'fit-content',
                        }}
                        data-testid="fulltime-equations-switch"
                    />
                </ContentSwitcher>
                {aircrewType === 'models' ? (
                    <div className="ops-equations-modal-top">
                        <section className="p-4 modal-body">
                            <h5>Model Selection</h5>
                            <p className="description mb-2">
                                Please select the model that should be used for the calculation:
                            </p>
                            <OpsSelectionTable
                                columns={columns}
                                models={models}
                                onEdit={handleOpenUpdateModel}
                                onDelete={(modelId, level) => {
                                    setDeleteOpsId(modelId);
                                    setDeleteLevel(level);
                                }}
                                onSelect={(modelId) => setSelectedRow(modelId)}
                                selectedId={selectedRow}
                                disabledEditButtons={openUpdateModel}
                                disabled={isApprovedScenario}
                                isLoading={isLoading && models?.length === 0}
                            />
                            <div className="d-flex align-items-center justify-content-between ms-auto mt-3">
                                <div style={{ width: 'fit-content' }}>
                                    {modelsUpdated && (
                                        <InlineNotification
                                            title=""
                                            subtitle={inlineNotificationTitle[modelsUpdated?.type]}
                                            kind={modelsUpdated?.type === 'delete' ? 'error' : 'success'}
                                            onClose={() => setModelsUpdated(null)}
                                        />
                                    )}
                                </div>
                                <Button
                                    kind="primary"
                                    size="md"
                                    renderIcon={Add}
                                    iconDescription="plus sign"
                                    onClick={() => handleOpenUpdateModel()}
                                    disabled={openUpdateModel || isApprovedScenario}
                                    data-testid="add-new-model"
                                >
                                    Add New Model
                                </Button>
                            </div>
                            {openUpdateModel ? (
                                <>
                                    <div className="divider" />
                                    <UpdateModel
                                        action={action}
                                        model={modelToUpdate}
                                        onCloseUpdateModel={handleCloseUpdateModel}
                                        onAddUpdatedModel={handleAddUpdatedModel}
                                        models={models}
                                        submitting={submitting}
                                    />
                                </>
                            ) : null}
                        </section>
                    </div>
                ) : (
                    <div className="ops-equations-modal-top">
                        <section className="p-4 modal-body">
                            {showFulltimeEquation !== showWlfConstants.NONE && (
                                <FulltimeEquationInputs
                                    open={open}
                                    scenarioId={scenario_id}
                                    onOpenClick={setOpenWlfEquationsModal}
                                    onDeleteModel={setOpenDeleteFulltimeModel}
                                />
                            )}
                        </section>
                    </div>
                )}
            </Modal>
            <Modal
                open={deleteOpsId !== -1}
                modalHeading="Are you sure you want to delete this model?"
                primaryButtonText="Delete"
                secondaryButtonText="Cancel"
                onRequestClose={() => setDeleteOpsId(-1)}
                onRequestSubmit={() => {
                    handleDelete(deleteOpsId);
                    setDeleteOpsId(-1);
                }}
                data-testid="ops-equations-delete-modal"
            />
            {isWlfEquationsModalVisible && (
                <WlfEquations
                    open={isWlfEquationsModalVisible}
                    onClose={() => setOpenWlfEquationsModal(null)}
                    onSubmit={handleSubmitWlfChanges}
                    eqnId={openWlfEquationsModal}
                    scenarioId={scenario_id}
                    ilc={ILC}
                    disabled={isApprovedScenario}
                />
            )}

            <DeleteFullTimeEqnModal
                openDeleteFulltimeModel={openDeleteFulltimeModel}
                setOpenDeleteFulltimeModel={setOpenDeleteFulltimeModel}
            />
        </div>
    );
};

OpsEquations.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    open: PropTypes.bool,
};
