import React, { useEffect } from 'react';
import { Login, User } from '@carbon/react/icons';
import { Tab, Tabs } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { loggedIn } from '../../helpers/jwt';
import { LoginForm } from './LoginForm';
import { RegistrationForm } from './RegistrationForm';
import { useAtom } from 'jotai';
import { isLoggedInAtom } from '../../utils/PrivateRoute';

export const AccountServices = () => {
    const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInAtom);

    useEffect(() => {
        (async () => {
            setIsLoggedIn(await loggedIn());
        })();
    }, [setIsLoggedIn]);

    if (isLoggedIn === null) {
        return <h1>Loading</h1>;
    } else if (isLoggedIn === false) {
        return (
            <div className="mt-5 align-self-center">
                <Tabs
                    defaultActiveKey="login"
                    id="justify-tab-example"
                    className="mb-3"
                    variant="pills"
                    justify
                >
                    <Tab
                        eventKey="login"
                        title={
                            <span>
                                <Login /> Login
                            </span>
                        }
                    >
                        <LoginForm />
                    </Tab>
                    <Tab
                        eventKey="registration"
                        title={
                            <span>
                                <User /> Registration
                            </span>
                        }
                    >
                        <RegistrationForm />
                    </Tab>
                </Tabs>
            </div>
        );
    } else {
        return <Navigate to="/manpower-wizard" />;
    }
};
