import React from 'react';
import './ReviewStep.scss';
import { ReviewSections } from './ReviewSections';

export const ReviewStep = () => {
    return (
        <div className="review-step">
            <ReviewSections />
        </div>
    );
};
