// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-scenario-modal .success-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.share-scenario-modal .success-body .success-checkmark {
  width: 10rem;
  height: 10rem;
}
.share-scenario-modal .cds--pagination, .share-scenario-modal .cds--pagination__control-buttons, .share-scenario-modal .cds--text-input, .share-scenario-modal .cds--text-area, .share-scenario-modal .cds--search-input, .share-scenario-modal .cds--select-input, .share-scenario-modal .cds--dropdown, .share-scenario-modal .cds--dropdown-list, .share-scenario-modal .cds--number input[type=number], .share-scenario-modal .cds--date-picker__input, .share-scenario-modal .cds--multi-select, .share-scenario-modal .cds--number__control-btn::before, .share-scenario-modal .cds--number__control-btn::after {
  background-color: var(--cds-field-02) !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/wizard/modals/shareScenario/ShareScenario.scss"],"names":[],"mappings":"AAEI;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AADR;AAGQ;EACI,YAAA;EACA,aAAA;AADZ;AAMI;EAaI,gDAAA;AAhBR","sourcesContent":[".share-scenario-modal {\n\n    .success-body {\n        width: 100%;\n        height: 100%;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        text-align: center;\n\n        .success-checkmark {\n            width: 10rem;\n            height: 10rem;\n        }\n    }\n\n\n    & .cds--pagination,\n    & .cds--pagination__control-buttons,\n    & .cds--text-input,\n    & .cds--text-area,\n    & .cds--search-input,\n    & .cds--select-input,\n    & .cds--dropdown,\n    & .cds--dropdown-list,\n    & .cds--number input[type=\"number\"],\n    & .cds--date-picker__input,\n    & .cds--multi-select,\n    & .cds--number__control-btn::before,\n    & .cds--number__control-btn::after {\n        background-color: var(--cds-field-02) !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
