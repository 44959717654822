export const newRowsToExclude = ({ rows, rowsSelected, scenario_id }) => {
    let rowsToExclude = [];
    rows.forEach((row) => {
        row.CHILDREN.forEach((child) => {
            let exclude = 0;
            if (!rowsSelected[child.parentId]) {
                exclude = 0;
            } else if (!rowsSelected[child.parentId].includes(child.id)) {
                exclude = 1;
            }
            rowsToExclude.push({
                SCENARIO_ID: scenario_id,
                UNIT: child.UNIT,
                PEC: child.PEC,
                RIC: child.RIC,
                OSC: child.OSC,
                FAC: child.FAC,
                AFS: child.AFS,
                API: child.API,
                MSI: child.MSI,
                POS_FUNDING: child.POS_FUNDING,
                EXCLUDE: exclude,
            });
        });
    });
    return rowsToExclude;
};
