export const validateFsStatus = (fsStatus) => {
    let paiTypes = {};
    for (const unit in fsStatus) {
        const row = fsStatus[unit];
        for (const pai in row) {
            if (row[pai] > 0) {
                paiTypes[pai] = true;
            }
        }
    }
    return Object.keys(paiTypes).length > 1 ? false : true;
};
