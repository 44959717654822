import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useOnClickYubikeyRegister, useOnClickYubikeyAuth } from './yubikeyHandlers';
import { useAtom } from 'jotai';
import { isLoggedInAtom } from '../../../utils/PrivateRoute';

/**
 * Sets TFA methods from getTFAMethods API route
 * Sets isLoggedIn on success or notificationData on error.
 * Updates state with yubikey option handlers.
 */

export function useFetchTFAMethods(setTFAMethods, updateTFAMethods) {
    let navigate = useNavigate();
    const [notificationData, setNotificationData] = useState({});
    const [isLoggedIn] = useAtom(isLoggedInAtom);

    /**
     * Handler for email authentication button.
     * Calls emailTFA route
     */
    const emailAuthOnClick = useCallback(async () => {
        try {
            const res = await axios.post('/api/emailTFA', {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                credentials: 'include',
            });
        } catch (err) {
            //TODO add error handling
        }
    }, []);

    const yubikeyAuthOnClick = useOnClickYubikeyAuth(setNotificationData);
    const yubikeyRegisterOnClick = useOnClickYubikeyRegister(setNotificationData, setTFAMethods);

    useEffect(() => {
        (async () => {
            try {
                await global.GetCSRFToken();
                const res = await axios.post(
                    '/api/getTFAMethods',
                    {
                        temp: 'temp',
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                const resTfa = res.data.TFAMethods;
                setTFAMethods((state) => {
                    let newState = {};
                    Object.keys(resTfa).forEach((label) => {
                        newState[label] = {
                            ...state[label],
                            value: resTfa[label],
                        };
                    });
                    return newState;
                });
            } catch (err) {
                console.log(err);
                if (err.response.data.status === 'unauthorized' || err.request.status === 403) navigate('/');
                else setNotificationData(err.response);
            }
        })();
    }, [navigate, setTFAMethods]);

    useEffect(() => {
        updateTFAMethods('email', { onClick: emailAuthOnClick });
        updateTFAMethods('yubiKey', { onClick: yubikeyAuthOnClick });
        updateTFAMethods('yubiKey', { onClickReg: yubikeyRegisterOnClick });
    }, [updateTFAMethods, emailAuthOnClick, yubikeyAuthOnClick, yubikeyRegisterOnClick]);

    return [isLoggedIn, notificationData, setNotificationData];
}
