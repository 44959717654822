import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@carbon/react';

const confirmationModalProps = {
    id: PropTypes.string,
    open: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof confirmationModalProps>>} props
 * @returns {React.ReactElement}
 */
export const ConfirmationModal = ({
    id = 'confirmation-modal',
    open = false,
    onSubmit,
    onClose,
    children = null,
    ...carbonModalProps
}) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            if (!loading) {
                setLoading(true);
                await onSubmit();
                onClose();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            open={open}
            onRequestClose={onClose}
            onRequestSubmit={handleSubmit}
            loadingStatus={loading ? 'active' : 'inactive'}
            {...carbonModalProps}
            data-testid={id}
        >
            {children}
        </Modal>
    );
};

ConfirmationModal.propTypes = confirmationModalProps;
