// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review-step .review-filters {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
}
.review-step .review-filters .filter-dropdown {
  flex: 0 1 auto;
  padding-right: 1.5rem;
}
.review-step .review-filters .scenario-dropdown {
  flex: 2 1 auto;
}
.review-step .review-content .chart-placeholder {
  width: 100%;
  min-height: 30vh;
}`, "",{"version":3,"sources":["webpack://./src/pages/wizard/steps/reviewStep/ReviewStep.scss"],"names":[],"mappings":"AAEI;EACI,aAAA;EACA,8BAAA;EACA,sBAAA;AADR;AAGQ;EACI,cAAA;EACA,qBAAA;AADZ;AAIQ;EACI,cAAA;AAFZ;AAOQ;EACI,WAAA;EACA,gBAAA;AALZ","sourcesContent":[".review-step {\n\n    .review-filters {\n        display: flex;\n        justify-content: space-between;\n        padding-bottom: 1.5rem;\n\n        .filter-dropdown {\n            flex: 0 1 auto;\n            padding-right: 1.5rem;\n        }\n\n        .scenario-dropdown {\n            flex: 2 1 auto;\n        }\n    }\n\n    .review-content {\n        .chart-placeholder {\n            width: 100%;\n            min-height: 30vh;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
