import { useCallback, useState } from 'react';

export const useExpandStates = () => {
    const [expandStates, setExpandStates] = useState({});

    const handleExpand = useCallback(
        (unitLevel) => {
            setExpandStates({
                ...expandStates,
                [unitLevel]: !expandStates[unitLevel],
            });
        },
        [expandStates]
    );

    return {
        expandStates,
        handleExpand,
    };
};
