import axios from 'axios';

export const saveAllExclusions = async ({ exclusionData, mpType }) => {
    try {
        await axios.post(
            `/api/fast-api/scenario/save_all_exclusions`,
            {
                data: exclusionData,
            },
            { params: { mp_type: mpType } }
        );
        return { success: true, error: null };
    } catch (error) {
        console.error(error);
        return { success: false, error };
    }
};
