import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    ButtonUtil,
    SelectUtil,
    TextAreaUtil,
    TextField,
    PasswordField,
    EmailField,
    PostResponseNotification,
    LinkUtil,
} from '../../utils';
import { Email, Password, User, Identification, Chat } from '@carbon/icons-react';
import './RegistrationForm.scss';

export const RegistrationForm = () => {
    const [email, setEmail] = useState({ value: '' });
    const [name, setName] = useState({ value: '' });
    const [password, setPassword] = useState({ value: '' });
    const [retypePassword, setRetypePassword] = useState({ value: '' });
    const [accountType, setAccountType] = useState({
        value: 'placeholder-item',
    });
    const [message, setMessage] = useState('');
    const [formStep, setFormStep] = useState('step1');
    const [notificationData, setNotificationData] = useState({});

    const [button1Disabled, setButton1Disabled] = useState(true);
    const [button2Disabled, setButton2Disabled] = useState(true);
    const emailCheck = !email.invalid && email.value.length > 0;
    const nameCheck = !name.invalid && name.value.length > 0;
    const passwordCheck = !password.invalid && password.value.length > 0;
    const duplicateCheck = !retypePassword.invalid && retypePassword.value.length > 0;
    const accountTypeCheck = !accountType.invalid && accountType.value !== 'placeholder-item';

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
        })();
    }, []);

    //validation for step 1
    useEffect(() => {
        if (emailCheck && nameCheck && passwordCheck && duplicateCheck) {
            setButton1Disabled(false);
        } else {
            setButton1Disabled(true);
        }
    }, [emailCheck, nameCheck, passwordCheck, duplicateCheck]);

    //validation for step 2
    useEffect(() => {
        if (accountTypeCheck) {
            setButton2Disabled(false);
        }
    }, [accountTypeCheck]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setButton2Disabled(true);
        (async () => {
            try {
                const res = await axios.post(
                    '/api/register',
                    {
                        email: email.value,
                        name: name.value,
                        password: password.value,
                        accountType: accountType.value.toLowerCase(),
                        message: message,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );

                //clear the form and show success if registration successful
                if (res.data.status === 'success') {
                    setEmail({ value: '' });
                    setName({ value: '' });
                    setPassword({ value: '' });
                    setRetypePassword({ value: '' });
                    setAccountType({ value: 'placeholder-item' });
                    setMessage('');
                    if (res.data.verifyEmail) {
                        setFormStep('successVerifyRequired');
                    } else {
                        setFormStep('successVerifyNotRequired');
                    }
                }
                setNotificationData(res);
            } catch (error) {
                setNotificationData(error.response);
            } finally {
                setTimeout(() => {
                    setButton2Disabled(false);
                }, '1000');
            }
        })();
    };

    const registrationFormSteps = {
        step1: (
            <>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: 15,
                        marginTop: 32,
                    }}
                >
                    <Email style={{ width: 30 }} />
                    <EmailField
                        textInput={{
                            ...email,
                            className: '',
                            labelText: (
                                <>
                                    Email <span style={{ color: 'red' }}>* </span>
                                </>
                            ),
                        }}
                        type="emailDomain"
                        validation={true}
                        setState={setEmail}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: 15,
                    }}
                >
                    <User style={{ width: 30 }} />
                    <TextField
                        textInput={{
                            ...name,
                            className: '',
                            labelText: (
                                <>
                                    Name <span style={{ color: 'red' }}>* </span>
                                </>
                            ),
                        }}
                        setState={setName}
                        type="name"
                        validation={true}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: 15,
                    }}
                >
                    <Password style={{ width: 30 }} />
                    <PasswordField
                        passwordInput={{
                            ...password,
                            className: '',
                            labelText: (
                                <>
                                    Password <span style={{ color: 'red' }}>* </span>
                                </>
                            ),
                        }}
                        type="password"
                        validation={true}
                        duplicate={retypePassword.value}
                        setState={setPassword}
                        setDuplicate={setRetypePassword}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: 15,
                    }}
                >
                    <Password style={{ width: 30 }} />
                    <PasswordField
                        passwordInput={{
                            ...retypePassword,
                            labelText: (
                                <>
                                    Confirm Password <span style={{ color: 'red' }}>* </span>
                                </>
                            ),
                        }}
                        type="duplicate"
                        validation={true}
                        original={password.value}
                        setState={setRetypePassword}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }} className="mt-4">
                    <LinkUtil
                        pathKey="accountServices"
                        link={{
                            reloadDocument: true,
                            children: 'Already have an Account?',
                            style: { marginLeft: '2em' },
                            className: 'small me-auto',
                        }}
                    />
                    <ButtonUtil
                        button={{
                            children: 'Next',
                            onClick: () => setFormStep('step2'),
                            disabled: button1Disabled,
                            type: 'submit',
                            kind: 'tertiary',
                        }}
                    />
                </div>
                <PostResponseNotification response={notificationData} />
            </>
        ),

        step2: (
            <>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: 15,
                        marginTop: 32,
                    }}
                >
                    <Identification style={{ width: 30 }} />
                    <SelectUtil
                        select={{
                            ...accountType,
                            id: 'registerSelect',
                            className: '',
                            labelText: (
                                <>
                                    Account Type <span style={{ color: 'red' }}>* </span>
                                </>
                            ),
                            invalidText: 'Select an account type.',
                        }}
                        placeholderItem={''}
                        type="accountType"
                        setState={setAccountType}
                        options={{
                            admin: 'Admin',
                            moderator: 'Moderator',
                            user: 'User',
                        }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginBottom: 15,
                    }}
                >
                    <Chat style={{ width: 30 }} />
                    <TextAreaUtil
                        textArea={{
                            value: message,
                            labelText: <>Give a description of your role</>,
                            maxCount: 400,
                        }}
                        setMessage={setMessage}
                    />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }} className="mt-4">
                    <ButtonUtil
                        button={{
                            children: 'Back',
                            onClick: () => setFormStep('step1'),
                            disabled: false,
                            type: 'button',
                            kind: 'tertiary',
                            style: { marginLeft: '2em' },
                            className: 'small me-auto',
                        }}
                    />
                    <ButtonUtil
                        button={{
                            children: 'Register',
                            onClick: handleSubmit,
                            disabled: button2Disabled,
                            type: 'submit',
                        }}
                    />
                </div>
                <PostResponseNotification response={notificationData} />
            </>
        ),
        successVerifyRequired: (
            <>
                <p className="py-3">Your application was received!</p>
                <p className="py-3">
                    To continue the registration process, verify that this email address is yours by following the link
                    we've just emailed you.
                </p>
            </>
        ),
        successVerifyNotRequired: (
            <>
                <p className="py-3">Your application was received!</p>
                <p className="py-3">You will be notified when your request has been approved by an administrator.</p>
            </>
        ),
    };

    return <div className="registration-form">{registrationFormSteps[formStep]}</div>;
};
