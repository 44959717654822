import React from 'react';
import { TextInput } from '@carbon/react';
import { InputValidator } from '../../helpers';

export const IpAddressField = ({ ipAddress = {}, other = {} }) => {
    if (other.type === undefined) {
        other.type = 'ip';
    }

    const ipAddressProps = {
        invalidText: `Please enter a valid IP address.`,
        placeholder: '',
        labelText: '',
        id: `${other.type}${Math.random().toString()}`,
        className: 'my-3',
        required: false,
        onChange: (e) => handleChange(e),
        ...ipAddress,
        invalid: ipAddress.value !== '' ? ipAddress.invalid : false,
    };

    const handleChange = (e) => {
        if (other.validation) {
            var validate = InputValidator({ type: other.type, value: e.target.value });
        }
        other.setState({
            value: e.target.value,
            invalid: validate === undefined ? false : !validate,
        });
    };
    return <TextInput {...ipAddressProps} />;
};
