import { ComboBox, Modal } from '@carbon/react';
import { atom, useAtom, useSetAtom } from 'jotai';
import {
    CMDFiltersAtom,
    ILCFiltersAtom,
    MDSFiltersAtom,
    filtersAtom,
} from '../../steps/generalSettings/GeneralSettingsAtoms';
import './modal.scss';
import { useState } from 'react';
import { useFocusAtom } from '../../../../hooks';
import { scenarioAtom } from '../../../../hooks/wizardAtoms';
import { fetchFiltersCombined } from '../../../../httpRequests/filtersCombined';
import { comboBoxShouldFilterItem } from '../../../../helpers';
import axios from 'axios';

const addMdsModalStateAtom = atom({});

const initialInvalidState = {
    CMD: false,
    ILC: false,
    MDS: false,
    NAME: false,
    CODE: false,
};

export const AddMdsModal = ({ open, onClose }) => {
    const [invalid, setInvalid] = useState(initialInvalidState);
    const setFilters = useSetAtom(filtersAtom);
    const [CMDFilters] = useAtom(CMDFiltersAtom);
    const [MDSFilters] = useAtom(MDSFiltersAtom);
    const [ILCFilters] = useAtom(ILCFiltersAtom);
    const [addMdsModalState, setAddMdsModalState] = useAtom(addMdsModalStateAtom);
    const [scenario_id] = useFocusAtom(scenarioAtom, 'scenario_id');

    const isValid = addMdsModalState['CMD'] && addMdsModalState['ILC'] && addMdsModalState['MDS'];

    const handleCloseModal = () => {
        setAddMdsModalState({});
        onClose();
    };

    const handleSubmit = () => {
        if (!addMdsModalState['CMD'] || !addMdsModalState['ILC'] || !addMdsModalState['MDS']) {
            setInvalid({
                CMD: !addMdsModalState['CMD'] ? true : false,
                ILC: !addMdsModalState['ILC'] ? true : false,
                MDS: !addMdsModalState['MDS'] ? true : false,
            });
            return;
        }

        createMds(scenario_id, addMdsModalState);
        handleCloseModal();
    };

    const createMds = async (scenario_id, input) => {
        if (isValid) {
            try {
                const { res } = await axios.post(`/api/fast-api/filters/create_mds`, {
                    SCENARIO_ID: scenario_id,
                    CMD: input.CMD,
                    ILC: input.ILC,
                    MDS: input.MDS,
                });
                return res;
            } catch (error) {
                console.error(error);
            } finally {
                const { data } = await fetchFiltersCombined();
                setFilters(data);
            }
        }
    };

    return (
        <Modal
            open={open}
            modalHeading="Add MDS"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            onRequestClose={handleCloseModal}
            onRequestSubmit={handleSubmit}
        >
            <div className="row">
                <div className="col-4" data-qa="amm-CMD-dropdown-select">
                    <ComboBox
                        id={`CMD-dropdown`}
                        titleText={'CMD'}
                        items={CMDFilters}
                        selectedItem={addMdsModalState['CMD'] ?? ''}
                        invalid={invalid['CMD']}
                        invalidText="Please select a CMD."
                        shouldFilterItem={comboBoxShouldFilterItem}
                        onChange={({ selectedItem }) => {
                            setInvalid({
                                ...invalid,
                                CMD: false,
                            });
                            setAddMdsModalState({
                                ...addMdsModalState,
                                CMD: selectedItem,
                            });
                        }}
                    />
                </div>
                <div className="col-4" data-qa="amm-ILC-dropdown-select">
                    <ComboBox
                        id={`ILC-dropdown`}
                        titleText={'ILC'}
                        items={ILCFilters}
                        selectedItem={addMdsModalState['ILC'] ?? ''}
                        invalid={invalid['ILC']}
                        invalidText="Please select an ILC."
                        shouldFilterItem={comboBoxShouldFilterItem}
                        onChange={({ selectedItem }) => {
                            setInvalid({
                                ...invalid,
                                ILC: false,
                            });
                            setAddMdsModalState({
                                ...addMdsModalState,
                                ILC: selectedItem,
                            });
                        }}
                    />
                </div>
                <div className="col-4" data-qa="amm-MDS-dropdown-select">
                    <ComboBox
                        id={`MDS-dropdown`}
                        titleText={'MDS'}
                        items={MDSFilters}
                        selectedItem={addMdsModalState['MDS'] ?? ''}
                        invalid={invalid['MDS']}
                        invalidText="Please select an MDS."
                        shouldFilterItem={comboBoxShouldFilterItem}
                        onChange={({ selectedItem }) => {
                            setInvalid({
                                ...invalid,
                                MDS: false,
                            });
                            setAddMdsModalState({
                                ...addMdsModalState,
                                MDS: selectedItem,
                            });
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};
