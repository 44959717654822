import { useMemo } from 'react';
import { atom, useAtom } from 'jotai';
import { useReducerAtom } from 'jotai/utils';

import { gsFiltersAtom } from '../../../steps/generalSettings/GeneralSettingsAtoms';
import { createFulltimeEquation, editFulltimeEquation } from '../../../aircrewApiCalls';

export const fulltimeEquationSelectionsAtom = atom({
    ENL: {},
    OFF: {},
});

export const showWlfConstants = {
    PARTIAL: 'PARTIAL',
    FULL: 'FULL',
    NONE: 'NONE',
};

const showWlfEquationsAtom = atom((get) => {
    const cmd = get(gsFiltersAtom).CMD;
    const cmdShorthand = cmd.split(' - ')[0];
    if (cmdShorthand === 'ANG') {
        return showWlfConstants.PARTIAL;
    }
    if (cmdShorthand === 'AFR') {
        return showWlfConstants.FULL;
    }
    return showWlfConstants.NONE;
});

const wlfEquationAtom = atom({
    enlEqnIds: [],
    offEqnIds: [],
});

export const useShowFulltimeEquation = () => {
    return useAtom(showWlfEquationsAtom)[0];
};

const actionConstants = Object.freeze({
    addWlfQueryRow: 'add_row',
    updateInput: 'update_input',
    updateRootInput: 'update_root_input',
    deleteRow: 'delete_row',
    reset: 'reset',
    insertBulkData: 'insert_bulk_data',
    highlightInvalidFields: 'highlight_invalid_fields',
});

const initWlfEquation = { field: '', criteria: null, search: '' };

const initWlfEquationState = {
    fac: '',
    osc: '',
    afsc: '',
    iaf: 1,
    constant: '',
    coefficient: '',
    wlfType: '',
    maf: '',
    customValue: '',
    inputs: [],
    updatedRootInput: false,
    canReset: false,
    enlEqnIds: [],
    offEqnIds: [],
};

/**
 *
 * @param {{
 *      enlEqnIds: (number | string)[],
 *      offEqnIds: (number | string)[],
 *      [eqnId]: {
 *          inputs: {field: string; criteria: null | string; search: string}[]
 *          dataInputs: {field: string; criteria: null | string; search: string}[]
 *          constant: number | null;
 *          dataConstant: number | null;
 *          id: number | null;
 *          value: number;
 *          pos: number;
 *      }
 * }} prevState
 * @param {{
 *      type: string,
 *      wlf: string,
 *      pos: string,
 *      eqnId: number | string,
 *      payload?: any,
 *      index?: number,
 *      inputType?: 'fac' | 'osc' | 'asfc' | 'constant' | 'coefficient',
 *      input?: 'field' | 'criteria' | 'search',
 * }} action
 * @returns new state
 */
const wlfEquationReducer = (prevState, action) => {
    const { eqnId } = action;
    switch (action.type) {
        case actionConstants.addWlfQueryRow: {
            const inputs = prevState[eqnId]?.inputs ?? [];
            return {
                ...prevState,
                [eqnId]: {
                    ...prevState[eqnId],
                    inputs: [...inputs, { ...initWlfEquation, id: inputs.length + 1 }],
                },
            };
        }
        case actionConstants.deleteRow: {
            const inputs = prevState[eqnId]?.inputs || [];
            const copy = [...inputs];
            copy.pop();
            return {
                ...prevState,
                [eqnId]: {
                    ...prevState[eqnId],
                    inputs: copy,
                },
            };
        }
        case actionConstants.updateInput: {
            const inputs = prevState[eqnId]?.inputs || [];
            const copy = [...inputs];
            copy[action.index] = {
                ...copy[action.index],
                [action.input]: action.payload,
            };
            const newState = {
                ...prevState,
                [eqnId]: {
                    ...prevState[eqnId],
                    inputs: copy,
                },
            };
            return newState;
        }
        case actionConstants.updateRootInput: {
            return {
                ...prevState,
                [eqnId]: {
                    ...prevState[eqnId],
                    [action.inputType]: action.payload,
                    [`${action.inputType}Error`]: false,
                    updatedRootInput: true,
                },
            };
        }
        case actionConstants.insertBulkData: {
            if (action.meta.keepState) {
                return {
                    ...action.payload.equations,
                    // keep previous equations
                    ...prevState,
                    ...action.payload.eqnIds,
                };
            }
            return {
                ...prevState,
                ...action.payload.equations,
                ...action.payload.eqnIds,
            };
        }
        case actionConstants.reset:
            return {
                ...prevState,
                [eqnId]: {
                    ...initWlfEquationState,
                    ...prevState[`${eqnId}_data`],
                    updatedRootInput: false,
                },
            };
        case actionConstants.highlightInvalidFields: {
            const fields = { ...prevState[eqnId] };
            Object.keys(initWlfEquationState).forEach((key) => {
                if (typeof fields[key] === 'string' && fields[key] === '') {
                    fields[`${key}Error`] = true;
                }
            });

            return {
                ...prevState,
                [eqnId]: {
                    ...fields,
                },
            };
        }
        default:
            return prevState;
    }
};

export const useWlfEquationsState = (eqnId) => {
    const [fullWlfEquation, dispatch] = useReducerAtom(wlfEquationAtom, wlfEquationReducer);
    const [gsFilters = {}] = useAtom(gsFiltersAtom);

    const handlers = useMemo(
        () => ({
            insertBulkData: (dataList, aircrewType, keepState = false) => {
                const dataCopy = {};
                const fulltimeEquations = {};
                dataList.forEach((data) => {
                    fulltimeEquations[data.eqnId] = {
                        ...initWlfEquationState,
                        ...data,
                        inputs: data.inputs ?? [],
                        updatedRootInput: false,
                        canReset: data.isBaseline === false,
                    };
                    dataCopy[`${data.eqnId}_data`] = { ...data };
                });
                const [offEqnIds, enlEqnIds] = dataList.reduce(
                    (tuple, current) => {
                        if (current.aircrewType === 'OFF') {
                            return [[...tuple[0], current.eqnId], tuple[1]];
                        }
                        return [tuple[0], [...tuple[1], current.eqnId]];
                    },
                    [[], []]
                );

                const eqnIds = {};
                if (aircrewType === 'OFF') {
                    eqnIds.offEqnIds = offEqnIds;
                }
                if (aircrewType === 'ENL') {
                    eqnIds.enlEqnIds = enlEqnIds;
                }
                dispatch({
                    type: actionConstants.insertBulkData,
                    payload: {
                        equations: {
                            ...fulltimeEquations,
                            ...dataCopy,
                        },
                        eqnIds,
                    },
                    meta: { keepState },
                });
            },
            addWlfQueryRow: () => {
                dispatch({
                    type: actionConstants.addWlfQueryRow,
                    eqnId,
                });
            },
            deleteRow: () => {
                dispatch({
                    type: actionConstants.deleteRow,
                    eqnId,
                });
            },
            reset: (eqnIdToReset, keepData = true) => {
                if (eqnIdToReset) {
                    dispatch({
                        type: actionConstants.reset,
                        eqnId: eqnIdToReset,
                        payload: { keepData },
                    });
                }
            },
            updateInput: ({ index, input, value }) => {
                dispatch({
                    type: actionConstants.updateInput,
                    eqnId,
                    payload: value,
                    index,
                    input,
                });
            },
            updateRootInput: ({ value, inputType }) => {
                dispatch({
                    type: actionConstants.updateRootInput,
                    payload: value,
                    inputType,
                    eqnId,
                });
            },
            updateErrors: (overrideEqnId) => {
                dispatch({
                    type: actionConstants.highlightInvalidFields,
                    eqnId: overrideEqnId ?? eqnId,
                });
            },
        }),
        [dispatch, eqnId]
    );

    const submitFullTimeEquation = async ({ wlfEquation, scenarioId }) => {
        let wasSuccessful = true;
        const { aircrewType } = wlfEquation;
        const wlf = {};
        if (wlfEquation.wlfType.toUpperCase() === 'SEARCH CRITERIA') {
            wlf.QUERY = wlfEquation.inputs.map(({ field, criteria, search }) => ({
                FIELD: field,
                CRITERIA: criteria,
                REGEX: search,
            }));
        } else if (wlfEquation.wlfType.toUpperCase() === 'CUSTOM VALUE') {
            wlf.VALUE = `${wlfEquation.customValue}`;
        }

        if (wlfEquation.eqnId && !wlfEquation.isBaseline) {
            const { isOk } = await editFulltimeEquation(scenarioId, {
                eqn_id: wlfEquation.eqnId,
                fac: wlfEquation.fac,
                osc: wlfEquation.osc,
                afsc: wlfEquation.afsc,
                iaf: wlfEquation.iaf,
                ilc: wlfEquation.ilcSpecific ?? 'ALL',
                constant: wlfEquation.constant || 0,
                coefficient: wlfEquation.coefficient || 0,
                maf: wlfEquation.maf || null,
                aircrew_type: aircrewType,
                wlf,
            });
            if (!isOk) {
                handlers.updateErrors(wlfEquation.eqnId);
                wasSuccessful = false;
            }
        } else {
            const { isOk } = await createFulltimeEquation(scenarioId, {
                cmd: gsFilters.CMD,
                mds: gsFilters.MDS,
                fac: wlfEquation.fac,
                osc: wlfEquation.osc,
                afsc: wlfEquation.afsc,
                iaf: wlfEquation.iaf,
                ilc: wlfEquation.ilcSpecific ?? 'ALL',
                constant: wlfEquation.constant || 0,
                coefficient: wlfEquation.coefficient || 0,
                maf: wlfEquation.maf || null,
                aircrew_type: aircrewType,
                wlf,
            });
            if (!isOk) {
                handlers.updateErrors(wlfEquation.eqnId);
                wasSuccessful = false;
            }
        }
        return wasSuccessful;
    };

    const submitAllUpdatedFulltimeEquations = async (scenarioId) => {
        const updateEqnsPromises = fullWlfEquation.offEqnIds.concat(fullWlfEquation.enlEqnIds).map((eqnId) => {
            const wlfEquation = fullWlfEquation[eqnId];
            if (wlfEquation?.updatedRootInput) {
                return submitFullTimeEquation({ wlfEquation, scenarioId });
            }
            return null;
        });
        const responses = await Promise.all(updateEqnsPromises).catch(() => {
            return [false];
        });
        return responses.every((response) => response !== false);
    };

    const wlfEquation = { ...initWlfEquationState, ...fullWlfEquation[eqnId] };
    return {
        enlEqnIds: fullWlfEquation.enlEqnIds,
        offEqnIds: fullWlfEquation.offEqnIds,
        wlfEquation,
        fullWlfEquation,
        submitAllUpdatedFulltimeEquations,
        ...handlers,
    };
};
