import { TextInput } from '@carbon/react';
import { checkIfInputIsInvalid, invalidTextBasedOnColumn } from './mxInputValidation';

export const MxValidationTextInput = ({
    id,
    testId,
    labelText = '',
    cellHeader,
    value,
    isRequired,
    invalid = false,
    onChange,
    disabled = false,
}) => {
    if (checkIfInputIsInvalid(value, cellHeader)) {
        invalid = true;
    }
    let invalidText = invalidTextBasedOnColumn[cellHeader];
    if (value === '' && isRequired) {
        invalidText = 'Field is required.';
    }
    return (
        <TextInput
            id={id}
            data-testid={testId}
            disabled={disabled}
            value={cellHeader === 'WC_TITLE' || cellHeader === 'FAC_TITLE' ? value : value?.toUpperCase()}
            labelText={labelText}
            onChange={onChange}
            invalid={invalid}
            invalidText={invalidText}
        />
    );
};
