import { Button, ComboBox } from '@carbon/react';
import React from 'react';
import { useAtom } from 'jotai';
import { filtersAtom, gsFiltersAtom } from './GeneralSettingsAtoms';
import { comboBoxShouldFilterItem } from '../../../../helpers';
import PropTypes from 'prop-types';
import { isApprovedScenarioAtom } from '../../../../hooks/wizardAtoms';

export const ForceStructureInteraction = ({ resetFilters, disabled }) => {
    return (
        <div className="force-structure-interaction">
            <div className="fs-dropdowns-wrapper">
                <FSDropdowns />
                <Button
                    disabled={disabled}
                    onClick={() => resetFilters()}
                    size="md"
                    className="reset-button"
                    kind="ghost"
                >
                    Reset
                </Button>
            </div>
        </div>
    );
};

const FSDropdowns = () => {
    const [gsFilters, setGsFilters] = useAtom(gsFiltersAtom);
    const [filters] = useAtom(filtersAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);

    const filteredSettings = (setting) => {
        const allSettings = ['MDS', 'CMD', 'ILC'];
        const otherSettings = allSettings.filter((listedSetting) => listedSetting !== setting);

        const otherSettingA = otherSettings[0]; //MDS
        const otherSettingB = otherSettings[1]; //CMD
        const otherSettingValueA = gsFilters[otherSettingA]; //Thunderbolt
        const otherSettingValueB = gsFilters[otherSettingB]; //ACC

        const otherSelectedA = otherSettingValueA !== '';
        const otherSelectedB = otherSettingValueB !== '';
        const bothSelected = otherSelectedA && otherSelectedB;
        const noneSelected = !otherSelectedA && !otherSelectedB;

        const determineCallback = () => {
            if (bothSelected) {
                return 'both';
            } else if (!bothSelected && otherSelectedA) {
                return otherSettings[0];
            } else if (!bothSelected && otherSelectedB) {
                return otherSettings[1];
            } else if (noneSelected) {
                return 'none';
            }
        };
        const callbacks = {
            both: (combination) =>
                combination[`${otherSettingA}_SELECT`] === gsFilters[otherSettingA] &&
                combination[`${otherSettingB}_SELECT`] === gsFilters[otherSettingB],
            none: () => true,
            [otherSettingA]: (combination) => combination[`${otherSettingA}_SELECT`] === gsFilters[otherSettingA],
            [otherSettingB]: (combination) => combination[`${otherSettingB}_SELECT`] === gsFilters[otherSettingB],
        };

        const filteredCombinations = filters.filter(callbacks[determineCallback()]);
        const filteredValues = [
            ...new Set(filteredCombinations.map((filteredCombination) => filteredCombination[`${setting}_SELECT`])),
        ].sort();
        return filteredValues;
    };

    return (
        <div className="fs-dropdowns">
            <div data-testid="fsi-CMD-dropdown-select">
                <Dropdown
                    disabled={isApprovedScenario}
                    items={filteredSettings('CMD')}
                    dropdownName="CMD"
                    gsFilters={gsFilters}
                    setGsFilters={setGsFilters}
                />
            </div>
            <div data-testid="fsi-ILC-dropdown-select">
                <Dropdown
                    disabled={isApprovedScenario}
                    items={filteredSettings('ILC')}
                    dropdownName="ILC"
                    gsFilters={gsFilters}
                    setGsFilters={setGsFilters}
                />
            </div>
            <div data-testid="fsi-MDS-dropdown-select">
                <Dropdown
                    disabled={isApprovedScenario}
                    items={filteredSettings('MDS')}
                    dropdownName="MDS"
                    gsFilters={gsFilters}
                    setGsFilters={setGsFilters}
                />
            </div>
        </div>
    );
};

const Dropdown = ({ items, gsFilters, setGsFilters, dropdownName, disabled }) => {
    const fieldName = {
        CMD: 'Major Command',
        MDS: 'Mission Design Series',
        ILC: 'Installation Location Name',
    };
    return (
        <div className="dropdown">
            <ComboBox
                id={`${dropdownName}Dropdown`}
                disabled={disabled}
                titleText={fieldName[dropdownName]}
                label=""
                items={items}
                selectedItem={gsFilters[dropdownName]}
                shouldFilterItem={comboBoxShouldFilterItem}
                onChange={({ selectedItem }) => {
                    setGsFilters({
                        ...gsFilters,
                        [dropdownName]: selectedItem || '',
                    });
                }}
            />
        </div>
    );
};

Dropdown.propTypes = {
    items: PropTypes.arrayOf(PropTypes.string),
    gsFilters: PropTypes.objectOf(PropTypes.string).isRequired,
    setGsFilters: PropTypes.func.isRequired,
    dropdownName: PropTypes.string,
    disabled: PropTypes.bool,
};

ForceStructureInteraction.propTypes = {
    resetFilters: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
