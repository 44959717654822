import { TextInput } from '@carbon/react';

export const PhoneField = ({ textInput = {}, isInvalid, setIsInvalid, setPhoneNumber, phoneNumber }) => {
    const handleChange = (e) => {
        let cleanedNum = e.target.value.replace(/\D/g, '').substr(1, 10);

        setPhoneNumber(cleanedNum);
    };
    const formatPhoneNumber = (val) => {
        if (val.length > 6) {
            return `+1 (${val.substr(0, 3)}) ${val.substr(3, 3)}-${val.substr(6)}`;
        } else if (val.length > 3) {
            return `+1 (${val.substr(0, 3)}) ${val.substr(3)}`;
        }
        return '+1 ' + val;
    };
    const validateNumber = (e) => {
        if (phoneNumber.length !== 10) {
            setIsInvalid(true);
        } else {
            setIsInvalid(false);
        }
    };

    const textInputProps = {
        id: `phone${Math.random().toString()}`,
        invalid: isInvalid,
        className: 'my-3',
        onBlur: validateNumber,
        value: formatPhoneNumber(phoneNumber),
        onChange: handleChange,
        labelText: 'Phone Number',
        ...textInput,
    };
    return <TextInput {...textInputProps} />;
};
