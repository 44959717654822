import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAtom } from 'jotai';
import { Button } from '@carbon/react';
import { AddFilled } from '@carbon/react/icons';

import { useShowFulltimeEquation, useWlfEquationsState } from './wlfEquationsState';
import { gsFiltersAtom } from '../../../steps/generalSettings/GeneralSettingsAtoms';
import { createFulltimeEquation, fetchOpsFullTimeEquation } from '../../../aircrewApiCalls';
import { FulltimeEquationDataTable } from './FulltimeEquationDataTable';
import { isApprovedScenarioAtom } from '../../../../../hooks/wizardAtoms';

export const FulltimeEquationInputs = ({
    onOpenClick,
    onDeleteModel,
    scenarioId,
    unitId,
    open = false,
    // onlyUserCanEdit is admin experience. From /admin/create-models
    onlyUserCanEdit = false,
    ilcList = [],
}) => {
    const { enlEqnIds, offEqnIds, insertBulkData, fullWlfEquation } = useWlfEquationsState();
    const [gsFilters = {}] = useAtom(gsFiltersAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const showFulltimeEquation = useShowFulltimeEquation();

    const { CMD = '', MDS = '', ILC = '' } = gsFilters;

    // Keep a ref of the eqn_id object to be passed into useEffect
    // but stays current with renders
    // const opsSelectionRef = useRef(eqn_id);
    // opsSelectionRef.current = eqn_id;

    const shorthandILC = useMemo(() => {
        const ilcTuple = ILC.split(' - ');
        return ilcTuple[ilcTuple.length - 1] || '';
    }, [ILC]);

    const handleAddRow = async (aircrewType, fulltimeEqn = {}) => {
        await createFulltimeEquation(scenarioId, {
            cmd: CMD,
            mds: MDS,
            ilc: ILC,
            iaf: 1,
            wlf: {},
            aircrew_type: aircrewType,
            ...fulltimeEqn,
        });
        const { isOk, data } = await fetchOpsFullTimeEquation({
            cmd: CMD,
            mds: MDS,
            ilc: ILC,
            aircrew_type: aircrewType,
        });
        if (isOk) {
            insertBulkData(data, aircrewType, true);
        }
    };

    const offEqnRows = offEqnIds.map((id) => ({ ...fullWlfEquation[id], id }));
    const enlEqnRows = enlEqnIds.map((id) => ({ ...fullWlfEquation[id], id }));

    return (
        <React.Fragment>
            <div className="d-flex mt-2">
                <h5 style={{ width: 'fit-content' }}>Fulltime Equation: Officer</h5>
                <Button
                    kind="ghost"
                    size="sm"
                    disabled={isApprovedScenario}
                    renderIcon={AddFilled}
                    iconDescription="plus sign"
                    onClick={() => handleAddRow('OFF')}
                    data-testid="add-new-fulltime-eqn"
                ></Button>
            </div>
            <div className="fulltime-equations-datatable">
                {offEqnRows.length > 0 ? (
                    <FulltimeEquationDataTable
                        eqnRows={offEqnRows}
                        open={open}
                        disabled={isApprovedScenario}
                        showFulltimeEquation={showFulltimeEquation}
                        ILC={shorthandILC}
                        onOpenClick={onOpenClick}
                        onDeleteModel={onDeleteModel}
                        onDuplicateRow={handleAddRow}
                        aircrewType="OFF"
                        onlyUserCanEdit={onlyUserCanEdit}
                        ilcList={ilcList}
                    />
                ) : null}
            </div>
            <div className="d-flex mt-3">
                <h5 style={{ width: 'fit-content' }}>Fulltime Equation: Enlisted</h5>
                <Button
                    kind="ghost"
                    size="sm"
                    disabled={isApprovedScenario}
                    renderIcon={AddFilled}
                    iconDescription="plus sign"
                    onClick={() => handleAddRow('ENL')}
                    data-testid="add-new-fulltime-eqn"
                ></Button>
            </div>
            <div className="fulltime-equations-datatable">
                {enlEqnRows.length > 0 ? (
                    <FulltimeEquationDataTable
                        eqnRows={enlEqnRows}
                        open={open}
                        disabled={isApprovedScenario}
                        showFulltimeEquation={showFulltimeEquation}
                        ILC={shorthandILC}
                        onOpenClick={onOpenClick}
                        onDeleteModel={onDeleteModel}
                        onDuplicateRow={handleAddRow}
                        aircrewType="ENL"
                        onlyUserCanEdit={onlyUserCanEdit}
                        ilcList={ilcList}
                    />
                ) : null}
            </div>
        </React.Fragment>
    );
};
FulltimeEquationInputs.propTypes = {
    onOpenClick: PropTypes.func.isRequired,
    onDeleteModel: PropTypes.func.isRequired,
    scenarioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    open: PropTypes.bool,
};
