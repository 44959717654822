// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `Footer {
  font-size: 12px;
  text-align: center;
}

#root {
  height: 100vh;
}

.hidden {
  display: none;
}

.cds--data-table th,
.cds--data-table td {
  padding-left: var(--cds-spacing-03, 0.5rem);
  padding-right: var(--cds-spacing-03, 0.5rem);
}
.cds--data-table .cds--table-sort {
  padding-left: var(--cds-spacing-03, 0.5rem);
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAKI;;EAEI,2CAAA;EACA,4CAAA;AAFR;AAKI;EACI,2CAAA;AAHR","sourcesContent":["Footer {\n    font-size: 12px;\n    text-align: center;\n}\n\n#root {\n    height: 100vh;\n}\n\n.hidden {\n    display: none;\n}\n\n// globalcarbon data table customization\n.cds--data-table {\n\n    th,\n    td {\n        padding-left: var(--cds-spacing-03, 0.5rem);\n        padding-right: var(--cds-spacing-03, 0.5rem);\n    }\n\n    .cds--table-sort {\n        padding-left: var(--cds-spacing-03, 0.5rem);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
