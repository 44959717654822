// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#forgot-pass-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#forgot-pass-content h4 {
  padding: 1rem 5rem 0 7rem;
}
#forgot-pass-content .email-content {
  display: flex;
  margin-top: 2rem;
}
#forgot-pass-content .email-content svg {
  width: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/accountServices/Styles/ForgotPassword.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACI,yBAAA;AACR;AAEI;EACI,aAAA;EACA,gBAAA;AAAR;AAEQ;EACI,WAAA;AAAZ","sourcesContent":["#forgot-pass-content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    h4 {\n        padding: 1rem 5rem 0 7rem;\n    }\n\n    .email-content {\n        display: flex;\n        margin-top: 2rem;\n\n        svg {\n            width: 2rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
