// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_notification--container {
  position: fixed;
  top: 5%;
  left: 0;
  right: 0;
  z-index: 9999;
  pointer-events: none;
}
.error_notification--container > div {
  pointer-events: initial;
  min-width: 25rem;
  min-height: 7rem;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/common/ErrorNotification/ErrorNotification.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,OAAA;EACA,OAAA;EACA,QAAA;EAEA,aAAA;EACA,oBAAA;AAAJ;AAEI;EACI,uBAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;AAAR","sourcesContent":[".error_notification--container {\n    position: fixed;\n    top: 5%;\n    left: 0;\n    right: 0;\n    // transform: translateX(50%);\n    z-index: 9999;\n    pointer-events: none;\n\n    &>div {\n        pointer-events: initial;\n        min-width: 25rem;\n        min-height: 7rem;\n        margin: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
