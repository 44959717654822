import React from 'react';
import {IconButton} from '@carbon/react';
import {SoftwareResource} from '@carbon/icons-react';

export const IconButtonUtil = ({iconButton = {}}) => {
    const handleClick = () => {
        console.log('no onClick function provided');
    };

    const iconButtonProps = {
        children: <SoftwareResource />,
        kind: 'ghost',
        label: 'Icon Button',
        onClick: handleClick,
        ...iconButton,
    };

    return <IconButton {...iconButtonProps} />;
};
