import { useMemo } from 'react';
import { atom, useAtom } from 'jotai';

export const errorNotificationIsOpenAtom = atom(false);
const initialErrorMessage = {
    title: 'Error',
    kind: 'error',
    subtitle: '',
};
export const errorNotificationMessageAtom = atom(initialErrorMessage);

export const useErrorNotification = () => {
    const [isOpen, setIsOpen] = useAtom(errorNotificationIsOpenAtom);
    const [errorNotificationMessage, setErrorNotificationMessage] = useAtom(errorNotificationMessageAtom);

    const handlers = useMemo(() => {
        return {
            /**
             * @param {{
             *  title?: string,
             *  kind?: 'error' | 'success',
             *  subtitle?: string
             * }} errorOptions
             */
            openError: (errorOptions = {}) => {
                setErrorNotificationMessage((prevErrorMessage) => ({
                    ...prevErrorMessage,
                    ...errorOptions,
                }));
                setIsOpen(true);
            },
            closeError: () => {
                setErrorNotificationMessage(initialErrorMessage);
                setIsOpen(false);
            },
        };
    }, [setIsOpen, setErrorNotificationMessage]);

    return {
        isOpen,
        errorMessage: errorNotificationMessage,
        ...handlers,
    };
};
