import {
    Button,
    NumberInput,
    TextInput,
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    SkeletonText,
    MultiSelect,
} from '@carbon/react';
import { Add, TrashCan } from '@carbon/react/icons';
import React, { useState } from 'react';
import { MxToolTip } from './mxInputs/MxToolTip';
import { useAtom, useSetAtom } from 'jotai';
import { paiReqAtom, mxWorkcentersInvalidInputAtom } from '../../../../../hooks/mxAtoms';
import { checkAllInputIsValid } from './mxInputs/mxInputValidation';
import { MxValidationTextInput } from './mxInputs/MxValidationTextInput';
import { isApprovedScenarioAtom } from '../../../../../hooks/wizardAtoms';

const missionTypes = [
    {
        id: 'fulltime',
        text: 'Fulltime',
    },
    {
        id: 'military',
        text: 'Military',
    },
    {
        id: 'combat',
        text: 'Combat',
    },
    {
        id: 'training',
        text: 'Training',
    },
    {
        id: 'development',
        text: 'Development',
    },
];

export const ActiveWorkcenters = ({
    open,
    isLoading,
    onClickAdd,
    activeWorkcenters,
    setActiveWorkcenters,
    modelType,
}) => {
    const [paiAddVal, setPaiAddVal] = useState(0);
    const [filteredTitle, setFilteredTitle] = useState('FAC_TITLE');
    const [paiReq, setPaiReq] = useAtom(paiReqAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const setMxWorkcenterInvalidInput = useSetAtom(mxWorkcentersInvalidInputAtom);

    const handleColAdd = () => {
        const newTableData = [];

        if (Object.hasOwn(activeWorkcenters[0], `PAI_${paiAddVal}_RQMT`)) {
            console.error('PAI already exists');
            alert('This PAI Column already exists');
        } else if (paiAddVal === '') {
            console.error('Please enter a PAI value.');
            alert('Please enter a PAI value.');
        } else if (paiAddVal >= 0) {
            activeWorkcenters.forEach((entry) => {
                entry[`PAI_${paiAddVal}_RQMT`] = '';
                newTableData.push(entry);
            });
            setActiveWorkcenters(newTableData);
            setPaiReq({ ...paiReq, [`PAI_${paiAddVal}_RQMT`]: '' });
        }
    };

    const handleColDelete = (colName) => {
        const newTableData = [];
        const newPaiReq = { ...paiReq };
        activeWorkcenters.forEach((elem) => {
            let newElem = { ...elem };
            delete newElem[colName];
            newTableData.push(newElem);
        });
        setActiveWorkcenters(newTableData);
        delete newPaiReq[colName];
        setPaiReq(newPaiReq);
    };

    const processPaiHeader = (header) => {
        const headerParts = header.split('_');
        return `${headerParts[0]} ${headerParts[1]}`;
    };

    const headers = activeWorkcenters[0]
        ? Object.keys(activeWorkcenters[0])
              .map((key) => ({
                  header: key,
                  key,
              }))
              .filter(
                  ({ header }) =>
                      !(header === 'id' || header === 'WC_ID' || header === 'isInvalid' || header === filteredTitle)
              )
              .concat([
                  {
                      header: '',
                      key: 'editAndDelete',
                  },
              ])
        : [];

    const onChange = (e, cell, rowIndex) => {
        if (e.target.value >= 0) {
            const tableDataCopy = [...activeWorkcenters];
            const newRow = {
                ...tableDataCopy[rowIndex],
            };
            newRow[cell.info.header] = e.target.value;
            tableDataCopy[rowIndex] = newRow;
            setActiveWorkcenters(tableDataCopy);
        }
    };

    const onChangeTextInputs = (e, cell, rowIndex) => {
        const inputValue =
            cell.info.header === 'WC_TITLE' || cell.info.header === 'FAC_TITLE'
                ? e.target.value
                : e.target.value.toUpperCase();
        const tableDataCopy = [...activeWorkcenters];
        const newRow = {
            ...tableDataCopy[rowIndex],
        };
        newRow[cell.info.header] = inputValue;
        const isInvalid = !checkAllInputIsValid(newRow);
        tableDataCopy[rowIndex] = { ...newRow, isInvalid };
        setActiveWorkcenters(tableDataCopy);
        setMxWorkcenterInvalidInput(tableDataCopy.some((workcenter) => workcenter?.isInvalid));
    };

    const handleRowDelete = (rowId) => {
        let tableDataFiltered = activeWorkcenters.filter(function (row) {
            return row.id !== rowId;
        });
        setActiveWorkcenters(tableDataFiltered);
    };

    const handleSelectionChange = (selectedItems, cell, rowIndex) => {
        const mappedItems = selectedItems.map((item) => item.text);
        const tableDataCopy = [...activeWorkcenters];
        const newRow = {
            ...tableDataCopy[rowIndex],
        };
        newRow[cell.info.header] = mappedItems.join('/');
        tableDataCopy[rowIndex] = { ...newRow };
        setActiveWorkcenters(tableDataCopy);
    };

    const getTotalForWorkcenterCol = (column) => {
        let total = 0;
        activeWorkcenters.forEach((workcenter) => {
            total += Number(workcenter[column]);
        });
        return total;
    };

    if (!open) return null;

    return (
        <div className="active-workcenters" data-testid="active-workcenters">
            <div className="add-pai-controls">
                <Button
                    size="md"
                    onClick={handleColAdd}
                    disabled={activeWorkcenters.length === 0 || isApprovedScenario}
                >
                    Add PAI Requirement
                </Button>
                <NumberInput
                    id="number-input"
                    className="pai-input"
                    size="md"
                    min={0}
                    max={100}
                    aria-label="add pai requirement column"
                    data-testid="pai-number-input"
                    disabled={activeWorkcenters.length === 0 || isApprovedScenario}
                    value={paiAddVal}
                    onChange={(event, { value, direction }) => setPaiAddVal(value)}
                    onKeyDown={(e) => {
                        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
                    }}
                />
                <div className="me-auto workcenter-container align-items-center">
                    <div className="d-flex align-items-center">
                        <h5 className="title">Workcenters</h5>
                        <Button
                            className="edit-workcenters-button"
                            size="md"
                            kind="ghost"
                            iconDescription="Add Workcenter"
                            hasIconOnly={true}
                            renderIcon={Add}
                            onClick={onClickAdd}
                            data-testid="add-workcenter-btn"
                            disabled={isApprovedScenario}
                        />
                        <Button
                            size="md"
                            onClick={() => setFilteredTitle(filteredTitle === 'WC_TITLE' ? 'FAC_TITLE' : 'WC_TITLE')}
                            className="mx-2"
                        >
                            {filteredTitle === 'WC_TITLE' ? 'Show WC Title' : 'Show FAC Title'}
                        </Button>
                    </div>
                </div>
            </div>
            <DataTable
                rows={activeWorkcenters}
                headers={headers}
                id="datatable-conditiona-equation"
                data-testid="active-workcenters-datatable"
            >
                {({ rows, headers, getHeaderProps, getRowProps, getTableProps }) => (
                    <Table {...getTableProps()}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header) => {
                                    const headerParts = header.key.split('_');
                                    return header.key.includes('PAI') ? (
                                        <TableHeader
                                            id={header.key}
                                            key={header.key}
                                            className="pai-header"
                                            {...getHeaderProps({ header })}
                                        >
                                            {processPaiHeader(header.key)}
                                            <span className="edit-pai-tooltip">
                                                <MxToolTip
                                                    currentValue={headerParts[1]}
                                                    col={header.key}
                                                    activeWorkcenters={activeWorkcenters}
                                                    handleColDelete={handleColDelete}
                                                    setActiveWorkcenters={setActiveWorkcenters}
                                                />
                                            </span>
                                        </TableHeader>
                                    ) : (
                                        <TableHeader col={header.key} key={header.key} {...getHeaderProps({ header })}>
                                            {header.header.replaceAll('_', ' ')}
                                        </TableHeader>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        {isLoading ? (
                            <TableBody>
                                {Array.from(Array(10)).map((r, idx) => (
                                    <TableRow
                                        key={`loading_row_${idx}`}
                                        id={`loading_${idx}`}
                                        data-testid="loading-row"
                                    >
                                        {headers.map((header, idx) => (
                                            <TableCell key={`loading_${header}_${idx}`}>
                                                <SkeletonText />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                            <TableBody>
                                {rows.map((row, index) => {
                                    return (
                                        <React.Fragment key={row.id + index}>
                                            <TableRow {...getRowProps({ row })} data-testid="active-workcenters-row">
                                                <ConditionalEquationRow
                                                    cells={row.cells}
                                                    onChange={onChange}
                                                    handleRowDelete={handleRowDelete}
                                                    onChangeTextInputs={onChangeTextInputs}
                                                    onSelectionChange={handleSelectionChange}
                                                    rowIndex={index}
                                                    rowId={row.id}
                                                    modelType={modelType}
                                                    disabled={isApprovedScenario}
                                                />
                                            </TableRow>
                                        </React.Fragment>
                                    );
                                })}
                                <TableRow key={'total-row'} data-testid="total-row">
                                    {headers.map((header, idx) => {
                                        const colHeader = header.header;
                                        if (colHeader === 'MISSION_TYPE') {
                                            return (
                                                <TableCell key={`total_${colHeader}_${idx}`}>
                                                    <strong>Total</strong>
                                                </TableCell>
                                            );
                                        } else if (colHeader.includes('RQMT') || colHeader.includes('REQ')) {
                                            return (
                                                <TableCell key={`total_${colHeader}_${idx}`}>
                                                    <strong>{getTotalForWorkcenterCol(colHeader)}</strong>
                                                </TableCell>
                                            );
                                        } else return <TableCell key={`total_${colHeader}_${idx}`}></TableCell>;
                                    })}
                                </TableRow>
                            </TableBody>
                        )}
                    </Table>
                )}
            </DataTable>
        </div>
    );
};

const ConditionalEquationRow = ({
    cells,
    onChange,
    handleRowDelete,
    onChangeTextInputs,
    rowIndex,
    rowId,
    onSelectionChange,
    modelType,
    disabled,
}) => {
    return (
        <React.Fragment key={rowId}>
            {cells.map((cell) => {
                return (
                    <ConditionalEquationCell
                        key={cell.id}
                        cellHeader={cell.info.header}
                        value={cell.value}
                        id={cell.id}
                        disabled={disabled}
                        modelType={modelType}
                        onChange={(e) => onChange(e, cell, rowIndex)}
                        onChangeTextInputs={(e) => onChangeTextInputs(e, cell, rowIndex)}
                        onRowDelete={() => handleRowDelete(rowId)}
                        onSelectionChange={({ selectedItems }) => onSelectionChange(selectedItems, cell, rowIndex)}
                    />
                );
            })}
        </React.Fragment>
    );
};

const ConditionalEquationCell = ({
    value,
    cellHeader = '',
    id,
    modelType,
    onChange,
    onChangeTextInputs,
    onRowDelete,
    onSelectionChange,
    disabled,
}) => {
    const requiredFields = { FAC: true, WC_TITLE: true, AFSC: true };
    let tableCell = (
        <TableCell key={cellHeader}>
            <MxValidationTextInput
                id={id}
                testId={`${cellHeader}-mx-input`}
                disabled={disabled}
                cellHeader={cellHeader}
                value={value}
                isRequired={requiredFields[cellHeader]}
                onChange={onChangeTextInputs}
            />
        </TableCell>
    );
    if (cellHeader.includes('RQMT') || cellHeader.includes('REQ')) {
        tableCell = (
            <TableCell className="pai-input">
                <TextInput
                    id={id}
                    data-testid="pai-req-input"
                    labelText=""
                    type="number"
                    disabled={disabled}
                    max={999}
                    value={value}
                    onChange={onChange}
                    onWheel={(e) => e.target.blur()}
                />
            </TableCell>
        );
    } else if (cellHeader === 'MISSION_TYPE') {
        tableCell = (
            <TableCell key={cellHeader} style={{ minWidth: '200px' }}>
                <MultiSelect
                    className="d-flex"
                    label=""
                    id="mission-type-multiselect"
                    titleText=""
                    initialSelectedItems={
                        !value ? [] : missionTypes.filter((item) => value.split('/').includes(item.text))
                    }
                    items={
                        modelType === 'full-time'
                            ? missionTypes.filter((type) => type.id !== 'military')
                            : missionTypes.filter((type) => type.id !== 'fulltime')
                    }
                    itemToString={(item) => (item ? item.text : '')}
                    onChange={onSelectionChange}
                />
            </TableCell>
        );
    } else if (cellHeader === 'editAndDelete') {
        tableCell = (
            <TableCell className="editAndDeleteCell">
                <div className="editAndDeleteIcons">
                    <Button
                        className="editNote"
                        renderIcon={TrashCan}
                        disabled={disabled}
                        kind="ghost"
                        size="sm"
                        iconDescription="Trash"
                        data-testid="delete-button"
                        onClick={onRowDelete}
                    />
                </div>
            </TableCell>
        );
    }

    return <React.Fragment key={cellHeader}>{tableCell}</React.Fragment>;
};
