import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableRow, TableCell, TableExpandRow, TableExpandedRow } from '@carbon/react';

import { HeadersRow, RadioCell, ButtonsCell, DeleteButtonCell } from './TableCells';
import { TableLoading } from '../../../steps/opsMx/EquationsTableChildren/TableLoading';

import './OpsSelectionTable.scss';

export const OpsSelectionTable = ({
    columns,
    models,
    onEdit,
    onDelete,
    onSelect,
    selectedId,
    disabledEditButtons,
    isLoading,
    disabled,
    isAdminPage = false,
}) => {
    const [expandStates, setExpandStates] = useState({});
    const handleExpand = useCallback(
        (modelLevel) => {
            setExpandStates({
                ...expandStates,
                [modelLevel]: !expandStates[modelLevel],
            });
        },
        [expandStates]
    );
    return (
        <Table className="level-0" data-testid="ops-selection-table" aria-label="selection table">
            <HeadersRow columns={columns} />
            {isLoading ? (
                <TableLoading headers={columns} />
            ) : (
                <TableBody>
                    {models.map((summaryRow, i) => {
                        const modelName = summaryRow['PARENT']['MODEL_NAME'];
                        const modelLevel = `${modelName}-row-index-${i}`;
                        const modelId = summaryRow['modelId'];
                        const alignedChildColumns = isAdminPage ? columns : ['', ...columns];
                        const isOnlyChild = summaryRow.CHILDREN?.length === 1;
                        const isEditable = summaryRow['PARENT']?.EDITABLE === 1;

                        return (
                            <React.Fragment key={i}>
                                <TableExpandRow
                                    isExpanded={expandStates[modelLevel] ?? false}
                                    onExpand={() => handleExpand(modelLevel)}
                                    aria-label="expandable row"
                                    ariaLabel="expandable row"
                                    data-testid="ops-selection-parent-row"
                                >
                                    {columns.map(({ key }, j) => (
                                        <React.Fragment key={j}>
                                            {key === 'radio' && (
                                                <RadioCell
                                                    disabled={disabled}
                                                    model={summaryRow['PARENT']}
                                                    modelId={modelId}
                                                    col={key}
                                                    idx={i}
                                                    selectedId={selectedId}
                                                    onSelect={onSelect}
                                                />
                                            )}
                                            {key !== 'radio' && key !== 'edit' && (
                                                <TableCell id={key}>{summaryRow['PARENT'][key]}</TableCell>
                                            )}
                                            {key === 'edit' && (
                                                <ButtonsCell
                                                    model={summaryRow['PARENT']}
                                                    childModel={summaryRow['CHILDREN']}
                                                    showCloneButton={!isEditable}
                                                    onEdit={onEdit}
                                                    onDelete={onDelete}
                                                    disabledEdit={disabledEditButtons || disabled}
                                                    disabledDelete={disabledEditButtons || !isEditable || disabled}
                                                    modelId={modelId}
                                                />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableExpandRow>
                                <TableExpandedRow
                                    colSpan={isAdminPage ? alignedChildColumns.length + 1 : alignedChildColumns.length}
                                >
                                    <Table className="level-1" data-testid="ops-selection-inner-table">
                                        <TableBody>
                                            {summaryRow['CHILDREN'].map((childRow, k) => (
                                                <TableRow key={k}>
                                                    <TableCell className="expand-replacement">
                                                        <span>&nbsp;</span>
                                                    </TableCell>
                                                    {alignedChildColumns.map(({ key }, cellId) => (
                                                        <React.Fragment key={cellId}>
                                                            {key !== 'edit' && key !== 'radio' && (
                                                                <TableCell id={key}>{childRow[key]}</TableCell>
                                                            )}
                                                            {key === 'edit' && typeof onDelete === 'function' && (
                                                                <DeleteButtonCell
                                                                    onDelete={onDelete}
                                                                    disabled={
                                                                        disabledEditButtons ||
                                                                        isOnlyChild ||
                                                                        !isEditable
                                                                    }
                                                                    modelId={childRow.childModelId}
                                                                />
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableExpandedRow>
                            </React.Fragment>
                        );
                    })}
                </TableBody>
            )}
        </Table>
    );
};

OpsSelectionTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    models: PropTypes.arrayOf(PropTypes.object).isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabledEditButtons: PropTypes.bool,
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
};
