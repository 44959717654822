import React, { useEffect, useState } from 'react';
import { EmailField, ButtonUtil, PostResponseNotification, LinkUtil } from '../../utils';
import { Form } from '@carbon/react';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';

export const UserRenewAccess = () => {
    const [email, setEmail] = useState({ value: '' });
    const [notificationData, setNotificationData] = useState({});
    const [formSubmitted, setFormSubmitted] = useState();

    const [buttonDisabled, setButtonDisabled] = useState(true);

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
        })();
    }, []);

    useEffect(() => {
        if (email.invalid === false || email.value.length > 0) {
            setButtonDisabled(false);
        }
    }, [email.value, email.invalid]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        (async () => {
            try {
                const res = await axios.post(
                    '/api/userRenewAccess',
                    {
                        email: email.value,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );

                setNotificationData(res);
            } catch (err) {
                setNotificationData(err.response);
            } finally {
                setTimeout(() => {
                    setButtonDisabled(false);
                }, '1000');
            }
        })();
    };

    return (
        <div id="user-renew-content">
            <Form onSubmit={handleSubmit}>
                <div className="no-gutter container mb-5">
                    <h4>Please enter your email address to renew access</h4>
                </div>
                {!formSubmitted && (
                    <>
                        <Container className="no-gutter">
                            <Row className="email-content">
                                <Col md="12">
                                    <EmailField
                                        textInput={{
                                            ...email,
                                            onChange: () => {
                                                setFormSubmitted();
                                            },
                                        }}
                                        type="emailDomain"
                                        validation={true}
                                        setState={setEmail}
                                    />
                                </Col>
                            </Row>
                            <Row className="bottom-content mt-5 mb-5">
                                <Col md="4" className="pt-2 pb-2">
                                    <LinkUtil
                                        pathKey="accountServices"
                                        link={{
                                            children: 'Go back',
                                            className: 'small',
                                        }}
                                    />
                                </Col>
                                <Col md={{ span: 6, offset: 2 }}>
                                    <ButtonUtil
                                        button={{
                                            children: 'Submit',
                                            disabled:
                                                email.invalid === true || email.value.length === 0 || buttonDisabled,
                                            onClick: handleSubmit,
                                            type: 'submit',
                                            className: 'w-100',
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </>
                )}
                <PostResponseNotification response={notificationData} />
            </Form>
        </div>
    );
};
