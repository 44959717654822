import React from 'react';
import PropTypes from 'prop-types';
import { ActionableNotification } from '@carbon/react';

const createdScenarioNotificationProps = {
    onClose: PropTypes.func.isRequired,
    scenarioName: PropTypes.string.isRequired,
    scenarioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof createdScenarioNotificationProps>>} props
 * @returns {React.ReactElement}
 */
export const CreatedScenarioNotification = ({ onClose, scenarioName, scenarioId }) => {
    return (
        <div className="scenario-toast-notification--container">
            <ActionableNotification
                data-testid="scenario-toast-notification"
                aria-label="closes notification"
                actionButtonLabel="See Scenario"
                subtitle={`Created scenario: ${scenarioName}`}
                kind="success"
                onActionButtonClick={() => {
                    window.localStorage.setItem('scenario_id', `${scenarioId}`);
                    Object.assign(document.createElement('a'), {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                        href: '/manpower-wizard/',
                    }).click();

                    onClose();
                }}
                onClose={() => onClose()}
                onCloseButtonClick={() => onClose()}
                role="status"
                statusIconDescription="success notification"
                timeout={30000}
                title="Successfully Created Scenario"
            />
        </div>
    );
};

CreatedScenarioNotification.propTypes = createdScenarioNotificationProps;
