import {
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableExpandedRow,
    TableExpandHeader,
    TableExpandRow,
    TableRow,
    TableSelectRow,
    DefinitionTooltip,
} from '@carbon/react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { HeaderContent, TableHeaderContent } from './EquationsTableChildren';
import { flattenDeep } from 'lodash';
import { TablePagination } from './TablePagination';
import { EmptyTable } from './EmptyTable';
import { TableLoading } from './EquationsTableChildren/TableLoading';
import { useSelectAllRows } from './helpers/useSelectAllRows';
import { sortInfoAtom } from './EquationsTable';
import { useAtom } from 'jotai';
import { compare } from './helpers/compare';
import { useExpandStates } from './helpers/useExpandStates';
import PropTypes from 'prop-types';

export const RecursiveTable = ({
    id,
    title,
    rows = [],
    isLoading,
    headers,
    page,
    pageSize,
    selectedIds,
    tooltips,
    disabled = false,
    onPageSizeChange = () => {},
    onSelect = () => {},
    onInit = () => {},
}) => {
    const [parentQtySum, setParentQtySum] = useState({});
    const [qtySum, setQtySum] = useState({});
    const [sortInfo] = useAtom(sortInfoAtom);

    const rowsRef = useRef(rows);
    const tooltipsRef = useRef(tooltips);
    tooltipsRef.current = tooltips;

    const { expandStates, handleExpand } = useExpandStates();

    const totalQty = () => {
        let sum = 0;
        sum = Object.values(parentQtySum).reduce((acc, row) => parseInt(acc) + parseInt(row, 10), parseInt(sum));
        return sum;
    };

    const sortedRows = useMemo(() => {
        const { columnId, sortDirection } = sortInfo;
        const parentHeaders = new Set(['UNIT', 'PEC', 'RIC', 'PAI_TYPE', 'PAI_QTY']);
        let sortedRows = rows;
        if (parentHeaders.has(columnId)) {
            sortedRows =
                sortDirection === 'none'
                    ? rows
                    : rows
                          .slice()
                          .sort(
                              (lhs, rhs) =>
                                  (sortDirection === 'ascending' ? 1 : -1) *
                                  compare(lhs.PARENT[columnId], rhs.PARENT[columnId])
                          );
        } else {
            sortedRows =
                sortDirection === 'none'
                    ? rows
                    : rows.slice().map((row) => {
                          return {
                              ...row,
                              CHILDREN: row.CHILDREN.map((child) => {
                                  return {
                                      ...child,
                                      CHILDREN: child.CHILDREN.sort(
                                          (lhs, rhs) =>
                                              (sortDirection === 'ascending' ? 1 : -1) *
                                              compare(lhs[columnId], rhs[columnId])
                                      ),
                                  };
                              }),
                          };
                      });
        }
        return sortedRows;
    }, [rows, sortInfo]);

    const paginatedRows = useMemo(() => {
        return sortedRows.slice((page - 1) * pageSize).slice(0, pageSize);
    }, [sortedRows, page, pageSize]);

    const getColValue = (values, col) => {
        const filteredValues = values.filter((cellKey) => {
            return cellKey[0] === col;
        });
        if (filteredValues.length > 0) {
            return filteredValues.at(0).at(-1);
        } else {
            return '';
        }
    };

    const renderCell = useCallback(
        ({ values, col, rowId, isParentRow, children }) => {
            switch (col) {
                case 'QTY':
                    if (isParentRow) {
                        return qtySum[rowId] ?? getColValue(values, col);
                    } else {
                        return getColValue(values, col);
                    }
                case 'PEC':
                    return (
                        <DefinitionTooltip
                            align="right"
                            definition={tooltipsRef.current.pecTitles[getColValue(values, col)] ?? ''}
                            openOnHover={true}
                            triggerClassName="ms-0 border-bottom-0"
                        >
                            <span className="ms-0 text-left">{getColValue(values, col)}</span>
                        </DefinitionTooltip>
                    );
                case 'RIC':
                    return (
                        <DefinitionTooltip
                            align="right"
                            definition={tooltipsRef.current.ricTitles[getColValue(values, col)] ?? ''}
                            openOnHover={true}
                            triggerClassName="ms-0 border-bottom-0"
                        >
                            <span className="ms-0 text-left">{getColValue(values, col)}</span>
                        </DefinitionTooltip>
                    );
                default:
                    return getColValue(values, col);
            }
        },
        [qtySum]
    );

    const selectAllChildRows = useCallback(
        ({ isSelected, parentId, rowId, mainParentIdx }) => {
            let children = [];
            let mainParentChildren = [];
            const newState = { ...qtySum };
            const newParentState = { ...parentQtySum };
            const mainParentId = paginatedRows[mainParentIdx].id;
            const row = paginatedRows.find((row) => row.id === rowId);
            const childRow = flattenDeep(paginatedRows.map((row) => row.CHILDREN)).find((row) => row.id === parentId);

            if (row) {
                let childSelections = {};
                children = children
                    .concat(row.CHILDREN.map((child) => child.id))
                    .concat(...row.CHILDREN.map((child) => child.CHILDREN.map((secondChild) => secondChild.id)));

                const totalSum = row.CHILDREN.map((child) => child.PARENT['QTY']).reduce(
                    (acc, current) => parseInt(acc) + parseInt(current)
                );
                newState[rowId] = isSelected ? parseInt(totalSum) : 0;
                newParentState[mainParentId] = isSelected ? parseInt(totalSum) : 0;

                row.CHILDREN.forEach((child) => {
                    newState[child.id] = isSelected
                        ? child.CHILDREN.map((secondChild) => secondChild['QTY']).reduce(
                              (acc, current) => parseInt(acc) + parseInt(current)
                          )
                        : 0;
                });

                row.CHILDREN.forEach((child) => {
                    child.CHILDREN.forEach((secondChild) => {
                        if (childSelections[child.id]) {
                            childSelections[child.id].push(secondChild.id);
                        } else {
                            childSelections[child.id] = [secondChild.id];
                        }
                    });
                    onSelect(isSelected, child.id, childSelections[child.id]);
                });

                onSelect(isSelected, row.id, children);
            } else if (childRow) {
                children = children.concat(childRow.CHILDREN.map((child) => child.id));
                mainParentChildren = mainParentChildren
                    .concat(childRow.id)
                    .concat(childRow.CHILDREN.map((child) => child.id));
                onSelect(isSelected, mainParentId, mainParentChildren);

                const totalSum = childRow.CHILDREN.map((child) => child['QTY']).reduce(
                    (acc, current) => parseInt(acc) + parseInt(current)
                );

                newState[rowId] = isSelected ? parseInt(totalSum) : 0;
                newState[mainParentId] += isSelected
                    ? parseInt(totalSum)
                    : qtySum[rowId] === 0
                    ? 0
                    : -parseInt(totalSum);
                newParentState[mainParentId] += isSelected ? parseInt(totalSum) : -parseInt(totalSum);
                onSelect(isSelected, childRow.id, children);
            }
            setQtySum(newState);
            setParentQtySum(newParentState);
            children = [];
        },
        [onSelect, setQtySum, setParentQtySum, parentQtySum, qtySum, paginatedRows]
    );

    const selectAllRows = useCallback(
        (isSelected) => {
            let children = [];
            let newState = { ...qtySum };
            let newParentState = { ...parentQtySum };
            rows.forEach((row) => {
                if (row.PARENT) {
                    let childSelections = {};
                    const totalChildSum = row.CHILDREN.map((child) => child.PARENT['QTY']).reduce(
                        (acc, current) => parseInt(acc) + parseInt(current),
                        0
                    );

                    newState[row.id] = parseInt(totalChildSum);
                    newParentState[row.id] = isSelected ? parseInt(totalChildSum) : 0;

                    row.CHILDREN.forEach((child) => {
                        child.CHILDREN.forEach((secondChild) => {
                            if (childSelections[child.id]) {
                                childSelections[child.id].push(secondChild.id);
                            } else {
                                childSelections[child.id] = [secondChild.id];
                            }
                        });
                        onSelect(isSelected, child.id, childSelections[child.id]);
                    });
                }
                if (row.CHILDREN) {
                    children = children.concat(
                        row.CHILDREN.map((child) => child.id).concat(
                            ...row.CHILDREN.map((child) => child.CHILDREN.map((secondChild) => secondChild.id))
                        )
                    );
                    onSelect(isSelected, row.id, children);
                }
                const totalSum = row.CHILDREN.map((child) => child.PARENT['QTY']).reduce(
                    (acc, current) => parseInt(acc) + parseInt(current)
                );
                newState[row.id] = isSelected ? parseInt(totalSum) : 0;
                setQtySum(newState);
                children = [];
            });
            setParentQtySum(newParentState);
        },
        [onSelect, parentQtySum, qtySum, rows]
    );

    const initSelectAllRows = () => {
        let children = [];
        let newState = {};
        let newParentState = {};
        let selections = {};
        rows.forEach((row) => {
            if (row.PARENT) {
                let childSelections = {};
                const totalChildSum = row.CHILDREN.map((child) => child.PARENT['QTY']).reduce(
                    (acc, current) => parseInt(acc) + parseInt(current)
                );

                newState[row.id] = parseInt(totalChildSum);
                newParentState[row.id] = parseInt(totalChildSum);

                row.CHILDREN.forEach((child) => {
                    child.CHILDREN.forEach((secondChild) => {
                        if (childSelections[child.id]) {
                            childSelections[child.id].push(secondChild.id);
                        } else {
                            childSelections[child.id] = [secondChild.id];
                        }
                    });
                    selections[child.id] = childSelections[child.id];
                });
            }
            if (row.CHILDREN) {
                children = children.concat(
                    row.CHILDREN.map((child) => child.id).concat(
                        ...row.CHILDREN.map((child) => child.CHILDREN.map((secondChild) => secondChild.id))
                    )
                );
                selections[row.id] = children;
            }
            const totalSum = row.CHILDREN.map((child) => child.PARENT['QTY']).reduce(
                (acc, current) => parseInt(acc) + parseInt(current),
                0
            );
            newState[row.id] = parseInt(totalSum);
            children = [];
        });
        setParentQtySum(newParentState);
        setQtySum(newState);
        onInit(id, selections);
    };

    useSelectAllRows({ isLoading, rowsRef, rows, selectAllRows: initSelectAllRows });

    const handleGrandChildSelect = useCallback(
        ({ checked, rowId, parentId, qty, mainParentIdx }) => {
            const mainParentId = paginatedRows[mainParentIdx].id;
            const parentQty = parseInt(
                paginatedRows[mainParentIdx].CHILDREN.find((child) => child.id === parentId).PARENT.QTY
            );
            const newParentState = { ...parentQtySum };
            newParentState[mainParentId] = checked
                ? qtySum[mainParentId]
                    ? qtySum[mainParentId] + qty
                    : paginatedRows[mainParentIdx].PARENT.QTY
                : qtySum[mainParentId]
                ? qtySum[mainParentId] - qty
                : parentQty - qty;
            onSelect(checked, parentId, [rowId]);
            onSelect(checked, mainParentId, [rowId]);
            setQtySum({
                ...qtySum,
                [parentId]: checked
                    ? qtySum[parentId]
                        ? qtySum[parentId] + qty
                        : qty
                    : qtySum[mainParentId]
                    ? qtySum[mainParentId] - qty
                    : parentQty - qty,
                [mainParentId]: checked
                    ? qtySum[mainParentId]
                        ? qtySum[mainParentId] + qty
                        : qty
                    : qtySum[mainParentId]
                    ? qtySum[mainParentId] - qty
                    : parentQty - qty,
            });
            setParentQtySum(newParentState);
        },
        [paginatedRows, qtySum, parentQtySum, setParentQtySum, setQtySum, onSelect]
    );

    const allChecked = useCallback(() => {
        let allRows = {};
        paginatedRows.forEach((row) => {
            let totalChildRows = row.CHILDREN.length;
            row.CHILDREN.forEach((child) => {
                totalChildRows += child.CHILDREN.length;
            });
            allRows[row.id] = totalChildRows;
        });
        for (const key in allRows) {
            if (selectedIds[key]?.length !== allRows[key]) {
                return false;
            }
        }
        return true;
    }, [paginatedRows, selectedIds]);

    const recursiveTable = useMemo(() => {
        let level = 0;
        const createRecursiveTable = (tableContents = [], level) => {
            if (tableContents.length > 0) {
                const rowValues = tableContents.map((cell) => {
                    if (cell.PARENT) {
                        return Object.entries(cell.PARENT);
                    } else {
                        return Object.entries(cell);
                    }
                });
                const rowChildren = tableContents.map((cell) => {
                    if (cell.CHILDREN) {
                        return cell.CHILDREN;
                    } else {
                        return cell;
                    }
                });

                level++;

                return (
                    <DataTable rows={tableContents} headers={headers} isSortable id={`datatable-recursive`}>
                        {({ headers, getHeaderProps, getSelectionProps, getTableProps }) => (
                            <Table
                                className={`level${level} ${level === 1 ? 'sticky-table-header' : ''}`}
                                {...getTableProps()}
                            >
                                {level === 1 && (
                                    <TableHeaderContent
                                        headers={headers}
                                        disabled={disabled}
                                        getHeaderProps={getHeaderProps}
                                        getSelectionProps={getSelectionProps}
                                        allChecked={allChecked()}
                                        onSelectAllRows={selectAllRows}
                                    />
                                )}
                                {isLoading ? (
                                    <TableLoading headers={headers} />
                                ) : (
                                    <TableBody>
                                        {rowValues.map((cellValues, cellRowIndex) => {
                                            const unit = getColValue(cellValues, 'UNIT');
                                            const qty = getColValue(cellValues, 'QTY');
                                            const parentId = getColValue(cellValues, 'parentId');
                                            const isMissing = getColValue(cellValues, 'MISSING');
                                            const isUnconventional = getColValue(cellValues, 'UNCONVENTIONAL');
                                            const isCarryover = getColValue(cellValues, 'CARRYOVER');
                                            const rowId = tableContents[cellRowIndex].id;
                                            const unitLevel = `${rowId}-level${level}`;
                                            const mainParentIdx = rowChildren[cellRowIndex][0]?.mainParentIdx;
                                            const hasChildren = rowChildren[cellRowIndex].length > 0;
                                            if (hasChildren) {
                                                const grandChildrenLengths = tableContents[cellRowIndex]?.CHILDREN.map(
                                                    (child) => (child.CHILDREN ? child.CHILDREN.length : 0)
                                                );
                                                let totalChildren =
                                                    tableContents[cellRowIndex]?.CHILDREN &&
                                                    tableContents[cellRowIndex]?.CHILDREN[0]?.PARENT
                                                        ? tableContents[cellRowIndex]?.CHILDREN.length
                                                        : 0 + rowChildren[cellRowIndex].length;

                                                if (grandChildrenLengths) {
                                                    totalChildren += grandChildrenLengths.reduce(
                                                        (acc, value) => acc + value
                                                    );
                                                }
                                                const childRowChecked =
                                                    totalChildren === selectedIds[rowId]?.length ?? false;
                                                let outerLabel = '';
                                                if (level === 2) {
                                                    outerLabel = childRowChecked
                                                        ? 'Deselect child row for calculation'
                                                        : 'Select child row for calculation';
                                                } else {
                                                    outerLabel = childRowChecked
                                                        ? 'Deselect row for calculation'
                                                        : 'Select row for calculation';
                                                }
                                                return (
                                                    <React.Fragment key={rowId}>
                                                        <TableExpandRow
                                                            ariaLabel="rows"
                                                            isExpanded={expandStates[unitLevel] ?? false}
                                                            onExpand={() => handleExpand(unitLevel)}
                                                            data-testid={
                                                                level === 2 ? 'recursive-table-parent-row' : ''
                                                            }
                                                            className={
                                                                isUnconventional
                                                                    ? 'green-background'
                                                                    : isMissing
                                                                    ? 'amber-background'
                                                                    : ''
                                                            }
                                                        >
                                                            <TableSelectRow
                                                                key={rowId}
                                                                id={rowId}
                                                                ariaLabel={outerLabel}
                                                                checked={childRowChecked}
                                                                disabled={disabled}
                                                                onSelect={(e) => {
                                                                    onSelect(e.target.checked, id, rowId);
                                                                    selectAllChildRows({
                                                                        isSelected: e.target.checked,
                                                                        unit,
                                                                        parentId: rowChildren[cellRowIndex][0].parentId,
                                                                        rowIdx: cellRowIndex,
                                                                        rowId,
                                                                        mainParentIdx,
                                                                    });
                                                                }}
                                                                name={`select-${rowId}`}
                                                            />
                                                            {headers.map((header) => {
                                                                return (
                                                                    <TableCell
                                                                        key={header.key}
                                                                        className={
                                                                            isUnconventional
                                                                                ? `green-background table-cell-${header.key}`
                                                                                : isMissing
                                                                                ? `amber-background table-cell-${header.key}`
                                                                                : `table-cell-${header.key}`
                                                                        }
                                                                    >
                                                                        {renderCell({
                                                                            values: cellValues,
                                                                            col: header.key,
                                                                            cellId: unitLevel,
                                                                            rowId: rowChildren[cellRowIndex][0]
                                                                                .parentId,
                                                                            isParentRow: true,
                                                                            children: rowChildren[cellRowIndex],
                                                                        })}
                                                                        {header.key === 'QTY' && isCarryover ? (
                                                                            <DefinitionTooltip
                                                                                align="left"
                                                                                definition="Carryover number from ASIS"
                                                                                openOnHover={true}
                                                                                triggerClassName="ms-0 border-bottom-0"
                                                                            >
                                                                                <strong>*</strong>
                                                                            </DefinitionTooltip>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                        </TableExpandRow>
                                                        <TableExpandedRow
                                                            colSpan={headers.length + 2}
                                                            className={!expandStates[unitLevel] ? 'hidden-row' : ''}
                                                        >
                                                            {createRecursiveTable(rowChildren[cellRowIndex], level)}
                                                        </TableExpandedRow>
                                                    </React.Fragment>
                                                );
                                            } else {
                                                const grandChildRowChecked =
                                                    selectedIds[parentId]?.includes(rowId) ?? false;
                                                return (
                                                    <TableRow key={rowId}>
                                                        <TableExpandHeader />
                                                        <TableSelectRow
                                                            key={rowId}
                                                            id={rowId}
                                                            ariaLabel={
                                                                grandChildRowChecked
                                                                    ? 'Deselect grandchild row for calculation'
                                                                    : 'Select grandchild row for calculation'
                                                            }
                                                            checked={grandChildRowChecked}
                                                            onSelect={(e) => {
                                                                handleGrandChildSelect({
                                                                    checked: e.target.checked,
                                                                    rowId,
                                                                    parentId,
                                                                    mainParentIdx: getColValue(
                                                                        cellValues,
                                                                        'mainParentIdx'
                                                                    ),
                                                                    qty,
                                                                });
                                                            }}
                                                            name={`select-${rowId}`}
                                                        />
                                                        {[...cellValues.slice(1, -1)].map((cellValue) => {
                                                            const [key, value] = cellValue;
                                                            const className = isUnconventional
                                                                ? `green-background table-cell-${key}`
                                                                : isMissing
                                                                ? `amber-background table-cell-${key}`
                                                                : `table-cell-${key}`;
                                                            switch (key) {
                                                                case 'id':
                                                                case 'parentId':
                                                                case 'RIC_TITLE':
                                                                case 'FAC_TITLE':
                                                                case 'PEC_TITLE':
                                                                case 'EXCLUDE':
                                                                case 'MISSING':
                                                                case 'CARRYOVER':
                                                                case 'UNCONVENTIONAL':
                                                                    return null;
                                                                case 'FAC':
                                                                    return (
                                                                        <TableCell key={key} className={className}>
                                                                            <DefinitionTooltip
                                                                                align="right"
                                                                                definition={
                                                                                    tooltipsRef.current.facTitles[
                                                                                        value
                                                                                    ] ?? ''
                                                                                }
                                                                                openOnHover={true}
                                                                                triggerClassName="ms-0 border-bottom-0"
                                                                            >
                                                                                {value}
                                                                            </DefinitionTooltip>
                                                                        </TableCell>
                                                                    );
                                                                case 'PEC':
                                                                    return (
                                                                        <TableCell key={key} className={className}>
                                                                            <DefinitionTooltip
                                                                                align="right"
                                                                                definition={
                                                                                    tooltipsRef.current.pecTitles[
                                                                                        value
                                                                                    ] ?? ''
                                                                                }
                                                                                openOnHover={true}
                                                                                triggerClassName="ms-0 border-bottom-0"
                                                                            >
                                                                                {value}
                                                                            </DefinitionTooltip>
                                                                        </TableCell>
                                                                    );
                                                                case 'QTY':
                                                                    return isCarryover ? (
                                                                        <TableCell key={key} className={className}>
                                                                            {value}
                                                                            <DefinitionTooltip
                                                                                align="left"
                                                                                definition="Carryover number from ASIS"
                                                                                openOnHover={true}
                                                                                triggerClassName="ms-0 border-bottom-0"
                                                                            >
                                                                                <strong>*</strong>
                                                                            </DefinitionTooltip>
                                                                        </TableCell>
                                                                    ) : (
                                                                        <TableCell key={key} className={className}>
                                                                            {value}
                                                                        </TableCell>
                                                                    );
                                                                default:
                                                                    return (
                                                                        <TableCell key={key} className={className}>
                                                                            {value}
                                                                        </TableCell>
                                                                    );
                                                            }
                                                        })}
                                                    </TableRow>
                                                );
                                            }
                                        })}
                                    </TableBody>
                                )}
                            </Table>
                        )}
                    </DataTable>
                );
            }
        };
        return paginatedRows.length > 0 ? createRecursiveTable(paginatedRows, level) : <EmptyTable headers={headers} />;
    }, [
        id,
        headers,
        paginatedRows,
        expandStates,
        selectedIds,
        isLoading,
        handleExpand,
        handleGrandChildSelect,
        allChecked,
        onSelect,
        renderCell,
        selectAllChildRows,
        selectAllRows,
    ]);

    return (
        <div className="recursive-table" data-testid="recursive-table-root">
            <HeaderContent title={title} id={id} />
            {recursiveTable}
            {rows.length > 0 && (
                <TablePagination
                    id={id}
                    page={page}
                    pageSize={pageSize}
                    totalItems={rows.length}
                    onPageSizeChange={onPageSizeChange}
                />
            )}
            {!isLoading && (
                <div className="table-footer">
                    <h4>
                        Total:&nbsp;
                        <strong>{totalQty()}</strong>
                        &nbsp;/&nbsp;
                        <strong>
                            {rows.reduce((prev, curr) => {
                                return prev + parseInt(curr.PARENT.QTY);
                            }, 0)}
                        </strong>
                        &nbsp; (Baseline Total)
                    </h4>
                </div>
            )}
        </div>
    );
};

RecursiveTable.propTypes = {
    id: PropTypes.string,
    title: PropTypes.node,
    content: PropTypes.string,
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            PARENT: PropTypes.shape({
                ID: PropTypes.number,
                PAI_QTY: PropTypes.string,
                PAI_TYPE: PropTypes.string,
                PEC: PropTypes.string,
                QTY: PropTypes.string,
                RIC: PropTypes.string,
                UNIT: PropTypes.string,
            }),
            CHILDREN: PropTypes.arrayOf(
                PropTypes.shape({
                    PARENT: PropTypes.shape({
                        UNIT: PropTypes.string,
                        PEC: PropTypes.string,
                        RIC: PropTypes.string,
                        PAI_TYPE: PropTypes.string,
                        PAI_QTY: PropTypes.string,
                        ID: PropTypes.number,
                        QTY: PropTypes.string,
                    }),
                    CHILDREN: PropTypes.arrayOf(
                        PropTypes.shape({
                            AFS: PropTypes.string,
                            API: PropTypes.string,
                            EXCLUDE: PropTypes.number,
                            FAC: PropTypes.string,
                            ID: PropTypes.number,
                            MSI: PropTypes.string,
                            OSC: PropTypes.string,
                            PAI_QTY: PropTypes.string,
                            PAI_TYPE: PropTypes.string,
                            PEC: PropTypes.string,
                            POS_FUNDING: PropTypes.string,
                            QTY: PropTypes.number,
                            RIC: PropTypes.string,
                            RIC_TITLE: PropTypes.string,
                            UNIT: PropTypes.string,
                            id: PropTypes.string,
                            mainParentIdx: PropTypes.number,
                            parentId: PropTypes.string,
                        })
                    ),
                    id: PropTypes.string,
                    parentId: PropTypes.string,
                    mainParentIdx: PropTypes.number,
                })
            ),
            id: PropTypes.string,
        })
    ),
    isLoading: PropTypes.bool,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string,
            key: PropTypes.string.isRequired,
        })
    ),
    page: PropTypes.number,
    pageSize: PropTypes.number,
    selectedIds: PropTypes.object,
    onPageSizeChange: PropTypes.func,
    onSelect: PropTypes.func,
    onInit: PropTypes.func,
    disabled: PropTypes.bool,
};
