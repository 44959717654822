import { utils, writeFile } from 'xlsx';

/**
 * Filter columns from being exported to excel file
 * @param {string[]} validColumns ['UNIT', 'PEC', 'RIC', ...etc]
 * @returns {(obj: object) => Partial<object>}
 */
export const generateColumnFilter = (validColumns) => {
    const columnSet = new Set(validColumns);

    return (row) => {
        return Object.entries(row)
            .filter(([key]) => columnSet.has(key))
            .reduce(
                (obj, [key, value]) => ({
                    ...obj,
                    [key]: value,
                }),
                {}
            );
    };
};

export class JsonToExcel {
    constructor() {
        this.wb = utils.book_new();
    }

    getWorkbook() {
        return this.wb;
    }

    /**
     * Adds multiple tables to a single worksheet
     * @param {string} worksheetTitle
     * @param {...object} tables
     */
    addWorksheet(worksheetTitle, ...tables) {
        let tablesToAdd = tables;
        if (tables.length > 1) {
            tablesToAdd = tables.map((table) => {
                if (typeof table === 'string') {
                    return [{ [table]: '' }];
                }
                return [...table, {}];
            });
        }
        // Start a worksheet
        const ws = utils.json_to_sheet(tablesToAdd[0]);
        // Append other tables to the worksheet
        tablesToAdd.slice(1).forEach((table) => {
            utils.sheet_add_json(ws, table, { origin: -1 });
        });
        utils.book_append_sheet(this.getWorkbook(), ws, worksheetTitle);
    }

    /**
     * Writes the xlsx
     * @param {string} fileName eg 'my_xlsx_file'
     */
    exportFile(fileName) {
        writeFile(this.getWorkbook(), fileName + '.xlsx');
    }
}
