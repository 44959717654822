// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invalidScenariosTab .dropdowns {
  display: flex;
  gap: 1.5rem;
}
.invalidScenariosTab .dropdowns > div {
  flex: 1 1 100%;
}
.invalidScenariosTab .graph-view {
  border: 0 solid var(--bs-gray-700);
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0px;
  background-color: var(--cds-layer);
  padding: 0;
}
.invalidScenariosTab .graph-view > div {
  width: 100%;
}
.invalidScenariosTab .graph-view h4 {
  font-size: 0.8em;
}
.invalidScenariosTab .cds--loading-overlay {
  z-index: 9101;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/ManageScenarios/InvalidScenariosTab/InvalidScenariosTab.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,WAAA;AAAR;AAEQ;EACI,cAAA;AAAZ;AAII;EACI,kCAAA;EACA,WAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kCAAA;EACA,UAAA;AAFR;AAIQ;EACI,WAAA;AAFZ;AAKQ;EACI,gBAAA;AAHZ;AAOI;EACI,aAAA;AALR","sourcesContent":[".invalidScenariosTab {\n    .dropdowns {\n        display: flex;\n        gap: 1.5rem;\n\n        &>div {\n            flex: 1 1 100%;\n        }\n    }\n\n    .graph-view {\n        border: 0 solid var(--bs-gray-700);\n        width: 100%;\n        min-height: 200px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        margin: 1rem 0px;\n        background-color: var(--cds-layer);\n        padding: 0;\n\n        &>div {\n            width: 100%;\n        }\n\n        h4 {\n            font-size: 0.8em;\n        }\n    }\n\n    .cds--loading-overlay {\n        z-index: 9101;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
