import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionItem, Modal, TabPanel } from '@carbon/react';

import { fetchPublishedScenarios } from '../adminApiCalls';

import { PublishTable } from './PublishTable';
import { PublishFilters } from './PublishFilters';

import { CloneScenarioModal } from './CloneScenarioModal';
import { PublishScenarioModal } from './PublishScenarioModal';
import { CreatedScenarioNotification } from './CreatedScenarioNotification';

import './PublishScenarioTab.scss';

const publishScenarioTabProps = {
    refetch: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onReviewScenario: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    highlightScenarioId: PropTypes.string,
};

/**
 * @typedef {Object} summary
 * @property {string} title
 * @property {number} count of total published
 * @property {string} id
 */

/**
 * @type {React.FC<PropTypes.InferProps<typeof publishScenarioTabProps>>} props
 * @returns {React.ReactElement}
 */
export const PublishScenarioTab = ({ refetch, onReviewScenario, onChange, highlightScenarioId }) => {
    const [scenarioList, setScenarioList] = useState([]);
    const [activeScenario, setActiveScenario] = useState(null);
    const [action, setAction] = useState('PUBLISH');
    const [showUnpublishExplanation, setShowUnpublishExplanation] = useState(null);
    const [cmdSummary, setCmdSummary] = useState(/** @type {summary[]} */ ([]));
    const [mdsSummary, setMdsSummary] = useState(/** @type {summary[]} */ ([]));
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        cmd: null,
        mds: null,
        ilc: null,
    });
    const [cmd, setCmd] = useState(/** @type {string[]} */ ([]));
    const [mds, setMds] = useState(/** @type {string[]} */ ([]));
    const [ilc, setIlc] = useState(/** @type {string[]} */ ([]));
    const [cloneScenario, setCloneScenario] = useState(null);
    const [newScenario, setNewScenario] = useState(null);

    const populateScenarios = useCallback(
        async function () {
            const { success, data, summary, cmdList, mdsList, ilcList } = await fetchPublishedScenarios(
                highlightScenarioId
            );
            if (success) {
                setScenarioList(data);
                setCmdSummary(summary.cmds ?? []);
                setMdsSummary(summary.mds ?? []);
                setCmd(cmdList);
                setMds(mdsList);
                setIlc(ilcList);
                setTotal(summary.TOTAL);
            }
        },
        [highlightScenarioId]
    );

    useEffect(() => {
        populateScenarios();
    }, [populateScenarios, refetch]);

    const cmdFilter = filter.cmd;
    const ilcFilter = filter.ilc;
    const mdsFilter = filter.mds;

    const isFiltered = (scenario) => {
        if (cmdFilter && scenario.CMD !== cmdFilter) {
            return false;
        }
        if (ilcFilter && scenario.ILC !== ilcFilter) {
            return false;
        }
        if (mdsFilter && scenario.MDS !== mdsFilter) {
            return false;
        }
        return true;
    };

    const modalIsOpen = !!showUnpublishExplanation || !!cloneScenario || !!activeScenario;

    return (
        <TabPanel className="publish-scenario-tab" aria-label="publish-scenario-tab">
            <PublishFilters
                cmdSummary={cmdSummary}
                mdsSummary={mdsSummary}
                cmdList={cmd}
                cmdFilter={cmdFilter}
                ilcList={ilc}
                ilcFilter={ilcFilter}
                mdsFilter={mdsFilter}
                mdsList={mds}
                totalCount={total}
                onSelect={(update) => {
                    setFilter((prevFilter) => ({
                        ...prevFilter,
                        ...update,
                    }));
                }}
            />
            <Accordion>
                {scenarioList.map((scenarioData, i) =>
                    isFiltered(scenarioData) ? (
                        <AccordionItem
                            key={`accordion_item_${i}`}
                            title={
                                <div className="accordion-title">
                                    <div>{`CMD: ${scenarioData.CMD}`}</div>
                                    <div>{`ILC: ${scenarioData.ILC}`}</div>
                                    <div>{`MDS: ${scenarioData.MDS}`}</div>
                                </div>
                            }
                            data-testid={`accordion_item_${i}`}
                            open={scenarioData.startOpen}
                        >
                            <PublishTable
                                tableDescription="Approved user scenarios for the user for the CMD, MDS, ILC"
                                scenarios={scenarioData.APPROVED}
                                actions={[
                                    {
                                        actionText: 'Publish',
                                        onClick: async (scenarioId, scenarioName) => {
                                            setAction('PUBLISH');
                                            setActiveScenario({ scenarioId, scenarioName });
                                        },
                                    },
                                ]}
                                onReviewScenario={onReviewScenario}
                                tableHeader="Approved Scenarios"
                                ariaLabel="Approved Scenarios Table"
                            />
                            <PublishTable
                                tableDescription="All published scenarios for the CMD, MDS, ILC"
                                scenarios={scenarioData.PUBLISHED}
                                actions={[
                                    {
                                        actionText: 'Create',
                                        onClick: async (scenarioId, scenarioName) => {
                                            setCloneScenario({ scenarioId, scenarioName });
                                        },
                                    },
                                    {
                                        actionText: 'Unpublish',
                                        onClick: async (scenarioId, scenarioName) => {
                                            setAction('UNPUBLISH');
                                            setActiveScenario({ scenarioId, scenarioName });
                                        },
                                    },
                                ]}
                                onReviewScenario={onReviewScenario}
                                tableHeader="Published Scenarios"
                                ariaLabel="Published Scenarios Table"
                                headers={[
                                    { header: '', key: 'ICON' },
                                    { header: 'Scenario Name', key: 'SCENARIO_NAME' },
                                    { header: 'Published By', key: 'PUBLISHED_BY' },
                                    { header: 'Published Date', key: 'DATE_PUBLISHED' },
                                    { header: '', key: 'action' },
                                ]}
                            />
                            <PublishTable
                                tableDescription="All unpublished scenarios for the CMD, MDS, ILC"
                                scenarios={scenarioData.UNPUBLISHED ?? []}
                                highlightScenarioId={highlightScenarioId}
                                actions={[
                                    {
                                        actionText: 'Create',
                                        onClick: (scenarioId, scenarioName) => {
                                            setCloneScenario({ scenarioId, scenarioName });
                                        },
                                    },
                                ]}
                                onReviewScenario={onReviewScenario}
                                onUnpublishExplanation={(scenario) => {
                                    setShowUnpublishExplanation(scenario.UNPUBLISH_REASON ?? 'None Provided');
                                }}
                                tableHeader="Unpublished Scenarios"
                                ariaLabel="Unpublished Scenarios Table"
                                headers={[
                                    { header: '', key: 'ICON' },
                                    { header: 'Scenario Name', key: 'SCENARIO_NAME' },
                                    { header: 'Unpublished By', key: 'UNPUBLISHED_BY' },
                                    { header: 'Unpublished Date', key: 'DATE_UNPUBLISHED' },
                                    { header: '', key: 'action' },
                                ]}
                            />
                        </AccordionItem>
                    ) : null
                )}
            </Accordion>

            <Modal
                open={!!showUnpublishExplanation}
                onRequestClose={() => setShowUnpublishExplanation(null)}
                passiveModal
                modalHeading="Unpublish Reason"
            >
                <p className="mt-5">{showUnpublishExplanation}</p>
            </Modal>
            <CloneScenarioModal
                isOpen={!!cloneScenario}
                originalName={cloneScenario?.scenarioName ?? ''}
                originalId={cloneScenario?.scenarioId ?? ''}
                onClose={() => setCloneScenario(null)}
                onSubmit={(scenario) => {
                    setNewScenario(scenario);
                    populateScenarios();
                }}
            />

            <PublishScenarioModal
                action={action}
                populateScenarios={populateScenarios}
                onClose={() => setActiveScenario(null)}
                isOpen={!!activeScenario}
                onChange={onChange}
                scenarioName={activeScenario?.scenarioName}
                scenarioId={activeScenario?.scenarioId}
            />

            {newScenario !== null && !modalIsOpen ? (
                <CreatedScenarioNotification
                    onClose={() => setNewScenario(null)}
                    scenarioName={newScenario?.scenarioName}
                    scenarioId={newScenario?.scenarioId}
                />
            ) : null}
        </TabPanel>
    );
};

PublishScenarioTab.propTypes = publishScenarioTabProps;
