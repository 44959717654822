import { Tag } from '@carbon/react';
import { useAtom } from 'jotai';
import { gsFiltersAtom } from '../generalSettings/GeneralSettingsAtoms';
import { selectedPecsAtom } from '../unitDetails/UnitDetailsAtoms';

export const SelectedFilterTags = () => {
    const [gsFilters] = useAtom(gsFiltersAtom);
    const [selectedPecs] = useAtom(selectedPecsAtom);

    return (
        <div data-testid="selected-filter-tags">
            <Tag
                className="some-class"
                type="high-contrast"
                size="md"
                title="Clear Filter"
            >
                {gsFilters.CMD}
            </Tag>
            <Tag
                className="some-class"
                type="high-contrast"
                size="md"
                title="Clear Filter"
            >
                {gsFilters.ILC}
            </Tag>
            {selectedPecs.map((pecSelected, idx) => (
                <Tag
                    className="some-class"
                    key={idx}
                    type="high-contrast"
                    size="md"
                    title="Clear Filter"
                >
                    {pecSelected}
                </Tag>
            ))}
        </div>
    );
};
