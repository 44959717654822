import { React } from 'react';
import { LoadingUtil, PostResponseNotification } from '../../../utils';
import { Link } from 'react-router-dom';
import { useFetchTFAMethods } from './useFetchTFAMethods';
import { useTFAElements } from './useTFAElements';
import './AuthenticationOptions.scss';

export const AuthenticationOptions = () => {
    const [TFAMethodTiles, setTFAMethods, updateTFAMethods, TFAMethods] = useTFAElements();
    const [isLoggedIn, notificationData] = useFetchTFAMethods(setTFAMethods, updateTFAMethods, TFAMethods);

    if (isLoggedIn === undefined) {
        return (
            <LoadingUtil
                type="inline"
                inlineLoading={{
                    className: 'mt-3 justify-content-center',
                    description: <em>Loading</em>,
                }}
            />
        );
    } else {
        return (
            <>
                {/* <img src="/assets/Logos/guardian_logo.png" alt='Logo' className='logo_style' /> */}
                <h3>2-Step Verification</h3>
                <p>To help keep your account safe, we want to make sure it's really you trying to log in</p>
                <div className="w-100 pt-4">
                    <h5>Choose how you want to log in: </h5>
                    <div className="tile-container">{TFAMethodTiles}</div>
                    <div className="text-start account-recovery-text">
                        Or try&nbsp;
                        <Link className="recoveryLink" to="/forgot-password">
                            account recovery
                        </Link>
                        &nbsp;instead.
                    </div>
                    <PostResponseNotification response={notificationData} />
                </div>
            </>
        );
    }
};
