import axios from 'axios';

// Simple Fetch
// TODO: Change the URL to the Unit Organizational Chart endpoint
export const fetchUnitDetails = (queryOptions) => {
    const [, gsFilters] = queryOptions.queryKey;
    return axios.post(
        '/api/fast-api/unit_details/',
        { mds: gsFilters.MDS, ilc: gsFilters.ILC, cmd: gsFilters.CMD },
        null
    );
};

export const fetchUnitDetailsBaseline = (queryOptions) => {
    const [, gsFilters] = queryOptions.queryKey;
    return axios.post(
        '/api/fast-api/unit_details/baseline',
        { mds: gsFilters.MDS, ilc: gsFilters.ILC, cmd: gsFilters.CMD },
        null
    );
};

/**
 *
 * @param {{
 *      cmd: string,
 *      mds: string,
 *      ilc?: string,
 *      scenarioId: number | string,
 * }} options
 */
export const fetchAdditionalUnitDetails = async (options) => {
    try {
        const { data } = await axios.post('/api/fast-api/scenario/get_additional_unit_details', null, {
            params: {
                scenario_id: options.scenarioId,
                ilc_check: !!options.ilc,
            },
        });

        const allUnits = {};
        const availableOpsUnits = [];
        const cmdSet = new Set();
        const opsUnits = data.AIRCREW.reduce((acc, curr) => {
            availableOpsUnits.push(curr);
            cmdSet.add(curr.CMD);
            allUnits[curr.UNIT] = curr;
            return {
                ...acc,
                [curr.UNIT]: curr.PEC,
            };
        }, {});
        const availableMxUnits = [];
        const mxUnits = data.MX.reduce((acc, curr) => {
            availableMxUnits.push(curr);
            cmdSet.add(curr.CMD);
            allUnits[curr.UNIT] = curr;
            return {
                ...acc,
                [curr.UNIT]: curr.PEC,
            };
        }, {});
        return {
            isOk: true,
            data: {
                opsUnits,
                availableOpsUnits,
                availableMxUnits,
                mxUnits,
                allUnits,
                availableCMDs: Array.from(cmdSet),
            },
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            data: null,
            error,
        };
    }
};

/**
 *
 * @returns {Promise<{isOk: boolean, data: string[], error: any}>}
 */
export const fetchCmds = async () => {
    try {
        const { data } = await axios.post('/api/fast-api/filters/cmd', null);
        return {
            isOk: true,
            error: null,
            data,
        };
    } catch (error) {
        return {
            isOk: false,
            data: [],
            error,
        };
    }
};
