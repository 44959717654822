import { Button, Tab, TabList, TabPanel, TabPanels, Tabs, Theme } from '@carbon/react';
import { Navigation } from '../../../common';
import { CARBON_THEME, CUSTOM_THEME } from '../../wizard/constants';
import { useNavigate } from 'react-router-dom';

import './ManageModels.scss';
import { OpsModelTab } from './OpsModelTab';
import { TimeoutAlert } from '../../../common/TimeoutAlert';
import { MxModelTab } from './MxModelTab';
import { FulltimeEquationsTab } from './FulltimeEquationsTab';
import { useState } from 'react';
import { OpsModelInfoModal } from './OpsModelInfoModal';
import { MxModelInfoModal } from './MxModelInfoModal';
import { FulltimeEquationsInfoModal } from './FulltimeEquationsInfoModal';
import { useFetchAllUsers } from '../../wizard/modals/shareScenario/useFetchAllUsers';
import { ErrorNotification } from '../../../common/ErrorNotification/ErrorNotification';

export const ManageModels = () => {
    const navigate = useNavigate();
    const [viewOpsModel, setViewOpsModel] = useState(null);
    const [viewMxModel, setViewMxModel] = useState(null);
    const [viewFulltimeEquation, setViewFulltimeEquation] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    useFetchAllUsers();

    return (
        <Theme theme={localStorage.getItem('theme') === CUSTOM_THEME.DARK ? CARBON_THEME.G90 : CARBON_THEME.WHITE}>
            <div className="wizard" style={{ minHeight: '100vh' }}>
                <Navigation />
                <div className="manage-models-content">
                    <Button className="back-to-admin-btn" onClick={() => navigate('/admin')}>
                        Back to Admin
                    </Button>
                    <Tabs onChange={({ selectedIndex }) => setActiveIndex(selectedIndex)}>
                        <TabList aria-label="List of tabs" contained>
                            <Tab data-testid="ops-models-tab">Aircrew Models</Tab>
                            <Tab data-testid="mx-models-tab">Maintenance Models</Tab>
                            <Tab data-testid="fulltime-equations-tab">Aircrew Fulltime Equations</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel aria-label="ops-model-tab">
                                <OpsModelTab
                                    onViewModel={(model) => setViewOpsModel(model)}
                                    activeIndex={activeIndex}
                                />
                            </TabPanel>
                            <TabPanel aria-label="mx-models-tab">
                                <MxModelTab onViewModel={(model) => setViewMxModel(model)} activeIndex={activeIndex} />
                            </TabPanel>
                            <TabPanel aria-label="fulltime-equations-tab">
                                <FulltimeEquationsTab
                                    onViewEquation={(eq) => setViewFulltimeEquation(eq)}
                                    activeIndex={activeIndex}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    {viewOpsModel ? (
                        <OpsModelInfoModal
                            open={!!viewOpsModel}
                            model={viewOpsModel}
                            onClose={() => setViewOpsModel(null)}
                        />
                    ) : null}
                    {viewMxModel ? (
                        <MxModelInfoModal
                            open={!!viewMxModel}
                            model={viewMxModel}
                            onClose={() => setViewMxModel(null)}
                        />
                    ) : null}
                    {viewFulltimeEquation ? (
                        <FulltimeEquationsInfoModal
                            open={!!viewFulltimeEquation}
                            model={viewFulltimeEquation}
                            onClose={() => setViewFulltimeEquation(null)}
                        />
                    ) : null}
                </div>
            </div>
            <TimeoutAlert />
            <ErrorNotification />
        </Theme>
    );
};
