import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@carbon/react';

const InputFieldsPropTypes = {
    maf: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handleMafChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    showError: PropTypes.bool,
};

const mafIsInvalid = (maf) => {
    return isNaN(maf) || maf === '' || maf <= 0;
};
/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof InputFieldsPropTypes>>}
 * @returns {React.ReactElement}
 */
export const InputFields = ({ disabled, maf, handleMafChange, showError }) => {
    const [showWarning, setShowWarning] = React.useState(false);
    const forceWarning = showError && mafIsInvalid(maf);
    return (
        <div className="input-fields" data-testid="input-fields">
            <TextInput
                id="input-fields-maf"
                data-testid="input-fields-maf"
                labelText="MAF"
                type="number"
                disabled={disabled}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
                value={maf ?? ''}
                warn={forceWarning || showWarning}
                warnText="Enter a valid positive non-zero number"
                onBlur={() => {
                    if (mafIsInvalid(maf)) {
                        setShowWarning(true);
                    }
                }}
                onChange={(e) => {
                    handleMafChange(e);
                    setShowWarning(false);
                }}
            />
        </div>
    );
};

InputFields.propTypes = InputFieldsPropTypes;
