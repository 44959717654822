import { useAtom, useSetAtom } from 'jotai';
import { isEqual } from 'lodash';
import { getScenario } from '../pages/wizard/scenarioApiCalls';
import {
    apiScenarioAtom,
    getAllOfScenarioStateAtom,
    insertScenarioApiDataAtom,
    isApprovedScenarioAtom,
    scenarioIsSavingAtom,
} from './wizardAtoms';
import { useUnmount } from './useUnmount';
import { useSaveScenario } from './useSaveScenario';
import { useClearMxOpsScenarios } from './useClearMxOpsScenarios';
import { unitDetailsInitialState } from '../pages/wizard/steps/unitDetails/UnitDetailsAtoms';
import { useCloneAdjustmentDataToScenario } from '../pages/wizard/steps/generalSettings/useCloneAdjustmentDataToScenario';

/**
 * Saves state of the atom to scenario when the component unmounts
 *  * @param {boolean} isValid check if all required fields selected
 */
export function useSaveScenarioAtomOnUnmount({ isValid, isGeneralSettings = false }) {
    const [scenario] = useAtom(apiScenarioAtom);
    const [fullScenario] = useAtom(getAllOfScenarioStateAtom);
    const insertScenarioData = useSetAtom(insertScenarioApiDataAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const { saveScenarioCallback } = useSaveScenario();
    const clearMxOpsScenarios = useClearMxOpsScenarios();
    const setIsSaving = useSetAtom(scenarioIsSavingAtom);

    const cloneAdjustmentDataToScenario = useCloneAdjustmentDataToScenario();

    const handleUpdateScenario = async () => {
        const hasGsFilterChange = !isEqual(
            {
                ...scenario.generalSettings,
            },
            {
                ...fullScenario.generalSettings,
            }
        );

        const hasUnitDetailChange = !isEqual(
            {
                ...scenario.unitDetails,
            },
            {
                ...fullScenario.unitDetails,
            }
        );

        try {
            setIsSaving(true);
            let updatedScenario = isGeneralSettings ? cloneAdjustmentDataToScenario() : fullScenario;
            if (hasGsFilterChange) {
                updatedScenario = {
                    ...updatedScenario,
                    unitDetails: { ...unitDetailsInitialState, MX_PECS: [], OG_PECS: [] },
                    additionalUnitDetails: { AIRCREW: [], CMD: [], ILC: [], MX: [] },
                };
            }
            if (hasUnitDetailChange) {
                updatedScenario = {
                    ...updatedScenario,
                    additionalUnitDetails: { AIRCREW: [], CMD: [], ILC: [], MX: [] },
                };
            }

            const data = await saveScenarioCallback(updatedScenario);
            await clearMxOpsScenarios(updatedScenario);
            const [updateStatus] = data;
            const { scenario_name, scenario_id } = fullScenario;
            const { isOk, data: updatedScenarioFromApi } = await getScenario(scenario_id);

            if (updateStatus === 200 && isOk) {
                // Keep updated name since api does not return scenario_name
                updatedScenarioFromApi.scenario_name = scenario_name;
                updatedScenarioFromApi.scenario_id = scenario_id;
            }
            insertScenarioData(updatedScenarioFromApi);
        } catch (error) {
            console.error('Could not update scenario', error);
        } finally {
            setIsSaving(false);
        }
    };

    useUnmount(async () => {
        if (fullScenario?.scenario_id && isValid && !isApprovedScenario) {
            handleUpdateScenario();
        } else if (fullScenario?.scenario_id && !isApprovedScenario) {
            const { isOk, data } = await getScenario(fullScenario?.scenario_id);
            if (isOk) {
                insertScenarioData(data);
            }
        }
    });
}
