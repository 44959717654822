import React, { useCallback, useEffect, useState } from 'react';

import { ModelFilters } from './ModelFilters';
import { Accordion, AccordionItem, Loading } from '@carbon/react';
import { ModelsTable } from './ModelsTable';
import { fetchMxModels } from '../adminApiCalls';
import { deleteMxModel } from '../../wizard/maintenanceApiCalls';
import { useErrorNotification } from '../../../common/ErrorNotification/useErrorNotification';

const mxModelProps = {};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof mxModelProps>>}
 * @returns {React.ReactElement}
 */

export const MxModelTab = ({ onViewModel, activeIndex }) => {
    const [modelList, setModelList] = useState([]);
    const [cmd, setCmd] = useState([]);
    const [mds, setMds] = useState([]);
    const [filter, setFilter] = useState({
        cmd: null,
        mds: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { openError } = useErrorNotification();

    const populateMxModels = useCallback(
        async function () {
            if (modelList.length < 1) {
                setIsLoading(true);
            }
            setButtonLoading(true);
            const { success, data, cmdList, mdsList } = await fetchMxModels();
            if (success) {
                setModelList(data);
                setCmd(cmdList);
                setMds(mdsList);
                setIsLoading(false);
                setButtonLoading(false);
            }
        },
        [modelList]
    );

    useEffect(() => {
        if (activeIndex === 1 && modelList.length < 1) {
            populateMxModels();
        }
    }, [activeIndex, modelList, populateMxModels]);

    const cmdFilter = filter.cmd;
    const mdsFilter = filter.mds;

    const isFiltered = (model) => {
        if (cmdFilter && model.CMD !== cmdFilter) {
            return false;
        }
        if (mdsFilter && model.MDS !== mdsFilter) {
            return false;
        }
        return true;
    };

    const updateModelList = (id) => {
        const updatedModelList = modelList.map((list) => {
            const updatedModels = list.MODELS.map((model) => {
                return {
                    ...model,
                    ARCHIVED: model.MX_ID === id ? (model.ARCHIVED === 1 ? 0 : 1) : model.ARCHIVED,
                };
            });
            return {
                ...list,
                MODELS: updatedModels,
            };
        });
        setModelList(updatedModelList);
    };

    return (
        <div className="mt-4 mb-4" data-testid="mx-model-tab-content">
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <ModelFilters
                        cmdList={cmd}
                        cmdFilter={cmdFilter}
                        mdsFilter={mdsFilter}
                        mdsList={mds}
                        onSelect={(update) => {
                            setFilter((prevFilter) => ({
                                ...prevFilter,
                                ...update,
                            }));
                        }}
                    />
                    <Accordion>
                        {modelList.map((modelData, i) =>
                            isFiltered(modelData) ? (
                                <AccordionItem
                                    key={`accordion_item_${i}`}
                                    title={
                                        <div className="accordion-title">
                                            <div>{`CMD: ${modelData.CMD}`}</div>
                                            <div>{`MDS: ${modelData.MDS}`}</div>
                                        </div>
                                    }
                                    data-testid={`accordion_item_${i}`}
                                >
                                    <ModelsTable
                                        models={modelData.MODELS}
                                        actions={[
                                            {
                                                actionText: 'View',
                                                onClick: (model) => onViewModel(model),
                                            },
                                            {
                                                actionText: 'Archive',
                                                onClick: async (model) => {
                                                    setButtonLoading(true);
                                                    const { isOk } = await deleteMxModel(model?.MX_ID);
                                                    if (isOk) {
                                                        updateModelList(model?.MX_ID);
                                                        openError({
                                                            kind: 'success',
                                                            subtitle: 'Model archived successfully.',
                                                            title: 'Success',
                                                        });
                                                        setButtonLoading(false);
                                                    } else {
                                                        openError({
                                                            subtitle: `Could not archive model.`,
                                                        });
                                                        setButtonLoading(false);
                                                        throw new Error('Could not archive model');
                                                    }
                                                },
                                            },
                                            {
                                                actionText: 'Unarchive',
                                                onClick: async (model) => {
                                                    setButtonLoading(true);
                                                    const { isOk } = await deleteMxModel(model?.MX_ID, false);
                                                    if (isOk) {
                                                        updateModelList(model?.MX_ID);
                                                        openError({
                                                            kind: 'success',
                                                            subtitle: 'Model unarchived successfully.',
                                                            title: 'Success',
                                                        });
                                                        setButtonLoading(false);
                                                    } else {
                                                        openError({
                                                            subtitle: `Could not unarchive model.`,
                                                        });
                                                        setButtonLoading(false);
                                                        throw new Error('Could not unarchive model');
                                                    }
                                                },
                                            },
                                        ]}
                                        onViewModel={onViewModel}
                                        ariaLabel="Mx Model Table"
                                        headers={[
                                            { header: 'Model Name', key: 'MODEL_NAME' },
                                            { header: 'Model Type', key: 'MODEL_TYPE' },
                                            { header: 'Source', key: 'SOURCE' },
                                            { header: 'Created Date', key: 'CREATED_DATE' },
                                            { header: 'Updated Date', key: 'UPDATED_DATE' },
                                            { header: 'Created By', key: 'USER_ID' },
                                            { header: '', key: 'action' },
                                        ]}
                                        buttonLoading={buttonLoading}
                                    />
                                </AccordionItem>
                            ) : null
                        )}
                    </Accordion>
                </>
            )}
        </div>
    );
};

MxModelTab.propTypes = mxModelProps;
