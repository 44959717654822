import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    TableToolbarMenu,
    PaginationNav,
    Checkbox,
} from '@carbon/react';
import { LoadingUtil } from './LoadingUtil';

export const Datatable = ({
    rows = [],
    headers = [],
    tableTitle = 'Table Title',
    searchBar = true,
    datatableStatus = true,
    defaultPageSize = 9,
}) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [currentPageSize] = useState(defaultPageSize);
    const paginationsProps = {
        totalItems: Math.ceil(rows.length / currentPageSize),
        onChange: (page) => setPageIndex(page * currentPageSize),
    };

    const [visibleColumns, setVisibleColumns] = useState(
        headers.map((column) => column.key).reduce((a, v) => ({ ...a, [v]: true }), {})
    );

    useEffect(() => {
        setPageIndex(0);
    }, [rows]);

    const columnSelector = () => {
        return (
            <TableToolbarMenu>
                {headers.map((column) => (
                    <Checkbox
                        labelText={column.header}
                        checked={visibleColumns[column.key]}
                        id={`columnSelector_${column.key}`}
                        key={`columnSelector_${column.key}`}
                        data-testid={`columnSelector_${column.key}`}
                        onChange={(event) => {
                            if (event.target.checked)
                                setVisibleColumns((state) => {
                                    return { ...state, [column.key]: true };
                                });
                            else
                                setVisibleColumns((state) => {
                                    return { ...state, [column.key]: false };
                                });
                        }}
                    />
                ))}
            </TableToolbarMenu>
        );
    };

    return (
        <>
            <DataTable rows={rows.slice(pageIndex, pageIndex + currentPageSize)} headers={headers} isSortable>
                {({
                    rows,
                    headers,
                    getTableProps,
                    getHeaderProps,
                    getRowProps,
                    getToolbarProps,
                    onInputChange,
                    getTableContainerProps,
                }) => (
                    <TableContainer title={tableTitle} {...getTableContainerProps()}>
                        <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                            <TableToolbarContent>
                                {searchBar ? <TableToolbarSearch onChange={onInputChange} persistent /> : <></>}
                                {columnSelector()}
                            </TableToolbarContent>
                        </TableToolbar>
                        <Table {...getTableProps()}>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) =>
                                        visibleColumns[header.key] ? (
                                            <TableHeader {...getHeaderProps({ header })} children={header.header} />
                                        ) : (
                                            <></>
                                        )
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                    <TableRow key={row.id} {...getRowProps({ row })}>
                                        {row.cells.map((cell) =>
                                            visibleColumns[cell.info.header] ? (
                                                <TableCell key={cell.id} children={cell.value} />
                                            ) : (
                                                <></>
                                            )
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </DataTable>
            <DatatableStatus status={datatableStatus} />
            <PaginationNav {...paginationsProps} />
        </>
    );
};

const DatatableStatus = ({ status = false }) => {
    if (!status) {
        return <div className="text-center my-5">No Data Found</div>;
    } else if (status === 'loading') {
        return (
            <LoadingUtil
                type="inline"
                inlineLoading={{
                    className: 'mt-3 justify-content-center',
                    description: <em>Loading data</em>,
                }}
            />
        );
    } else return <></>;
};

const formatRows = (rows, headers) => {
    let newRows = rows.map((row) => {
        let newRow = { id: row.id };
        headers.forEach((cell) => {
            newRow[cell.key] = cell.format(row);
        });
        return newRow;
    });
    return newRows;
};

export const refreshTable = (newData, headers, setRows, setFormattedRows) => {
    setRows(newData);
    setFormattedRows(formatRows(newData, headers));
};

export const handleTableSubmitLayout = async (url, postData) => {
    try {
        const res = await axios.post(url, postData, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            credentials: 'include',
        });
        return res.data;
    } catch (error) {
        console.error(error);
    }
};
