import { Button } from '@carbon/react';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { addMDSModalOpenAtom, addUnitModalOpenAtom } from '../generalSettings/GeneralSettingsAtoms';
import PropTypes from 'prop-types';
import { isApprovedScenarioAtom } from '../../../../hooks/wizardAtoms';

export const StepHeader = ({ title, subtitle }) => {
    const setAddMDSOpen = useSetAtom(addMDSModalOpenAtom);
    const setAddUnitOpen = useSetAtom(addUnitModalOpenAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);

    if (title === 'General Settings') {
        return (
            <div className="step-header d-flex justify-content-between">
                <h3>{title}</h3>
                <div>
                    <Button
                        size="sm"
                        className="mx-2"
                        disabled={isApprovedScenario}
                        onClick={() => setAddMDSOpen(true)}
                    >
                        Add MDS
                    </Button>
                    <Button size="sm" disabled={isApprovedScenario} onClick={() => setAddUnitOpen(true)}>
                        Add Unit
                    </Button>
                </div>
            </div>
        );
    } else {
        return (
            <div className="step-header">
                <h3>{title}</h3>
                <div className="subtitle">{subtitle}</div>
            </div>
        );
    }
};

StepHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};
