import React, { useEffect, useRef, useState } from 'react';
import { fetchVnv } from '../../adminApiCalls';
import { MultiSelectAll } from '../../../wizard/steps/additionalUnitDetails/AdditionalUnitDetails';
import { useErrorNotification } from '../../../../common/ErrorNotification/useErrorNotification';
import { useFetchAllUsers } from '../../../wizard/modals/shareScenario/useFetchAllUsers';

export const VnvFilters = ({ onLoading, onFiltersUpdate, shouldFetchVnv }) => {
    const [filters, setFilters] = useState({
        mds: [],
        ilc: [],
        cmd: [],
    });

    const [mds, setMds] = useState([]);
    const [ilc, setIlc] = useState([]);
    const [cmd, setCmd] = useState([]);
    const [savedData, setSavedData] = useState({});
    const { openError } = useErrorNotification();
    const { init, usersById } = useFetchAllUsers();
    const [fetched, setFetched] = useState(false);

    const usersByIdRef = useRef(usersById);
    usersByIdRef.current = usersById;
    useEffect(() => {
        const loadVnvData = async () => {
            onLoading(true);
            const {
                success,
                issues,
                error,
                filters,
                graphData,
                mxGraphData,
                mxTobeCount,
                mxAsisCount,
                tobeCount,
                asisCount,
                vnv_path,
            } = await fetchVnv(usersByIdRef.current);
            onLoading(false);

            if (success) {
                const result = {
                    vnv: graphData,
                    mxVnv: mxGraphData,
                    issues: issues,
                    mxTobeCount,
                    mxAsisCount,
                    tobeCount,
                    asisCount,
                    vnv_path,
                };
                setSavedData(result);
                onFiltersUpdate(result);
                setFilters(filters);
                setCmd(filters.cmd);
                setMds(filters.mds);
                setIlc(filters.ilc);
                setFetched(true);
            } else {
                if (error?.name !== 'CanceledError') {
                    openError({ title: 'VNV Building', subtitle: 'VNV is building. Please try again in 10 minutes' });
                }
            }
        };
        if (!init && shouldFetchVnv) {
            loadVnvData();
        }
        return () => {
            fetchVnv(null);
        };
    }, [onFiltersUpdate, onLoading, openError, init, shouldFetchVnv]);

    const handleDropdownChange = (selectedItems, type) => {
        switch (type) {
            case 'cmd':
                setCmd(selectedItems);
                break;
            case 'ilc':
                setIlc(selectedItems);
                break;
            case 'mds':
                setMds(selectedItems);
                break;
            default:
                break;
        }
        const vnvFilters = {
            cmd,
            ilc,
            mds,
            [type]: selectedItems,
        };
        let tobeCount = 0;
        let asisCount = 0;
        let mxTobeCount = 0;
        let mxAsisCount = 0;
        const updatedVnv = savedData.vnv?.filter((scenario) => {
            return (
                vnvFilters.cmd.includes(scenario.CMD) &&
                vnvFilters.ilc.includes(scenario.ILC) &&
                vnvFilters.mds.includes(scenario.MDS)
            );
        });
        const updatedMxVnv = savedData.mxVnv?.filter((scenario) => {
            return (
                vnvFilters.cmd.includes(scenario.CMD) &&
                vnvFilters.ilc.includes(scenario.ILC) &&
                vnvFilters.mds.includes(scenario.MDS)
            );
        });
        const updatedIssues = savedData.issues?.filter((scenario) => {
            return (
                vnvFilters.cmd.includes(scenario.CMD) &&
                vnvFilters.ilc.includes(scenario.ILC) &&
                vnvFilters.mds.includes(scenario.MDS)
            );
        });
        updatedVnv.forEach((scenario) => {
            asisCount += scenario.data?.[0]?.x;
            tobeCount += scenario.data?.[0]?.y;
        });
        updatedMxVnv.forEach((scenario) => {
            mxAsisCount += scenario.data?.[0]?.x;
            mxTobeCount += scenario.data?.[0]?.y;
        });
        onFiltersUpdate({
            vnv: updatedVnv,
            mxVnv: updatedMxVnv,
            issues: updatedIssues,
            mxTobeCount,
            mxAsisCount,
            tobeCount,
            asisCount,
        });
    };

    return (
        <div className="dropdowns">
            <div data-testid="fsi-CMD-dropdown-select">
                <Dropdown
                    key={`cmd_${fetched}`}
                    items={filters.cmd}
                    selectedItems={cmd}
                    dropdownName="CMD"
                    onChange={(selectedItems) => handleDropdownChange(selectedItems, 'cmd')}
                />
            </div>
            <div data-testid="fsi-ILC-dropdown-select">
                <Dropdown
                    key={`ilc_${fetched}`}
                    items={filters.ilc}
                    selectedItems={ilc}
                    dropdownName="ILC"
                    onChange={(selectedItems) => handleDropdownChange(selectedItems, 'ilc')}
                />
            </div>
            <div data-testid="fsi-MDS-dropdown-select">
                <Dropdown
                    key={`mds_${fetched}`}
                    items={filters.mds}
                    selectedItems={mds}
                    dropdownName="MDS"
                    onChange={(selectedItems) => handleDropdownChange(selectedItems, 'mds')}
                />
            </div>
        </div>
    );
};

const fieldName = {
    CMD: 'Major Command',
    MDS: 'Mission Design Series',
    ILC: 'Installation Location Name',
};
const Dropdown = ({ items, selectedItems, dropdownName, onChange }) => {
    const [label, setLabel] = useState('Choose options');

    const handleChange = (value) => {
        if (value.selectedItems.length === 1) {
            setLabel('Option selected');
        } else if (value.selectedItems.length > 1) {
            setLabel('Options selected');
        } else {
            setLabel('Choose options');
        }
        onChange(value.selectedItems);
    };

    return (
        <div className="dropdown">
            <MultiSelectAll
                id="cmd-multi-select"
                className="pb-3"
                items={items}
                selectedItemsInState={selectedItems}
                selectAllItem={'Select All'}
                itemToString={(item) => item ?? ''}
                label={label}
                titleText={fieldName[dropdownName]}
                onChange={(selectedItems, selectAll) => {
                    if (selectAll) {
                        handleChange({ selectedItems: items });
                    } else {
                        handleChange({ selectedItems });
                    }
                }}
            />
        </div>
    );
};
