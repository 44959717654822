import { GeneralSettings, UnitDetails } from './';
import { AdditionalUnitDetails } from './additionalUnitDetails/AdditionalUnitDetails';
import { Mx } from './mxEquations/Mx';
import { Ops } from './opsEquations/Ops';
import { ReviewStep } from './reviewStep/ReviewStep';
import { BuildTool, Connect, LicenseDraft, Receipt, Settings, Tools } from '@carbon/icons-react';

export const stepOrder = [
    {
        path: '',
        element: <GeneralSettings />,
        title: 'General Settings',
        icon: <Settings />,
    },
    {
        path: 'unit-details',
        element: <UnitDetails />,
        title: 'Unit Details',
        icon: <LicenseDraft />,
    },
    {
        path: 'additional-unit-details',
        element: <AdditionalUnitDetails />,
        title: 'Additional Unit Details',
        icon: <Connect />,
    },
    {
        path: 'ops',
        element: <Ops />,
        title: 'OPS Equations',
        header: 'Operations Aircrew Equations',
        icon: <BuildTool />,
    },
    {
        path: 'mx',
        element: <Mx />,
        title: 'MX Equations',
        header: 'Direct Core Maintenance Equations',
        icon: <Tools />,
    },
    {
        path: 'review',
        element: <ReviewStep />,
        title: 'Review',
        icon: <Receipt />,
    },
];
