import React from 'react';

import { ReviewTableContainer } from './ReviewTableContainer';

const tableHeaders = [
    {
        header: 'UNIT',
        key: 'UNIT',
    },
    {
        header: 'PEC',
        key: 'PEC',
    },
];
export const ReviewUnitDetails = ({
    isLoading,
    opsGp = '',
    mxGp = '',
    opsDetails = [],
    mxDetails = [],
    //
}) => {
    return (
        <div className="d-flex justify-content-between">
            <div className="flex-grow-1 flex-shrink-1 mt-2 mb-2 me-2">
                <ReviewTableContainer
                    header="OPS Unit Details"
                    description={opsGp}
                    tableHeaders={tableHeaders}
                    rows={opsDetails}
                    isLoading={isLoading}
                    id={'ops-unit-details-review-table'}
                >
                    <div className="mb-2">OG/WING PAS: {opsGp}</div>
                </ReviewTableContainer>
            </div>
            <div className="flex-grow-1 flex-shrink-1 mt-2 mb-2 ms-2">
                <ReviewTableContainer
                    header="MX Unit Details"
                    description={mxGp}
                    tableHeaders={tableHeaders}
                    rows={mxDetails}
                    isLoading={isLoading}
                    id={'mx-unit-details-review-table'}
                >
                    <div className="mb-2">MXG/WING PAS: {mxGp}</div>
                </ReviewTableContainer>
            </div>
        </div>
    );
};
