import { ListItem, Modal, UnorderedList } from '@carbon/react';
import './ManageScenarios.scss';
import PropTypes from 'prop-types';

export const ApproveScenarioModal = ({ scenarios, open, onClose, onSubmit }) => {
    const handleSubmit = () => {
        onSubmit();
        onClose();
    };

    return (
        <Modal
            className="approve-scenario-modal"
            open={open}
            modalHeading="Approve Scenarios"
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
            onRequestClose={onClose}
            onRequestSubmit={handleSubmit}
            size="sm"
            data-testid="approve-scenario-modal"
        >
            <div className="mb-2">Are you sure you want to approve the following scenarios?</div>
            <UnorderedList>
                {scenarios.map((scenario) => {
                    return <ListItem key={scenario.id}>{scenario.scenario_name}</ListItem>;
                })}
            </UnorderedList>
        </Modal>
    );
};

ApproveScenarioModal.propTypes = {
    scenarios: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};
