import React, { useState, useEffect } from 'react';
import { SelectUtil } from '../../../../utils';
import PropTypes from 'prop-types';
import { selectionList } from './selectionList';

export const HostCommandSection = ({ setIsCompleteTfiHostInput, data }) => {
    const [hostCmd, setHostCmd] = useState({ value: data.cmd, invalid: false });
    const [hostLocation, setHostLocation] = useState({
        value: data.ilc,
        invalid: false,
    });
    const [hostOpPec, setHostOpPec] = useState({
        value: data.op_pec,
        invalid: false,
    });
    const [hostMxPec, setHostMxPec] = useState({
        value: data.mx_pec,
        invalid: false,
    });

    useEffect(() => {
        const isCompleteHostInput =
            !hostCmd.invalid && !hostLocation.invalid && !hostOpPec.invalid && !hostMxPec.invalid;
        setIsCompleteTfiHostInput(isCompleteHostInput);
    }, [setIsCompleteTfiHostInput, hostCmd.invalid, hostLocation.invalid, hostOpPec.invalid, hostMxPec.invalid]);

    return (
        <div id="tfi-host-command-section" className="bordered mb-3">
            <div className="mb-2">
                <h5>Host Command</h5>
            </div>
            <div className="d-flex">
                <SelectUtil
                    select={{
                        hideLabel: false,
                        size: 'sm',
                        labelText: 'CMD',
                        className: 'mb-3',
                        value: undefined,
                        defaultValue: hostCmd.value,
                    }}
                    options={selectionList.cmdOptions}
                    setState={setHostCmd}
                />
                <SelectUtil
                    select={{
                        hideLabel: false,
                        size: 'sm',
                        labelText: 'Location',
                        className: 'mb-3',
                        value: undefined,
                        defaultValue: hostLocation.value,
                    }}
                    options={selectionList.ilcOptions}
                    setState={setHostLocation}
                />
                <SelectUtil
                    select={{
                        hideLabel: false,
                        size: 'sm',
                        labelText: 'OP PEC',
                        className: 'mb-3',
                        value: undefined,
                        defaultValue: hostOpPec.value,
                    }}
                    options={selectionList.pecOptions}
                    setState={setHostOpPec}
                />
                <SelectUtil
                    select={{
                        hideLabel: false,
                        size: 'sm',
                        labelText: 'MX PEC',
                        className: 'mb-3',
                        value: undefined,
                        defaultValue: hostMxPec.value,
                    }}
                    options={selectionList.pecOptions}
                    setState={setHostMxPec}
                />
            </div>
        </div>
    );
};

HostCommandSection.propTypes = {
    setIsValidHostCommand: PropTypes.func,
    data: PropTypes.object,
};
