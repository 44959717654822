/**
 *
 * @param {{
 *  ACR_ID: number,
 *  CMD: string,
 *  MDS: string,
 *  MODEL_NAME: string,
 * }} param0
 * @returns {string}
 */
export const getParentId = ({ ACR_ID, CMD, MDS, MODEL_NAME }) => {
    const untrimmedParentId = `${ACR_ID}_${CMD}_${MDS}_${MODEL_NAME}`;
    return untrimmedParentId.replace(/ |:::/g, '');
};
/**
 *
 * @param {{
 *  MODEL_NAME: string,
 *  CREW_POSITION: string,
 *  AFSC: string,
 *  CREW_RATIO: number,
 *  CREW_COMP_ENL: number,
 *  CREW_COMP_OFF: number,
 *  NOTES: string
 * }} param0
 * @param {string} parentId
 * @returns {string}
 */
export const getChildId = (
    { CREW_POSITION, AFSC, CREW_RATIO, CREW_COMP_ENL, CREW_COMP_OFF, NOTES },
    parentId
) => {
    const untrimmedChildId = `${CREW_POSITION}_${AFSC}_${CREW_RATIO}_${CREW_COMP_ENL}_${CREW_COMP_OFF}_${NOTES}`;
    return `${parentId}:::${untrimmedChildId.replace(/ |:::/g, '')}`;
};
