import { useEffect } from 'react';

// Reset OG PEC/MXG PEC selections when required selections are cleared

export const useResetPecSelections = ({
    hasSelectedRequiredOgPas,
    hasSelectedRequiredMxgPas,
    setSelectedOgPecs,
    setSelectedMxgPecs,
}) => {
    useEffect(() => {
        if (!hasSelectedRequiredOgPas) {
            setSelectedOgPecs([]);
        }
        if (!hasSelectedRequiredMxgPas) {
            setSelectedMxgPecs([]);
        }
    }, [hasSelectedRequiredOgPas, hasSelectedRequiredMxgPas, setSelectedOgPecs, setSelectedMxgPecs]);
};
