import React, { useState } from 'react';
import { TabPanel, Button, Loading } from '@carbon/react';
import { ScenarioDatatable } from './ScenarioDatatable';
import { VnvChart } from './VnvChart';

import './InvalidScenariosTab.scss';
import { VnvFilters } from './VnvFilters';
import { getVnvDownload } from '../../adminApiCalls';
import { useErrorNotification } from '../../../../common/ErrorNotification/useErrorNotification';

export const InvalidScenariosTab = ({ shouldFetchVnv }) => {
    const [invalidScenarios, setInvalidScenarios] = useState([]);
    const [graphData, setGraphData] = useState([]);
    const [mxGraphData, setMxGraphData] = useState([]);
    const [url, setUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [mxAsisCount, setMxAsisCount] = useState(0);
    const [mxTobeCount, setMxTobeCount] = useState(0);
    const [asisCount, setAsisCount] = useState(0);
    const [tobeCount, setTobeCount] = useState(0);
    const { openError } = useErrorNotification();

    const handleFiltersUpdate = React.useCallback(
        ({
            issues,
            vnv,
            mxVnv,
            vnv_path = null,
            mxAsisCount = null,
            mxTobeCount = null,
            asisCount = null,
            tobeCount = null,
            //
        }) => {
            setGraphData(vnv);
            setMxGraphData(mxVnv);
            setInvalidScenarios(issues);
            setUrl((prev) => (typeof vnv_path === 'string' ? vnv_path : prev));
            setMxAsisCount((prev) => (typeof mxAsisCount === 'number' ? mxAsisCount : prev));
            setMxTobeCount((prev) => (typeof mxTobeCount === 'number' ? mxTobeCount : prev));
            setAsisCount((prev) => (typeof asisCount === 'number' ? asisCount : prev));
            setTobeCount((prev) => (typeof tobeCount === 'number' ? tobeCount : prev));
        },
        [setGraphData, setMxGraphData]
    );

    return (
        <TabPanel
            className={`publish-scenario-tab invalidScenariosTab`}
            style={{ minHeight: '100%' }}
            aria-label="invalid scenarios tab"
        >
            <VnvFilters
                shouldFetchVnv={shouldFetchVnv}
                onLoading={setIsLoading}
                onFiltersUpdate={handleFiltersUpdate}
            />
            <ScenarioDatatable type="Invalid" scenarios={invalidScenarios} />
            {Array.isArray(graphData) && graphData.length > 0 ? (
                <VnvChart
                    graphData={graphData}
                    title="Aircrew Verification And Validation"
                    asisTotal={asisCount}
                    tobeTotal={tobeCount}
                    //
                />
            ) : null}
            {Array.isArray(mxGraphData) && mxGraphData.length > 0 ? (
                <VnvChart
                    graphData={mxGraphData}
                    title="Maintenance Verification And Validation"
                    asisTotal={mxAsisCount}
                    tobeTotal={mxTobeCount}
                    //
                />
            ) : null}
            <div className="ms-auto mt-3" style={{ width: 'fit-content' }}>
                <Button
                    onClick={async () => {
                        const { success, data } = await getVnvDownload(url);
                        if (success) {
                            const anchor = document.createElement('a');
                            anchor.href = data.link;
                            anchor.download = url.split('/').pop();
                            document.body.appendChild(anchor);
                            anchor.click();
                            document.body.removeChild(anchor);
                        } else {
                            openError({ title: 'Error', subtitle: 'Could not get VNV file' });
                        }
                    }}
                >
                    Download
                </Button>
            </div>
            <Loading active={isLoading} withOverlay />
        </TabPanel>
    );
};
