import { IconButton } from '@carbon/react';
import { Share } from '@carbon/react/icons';
import { useAtom } from 'jotai';
import { isShareScenarioOpenAtom } from '../hooks/wizardAtoms';

export const ShareButton = ({ kind }) => {
    const [, setOpenShare] = useAtom(isShareScenarioOpenAtom);

    return (
        <IconButton
            data-testid="share-button"
            label="Share Scenario"
            align="left"
            enterDelayMs={1000}
            onClick={() => setOpenShare(true)}
            kind={kind}
            className="me-2"
            size="md"
        >
            <Share />
        </IconButton>
    );
};
