import React from 'react';
import PropTypes from 'prop-types';
import { useAtom } from 'jotai';
import { IconButton } from '@carbon/react';
import { Save } from '@carbon/react/icons';
import { isScenarioDialogOpenAtom } from '../../../../hooks/wizardAtoms';

export const OpenScenarioDialogButton = ({ kind }) => {
    const [, setIsScenarioDialogOpen] = useAtom(isScenarioDialogOpenAtom);
    return (
        <IconButton
            label="Update Scenario"
            align="left"
            onClick={() => setIsScenarioDialogOpen(true)}
            kind={kind}
            className="me-2"
            size="md"
        >
            <Save />
        </IconButton>
    );
};

OpenScenarioDialogButton.propTypes = {
    kind: PropTypes.string,
};
