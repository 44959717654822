import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Modal,
    ComposedModal,
    ModalFooter,
    ModalBody,
    ModalHeader,
    TextInput,
    Button,
    InlineNotification,
} from '@carbon/react';

import './UpdateScenarioDialog.scss';
import { useAtom } from 'jotai';
import { isApprovedScenarioAtom } from '../../../../hooks/wizardAtoms';
import { hasOnePaiTypeAtom } from '../../steps/generalSettings/GeneralSettingsAtoms';

export const UpdateScenarioDialog = ({
    onSubmitNew,
    onSubmitUpdate,
    onDelete,
    onClose,
    open,
    list = [],
    currentScenarioId = -1,
    currentScenarioName = '',
    disabled = false,
}) => {
    const [name, setName] = useState('');
    const [showLengthError, setShowLengthError] = useState(false);
    const [showUniqueError, setShowUniqueError] = useState(false);
    const [showEmptyError, setShowEmptyError] = useState(false);
    const [showDeleteWarning, setShowDeleteWarning] = useState(false);
    const [hasOnePaiType] = useAtom(hasOnePaiTypeAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);

    useEffect(() => {
        // Reset when modal opens
        if (open) {
            setName(currentScenarioName);
        }
    }, [currentScenarioName, open]);

    const trimmedName = name.trim();
    const isNotUnique = list.some(({ scenario_name }) => trimmedName === scenario_name);

    const handleUpdateScenario = () => {
        const containsDuplicateName = list.some(({ scenario_name, scenario_id }) => {
            return scenario_name === trimmedName && scenario_id !== currentScenarioId;
        });
        if (containsDuplicateName) {
            setShowUniqueError(true);
            return;
        }
        if (trimmedName.length === 0) {
            setShowEmptyError(true);
            return;
        }
        if (trimmedName.length > 50) {
            setShowLengthError(true);
            return;
        }
        onSubmitUpdate(trimmedName);
    };

    const handleCreateNew = () => {
        if (isNotUnique) {
            setShowUniqueError(true);
            return;
        }
        if (trimmedName.length === 0) {
            setShowEmptyError(true);
            return;
        }
        if (trimmedName.length > 50) {
            setShowLengthError(true);
            return;
        }
        onSubmitNew(trimmedName);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        setShowUniqueError(false);
        setShowEmptyError(false);
        setShowLengthError(false);
    };

    return (
        <>
            <ComposedModal
                open={open}
                onClose={onClose}
                className="update-scenario-dialog"
                data-testid="update-scenario-dialog"
            >
                <ModalHeader label="" title="Update Scenario" />
                <ModalBody>
                    <TextInput
                        id="scenario-name-input"
                        labelText="Scenario Name"
                        value={name}
                        size="sm"
                        onChange={handleNameChange}
                        className="mb-2"
                        data-testid="scenario-name-input"
                    />
                    {trimmedName.length === 0 && showEmptyError ? (
                        <InlineNotification
                            title=""
                            subtitle="Scenario name can not be empty"
                            hideCloseButton
                            kind="error"
                        />
                    ) : null}
                    {isNotUnique && showUniqueError ? (
                        <InlineNotification
                            title=""
                            subtitle="Scenario name must be unique"
                            hideCloseButton
                            kind="error"
                        />
                    ) : null}
                    {trimmedName.length > 50 && showLengthError ? (
                        <InlineNotification
                            title=""
                            subtitle="Scenario name must be less than 50 characters"
                            hideCloseButton
                            kind="error"
                        />
                    ) : null}
                    {!hasOnePaiType ? (
                        <InlineNotification
                            title="Multiple PAI types"
                            subtitle="Please only add one PAI type to Scenario."
                            hideCloseButton
                            kind="warning"
                        />
                    ) : null}
                </ModalBody>
                <ModalFooter className="button-row">
                    <Button
                        className="delete"
                        kind="danger--ghost"
                        onClick={() => setShowDeleteWarning(true)}
                        disabled={disabled}
                    >
                        Delete Scenario
                    </Button>
                    <Button kind="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        kind="primary"
                        onClick={handleCreateNew}
                        disabled={disabled || showUniqueError || !hasOnePaiType}
                    >
                        Save as New
                    </Button>
                    <Button
                        kind="primary"
                        onClick={handleUpdateScenario}
                        disabled={disabled || !hasOnePaiType || isApprovedScenario}
                    >
                        Update
                    </Button>
                </ModalFooter>
            </ComposedModal>
            {showDeleteWarning ? (
                <Modal
                    open
                    danger
                    modalHeading={`Are you sure you want to delete ${currentScenarioName}?`}
                    modalLabel="Delete Scenario"
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    data-testid="delete-scenario-confirmation"
                    onRequestClose={() => setShowDeleteWarning(false)}
                    onRequestSubmit={() => {
                        onDelete();
                        setShowDeleteWarning(false);
                    }}
                />
            ) : null}
        </>
    );
};

UpdateScenarioDialog.propTypes = {
    onSubmitNew: PropTypes.func.isRequired,
    onSubmitUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            scenario_name: PropTypes.string,
            scenario_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
    ),
    currentScenarioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    currentScenarioName: PropTypes.string,
    disabled: PropTypes.bool,
};
