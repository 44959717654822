import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAtom, useSetAtom } from 'jotai';

import { ActiveWorkcenters } from '../mxFormGroups/ActiveWorkcenters';
import { mxWorkcentersAtom, paiReqAtom, mxWorkcentersInvalidInputAtom } from '../../../../../hooks/mxAtoms';
import { mxTobeRowsAtom } from '../../../steps/mxEquations/MxAtoms';
import { AddWorkcenter } from '../mxFormGroups/allWorkcenters/AddWorkcenter';
import { fetchMxModelDetailsOptionA } from '../../../maintenanceApiCalls';
import { validateAFSC, validateFAC, validateOSC, validateTitle } from '../mxFormGroups/mxInputs/mxInputValidation';
import { useFocusAtom } from '../../../../../hooks';
import { gsFiltersAtom } from '../../../steps/generalSettings/GeneralSettingsAtoms';

const createId = ({ AFSC, WC_TITLE, FAC }) => `${AFSC}_${WC_TITLE}_${FAC}_${new Date().getTime()}`;

const optionAPropTypes = {
    open: PropTypes.bool,
    children: PropTypes.func.isRequired,
    mxId: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(null)]),
    modelType: PropTypes.oneOf(['full-time', 'military']).isRequired,
    scrollRefAfterLoading: PropTypes.func.isRequired,
};

const requiredColumns = {
    MISSION_TYPE: '',
    FAC: '',
    OSC: '',
    WC_TITLE: '',
    AFSC: '',
    MIN_REQ: 0,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof optionAPropTypes>>}
 * @returns {React.ReactElement}
 */
export const OptionA = ({ open, children, scrollRefAfterLoading, mxId = null, modelType }) => {
    const [activeWorkcenters, setActiveWorkcenters] = useAtom(mxWorkcentersAtom);
    const [addWorkcenters, setAddWorkcenters] = useState(false);
    const [mxTobeRows] = useAtom(mxTobeRowsAtom);
    const [paiReq, setPaiReq] = useAtom(paiReqAtom);
    const [isLoading, setIsLoading] = useState(false);
    const setMxWorkcenterInvalidInput = useSetAtom(mxWorkcentersInvalidInputAtom);
    const [ilc] = useFocusAtom(gsFiltersAtom, 'ILC');

    const mxRowIds = [];
    mxTobeRows.forEach((row) => {
        mxRowIds.push(row.MX_ID);
    });

    useEffect(() => {
        async function fetchWorkcenterDataTypeA() {
            try {
                setIsLoading(true);
                const { isOk, workcentersData } = await fetchMxModelDetailsOptionA(mxId, ilc);
                if (!isOk) {
                    throw new Error('No workcenter data');
                }

                const mappedWorkCenters = workcentersData.map((workcenter) => {
                    let isInvalid = false;
                    if (
                        !(
                            validateAFSC(workcenter.AFSC) &&
                            validateFAC(workcenter.FAC) &&
                            validateOSC(workcenter.OSC) &&
                            validateTitle(workcenter.WC_TITLE) &&
                            validateTitle(workcenter.FAC_TITLE)
                        )
                    ) {
                        isInvalid = true;
                        setMxWorkcenterInvalidInput(true);
                    }
                    return {
                        ...requiredColumns,
                        ...workcenter,
                        isInvalid,
                    };
                });

                setActiveWorkcenters(mappedWorkCenters);
                const paiReqCols = {};
                for (const property in workcentersData[0]) {
                    const resultKey = property.toLowerCase();
                    if (resultKey.includes('pai')) {
                        paiReqCols[property] = '';
                    }
                }
                setPaiReq(paiReqCols);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
                scrollRefAfterLoading();
            }
        }

        if (!open) return;
        if (mxId !== null) {
            fetchWorkcenterDataTypeA();
        }
    }, [open, mxId, ilc, setActiveWorkcenters, scrollRefAfterLoading, setPaiReq, setMxWorkcenterInvalidInput]);

    useEffect(() => {
        return () => {
            setActiveWorkcenters([]);
        };
    }, [setActiveWorkcenters]);

    return (
        <span data-testid="edit-mx-equations-option-a">
            {!addWorkcenters && (
                <div>
                    <ActiveWorkcenters
                        open={open}
                        isLoading={isLoading}
                        onClickAdd={() => setAddWorkcenters(true)}
                        modelType={modelType}
                        activeWorkcenters={activeWorkcenters}
                        setActiveWorkcenters={setActiveWorkcenters}
                    />
                    <div className="d-flex">
                        {children({
                            activeWorkcenters,
                        })}
                    </div>
                </div>
            )}
            {addWorkcenters && (
                <AddWorkcenter
                    onSubmit={(newWorkCenter) => {
                        setActiveWorkcenters([
                            ...activeWorkcenters,
                            {
                                ...requiredColumns,
                                ...newWorkCenter,
                                id: createId(newWorkCenter),
                                ...paiReq,
                            },
                        ]);
                        setAddWorkcenters(false);
                    }}
                    onClose={() => setAddWorkcenters(false)}
                />
            )}
        </span>
    );
};

OptionA.propTypes = optionAPropTypes;
