import {
    ContentSwitcher,
    DataTableSkeleton,
    InlineNotification,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableExpandedRow,
    TableExpandHeader,
    TableExpandRow,
    TableHead,
    TableHeader,
    TableRow,
    TextInput,
} from '@carbon/react';
import { useAtom } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    baselineMPESDataAtom,
    baselineMPESDataStaticAtom,
    forceStructureAdjustableAtom,
    forceStructureStaticAtom,
    getCombinedAdjustmentData,
    hasOnePaiTypeAtom,
} from './GeneralSettingsAtoms';
import { getAllOfScenarioStateAtom, isApprovedScenarioAtom } from '../../../../hooks/wizardAtoms';

import PropTypes from 'prop-types';

export const ForceStructureTable = ({ emptyMessage, rows = [], isLoading = false }) => {
    const [source, setSource] = useState('adjustment');
    const [realRows, timestamps] = rows;
    const [baselineMPESData] = useAtom(baselineMPESDataAtom);
    const [baselineMPESRows] = baselineMPESData;

    const FSDataRows = useMemo(() => {
        if (source === 'baseline') {
            return realRows;
        } else if (source === 'adjustment' && emptyMessage === 'No results found') {
            return baselineMPESRows;
        } else {
            return realRows;
        }
    }, [source, baselineMPESRows, realRows, emptyMessage]);

    const timestamp = useMemo(() => {
        if (source === 'baseline') {
            return timestamps && timestamps['REMIS'];
        } else if (source === 'planned') {
            return timestamps && timestamps['FSDM'];
        } else {
            return 'TBD';
        }
    }, [source, timestamps]);

    return (
        <div className="force-structure-table">
            <Header source={source} timestamp={timestamp} onContentChange={setSource} />
            <FSDatatable source={source} rows={FSDataRows} isLoading={isLoading} emptyMessage={emptyMessage} />
        </div>
    );
};

const Header = ({ source, timestamp, onContentChange = () => {} }) => (
    <div className="force-structure-header">
        <h4>Force Structure Status</h4>
        <div className="sources">
            <p>Sources:</p>
            <ContentSwitcher
                size="sm"
                selectedIndex={3}
                onChange={(e) => {
                    onContentChange(e.name);
                }}
            >
                <Switch name="baseline" text="Baseline (REMIS/LIMS-EV)" />
                <Switch name="planned" text="Programmed (FSDM)" disabled />
                <Switch name="pbes" text="PBES" disabled />
                <Switch name="adjustment" text="MAJCOM Input" />
            </ContentSwitcher>
            <div className="timestamp">
                <div className="text-nowrap">{source === 'adjustment' ? 'Last Reviewed: ' : 'Latest: '}</div>
                {timestamp || 'N/A'}
            </div>
        </div>
    </div>
);

const recursiveExpand = (rows) => {
    if (!rows) return;

    let level = 0;
    let expandedRowStates = {};
    const initRecursiveExpand = (tableRows, expandedRowStates, level) => {
        level++;
        tableRows.forEach((row) => {
            expandedRowStates[`${row.PARENT.UNIT}-level${level}`] = true;
            if (row.CHILDREN && row.CHILDREN.length > 0) {
                initRecursiveExpand(row.CHILDREN, expandedRowStates, level);
            }
        });
    };
    initRecursiveExpand(rows, expandedRowStates, level);
    return expandedRowStates;
};

const FSDatatable = ({ source, rows = [], isLoading, emptyMessage }) => {
    const [staticUnits] = useAtom(forceStructureStaticAtom);
    const [baselineMPESDataStaticUnits] = useAtom(baselineMPESDataStaticAtom);
    const [adjustableUnits, setAdjustableUnits] = useAtom(forceStructureAdjustableAtom);
    const [scenario] = useAtom(getAllOfScenarioStateAtom);
    const [hasOnePaiType] = useAtom(hasOnePaiTypeAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const [expandStates, setExpandStates] = useState({});

    const isAdjustmentTab = source === 'adjustment';

    const handleValueChange = useCallback(
        ({ unit, key, value }) => {
            const tmpUnits = {
                ...adjustableUnits,
                [unit]: {
                    ...adjustableUnits[unit],
                    [key]: +value,
                },
            };
            if (value > -1) {
                setAdjustableUnits(tmpUnits);
            }
        },
        [adjustableUnits, setAdjustableUnits]
    );

    const handleExpand = useCallback(
        (unitLevel) => {
            setExpandStates({
                ...expandStates,
                [unitLevel]: !expandStates[unitLevel],
            });
        },
        [expandStates]
    );

    const recursiveTable = useMemo(() => {
        let level = 0;
        const isEditable = {
            PMAI: true,
            PTAI: true,
            PDAI: true,
            BAI: true,
            AR: true,
        };
        const createRecursiveTable = (tableContents = [], level) => {
            if (tableContents.length > 0) {
                const headers = Object.keys(tableContents[0].PARENT);
                const rowValues = tableContents.map((cell) => Object.entries(cell.PARENT));
                const rowChildren = tableContents.map((cell) => cell.CHILDREN);
                level++;
                return (
                    <Table className={`level${level}`}>
                        {level === 1 && (
                            <TableHead>
                                <TableRow>
                                    <TableExpandHeader />
                                    {headers.map(
                                        (header, i) =>
                                            header !== 'POAI' && (
                                                <TableHeader key={i}>{header.replaceAll('_', ' ')}</TableHeader>
                                            )
                                    )}
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            {rowValues.map((cellValues, cellRowIndex) => {
                                const unit = cellValues
                                    .filter(([cellKey]) => cellKey === 'UNIT')
                                    .at(0)
                                    .at(-1);
                                const unitLevel = `${unit}-level${level}`;
                                const hasChildren = rowChildren[cellRowIndex].length > 0;

                                if (hasChildren) {
                                    return (
                                        <React.Fragment key={cellRowIndex}>
                                            <TableExpandRow
                                                ariaLabel="rows"
                                                isExpanded={expandStates[unitLevel] ?? false}
                                                onExpand={() => handleExpand(unitLevel)}
                                            >
                                                {cellValues.map((cellValue) => {
                                                    const [key, value] = cellValue;
                                                    if (key !== 'POAI') {
                                                        return (
                                                            <TableCell key={key} className={'table-cell-' + key}>
                                                                {value}
                                                            </TableCell>
                                                        );
                                                    }
                                                })}
                                            </TableExpandRow>
                                            <TableExpandedRow
                                                colSpan={headers.length + 1}
                                                className={!expandStates[unitLevel] ? 'hidden-row' : ''}
                                            >
                                                {createRecursiveTable(rowChildren[cellRowIndex], level)}
                                            </TableExpandedRow>
                                        </React.Fragment>
                                    );
                                } else {
                                    return (
                                        <TableRow key={cellRowIndex}>
                                            <TableCell className="expand-replacement">
                                                <span>&nbsp;</span>
                                            </TableCell>
                                            {cellValues.map((cellValue) => {
                                                const [key, value] = cellValue;
                                                if (key !== 'POAI') {
                                                    return (
                                                        <TableCell key={key} className={'table-cell-' + key}>
                                                            {isAdjustmentTab && isEditable[key] ? (
                                                                <TextInput
                                                                    id={unit}
                                                                    disabled={isApprovedScenario}
                                                                    labelText=""
                                                                    value={
                                                                        adjustableUnits[unit]
                                                                            ? adjustableUnits[unit][key]
                                                                            : 0
                                                                    }
                                                                    size="sm"
                                                                    maxLength="3"
                                                                    onChange={(e) =>
                                                                        handleValueChange({
                                                                            unit,
                                                                            key,
                                                                            value: e.target.value,
                                                                        })
                                                                    }
                                                                />
                                                            ) : (
                                                                value
                                                            )}
                                                        </TableCell>
                                                    );
                                                }
                                            })}
                                        </TableRow>
                                    );
                                }
                            })}
                        </TableBody>
                    </Table>
                );
            }
        };

        return rows.length > 0 ? createRecursiveTable(rows, level) : <></>;
    }, [rows, expandStates, adjustableUnits, handleExpand, isAdjustmentTab, handleValueChange, isApprovedScenario]);

    useEffect(() => {
        if (isLoading && rows.length > 0) {
            setExpandStates(recursiveExpand(rows));
        }
    }, [rows, isLoading]);

    useEffect(() => {
        if (source === 'adjustment' && emptyMessage === 'No results found') {
            setAdjustableUnits(getCombinedAdjustmentData(baselineMPESDataStaticUnits, scenario));
        } else {
            setAdjustableUnits(getCombinedAdjustmentData(staticUnits, scenario));
        }
    }, [source, emptyMessage, staticUnits, scenario, baselineMPESDataStaticUnits, setAdjustableUnits]);

    return (
        <div>
            {isLoading ? <DataTableSkeleton headers={[]} /> : recursiveTable}
            {!hasOnePaiType && (
                <div className="d-flex justify-content-end pt-2">
                    <InlineNotification
                        title="Multiple PAI types"
                        subtitle="Please only add one PAI type."
                        hideCloseButton
                        kind="warning"
                    />
                </div>
            )}
            {rows.length === 0 && !isLoading ? (
                <div>
                    <div
                        id="empty-force-structure-status"
                        className="d-flex justify-content-center align-items-center p-3"
                    >
                        {emptyMessage}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

ForceStructureTable.propTypes = {
    emptyMessage: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object])),
    isLoading: PropTypes.bool,
};

Header.propTypes = {
    source: PropTypes.string,
    timestamp: PropTypes.string,
    onContentChange: PropTypes.func,
};

FSDatatable.propTypes = {
    source: PropTypes.string,
    rows: PropTypes.arrayOf(
        PropTypes.shape({
            PARENT: PropTypes.shape({
                AR: PropTypes.number,
                BAI: PropTypes.number,
                CMD: PropTypes.string,
                ILC: PropTypes.string,
                ILC_TITLE: PropTypes.string,
                MDS: PropTypes.string,
                PDAI: PropTypes.number,
                PMAI: PropTypes.number,
                POAI: PropTypes.number,
                PTAI: PropTypes.number,
                UNIT: PropTypes.string,
            }),
            CHILDREN: PropTypes.array,
        })
    ),
    isLoading: PropTypes.bool,
    emptyMessage: PropTypes.string,
};
