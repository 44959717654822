import React, { useEffect, useRef, useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';

import { apiScenarioAtom, scenarioAtom } from '../../../../hooks/wizardAtoms';
import { updateTooltipsAtom } from './ReviewAtoms';
import { ReviewUnitDetails } from './ReviewUnitDetails';
import { ReviewTableContainer } from './ReviewTableContainer';
import { Link } from '@carbon/react';
import { opsHeaders } from '../opsEquations/OpsHeaders';
import { getScenarioReview } from '../../scenarioApiCalls';

import './ReviewSections.scss';
import { ReviewAdditionalUnits } from './ReviewAdditionalUnits';

const generalSettingsHeaders = [
    { header: 'UNIT', key: 'UNIT' },
    { header: 'PMAI', key: 'PMAI' },
    { header: 'PTAI', key: 'PTAI' },
    { header: 'PDAI', key: 'PDAI' },
    { header: 'BAI', key: 'BAI' },
    { header: 'AR', key: 'AR' },
];

export const ReviewSections = () => {
    const [scenario] = useAtom(apiScenarioAtom);
    const [scenario2] = useAtom(scenarioAtom);
    const [opsTobeData, setOpsTobeData] = useState([]);
    const [opsAsisData, setOpsAsisData] = useState([]);
    const [mxTobeData, setMxTobeData] = useState([]);
    const [mxAsisData, setMxAsisData] = useState([]);
    const [unitDetails, setUnitDetails] = useState({});
    const [addUnitDetails, setAddUnitDetails] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [generalSettingsRows, setGeneralSettingsRows] = useState([]);
    const setTooltips = useSetAtom(updateTooltipsAtom);
    const gsAdjustmentRef = useRef(scenario2?.generalSettings?.forceStructureTables?.adjustment ?? []);
    const scenarioId = scenario.scenario_id;
    gsAdjustmentRef.current = scenario2?.generalSettings?.forceStructureTables?.adjustment ?? [];

    useEffect(() => {
        async function fetchReviewData() {
            const { isOk, data } = await getScenarioReview(scenarioId);
            if (isOk) {
                setGeneralSettingsRows(data.generalSettings);
                setUnitDetails(data.unitDetails);
                setOpsTobeData(data.opsTobe);
                setAddUnitDetails(data.addUnitDetails);
                setMxTobeData(data.mxTobe);
                setOpsAsisData(data.opsAsis);
                setMxAsisData(data.mxAsis);

                const resultTooltips = {
                    PEC: {},
                    FAC: {},
                    RIC: {},
                };
                data.unitDetails.OPS_DETAILS.concat(data.unitDetails.MX_DETAILS).forEach(({ PEC, PEC_TITLE }) => {
                    resultTooltips.PEC[PEC] = PEC_TITLE;
                });
                const opsMx = [
                    ...data.opsTobe,
                    ...data.opsAsis,
                    ...data.mxTobe,
                    ...data.mxTobe,
                    ...data.addUnitDetails.OPS,
                    ...data.addUnitDetails.MX,
                ];
                opsMx.forEach(
                    ({ PEC = 'none', PEC_TITLE = '', FAC = 'none', FAC_TITLE = '', RIC = 'none', RIC_TITLE = '' }) => {
                        resultTooltips.PEC[PEC] = PEC_TITLE;
                        resultTooltips.FAC[FAC] = FAC_TITLE;
                        resultTooltips.RIC[RIC] = RIC_TITLE;
                    }
                );
                setTooltips(resultTooltips);
            }
        }

        if (scenarioId) {
            setIsLoading(true);
            fetchReviewData().then(() => {
                setIsLoading(false);
            });
        }
    }, [scenarioId, setTooltips]);

    return (
        <div className="review-sections">
            <ReviewTableContainer
                tableHeaders={generalSettingsHeaders}
                header="General Settings"
                rows={generalSettingsRows}
                key="general-settings"
                id="review-table-general-settings"
                isLoading={isLoading}
            />
            <ReviewUnitDetails
                opsGp={unitDetails.OPS_GP}
                mxGp={unitDetails.MX_GP}
                opsDetails={unitDetails.OPS_DETAILS}
                mxDetails={unitDetails.MX_DETAILS}
                isLoading={isLoading}
            />
            <ReviewAdditionalUnits
                cmds={addUnitDetails.CMD}
                ilcs={addUnitDetails.ILC}
                ops={addUnitDetails.OPS}
                mx={addUnitDetails.MX}
                isLoading={isLoading}
            />
            <ReviewTableContainer
                tableHeaders={opsHeaders}
                header="OPS ASIS"
                rows={opsAsisData}
                key="a"
                id="review-table-ops"
                enablePagination
                isLoading={isLoading}
            />
            <ReviewTableContainer
                tableHeaders={opsHeaders}
                header="OPS TOBE"
                rows={opsTobeData}
                key="a-tobe"
                id="review-table-ops-TOBE"
                enablePagination
                isLoading={isLoading}
            />
            <ReviewTableContainer
                tableHeaders={opsHeaders}
                header="MX ASIS"
                rows={mxAsisData}
                key="b"
                id="review-table-mx"
                enablePagination
                isLoading={isLoading}
            />
            <ReviewTableContainer
                tableHeaders={opsHeaders}
                header="MX TOBE"
                rows={mxTobeData}
                key="b-tobe"
                id="review-table-mx-TOBE"
                enablePagination
                isLoading={isLoading}
            />
            <Link
                className="mt-2 ms-auto p-3 pe-5 d-block cds--btn cds--btn--primary"
                href={`/review-download?q=${scenarioId}`}
                target="_blank"
                style={{ color: 'white', textDecoration: 'none' }}
                data-testid="review-page-download"
            >
                <div className="pe-3">Download</div>
            </Link>
        </div>
    );
};
