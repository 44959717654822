import React, { useCallback } from 'react';
import { Header, HeaderGlobalBar, HeaderName, OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { UserProfile, Logout } from '@carbon/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useHandleLogout } from '../helpers';
import { useAtom } from 'jotai';
import { currentUserAtom } from '../utils/PrivateRoute';

export const Navigation = () => {
    const navigate = useNavigate();
    const handleLogout = useHandleLogout();
    const location = useLocation();
    const [currentUser] = useAtom(currentUserAtom);

    const handleNavigateToAdmin = useCallback(() => {
        navigate('/admin', { state: { prevPath: location.pathname } });
    }, [navigate, location.pathname]);

    const handleNavigateToReportIssue = useCallback(() => {
        navigate('/report-issue', { state: { prevPath: location.pathname } });
    }, [navigate, location.pathname]);

    const handleReset = useCallback(() => {
        navigate('/reset-password', { state: { prevPath: location.pathname } });
    }, [navigate, location.pathname]);

    return (
        <Header className="top-nav" aria-label="Guardian Header">
            <HeaderName href="/manpower-wizard/#" prefix="">
                <img src="/assets/Logos/guardian_logo_70x70.png" alt="Guardian Logo" width="30" />
                <span>Guardian</span>
            </HeaderName>
            <HeaderGlobalBar data-testid="navigation-header-global-bar">
                <OverflowMenu
                    className="user-profile-top-nav-button me-2"
                    flipped
                    aria-label="User Profile"
                    label="User Profile"
                    renderIcon={UserProfile}
                    iconDescription="User Profile"
                    align="left"
                    data-testid="navigation-overflow-menu"
                >
                    <OverflowMenuItem
                        label="Logout"
                        className="user-profile-item"
                        hasDivider
                        itemText={
                            <div>
                                <Logout />
                                &nbsp;Logout
                            </div>
                        }
                        onClick={handleLogout}
                    />
                    <OverflowMenuItem
                        label="Report Issue"
                        hasDivider
                        itemText="Report Issue"
                        onClick={handleNavigateToReportIssue}
                    />
                    {currentUser?.isAdmin && (
                        <OverflowMenuItem
                            label="Admin Dashboard"
                            hasDivider
                            itemText="Admin Dashboard"
                            onClick={handleNavigateToAdmin}
                        />
                    )}
                    <OverflowMenuItem
                        label="Reset Password"
                        hasDivider
                        itemText="Reset Password"
                        onClick={handleReset}
                    />
                </OverflowMenu>
            </HeaderGlobalBar>
        </Header>
    );
};
