// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cds--modal-content {
  min-height: 400px;
}
.cds--modal-content .row {
  margin-top: 2rem;
}

.unit-dropdown .cds--list-box--expanded .cds--list-box__menu {
  max-height: 10rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/wizard/modals/generalSettings/modal.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;AACI;EACI,gBAAA;AACR;;AAII;EACI,iBAAA;AADR","sourcesContent":[".cds--modal-content {\n    min-height: 400px;\n\n    .row {\n        margin-top: 2rem;\n    }\n}\n\n.unit-dropdown {\n    .cds--list-box--expanded .cds--list-box__menu {\n        max-height: 10rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
