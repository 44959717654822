import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, TabList } from '@carbon/react';
import { InputFields } from './InputFields';
import { FormulaTable } from './FormulaTable';
import { useFormulaTable } from './useFormulaTable';
import { useAdvancedEquationsFetch, useAdvancedEquationsState } from './advancedEquationsState';

import { optionBTableAtom, optionBTabs } from '../../../../../../hooks/mxAtoms';
import { useAtom } from 'jotai';
import { useUnmount } from '../../../../../../hooks';

export const colHeaders = [
    // { header: 'id', key: 'id', dataType: 'readonly' },
    { header: 'FAC', key: 'fac', dataType: 'string' },
    { header: 'AFSC', key: 'afsc', dataType: 'string' },
    { header: 'OSC', key: 'osc', dataType: 'string' },
    { header: 'WC_TITLE', key: 'wc_title', dataType: 'string' },
    { header: 'A', key: 'A', dataType: 'number' },
    { header: 'B1', key: 'B1', dataType: 'number' },
    { header: 'B2', key: 'B2', dataType: 'number' },
    { header: 'B3', key: 'B3', dataType: 'number' },
    { header: 'X3', key: 'X3', dataType: 'number' },
    { header: 'B4', key: 'B4', dataType: 'number' },
    { header: 'X4', key: 'X4', dataType: 'positive-number' },
    { header: 'MIN_REQ', key: 'MIN_REQ', dataType: 'number' },
    { header: 'Is conditional?', key: 'conditionalEqns', dataType: 'conditional' },
    { header: '', key: 'edit', dataType: 'edit' },
];

export const AdvancedMxEquations = ({
    children,
    scrollRefAfterLoading = null,
    mxId = null,
    showRequiredFields = false,
    disabled,
}) => {
    const {
        state,
        handleMafChange,
        handleUpdateType,
        handleInsertState,
        handleReset: resetInputFields,
    } = useAdvancedEquationsState();

    // Necessary for inserting input state after the data is fetched
    const finishedFetch = React.useCallback(
        (data) => {
            handleInsertState(data);
            scrollRefAfterLoading?.();
        },
        [handleInsertState, scrollRefAfterLoading]
    );

    const { isLoading, overrideActiveType } = useAdvancedEquationsFetch(mxId, finishedFetch);
    const isFolActive = optionBTabs[state.type].type === 'fol';

    const [optionBTableState] = useAtom(optionBTableAtom);
    React.useEffect(() => {
        if (overrideActiveType !== null) {
            if (overrideActiveType === 'fol1' || overrideActiveType === 'fol2' || overrideActiveType === 'fol3') {
                handleUpdateType(optionBTabs[overrideActiveType].folIndex, 'fol');
            } else {
                handleUpdateType(optionBTabs[overrideActiveType].index);
            }
        }
    }, [overrideActiveType, handleUpdateType]);

    const { handleReset: optionBReset } = useFormulaTable();

    useUnmount(() => {
        resetInputFields();
        optionBReset();
    });

    const selectedIndexKey = overrideActiveType !== null ? overrideActiveType : state.type;
    const activeType = optionBTabs[selectedIndexKey].key;

    return (
        <div className="advanced-mx-equations" style={{ width: '100%' }} data-testid="edit-mx-equations-option-b">
            <div className="row align-items-start">
                <div className="col-md-9">
                    <Tabs
                        defaultSelectedIndex={0}
                        selectedIndex={optionBTabs[selectedIndexKey].index}
                        onChange={(e) => handleUpdateType(e.selectedIndex)}
                    >
                        <TabList aria-label="equation types" style={{ width: 'fit-content' }} contained>
                            <Tab
                                aria-label="peace"
                                style={{ width: 'fit-content', paddingRight: '3rem' }}
                                className="tab"
                                disabled={overrideActiveType !== 'peace' && overrideActiveType !== null}
                            >
                                Peace
                            </Tab>
                            <Tab
                                aria-label="fol 1"
                                style={{ width: 'fit-content', paddingRight: '3rem' }}
                                className="tab"
                                // disabled={!isFolActive && overrideActiveType !== null}
                                disabled
                            >
                                FOL
                            </Tab>
                            <Tab
                                aria-label="war"
                                style={{ width: 'fit-content', paddingRight: '3rem' }}
                                className="tab"
                                disabled={overrideActiveType !== 'war' && overrideActiveType !== null}
                            >
                                War
                            </Tab>
                        </TabList>
                    </Tabs>
                </div>
                <div className="col-md-3">
                    <InputFields
                        disabled={disabled}
                        maf={state.maf}
                        handleMafChange={handleMafChange}
                        showError={showRequiredFields}
                    />
                </div>
            </div>
            {/* TODO: Remove false */}
            {false && isFolActive && (
                <div className="col-md-9">
                    <Tabs
                        defaultSelectedIndex={0}
                        selectedIndex={optionBTabs[selectedIndexKey].folIndex}
                        onChange={(e) => handleUpdateType(e.selectedIndex, 'fol')}
                    >
                        <TabList aria-label="equation types" style={{ width: 'fit-content' }}>
                            <Tab
                                aria-label="peace"
                                style={{ width: 'fit-content', paddingRight: '3rem' }}
                                className="tab"
                                disabled={overrideActiveType !== 'split-ops-home' && overrideActiveType !== null}
                            >
                                Split OPs Home
                            </Tab>
                            <Tab
                                aria-label="fol 1"
                                style={{ width: 'fit-content', paddingRight: '3rem' }}
                                className="tab"
                                disabled={overrideActiveType !== 'fol1' && overrideActiveType !== null}
                            >
                                FOL 1
                            </Tab>
                            <Tab
                                aria-label="fol 2"
                                style={{ width: 'fit-content', paddingRight: '3rem' }}
                                className="tab"
                                disabled={overrideActiveType !== 'fol2' && overrideActiveType !== null}
                            >
                                FOL 2
                            </Tab>
                            <Tab
                                aria-label="fol 3"
                                style={{ width: 'fit-content', paddingRight: '3rem' }}
                                className="tab"
                                disabled={overrideActiveType !== 'fol3' && overrideActiveType !== null}
                            >
                                FOL 3
                            </Tab>
                        </TabList>
                    </Tabs>
                </div>
            )}
            <FormulaTable
                key={`${activeType}-table`}
                activeType={activeType}
                isEditable
                colHeaders={colHeaders}
                isLoading={isLoading}
                showRequiredFields={showRequiredFields}
                disabled={disabled}
            />
            {children({
                tableType: activeType,
                maf: state.maf,
                coefficients: optionBTableState.tableRows,
                canSubmit: optionBTableState.tableRows.length > 0,
            })}
        </div>
    );
};

AdvancedMxEquations.propTypes = {
    children: PropTypes.func.isRequired,
};
