import React, { useState, useEffect } from 'react';
import {
    TextField,
    PasswordField,
    ButtonUtil,
    PostResponseNotification,
    LoadingUtil,
} from '../../utils';
import { useParams, Navigate } from 'react-router-dom';
import { Password, Ticket } from '@carbon/icons-react';
import { Form } from '@carbon/react';
import { OneTimeTokenLayout } from '../../utils';
import axios from 'axios';

export const TokenResetPasswordForm = () => {
    const params = useParams();

    const [isTokenValid, setIsTokenValid] = useState();

    const [token, setToken] = useState({ value: '' });
    const [password, setPassword] = useState({ value: '' });
    const [duplicate, setDuplicate] = useState({ value: '' });
    const [notificationData, setNotificationData] = useState({});
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

    useEffect(() => {
        if (
            token.invalid === false &&
            token.value.length > 0 &&
            password.invalid === false &&
            password.value.length > 0 &&
            duplicate.invalid === false &&
            duplicate.value.length > 0
        ) {
            setButtonDisabled(false);
        }
    }, [token, password, duplicate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setButtonDisabled(true);
        (async () => {
            try {
                const res = await axios.post(
                    '/api/resetPasswordUsingToken',
                    {
                        resetPasswordToken: token.value,
                        password: password.value,
                        retypePassword: duplicate.value,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                setNotificationData(res);
            } catch (err) {
                setNotificationData(err.response);
            } finally {
                setTimeout(() => {
                    setButtonDisabled(false);
                }, '1000');
            }
            setResetPasswordSuccess(true);
        })();
    };

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
            if (params.token && params.token !== ':token') {
                setToken({
                    value: params.token,
                    invalid: false,
                });
            }
            try {
                const res = await axios.post(
                    '/api/resetPasswordTokenValid',
                    {
                        resetPasswordToken: params.token,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                setIsTokenValid(res.data.tokenValid);
            } catch (err) {
                setIsTokenValid(false);
            }
        })();
    }, []);

    if (isTokenValid === undefined) {
        return (
            <LoadingUtil
                type="inline"
                inlineLoading={{
                    className: 'mt-3 justify-content-center',
                    description: <em>Loading</em>,
                }}
            />
        );
    } else if (!isTokenValid) {
        return <Navigate to="/" />;
    } else if (resetPasswordSuccess) {
        return (
            <OneTimeTokenLayout
                type="tokenResetPassword"
                homeButton={resetPasswordSuccess}
                children={'Password successfully reset.'}
            />
        );
    } else {
        return (
            <OneTimeTokenLayout
                type="tokenResetPassword"
                homeButton={resetPasswordSuccess}
                children={
                    <Form>
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: 15,
                                marginTop: 32,
                            }}
                        >
                            <Ticket style={{ width: 30 }} />
                            <TextField
                                textInput={{
                                    ...token,
                                    labelText: (
                                        <>
                                            Token{' '}
                                            <span style={{ color: 'red' }}>
                                                *{' '}
                                            </span>
                                        </>
                                    ),
                                }}
                                type="token"
                                validation={true}
                                setState={setToken}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: 15,
                            }}
                        >
                            <Password style={{ width: 30 }} />
                            <PasswordField
                                passwordInput={{
                                    ...password,
                                    labelText: (
                                        <>
                                            Password{' '}
                                            <span style={{ color: 'red' }}>
                                                *{' '}
                                            </span>
                                        </>
                                    ),
                                }}
                                type="password"
                                validation={true}
                                duplicate={duplicate.value}
                                setState={setPassword}
                                setDuplicate={setDuplicate}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <Password style={{ width: 30 }} />
                            <PasswordField
                                passwordInput={{
                                    ...duplicate,
                                    labelText: (
                                        <>
                                            Confirm Password{' '}
                                            <span style={{ color: 'red' }}>
                                                *{' '}
                                            </span>
                                        </>
                                    ),
                                }}
                                type="duplicate"
                                validation={true}
                                original={password.value}
                                setState={setDuplicate}
                            />
                        </div>
                        <ButtonUtil
                            button={{
                                children: 'Reset Password',
                                disabled: buttonDisabled,
                                className: 'mt-5 rounded',
                                onClick: handleSubmit,
                                type: 'submit',
                            }}
                        />
                        <PostResponseNotification response={notificationData} />
                    </Form>
                }
            />
        );
    }
};
