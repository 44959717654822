import { React } from 'react';
import { ClickableTile, Row, Column } from '@carbon/react';

export const ClickableTileRow = ({
    clickableTile = {},
    row = {},
    columns = [],
    column = {},
}) => {
    let rowClassName = 'd-flex align-items-center text-start';
    if (row.className != undefined) {
        rowClassName += ` ${row.className}`;
    }
    let columnsHTML = [];
    return (
        <>
            <ClickableTile {...clickableTile} data-testid="clickable-tile-row">
                <Row {...row} className={rowClassName}>
                    {columns.forEach((columnObject, i) => {
                        let columnClassName = 'pe-2';
                        if (column.className != undefined) {
                            columnClassName += ` ${column.className}`;
                        }
                        if (columnObject.className != undefined) {
                            columnClassName += ` ${columnObject.className}`;
                        }

                        const columnProps = {
                            ...column,
                            ...columnObject,
                            className: columnClassName,
                            key: i,
                        };
                        columnsHTML.push(<Column {...columnProps}></Column>);
                    })}
                    {columnsHTML}
                </Row>
            </ClickableTile>
        </>
    );
};
