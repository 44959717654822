import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, ContentSwitcher, Switch, InlineNotification } from '@carbon/react';
import { Add } from '@carbon/react/icons';
import { useAtom, useSetAtom } from 'jotai';

import { paiReqAtom, updateMxEquationsAtom } from '../../../../hooks/mxAtoms';
import { EditMxEquations } from './EditMxEquations/EditMxEquations';
import { MxSelectionTable } from './MxSelectionTable';
import { useFetchModelSummaryData, useMxScenarioSelection } from './mxEquationsHooks';

import './MxEquations.scss';
import { updateModelId } from '../../scenarioApiCalls';
import { isApprovedScenarioAtom, scenarioAtom } from '../../../../hooks/wizardAtoms';
import { useFocusAtom } from '../../../../hooks';
import { useMilitaryFullTimeRequirment } from './EditMxEquations/useMilitaryFullTimeRequirement';
import { ConditionalEqns } from './EditMxEquations/advancedMxEquations/ConditionalEqns';
import { isConditionalEqnsOpenAtom } from './EditMxEquations/advancedMxEquations/useConditionalEqns';

const columns = [
    { header: '', key: 'radio' },
    { header: 'MDS', key: 'MDS' },
    { header: 'CMD', key: 'CMD' },
    { header: 'ILC', key: 'ILC' },
    { header: 'Model Name', key: 'MODEL_NAME' },
    { header: 'Mission Type', key: 'MODEL_TYPE' },
    { header: 'Option', key: 'MODEL_OPTION' },
    { header: 'Source', key: 'SOURCE' },
    { header: 'POC', key: 'POC' },
    { header: '', key: 'edit' },
];

const mxEquationsPropTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof mxEquationsPropTypes>>}
 * @returns {React.ReactElement}
 */
export const MxEquations = ({ open, onClose, onSubmit }) => {
    const updateMxEquations = useSetAtom(updateMxEquationsAtom);
    const [scenario_id] = useFocusAtom(scenarioAtom, 'scenario_id');
    const setPaiReq = useSetAtom(paiReqAtom);
    const [isConditionalEqnsOpen] = useAtom(isConditionalEqnsOpenAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);

    const [openEditModel, setOpenEditModel] = useState(false);
    const [editMxId, setEditMxId] = useState(null);
    const [editOption, setEditOption] = useState(null);
    const [action, setAction] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [modelsUpdated, setModelsUpdated] = useState(null);

    const { isDisabled, handleToggleRqmt, fullRequire } = useMilitaryFullTimeRequirment();

    const { isLoading, ftModels, milModels, handleDeleteModel, fetchModelSummaryData } = useFetchModelSummaryData(open);

    const models = fullRequire === 'full-time' ? ftModels : milModels;

    const { selectedModel, handleSelection } = useMxScenarioSelection(open, models, fullRequire, isLoading);

    const selectedModelId = fullRequire === 'full-time' ? selectedModel?.ft : selectedModel?.mil;

    const handleOpenUpdateModel = async (model, idx) => {
        if (model) {
            setAction('edit');
            setEditOption(model.MODEL_OPTION);
            setEditMxId(model.MX_ID);
            updateMxEquations({
                l_id: model.MX_ID,
                source: model.SOURCE,
                modelName: model.MODEL_NAME,
                poc: model.POC,
                notes: model.NOTES,
            });
        } else {
            setAction('add');
            setEditOption(null);
            setEditMxId(null);
            updateMxEquations({
                l_id: '',
                source: '',
                modelName: '',
                poc: '',
                notes: '',
            });
            setPaiReq({});
        }
        setOpenEditModel(true);
    };

    const handleOpenCloneModel = async (model, idx) => {
        const date = new Date().toLocaleString('en-us', {
            timeZone: 'UTC',
        });
        setAction('copy');
        setEditOption(model.MODEL_OPTION);
        setEditMxId(model.MX_ID);
        updateMxEquations({
            l_id: model.MX_ID,
            source: model.SOURCE,
            modelName: model.MODEL_NAME + ' ' + date,
            poc: model.POC,
            notes: model.NOTES,
        });
        setOpenEditModel(true);
    };

    const handleOpenDeleteConfirmation = (model) => {
        setDeleteConfirmation(model);
    };
    const closeDeleteConfirmation = () => {
        setDeleteConfirmation(null);
    };

    const handleSaveAndSubmit = async () => {
        const updateMxModelBody = {
            ft_mx_id: selectedModel?.ft.MX_ID,
        };
        if (!isDisabled && selectedModel?.mil) {
            updateMxModelBody.mil_mx_id = selectedModel?.mil.MX_ID;
        }
        try {
            const response = await updateModelId(scenario_id, updateMxModelBody);
            if (response.data[0] === 200) {
                onSubmit();
            }
        } catch (error) {
            console.error('Could not update scenario', error);
        }
        onClose();
    };

    const inlineNotificationTitle = {
        update: `${modelsUpdated?.modelName} has been updated.`,
        delete: `${modelsUpdated?.modelName} has been deleted.`,
        create: `A new model has been created: ${modelsUpdated?.modelName}.`,
    };

    return (
        <div className={`mx-equations ${openEditModel ? 'edit-model-open' : ''}`}>
            <Modal
                open={open && !isConditionalEqnsOpen}
                onRequestClose={() => {
                    setOpenEditModel(false);
                    onClose();
                }}
                modalLabel="Add Edit Maintenance Equations by Work Centers"
                primaryButtonText="Save & Submit"
                secondaryButtonText="Cancel"
                size="lg"
                onRequestSubmit={handleSaveAndSubmit}
                data-testid="mx-equations-modal"
                preventCloseOnClickOutside={openEditModel}
                primaryButtonDisabled={
                    openEditModel || selectedModelId === null || selectedModelId?.MX_ID < 0 || isApprovedScenario
                }
            >
                <div className="form-input-container">
                    <ContentSwitcher
                        style={{
                            minWidth: 'fit-content',
                            width: 'fit-content',
                            marginRight: 'auto',
                        }}
                        className="mb-3"
                        selectedIndex={fullRequire === 'military' ? 1 : 0}
                        onChange={handleToggleRqmt}
                    >
                        <Switch
                            name="full-time"
                            text="Full Time RQMT"
                            disabled={openEditModel}
                            style={{
                                minWidth: 'fit-content',
                                width: 'fit-content',
                            }}
                        />
                        <Switch
                            name="military"
                            text="Military RQMT"
                            disabled={isDisabled || openEditModel}
                            style={{
                                minWidth: 'fit-content',
                                width: 'fit-content',
                            }}
                        />
                    </ContentSwitcher>
                    <div>
                        <MxSelectionTable
                            columns={columns}
                            models={models}
                            onEdit={handleOpenUpdateModel}
                            onClone={handleOpenCloneModel}
                            onDelete={handleOpenDeleteConfirmation}
                            onSelect={handleSelection}
                            isLoading={isLoading}
                            disabledEditButtons={openEditModel}
                            selectedId={selectedModelId?.MX_ID}
                            disabled={isApprovedScenario}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3 mb-3 ms-auto">
                        <div style={{ width: 'fit-content' }}>
                            {modelsUpdated && (
                                <InlineNotification
                                    title=""
                                    subtitle={inlineNotificationTitle[modelsUpdated?.type]}
                                    kind={modelsUpdated?.type === 'delete' ? 'error' : 'success'}
                                    onClose={() => setModelsUpdated(null)}
                                />
                            )}
                        </div>
                        <Button
                            kind="primary"
                            size="md"
                            renderIcon={Add}
                            iconDescription="plus sign"
                            onClick={() => handleOpenUpdateModel()}
                            disabled={openEditModel || isApprovedScenario}
                        >
                            Add New Model
                        </Button>
                    </div>
                    {openEditModel ? (
                        <>
                            <div className="divider" />
                            <EditMxEquations
                                open={open}
                                handleCloseEdit={async (refetch) => {
                                    if (refetch) {
                                        await fetchModelSummaryData();
                                    }
                                    setAction(null);
                                    setOpenEditModel(false);
                                }}
                                mxModels={models}
                                modelType={fullRequire}
                                editOption={editOption}
                                mxId={editMxId}
                                action={action}
                                setModelsUpdated={setModelsUpdated}
                            />
                        </>
                    ) : null}
                </div>
            </Modal>
            {open ? (
                <Modal
                    data-testid="delete-mx-model-confirmation-modal"
                    open={deleteConfirmation !== null}
                    modalHeading="Are you sure you want to delete this model?"
                    danger
                    primaryButtonText="Delete"
                    secondaryButtonText="Cancel"
                    onRequestClose={closeDeleteConfirmation}
                    onRequestSubmit={async () => {
                        const { isOk } = await handleDeleteModel(deleteConfirmation, fullRequire);
                        if (isOk) {
                            setModelsUpdated({
                                type: 'delete',
                                modelName: models.find((model) => model.MX_ID === deleteConfirmation.MX_ID)?.MODEL_NAME,
                            });
                        }
                        if (deleteConfirmation.MX_ID === selectedModelId?.MX_ID) {
                            handleSelection(models[0]);
                        }
                        closeDeleteConfirmation();
                    }}
                />
            ) : null}
            {isConditionalEqnsOpen ? <ConditionalEqns /> : null}
        </div>
    );
};

MxEquations.propTypes = mxEquationsPropTypes;
