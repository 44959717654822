import { useRef, useCallback } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { isEqual } from 'lodash';

import { apiScenarioAtom } from './wizardAtoms';
import { updateMxQuantity } from '../pages/wizard/maintenanceApiCalls';
import { updateOpsQty } from '../pages/wizard/aircrewApiCalls';
import { opsScenarioAtom } from '../pages/wizard/steps/opsEquations/OpsAtoms';
import { mxScenarioAtom } from './mxAtoms';
import { forceStructureAdjustableAtom } from '../pages/wizard/steps/generalSettings/GeneralSettingsAtoms';

/**
 * Hook that returns a callback used to check if generalSettings or unitDetails scenarios have changed
 * and resets Ops and Mx scenarios if they have. Returns true if Ops and Mx were reset and false if not
 * @returns {(scenario: { generalSettings: any, unitDetails: any}) => Promise<boolean>}
 */
export const useClearMxOpsScenarios = () => {
    const [scenarioSnapshot] = useAtom(apiScenarioAtom);
    const [adjustableUnits] = useAtom(forceStructureAdjustableAtom);
    const setOpsScenario = useSetAtom(opsScenarioAtom);
    const setMxScenario = useSetAtom(mxScenarioAtom);

    const scenarioRef = useRef(scenarioSnapshot);
    const uiAdjustableUnits = useRef(adjustableUnits);

    scenarioRef.current = scenarioSnapshot;
    uiAdjustableUnits.current = adjustableUnits;

    /**
     * Checks for changes in unit details and clears mx and ops
     * if units have changed
     */
    const clearMxOpsOnUnitDetailsChange = useCallback(
        async (scenarioToUpdate) => {
            try {
                const { unitDetails } = scenarioToUpdate;
                const { scenario_id, generalSettings } = scenarioRef.current;
                const adjustmentsFromApi = generalSettings?.forceStructureTables?.adjustment || [];
                let hasAdjustmentDifference = false;
                if (Object.keys(uiAdjustableUnits.current).length > 0) {
                    hasAdjustmentDifference = adjustmentsFromApi.some((unit) => {
                        return !isEqual(unit, uiAdjustableUnits.current[unit.UNIT]);
                    });
                }
                let hasUnitDetailsDifference = !isEqual(unitDetails, scenarioRef.current.unitDetails);
                if (hasUnitDetailsDifference || hasAdjustmentDifference) {
                    await Promise.all([updateMxQuantity(scenario_id), updateOpsQty(scenario_id)]);
                    setOpsScenario([]);
                    setMxScenario([]);
                    return true;
                }
            } catch (error) {
                console.error(error);
            }
            return false;
        },
        [setMxScenario, setOpsScenario]
    );

    return /** @type {const} */ (clearMxOpsOnUnitDetailsChange);
};
