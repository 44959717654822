// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-header a.logo-link {
  color: black;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  margin-right: 3vw;
}
.nav-header a.logo-link div {
  display: flex;
  align-items: center;
  margin-right: 2vw;
}
.nav-header a.logo-link div img.logo-img {
  max-height: 30px;
}
.nav-header a.logo-link div span.logo-text {
  font-weight: bold;
}
.nav-header nav.page-nav {
  margin-left: 1rem;
  display: block !important;
  overflow-x: scroll;
  overflow-y: clip;
}
.nav-header nav.demo-nav {
  display: block !important;
}`, "",{"version":3,"sources":["webpack://./src/utils/Navbar.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AAAR;AACQ;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;AACZ;AAAY;EACI,gBAAA;AAEhB;AAAY;EACI,iBAAA;AAEhB;AAEI;EACI,iBAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AAAR;AAEI;EACI,yBAAA;AAAR","sourcesContent":[".nav-header{\n    a.logo-link{\n        color: black; \n        padding-left: 10px;\n        padding-right: 10px; \n        font-size: 14px; \n        margin-right:3vw;\n        div{\n            display:flex;  \n            align-items: center;\n            margin-right: 2vw;\n            img.logo-img{\n                max-height:30px;\n            }\n            span.logo-text{\n                font-weight:bold;\n            }\n        }\n    }\n    nav.page-nav{\n        margin-left: 1rem;\n        display: block !important;\n        overflow-x:scroll;\n        overflow-y:clip;\n    }\n    nav.demo-nav{\n        display: block !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
