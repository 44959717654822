import { useReducer, useState } from 'react';
import { validateUpdateModelState } from './validateUpdateModelState';

const initialAircrewComposition = {
    CREW_RATIO: 0,
    CREW_COMP_OFF: 0,
    CREW_COMP_ENL: 0,
    AFSC: '',
    FAC: '',
    CREW_POSITION: '',
    AIRCREW_TYPE: '',
    id: 0,
    childId: null,
    parentId: null,
    NOTES: '',
};

const initialState = {
    MDS: '',
    CMD: '',
    MODEL_NAME: '',
    SOURCE: '',
    POC: '',
    composition: [{ ...initialAircrewComposition }],
    row_id: 0,
};

function insertModel(state, { parent, children, modelId = null }) {
    const composition =
        children.length > 0 &&
        children.map((childModel, id) => {
            return {
                ...initialAircrewComposition,
                ...childModel,
                CREW_RATIO: childModel.CREW_RATIO,
                CREW_COMP_ENL: childModel.CREW_COMP_ENL,
                CREW_COMP_OFF: childModel.CREW_COMP_OFF,
                AIRCREW_TYPE: childModel.AIRCREW_TYPE,
                AFSC: childModel.AFSC,
                FAC: childModel.FAC,
                NOTES: childModel.NOTES,
                CREW_POSITION: childModel.CREW_POSITION,
                childId: childModel.childId,
                parentId: childModel.childId,
                id: id,
            };
        });

    // check for edit or Add
    return parent && parent.MODEL_NAME
        ? {
              ...state,
              ...parent,
              row_id: composition.length,
              composition,
              modelId,
          }
        : { ...state, ...parent, modelId };
}

const reducer = (state, action) => {
    if (action.type === 'update_row') {
        const compositionCopy = [...state.composition];
        const crew = compositionCopy[action.index];
        if (!crew) {
            throw new Error('Invalid row index');
        }
        const updatedCrew = {
            ...crew,
            ...action.payload,
        };
        updatedCrew.AIRCREW_TYPE =
            updatedCrew.CREW_COMP_ENL > updatedCrew.CREW_COMP_OFF
                ? 'ENL'
                : 'OFF';
        compositionCopy[action.index] = updatedCrew;
        return {
            ...state,
            composition: compositionCopy,
        };
    }

    if (action.type === 'add_row') {
        const { row_id, composition } = state;

        return {
            ...state,
            composition: [
                ...composition,
                { ...initialAircrewComposition, id: row_id + 1 },
            ],
            row_id: row_id + 1,
        };
    }
    if (action.type === 'delete_row') {
        return {
            ...state,
            composition: state.composition.filter(
                (crew) => crew.id !== action.payload
            ),
        };
    }
    return {
        ...state,
        ...action.payload,
    };
};

/**
 *
 * @param {{ parent: {[key: string]: any}, children: any[]}} model
 * @returns
 */
export const useUpdateModelState = (model) => {
    const [state, dispatch] = useReducer(
        reducer,
        insertModel(initialState, model)
    );
    const [madeModelChange, setMadeModelChange] = useState(false);

    /**
     *
     * @param {{PARENT: {[key: string]: any}, CHILDREN: any[]}} models
     * @returns {boolean}
     */
    const checkModelNameCollision = (models, action) => {
        let filteredModels =
            action === 'edit'
                ? models.filter(
                      ({ PARENT }) => `${state.ACR_ID}` !== `${PARENT.ACR_ID}`
                  )
                : models;
        const modelNames = filteredModels.map(({ PARENT }) =>
            PARENT.MODEL_NAME?.toLowerCase?.().trim()
        );
        const modelNamesSet = new Set(modelNames);
        const hasCollision = modelNamesSet.has(
            state.MODEL_NAME.toLowerCase().trim()
        );
        return hasCollision;
    };

    const formatModelsForSubmission = (action) => {
        if (action === 'edit') {
            return {
                PARENT: {
                    ACR_ID: state.ACR_ID,
                    MDS: state.MDS,
                    CMD: state.CMD,
                    MODEL_NAME: state.MODEL_NAME,
                },
                CHILDREN: state.composition.map((crew) => ({
                    AFSC: crew.AFSC,
                    FAC: crew.FAC,
                    NOTES: crew.NOTES,
                    POC: state.POC,
                    SOURCE: state.SOURCE,
                    ARCRW_RATIO: crew.CREW_RATIO,
                    ARCRW_COMP_ENL: crew.CREW_COMP_ENL,
                    ARCRW_COMP_OFF: crew.CREW_COMP_OFF,
                    CREW_POSITION: crew.CREW_POSITION,
                    TABLE_DATE: crew.TABLE_DATE ?? '',
                })),
            };
        }
        return {
            PARENT: {
                MDS: state.MDS,
                CMD: state.CMD,
                MODEL_NAME: state.MODEL_NAME,
            },
            CHILDREN: state.composition.map((crew) => ({
                AFSC: crew.AFSC,
                FAC: crew.FAC,
                NOTES: crew.NOTES,
                POC: state.POC,
                SOURCE: state.SOURCE,
                ARCRW_RATIO: crew.CREW_RATIO,
                ARCRW_COMP_ENL: crew.CREW_COMP_ENL,
                ARCRW_COMP_OFF: crew.CREW_COMP_OFF,
                CREW_POSITION: crew.CREW_POSITION,
            })),
        };
    };

    /**
     *
     * @param {*} payload piece of state to be updated
     * @param {'add_row' | 'update_row' | 'delete_row'} type type of update
     * @param {number} index index of crew composition row
     */
    const handleModelChange = (payload, type, index) => {
        dispatch({ payload, type, index });
        setMadeModelChange(true);
    };

    /** @type {boolean} */
    const isModelStateValid =
        validateUpdateModelState(state) && madeModelChange;

    return {
        state,
        isModelStateValid,
        handleModelChange,
        checkModelNameCollision,
        formatModelsForSubmission,
    };
};
