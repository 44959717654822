import React from 'react';
import { TextInput } from '@carbon/react';
import { useAtom } from 'jotai';

export const MxTextInput = ({
    labelText,
    onChange = () => {},
    valueAtom,
    invalidText = '',
    invalid = false,
    disabled = false,
}) => {
    const [value, setValue] = useAtom(valueAtom);

    return (
        <div className="MxTextInput">
            <TextInput
                data-testid={`mx-text-input-${labelText.replace(' ', '-')}`}
                type="text"
                id={`MxTextInput_${labelText.replace(' ', '')}`}
                disabled={disabled}
                labelText={labelText}
                value={value ?? ''}
                invalid={invalid}
                invalidText={invalidText}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </div>
    );
};
