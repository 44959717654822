// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-mpes-modal .cds--modal-container > .cds--modal-content {
  min-height: 20.75rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/wizard/modals/selectMPESModal/SelectMPESModal.scss"],"names":[],"mappings":"AACI;EACI,oBAAA;AAAR","sourcesContent":[".select-mpes-modal {\n    .cds--modal-container>.cds--modal-content {\n        min-height: 20.75rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
