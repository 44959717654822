import { IconButton } from '@carbon/react';
import { Asleep, AsleepFilled } from '@carbon/react/icons';
import { useEffect } from 'react';
import { CUSTOM_THEME } from '../pages/wizard/constants';

export const ThemeButton = ({ changeTheme, customTheme }) => {
    // set theme on page load
    useEffect(() => {
        localStorage.setItem('theme', customTheme);
        if (customTheme === CUSTOM_THEME.DARK) {
            document.documentElement.classList.add(CUSTOM_THEME.DARK);
        } else {
            document.documentElement.classList.remove(CUSTOM_THEME.DARK);
        }
    }, [customTheme]);

    return (
        <IconButton
            data-testid="theme-button"
            label="Switch Theme"
            align="left"
            enterDelayMs={1000}
            onClick={() => changeTheme()}
            size="md"
            kind={customTheme === CUSTOM_THEME.DARK ? 'primary' : 'tertiary'}
        >
            {customTheme === CUSTOM_THEME.DARK ? (
                <AsleepFilled data-testid="theme-button-dark" />
            ) : (
                <Asleep data-testid="theme-button-light" />
            )}
        </IconButton>
    );
};
