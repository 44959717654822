import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ComboBox, ComposedModal, ModalBody, ModalHeader, InlineNotification, TextArea } from '@carbon/react';
import { CheckmarkOutline } from '@carbon/react/icons';

import { useConditionalEffect } from '../../../../hooks';
import { useFetchAllUsers } from './useFetchAllUsers';
import { comboBoxShouldFilterItem } from '../../../../helpers';
import { shareScenario } from '../../scenarioApiCalls';

import './ShareScenario.scss';
import { useAtom } from 'jotai';
import { isUnpublishedScenarioAtom } from '../../../../hooks/wizardAtoms';

const shareScenarioPropTypes = {
    onClose: PropTypes.func.isRequired,
    scenario: PropTypes.shape({
        scenario_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        scenario_name: PropTypes.string.isRequired,
    }).isRequired,
    open: PropTypes.bool,
    alertDelay: PropTypes.number,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof shareScenarioPropTypes>>} props
 * @returns {React.ReactElement}
 */
export const ShareScenario = ({ onClose, scenario, open, alertDelay = 5000 }) => {
    const [additionalText, setAdditionalText] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const [errorState, setErrorState] = useState(false);
    const [successState, setSuccessState] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const timeoutRef = useRef(null);

    const [isUnpublishedScenario] = useAtom(isUnpublishedScenarioAtom);

    useConditionalEffect(() => {
        setErrorState(false);
        setSuccessState(false);
        setSelectedUser({});
        setLoading(false);
        setErrorMessage(null);
        setAdditionalText('');
    }, open === false);

    const { userList } = useFetchAllUsers();

    const handleSubmit = async () => {
        if (!selectedUser.name) {
            setErrorState(true);
            return;
        }
        if (loading) return;

        setLoading(true);
        const { success, error } = await shareScenario(scenario.scenario_id, selectedUser.id, additionalText);
        setLoading(false);

        if (success) {
            setSuccessState(true);
        }

        if (error) {
            setErrorMessage(error);
        } else {
            timeoutRef.current = setTimeout(() => {
                onClose();
            }, alertDelay);
        }
        setLoading(false);
    };

    if (successState) {
        return (
            <ComposedModal
                open
                onClose={() => {
                    setSuccessState(false);
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    onClose();
                }}
                className="share-scenario-modal"
            >
                <ModalHeader label="" title="Scenario Shared"></ModalHeader>
                <ModalBody>
                    <div className="success-body mt-4">
                        <CheckmarkOutline className="success-checkmark" />
                        <p>
                            Scenario successfully sent to&nbsp;
                            <span>{selectedUser.name}</span>
                        </p>
                    </div>
                </ModalBody>
            </ComposedModal>
        );
    }

    return (
        <Modal
            open={open}
            className="share-scenario-modal"
            modalHeading={`Share Scenario: ${scenario.scenario_name}?`}
            modalLabel="Share Scenario"
            primaryButtonText="Share"
            secondaryButtonText="Cancel"
            onRequestClose={onClose}
            onRequestSubmit={handleSubmit}
            primaryButtonDisabled={loading || isUnpublishedScenario}
            data-testid="share-scenario-form"
        >
            <div>
                <ComboBox
                    id="share-scenario-user-dropdown"
                    titleText="Active Users"
                    items={userList}
                    selectedItem={selectedUser ?? {}}
                    itemToString={(user) => {
                        return user.name ? `${user.name} [${user.email}]` : '';
                    }}
                    shouldFilterItem={comboBoxShouldFilterItem}
                    onChange={({ selectedItem }) => {
                        setSelectedUser(selectedItem);
                    }}
                    invalidText="User required"
                    invalid={errorState}
                />
                {errorMessage ? (
                    <InlineNotification title={errorMessage?.title} subtitle={errorMessage.message} />
                ) : null}
            </div>
            <div className="mt-3">
                <TextArea
                    labelText="Additional Information"
                    value={additionalText}
                    onChange={(e) => setAdditionalText(e.target.value)}
                />
            </div>
            {isUnpublishedScenario ? (
                <div className="mt-3">
                    <InlineNotification
                        kind="warning"
                        subtitle={'Cannot share a scenario that has been unpublished.'}
                    />
                </div>
            ) : null}
        </Modal>
    );
};

ShareScenario.propTypes = shareScenarioPropTypes;
