import React from 'react';
import PropTypes from 'prop-types';

import { ReviewTable } from './ReviewTable';

const reviewTableContainerProps = {
    id: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    tableHeaders: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            header: PropTypes.string.isRequired,
        })
    ).isRequired,
    header: PropTypes.node,
    children: PropTypes.node,
    enablePagination: PropTypes.bool,
    isLoading: PropTypes.bool,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof reviewTableContainerProps>>}
 * @returns {React.ReactElement}
 */
export const ReviewTableContainer = ({
    rows,
    tableHeaders,
    id,
    header,
    children,
    enablePagination = false,
    isLoading = false,
}) => {
    return (
        <div className="mb-4">
            <h3 className="mb-2">{header}</h3>
            {children}
            <ReviewTable
                rows={rows}
                headers={tableHeaders}
                id={id}
                enablePagination={enablePagination}
                isLoading={isLoading}
                //
            />
        </div>
    );
};

ReviewTableContainer.propTypes = reviewTableContainerProps;
