import { atom } from 'jotai';

// mx Atoms
export const mxAsisRowsAtom = atom([]);
export const mxTobeRowsAtom = atom([]);

// mxPaginationAtom
export const mxAsisPaginationAtom = atom(
    localStorage.getItem('pagination-mxAsis')
        ? JSON.parse(localStorage.getItem('pagination-mxAsis'))
        : { page: 1, pageSize: 10 }
);
export const mxTobePaginationAtom = atom(
    localStorage.getItem('pagination-mxTobe')
        ? JSON.parse(localStorage.getItem('pagination-mxTobe'))
        : { page: 1, pageSize: 10 }
);

// mxAsis selection atom to save selected state
export const mxAsisSelectionsAtom = atom({});

export const mxTooltipsAtom = atom({
    facTitles: {},
    pecTitles: {},
    ricTitles: {},
});

export const insertMxTooltipsAtom = atom(null, (get, set, update) => {
    const mxTooltips = get(mxTooltipsAtom);
    set(mxTooltipsAtom, {
        facTitles: {
            ...mxTooltips.facTitles,
            ...(update.facTitles ?? {}),
        },
        pecTitles: {
            ...mxTooltips.pecTitles,
            ...(update.pecTitles ?? {}),
        },
        ricTitles: {
            ...mxTooltips.ricTitles,
            ...(update.ricTitles ?? {}),
        },
    });
});

export const mxSelectedDropdownDataAtom = atom({ API: [], FAC: [], MSI: [] });
