export const opsHeaders = [
    { header: 'UNIT', key: 'UNIT' },
    { header: 'PEC', key: 'PEC' },
    { header: 'RIC', key: 'RIC' },
    { header: 'OSC', key: 'OSC' },
    { header: 'FAC', key: 'FAC' },
    { header: 'AFS', key: 'AFS' },
    { header: 'API', key: 'API' },
    { header: 'MSI', key: 'MSI' },
    { header: 'POS_FUNDING', key: 'POS_FUNDING' },
    { header: 'PAI_TYPE', key: 'PAI_TYPE' },
    { header: 'PAI_QTY', key: 'PAI_QTY' },
    { header: 'QTY', key: 'QTY' },
];
