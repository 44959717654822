import { React, useState, useReducer } from 'react';
import { Button } from '@carbon/react';
import { Add } from '@carbon/react/icons';
import { MxValidationTextInput } from '../mxInputs/MxValidationTextInput';
import { validateTitle } from '../mxInputs/mxInputValidation';

const initialState = {
    FAC: '',
    WC_ID: '',
    OSC: '',
    WC_TITLE: '',
    FAC_TITLE: '',
    AFSC: '',
};

const validateFields = ['FAC', 'OSC', 'AFSC'];

const reducer = (state, action) => {
    return {
        ...state,
        ...action,
    };
};

export const AddWorkcenter = ({ onSubmit, onClose }) => {
    const [newWorkcenter, dispatch] = useReducer(reducer, initialState);
    const [addDisabled, setAddDisabled] = useState(true);
    const handleReset = () => dispatch(initialState);

    const handleChange = (change) => {
        dispatch(change);
        const updatedNewWorkcenter = {
            ...newWorkcenter,
            ...change,
        };
        let fieldsValidated = true;
        validateFields
            .filter((field) => field !== 'OSC')
            .forEach((field) => {
                if (updatedNewWorkcenter['WC_TITLE'].trim() === '' && updatedNewWorkcenter['FAC_TITLE'].trim() === '') {
                    fieldsValidated = false;
                } else if (updatedNewWorkcenter[field] === '') {
                    fieldsValidated = false;
                }
            });
        if (!validateTitle(updatedNewWorkcenter['WC_TITLE']) || !validateTitle(updatedNewWorkcenter['FAC_TITLE'])) {
            fieldsValidated = false;
        }
        setAddDisabled(!fieldsValidated);
    };
    const handleCancel = () => {
        handleReset();
        onClose();
    };
    const handleAdd = () => {
        onSubmit({ ...newWorkcenter, MIN_REQ: 0 });
    };

    return (
        <span data-testid="add-workcenter">
            <div className="row add-workcenter">
                <div className="col-md-3">
                    <MxValidationTextInput
                        id="WC_TITLE"
                        labelText="Workcenter Title"
                        cellHeader="WC_TITLE"
                        value={newWorkcenter.WC_TITLE}
                        onChange={(e) => handleChange({ WC_TITLE: e.target.value })}
                        role="textbox"
                    />
                </div>
                <div className="col-md-3">
                    <MxValidationTextInput
                        id="FAC_TITLE"
                        labelText="FAC Title"
                        cellHeader="FAC_TITLE"
                        value={newWorkcenter.FAC_TITLE}
                        onChange={(e) => handleChange({ FAC_TITLE: e.target.value })}
                        role="textbox"
                    />
                </div>
                <div className="col-md-2">
                    <MxValidationTextInput
                        id="FAC"
                        labelText="FAC"
                        cellHeader="FAC"
                        value={newWorkcenter.FAC}
                        onChange={(e) => handleChange({ FAC: e.target.value.toUpperCase() })}
                        role="textbox"
                    />
                </div>
                <div className="col-md-2">
                    <MxValidationTextInput
                        id="OSC"
                        labelText="OSC"
                        cellHeader="OSC"
                        value={newWorkcenter.OSC}
                        onChange={(e) => handleChange({ OSC: e.target.value.toUpperCase() })}
                        role="textbox"
                    />
                </div>
                <div className="col-md-2">
                    <MxValidationTextInput
                        id="AFSC"
                        labelText="AFSC"
                        cellHeader="AFSC"
                        value={newWorkcenter.AFSC}
                        onChange={(e) => handleChange({ AFSC: e.target.value.toUpperCase() })}
                        role="textbox"
                    />
                </div>
            </div>
            <div className="d-flex mt-2 ms-auto" style={{ width: 'fit-content' }}>
                <Button className="me-2" kind="tertiary" size="md" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button kind="tertiary" size="md" onClick={handleAdd} disabled={addDisabled}>
                    Add
                </Button>
            </div>
        </span>
    );
};

export const AddWorkcenterButton = ({ setOpenAddRow, addRow }) => {
    const handleReset = () => dispatch(initialState);
    const [, dispatch] = useReducer(reducer, initialState);
    return (
        <div className="mt-2 ms-auto">
            <Button
                kind="tertiary"
                size="md"
                renderIcon={Add}
                iconDescription="plus sign"
                onClick={() => {
                    setOpenAddRow(true);
                    handleReset();
                }}
                disabled={addRow}
            >
                Add New Workcenter
            </Button>
        </div>
    );
};
