import React, { useState, useEffect } from 'react';
import { Loading } from '@carbon/react';

import { HostCommandSection } from './HostCommandSection';
import { AssociatedCommandSection } from './AssociatedCommandSection';
import { RequirementContributions } from './RequirementContributions';
import { mockData } from './mockData';
import { useUpdateWizardStepValid } from '../../../../hooks';

const useFetchTfiAssociations = () => {
    const [host, setHost] = useState(null);
    const [association, setAssociation] = useState(null);
    const [contribution, setContribution] = useState(null);

    useEffect(() => {
        async function fetchData() {
            setHost(mockData.host);
            setAssociation(mockData.association);
            setContribution(mockData.contribution);
        }
        fetchData();
    }, []);

    return {
        host,
        association,
        contribution,
    };
};

export const TfiAssociations = () => {
    // const wizardContext = useContext(WizardContext);
    const [isCompleteTfiHostInput, setIsCompleteTfiHostInput] = useState(false);
    const [isCompleteTfiAssocInput, setIsCompleteAssocInput] = useState(false);
    const [isCompleteTfiContributionInput, setIsCompleteTfiContributionInput] = useState(false);

    const allInputComplete = isCompleteTfiHostInput && isCompleteTfiAssocInput && isCompleteTfiContributionInput;
    useUpdateWizardStepValid('tfiAssociations', allInputComplete);

    // TODO: Remove this once data is available for TFI Associations
    // Detect if the wizardContext.data has been loaded
    // wizardContext.data = mockData;

    const { host, association, contribution } = useFetchTfiAssociations(); // wizardContext.data;

    if (!host) {
        return <Loading />;
    }

    return (
        <div>
            <HostCommandSection setIsCompleteTfiHostInput={setIsCompleteTfiHostInput} data={host} />
            <AssociatedCommandSection setIsCompleteAssocInput={setIsCompleteAssocInput} data={association} />
            <RequirementContributions
                setIsCompleteTfiContributionInput={setIsCompleteTfiContributionInput}
                data={contribution}
            />
        </div>
    );
};
