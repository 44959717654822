import { useEffect } from 'react';

export const useInitExclusions = ({ id, exclusions, exclusionsRef, selectAllRows }) => {
    const isCurrent = exclusionsRef.current === exclusions;
    useEffect(() => {
        if ((id === 'mxAsis' || id === 'opsAsis') && !isCurrent) {
            exclusionsRef.current = exclusions;
            selectAllRows();
        }
    }, [id, isCurrent, selectAllRows, exclusions, exclusionsRef]);
};
