import { useMemo } from 'react';
import { atom } from 'jotai';
import { useReducerAtom } from 'jotai/utils';

const unitDetailsPlusActions = Object.freeze({
    UPLOAD: 'upload',
    ADD_ROW: 'add-row',
    DELETE_ROW: 'delete-row',
    UPDATE_UNIT: 'update-unit',
    UPDATE_PEC: 'update-pec',
    FILTER_AVAILABLE_UNITS: 'filter-available-units',
});

const initialRow = {
    unit: '',
    hostUnit: [],
    ilc: '',
    cmd: '',
    pec: [],
};

export const getRandomId = (prefix = '') => {
    return prefix + '_' + Math.random() + Math.random();
};
/**
 * Create empty additional unit row
 * @param {string} prefix
 * @returns {{
 *      unit: string,
 *      hostUnit: string[],
 *      ilc: string,
 *      cmd: string,
 *      pec: string[],
 *      id: string,
 * }}
 */
export const createEmptyUnitRow = (prefix = '') => {
    return {
        ...initialRow,
        id: getRandomId(prefix),
    };
};

export const initialAdditionalDetailsState = {
    ops: [createEmptyUnitRow('ops')],
    mx: [createEmptyUnitRow('mx')],
    toggleIlc: null,
    selectedCMDs: [],
    opsUnits: {},
    availableOpsUnits: [],
    initialOpsUnits: [],
    mxUnits: {},
    availableMxUnits: [],
    initialMxUnits: [],
    availableCMDs: [],
    allCmds: [],
    allUnits: {},
    count: 0,
    allSelected: false,
};

export const additionalUnitsAtom = atom({ ...initialAdditionalDetailsState });
const getCmdsWithIlc = (units, ilcFilter) => {
    return Array.from(new Set(units.filter(({ CMD, ILC }) => ilcFilter === ILC).map(({ CMD }) => CMD)));
};

const reducer = (state, action) => {
    switch (action.type) {
        case unitDetailsPlusActions.UPLOAD: {
            let { ops, mx } = state;
            if (ops.length === 0 || action.payload.ops?.length === 0) {
                ops = [createEmptyUnitRow('ops')];
            }
            if (mx.length === 0 || action.payload.mx?.length === 0) {
                mx = [createEmptyUnitRow('mx')];
            }
            return {
                ...state,
                ...action.payload,
                allCmds: action.payload.availableCMDs,
                availableOpsUnits: action.payload.availableOpsUnits.map(({ UNIT }) => UNIT),
                availableMxUnits: action.payload.availableMxUnits.map(({ UNIT }) => UNIT),
                initialOpsUnits: action.payload.availableOpsUnits,
                initialMxUnits: action.payload.availableMxUnits,
                count: state.count + 1,
                ilcs: [],
                ops,
                mx,
            };
        }

        case unitDetailsPlusActions.ADD_ROW: {
            return {
                ...state,
                [action.meta]: [...state[action.meta], { ...initialRow, id: getRandomId(action.meta) }],
            };
        }

        case unitDetailsPlusActions.DELETE_ROW: {
            const currentState = [...state[action.meta]];
            const availableUnits = action.meta === 'ops' ? [...state.availableOpsUnits] : [...state.availableMxUnits];
            const deleted = currentState.splice(action.payload.index, 1)[0];
            if (deleted?.unit) {
                availableUnits.push(deleted.unit);
            }
            return {
                ...state,
                [action.meta]: [...currentState],
                [action.meta === 'ops' ? 'availableOpsUnits' : 'availableMxUnits']: availableUnits,
            };
        }

        case unitDetailsPlusActions.UPDATE_UNIT: {
            const { type, updateCount } = action.meta;
            const currentState = [...state[type]];
            const currentRow = { ...currentState[action.payload.index] };
            const oldUnit = currentRow.unit;
            let availableUnits = type === 'ops' ? [...state.availableOpsUnits] : [...state.availableMxUnits];

            currentRow.unit = action.payload.unit;
            currentRow.hostUnit = action.payload.hostUnit;

            // Add the old unit back to the list
            if (oldUnit) {
                availableUnits.push(oldUnit);
            }
            if (oldUnit !== action.payload.unit) {
                currentRow.pec = [];
                currentRow.hostUnit = [];
            }

            if (action.payload.unit) {
                const unit = state.allUnits[action.payload.unit];
                currentRow.cmd = state.availableCMDs.find((cmd) => {
                    return cmd.split('-')[0].trim() === unit.CMD;
                });
                currentRow.ilc = unit.ILC;
                // Remove the selected unit from the list
                availableUnits = availableUnits.filter((unit) => unit !== action.payload.unit);
            }
            currentState[action.payload.index] = currentRow;
            return {
                ...state,
                [type]: [...currentState],
                [type === 'ops' ? 'availableOpsUnits' : 'availableMxUnits']: availableUnits,
                count: updateCount ? state.count + 1 : state.count,
            };
        }

        case unitDetailsPlusActions.UPDATE_PEC: {
            const unitList = [...state[action.meta]];
            unitList[action.payload.index] = {
                ...unitList[action.payload.index],
                pec: action.payload.pecs,
            };
            return {
                ...state,
                [action.meta]: [...unitList],
            };
        }

        case unitDetailsPlusActions.FILTER_AVAILABLE_UNITS: {
            const { ilc, selectAll, initial, updateCount } = action.meta;
            const { allUnits } = state;
            let selectedCMDs = action.payload || state.selectedCMDs;
            let availableCMDs = state.allCmds;
            if (ilc) {
                availableCMDs = getCmdsWithIlc(state.initialOpsUnits.concat(state.initialMxUnits), ilc);
            }
            if (selectAll || (selectedCMDs.length === 0 && initial)) {
                selectedCMDs = availableCMDs;
            }
            const selectedCMDSet = new Set(selectedCMDs);

            const unitFilter = (unit) => {
                const cmd = allUnits[unit.unit]?.CMD;
                return !cmd || selectedCMDSet.has(cmd);
            };
            const ops = state.ops.filter(unitFilter);
            const mx = state.mx.filter(unitFilter);
            if (ops.length === 0) {
                ops.push(createEmptyUnitRow('ops'));
            }
            if (mx.length === 0) {
                mx.push(createEmptyUnitRow('mx'));
            }
            const sortList = (a, b) => {
                const aName = a.slice(5, 11);
                const bName = b.slice(5, 11);

                const num = aName.localeCompare(bName);
                if (num === 0) {
                    const aCode = a.slice(0, 5);
                    const bCode = b.slice(0, 5);
                    return aCode.localeCompare(bCode);
                }
                return num;
            };
            const availableOpsUnits = state.initialOpsUnits
                .filter(({ CMD }) => selectedCMDSet.has(CMD))
                .filter(({ ILC }) => !ilc || ilc === ILC)
                .map(({ UNIT }) => UNIT)
                .sort(sortList);
            const availableMxUnits = state.initialMxUnits
                .filter(({ CMD }) => selectedCMDSet.has(CMD))
                .filter(({ ILC }) => !ilc || ilc === ILC)
                .map(({ UNIT }) => UNIT)
                .sort(sortList);
            return {
                ...state,
                availableCMDs: availableCMDs,
                availableOpsUnits,
                availableMxUnits,
                selectedCMDs,
                toggleIlc: ilc,
                count: updateCount ? state.count + 1 : state.count,
                ops,
                mx,
            };
        }
        default:
            return state;
    }
};

export const useUnitDetailsPlusState = () => {
    const [state, dispatch] = useReducerAtom(additionalUnitsAtom, reducer);
    const actionCreators = useMemo(
        () => ({
            insertData: (payload) => {
                dispatch({
                    type: unitDetailsPlusActions.UPLOAD,
                    payload,
                });
            },
            addRow: (type = 'ops') => {
                dispatch({
                    type: unitDetailsPlusActions.ADD_ROW,
                    payload: type,
                    meta: type,
                });
            },
            deleteRow: ({ type = 'ops', index }) => {
                dispatch({
                    type: unitDetailsPlusActions.DELETE_ROW,
                    payload: { type, index },
                    meta: type,
                });
            },
            updateUnit: ({ type = 'ops', index, unit, hostUnit = [], updateCount = true }) => {
                dispatch({
                    type: unitDetailsPlusActions.UPDATE_UNIT,
                    payload: { type, index, unit, hostUnit },
                    meta: { updateCount, type },
                });
            },
            updatePec: ({ type = 'ops', index, unit, pecs }) => {
                dispatch({
                    type: unitDetailsPlusActions.UPDATE_PEC,
                    payload: { type, index, unit, pecs },
                    meta: type,
                });
            },
            filterAvailableUnits: (selectedCMDs, ilc, options = {}) => {
                const { selectAll = false, initial = false, updateCount = true } = options;
                dispatch({
                    type: unitDetailsPlusActions.FILTER_AVAILABLE_UNITS,
                    payload: selectedCMDs,
                    meta: {
                        ilc,
                        selectAll,
                        initial,
                        updateCount,
                    },
                });
            },
        }),
        [dispatch]
    );
    return {
        state,
        ...actionCreators,
    };
};

const validateRows = (rows) => {
    return rows.every(({ unit, hostUnit, pec }) => {
        if (!unit && hostUnit.length === 0) {
            return true;
        } else if (unit && pec.length > 0 && hostUnit.length > 0) {
            return true;
        } else {
            return false;
        }
    });
};

export const validateState = (state) => validateRows(state.ops) && validateRows(state.mx);
