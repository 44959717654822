import { useCallback, useEffect, useState } from 'react';
import { useReducerAtom } from 'jotai/utils';
import {
    advancedEquationsAtom,
    getTabByIndex,
    initialAdvancedEquationsState,
    optionBTabs,
} from '../../../../../../hooks/mxAtoms';
import { useFormulaTable } from './useFormulaTable';
import { fetchMxModelDetailsOptionB } from '../../../../maintenanceApiCalls';

/**
 * @type optionBTabs
 * @typedef {keyof optionBTabs}
 */

const actionTypes = Object.freeze({
    update_maf: 'update_maf',
    update_type: 'update_type',
    insert_state: 'insert_state',
    reset: 'reset',
});

const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.insert_state:
            return {
                ...state,
                ...action.payload,
            };
        case actionTypes.update_maf: {
            return {
                ...state,
                maf: action.payload,
            };
        }
        case actionTypes.update_type:
            return {
                ...state,
                type: action.payload,
            };
        case actionTypes.reset:
            return {
                ...initialAdvancedEquationsState,
            };
        default:
            return {
                ...state,
            };
    }
};

export const useAdvancedEquationsState = () => {
    const [state, dispatch] = useReducerAtom(advancedEquationsAtom, reducer);

    /**
     *
     * @param {React.KeyboardEvent<HTMLButtonElement, MouseEvent>} e
     */
    const handleMafChange = (e) => {
        dispatch({ type: actionTypes.update_maf, payload: e.target.value });
    };

    /**
     *
     */
    const handleUpdateType = useCallback(
        (index, level) => {
            const tab = getTabByIndex(index, level !== 'fol');
            dispatch({ type: actionTypes.update_type, payload: tab.key });
        },
        [dispatch]
    );

    /**
     * Change the type of fields that user is updating
     * @param {keyof optionBTabs} type
     */
    const handleUpdateFOLType = useCallback(
        (type) => {
            dispatch({ type: actionTypes.update_type, payload: type });
        },
        [dispatch]
    );

    const handleInsertState = useCallback(
        (newState) => {
            dispatch({
                type: actionTypes.insert_state,
                payload: {
                    type: newState.missionType !== null ? newState.missionType : optionBTabs.peace.key,
                    maf: newState.maf,
                },
            });
        },
        [dispatch]
    );

    const handleReset = useCallback(() => {
        dispatch({
            type: actionTypes.reset,
        });
    }, [dispatch]);

    return {
        state,
        handleMafChange,
        handleUpdateType,
        handleInsertState,
        handleUpdateFOLType,
        handleReset,
    };
};

/**
 *
 * @param {null | number | string} mxId
 * @param {function} finallyCallback is included in dependency array
 * @returns {{
 *  isLoading: boolean,
 * }}
 */
export const useAdvancedEquationsFetch = (mxId = null, finallyCallback) => {
    const [isLoading, setIsLoading] = useState(false);
    const { insertData } = useFormulaTable();
    const [overrideActiveType, setOverrideActiveType] = useState(null);

    useEffect(() => {
        async function fetchTableData() {
            try {
                setIsLoading(true);
                const { isOk, data } = await fetchMxModelDetailsOptionB(mxId);
                if (!isOk) {
                    throw new Error('No option B data');
                }
                const { dataTable, missionType } = data;

                insertData({ tableRows: dataTable });
                setOverrideActiveType(missionType);
                finallyCallback(data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }

        if (mxId !== null) {
            fetchTableData();
        }
    }, [finallyCallback, insertData, mxId]);

    return {
        isLoading,
        overrideActiveType,
    };
};
