import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useAtom } from 'jotai';
import { FilterableMultiSelect, IconButton, Loading, Toggle } from '@carbon/react';
import { AddFilled } from '@carbon/react/icons';

import { useSaveScenarioAtomOnUnmount } from '../../../../hooks/useSaveAtomOnUnmount';
import { fetchAdditionalUnitDetails } from '../../../../httpRequests/unitOrgChart';
import { gsFiltersAtom } from '../generalSettings/GeneralSettingsAtoms';

import './AdditionalUnitDetails.scss';
import { useFocusAtom, useIsMounted, useUpdateWizardStepValid } from '../../../../hooks';
import {
    aircrewHostUnitsAtom,
    getAllOfScenarioStateAtom,
    isApprovedScenarioAtom,
    scenarioAtom,
} from '../../../../hooks/wizardAtoms';
import { useSaveScenario } from '../../../../hooks/useSaveScenario';
import { AddUnitRow } from './AddUnitRow';

import { useUnitDetailsPlusState, validateState } from './useAdditionalUnitDetailsState';

const checkForOtherILCs = (ilcList = [], ilc = '') => {
    if (ilcList.length === 0) return false;
    if (ilcList.length === 1 && ilcList[0] === ilc) return false;
    return true;
};

export const AdditionalUnitDetails = () => {
    const { state, insertData, addRow, deleteRow, updateUnit, updatePec, filterAvailableUnits } =
        useUnitDetailsPlusState();
    const [aircrewHostUnits] = useAtom(aircrewHostUnitsAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const [fetchingState, setFetchingState] = /** @type {typeof useState<'init' | 'loading' | 'error' | 'fetched'>} */ (
        useState
    )('init');

    const [scenarioId] = useFocusAtom(scenarioAtom, 'scenario_id');
    const [gsFilters] = useAtom(gsFiltersAtom);
    const [scenario] = useAtom(getAllOfScenarioStateAtom);
    const isMounted = useIsMounted();

    const currentStateRef = useRef({ ops: state.ops, mx: state.mx });
    currentStateRef.current = { ops: state.ops, mx: state.mx };

    const { CMD: cmd, MDS: mds, ILC: ilc } = gsFilters;
    const hasOtherILCs = useRef(checkForOtherILCs(scenario.additionalUnitDetails.ILC, ilc));
    hasOtherILCs.current = checkForOtherILCs(scenario.additionalUnitDetails.ILC, ilc);

    const { isSaving } = useSaveScenario();

    useEffect(() => {
        async function fetchData() {
            setFetchingState('loading');
            const options = { cmd, mds, scenarioId };
            const { isOk, data } = await fetchAdditionalUnitDetails(options);
            if (!isMounted()) {
                return;
            }
            if (isOk) {
                insertData({ ...data, toggleIlc: ilc });
                if (hasOtherILCs.current) {
                    filterAvailableUnits(null, null, { selectAll: false, initial: true });
                } else {
                    filterAvailableUnits(null, ilc, { selectAll: false, initial: true });
                }
                setFetchingState('fetched');
            } else {
                setFetchingState('error');
            }
        }
        if (cmd && scenarioId && !isSaving) {
            fetchData();
        }
    }, [cmd, mds, ilc, scenarioId, isSaving, insertData, filterAvailableUnits, isMounted]);

    useSaveScenarioAtomOnUnmount({ isValid: fetchingState === 'fetched' && !isSaving && validateState(state) });

    useUpdateWizardStepValid('additionalUnits', validateState(state));

    if (fetchingState === 'loading' || isSaving) {
        return (
            <section>
                <Loading />
            </section>
        );
    }

    const { opsUnits, mxUnits, availableMxUnits, availableOpsUnits } = state;
    return (
        <section className="additional-unit-details">
            <div className="d-flex align-items-start" style={{ gap: '2rem' }}>
                <div style={{ flex: '0 0 50%', maxWidth: '30rem' }}>
                    <MultiSelectAll
                        id="cmd-multi-select"
                        key={`cmd-multi-select-${state.count}`}
                        className="pb-3"
                        items={state.availableCMDs}
                        selectedItemsInState={state.selectedCMDs}
                        itemToString={(item) => item || ''}
                        label=""
                        titleText="CMD Filter"
                        onChange={(selectedItems, selectAll) => {
                            if (isApprovedScenario) {
                                return;
                            }
                            filterAvailableUnits(selectedItems, state.toggleIlc, { selectAll, updateCount: false });
                        }}
                    />
                </div>
                <Toggle
                    labelText="ILC Filter"
                    id="ilc-checkbox"
                    data-testid="ILC Filter"
                    toggled={!!state.toggleIlc}
                    disabled={isApprovedScenario}
                    onToggle={() => {
                        let selectAll = false;
                        if (state.selectedCMDs.includes('SELECT ALL')) {
                            selectAll = true;
                        }
                        if (!state.toggleIlc) {
                            filterAvailableUnits(state.selectedCMDs, ilc, { selectAll });
                        } else {
                            filterAvailableUnits(state.selectedCMDs, null, { selectAll });
                        }
                    }}
                />
            </div>
            <div className="column-container d-flex">
                <div>
                    <h5>OPS PAS</h5>
                    {state.ops.map((unit, i) => (
                        <AddUnitRow
                            key={unit.id + state.count}
                            id={unit.id}
                            debugger={unit}
                            disabled={isApprovedScenario}
                            type="ops"
                            pecs={opsUnits[unit.unit] ?? []}
                            initialSelectedPecs={unit.pec ?? []}
                            onChangeMultiSelect={updatePec}
                            onChangeCombobox={updateUnit}
                            onDelete={deleteRow}
                            units={availableOpsUnits}
                            index={i}
                            canDelete={state.ops.length > 1}
                            selectedUnit={unit.unit}
                            hostUnits={aircrewHostUnits ?? []}
                            hostUnit={unit.hostUnit}
                        />
                    ))}
                    <IconButton
                        data-testid="ops-add-row-btn"
                        className="add-row"
                        disabled={availableOpsUnits.length === 0 || isApprovedScenario}
                        label="Add row"
                        kind="ghost"
                        align="right"
                        onClick={() => addRow('ops')}
                    >
                        <AddFilled />
                    </IconButton>
                </div>
                <div>
                    <h5>MX PAS</h5>
                    {state.mx.map((unit, i) => (
                        <AddUnitRow
                            type="mx"
                            key={unit.id + state.count}
                            id={unit.id}
                            disabled={isApprovedScenario}
                            pecs={mxUnits[unit.unit] ?? []}
                            initialSelectedPecs={unit.pec ?? []}
                            onChangeMultiSelect={updatePec}
                            onChangeCombobox={updateUnit}
                            onDelete={deleteRow}
                            units={availableMxUnits}
                            index={i}
                            canDelete={state.mx.length > 1}
                            selectedUnit={unit.unit}
                            hostUnits={aircrewHostUnits ?? []}
                            hostUnit={unit.hostUnit}
                        />
                    ))}
                    <IconButton
                        data-testid="mx-add-row-btn"
                        disabled={availableMxUnits.length === 0 || isApprovedScenario}
                        className="add-row"
                        label="Add row"
                        kind="ghost"
                        align="right"
                        onClick={() => addRow('mx')}
                    >
                        <AddFilled />
                    </IconButton>
                </div>
            </div>
        </section>
    );
};

const multiSelectAllPropTypes = {
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
    selectedItemsInState: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
    onChange: PropTypes.func.isRequired,

    className: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    direction: PropTypes.oneOf(['bottom', 'top']),
    itemToString: PropTypes.func,
    selectAllItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    sortItems: PropTypes.func,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof multiSelectAllPropTypes>>}
 * @returns
 */
export const MultiSelectAll = ({
    items,
    selectedItemsInState,
    onChange,
    className = '',
    id = '',
    label = '',
    titleText = 'Items',
    direction = 'bottom',
    itemToString = (item) => item || '',
    selectAllItem = 'SELECT ALL',
    sortItems = (items) => {
        items.sort((a, b) => {
            if (itemToString(a) === itemToString(selectAllItem)) {
                return -1;
            }
            if (itemToString(b) === itemToString(selectAllItem)) {
                return 1;
            }
            return itemToString(a).localeCompare(itemToString(b));
        });
        return items;
    },
    ...rest
}) => {
    const [refresh, setRefresh] = useState(0);
    const selectAllText = itemToString(selectAllItem);
    return (
        <FilterableMultiSelect
            id={id}
            key={`${id}-${refresh}`}
            className={className}
            items={[selectAllItem, ...items]}
            initialSelectedItems={selectedItemsInState.filter(
                (items) => itemToString(items) !== itemToString(selectAllItem)
            )}
            itemToString={itemToString}
            label={label}
            titleText={titleText}
            direction={direction}
            sortItems={sortItems}
            onChange={({ selectedItems }) => {
                let result = selectedItems;
                let selectAll = false;
                if (selectedItems.find((item) => selectAllText === itemToString(item))) {
                    if (selectedItems.length > selectedItemsInState.length) {
                        result = selectedItems;
                        selectAll = true;
                        setRefresh(refresh + 1);
                    } else if (selectedItems.length < selectedItemsInState.length) {
                        result = selectedItems.filter((item) => itemToString(item) !== selectAllText);
                    }
                }
                onChange(result, selectAll);
            }}
            {...rest}
        />
    );
};

MultiSelectAll.propTypes = multiSelectAllPropTypes;
