import { Modal } from '@carbon/react';
import { atom, useAtom } from 'jotai';
import { isApprovedScenarioAtom, scenarioAtom } from '../../../../hooks/wizardAtoms';
import './SaveSessionModal.scss';

export const saveSessionModalOpenAtom = atom(false);

export const SaveSessionModal = ({ onClose, open, onSubmit }) => {
    const [currentScenario] = useAtom(scenarioAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);

    return (
        <Modal
            open={open}
            className="save-session-modal"
            modalHeading={`Save Session`}
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
            onRequestClose={onClose}
            onRequestSubmit={onSubmit}
            data-testid="save-session-modal"
        >
            <div className="main-content">
                <h4>
                    {isApprovedScenario ? (
                        <div>
                            Approved Scenario <strong>{currentScenario.scenario_name}</strong> cannot be modified. To
                            make any new changes, create a new scenario.
                        </div>
                    ) : (
                        <div>
                            You are saving current session to scenario: <strong>{currentScenario.scenario_name}</strong>
                            .
                        </div>
                    )}
                </h4>
            </div>
        </Modal>
    );
};
