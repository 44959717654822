import {
    AccountServices,
    AccountManager,
    AccountProfile,
    Admin,
    AdminActivateAccount,
    AuthenticationOptions,
    CodeIntegrityTable,
    CreateDataDump,
    DownloadRecoveryCodePage,
    EmailTFA,
    FirstAdmin,
    FileUploadTestPage,
    LoadingPage,
    LoginSMS,
    ForgotPasswordForm,
    RecoveryCodeForm,
    ResetPasswordForm,
    TermsOfService,
    TokenResetPasswordForm,
    UserReactivateAccount,
    UserVerifyEmail,
    UploadDataDump,
    Wizard,
} from '../pages';
import { Navigate } from 'react-router-dom';

import { IconButtonUtil, CopyButtonUtil, SideNavUtil, DateField, ErrorPageLayout } from '../utils';
import { ReviewDownload } from '../pages/wizard/steps/reviewStep/ReviewDownload';
import { ManageScenarios } from '../pages/admin/ManageScenarios/ManageScenarios';
import { ManageModels } from '../pages/admin/ManageModels/ManageModels';
import { MissingUnitsDownload } from '../pages/admin/MissingUnits/MissingUnitsDownload';
import { ReportIssue } from '../pages/reportIssue/ReportIssue';
import { UserRenewAccess } from '../pages/accountServices/UserRenewAccess';

const pathType = {
    open: 'open',
    closed: 'closed',
};

export const demoPaths = {
    sideNavBar: {
        path: '/side-nav-bar',
        element: <SideNavUtil sideNavItems={{ children: <>test</> }} sideNavLink={{ children: <>test</> }} />,
        pathType: pathType.open,
        name: 'Test Navbar',
    },
    iconButton: {
        path: '/icon-button',
        element: <IconButtonUtil />,
        pathType: pathType.open,
        name: 'Icon Button',
    },
    copyButton: {
        path: '/copy-button',
        element: <CopyButtonUtil />,
        pathType: pathType.open,
        name: 'Copy Button',
    },
    fileUploadTestpage: {
        path: '/fileupload',
        element: <FileUploadTestPage />,
        pathType: pathType.open,
        name: 'File Upload',
    },
    LoadingPage: {
        path: '/loading-page',
        element: <LoadingPage />,
        pathType: pathType.open,
        name: 'Loading Page',
    },
    DateField: {
        path: '/date-field',
        element: (
            <>
                <DateField datePicker={{ dateFormat: 'y-m-d' }} datePickerType="range" />
                <DateField datePicker={{ dateFormat: 'd/m/Y' }} datePickerType="single" />
            </>
        ),
        pathType: pathType.open,
        name: 'Date Picker',
    },
};

export const paths = {
    accountServices: {
        path: '/',
        element: <AccountServices />,
        pathType: pathType.open,
        name: 'Account Services',
        guardianPanel: false,
        guardianPanelDark: true,
    },
    loginSMS: {
        path: '/login-sms',
        element: <LoginSMS />,
        pathType: pathType.open,
        name: 'Login SMS',
        guardianPanel: true,
    },
    authenticationOptions: {
        path: '/authentication-options',
        element: <AuthenticationOptions />,
        pathType: pathType.open,
        name: 'Select TFA Methods',
        guardianPanelDark: true,
    },
    emailAuthentication: {
        path: '/email-authentication',
        element: <EmailTFA />,
        pathType: pathType.open,
        name: 'Email Authentication',
        guardianPanelDark: true,
    },
    forgotPassword: {
        path: '/forgot-password',
        element: <ForgotPasswordForm />,
        pathType: pathType.open,
        name: 'Forgot Password',
        guardianPanel: false,
        guardianPanelDark: true,
    },
    useRecoveryCode: {
        path: '/use-recovery-code',
        element: <RecoveryCodeForm />,
        pathType: pathType.open,
        name: 'Use Recovery Code',
        guardianPanel: true,
    },
    resetPasswordUsingCurrentPassword: {
        path: '/reset-password',
        element: <ResetPasswordForm />,
        pathType: pathType.closed,
        accountTypes: ['user', 'moderator', 'admin'],
        name: 'Reset Password',
        guardianPanelDark: true,
    },
    resetPasswordUsingURL: {
        path: '/reset-password/:token',
        element: <TokenResetPasswordForm />,
        pathType: pathType.open,
        name: 'Reset Password',
        guardianPanelDark: true,
    },
    accountProfile: {
        path: '/account-profile',
        element: <AccountProfile />,
        pathType: pathType.closed,
        accountTypes: ['user', 'moderator', 'admin'],
        name: 'Account Profile Page',
    },
    downloadRecoveryCode: {
        path: '/download-recovery-code',
        element: <DownloadRecoveryCodePage />,
        pathType: pathType.closed,
        accountTypes: ['user', 'admin', 'moderator'],
        name: 'Download Recovery Code',
    },
    firstAdminForm: {
        path: '/first-admin',
        element: <FirstAdmin />,
        pathType: pathType.open,
        name: 'First Admin',
    },
    accountManager: {
        path: '/account-manager',
        element: <AccountManager />,
        pathType: pathType.closed,
        accountTypes: ['admin'],
        name: 'Account Manager',
    },
    uploadDataDump: {
        path: '/upload-data-dump',
        element: <UploadDataDump />,
        pathType: pathType.closed,
        accountTypes: ['admin', 'moderator'],
        name: 'Upload Data Dump',
    },
    createDataDump: {
        path: '/create-data-dump',
        element: <CreateDataDump />,
        pathType: pathType.closed,
        accountTypes: ['admin', 'moderator'],
        name: 'Create Data Dump',
    },
    codeIntegrity: {
        path: '/code-integrity',
        element: <CodeIntegrityTable />,
        pathType: pathType.closed,
        accountTypes: ['admin', 'moderator'],
        name: 'Code Integrity',
    },
    tos: {
        path: '/tos',
        element: <TermsOfService />,
        pathType: pathType.open,
        name: 'Terms of Service',
        guardianPanel: false,
        guardianPanelDark: true,
    },
    adminActivateAccount: {
        path: '/admin-activate-account/:token',
        element: <AdminActivateAccount />,
        pathType: pathType.closed,
        accountTypes: ['user', 'admin'],
        name: 'Admin Activate Account',
    },
    userReactivateAccount: {
        path: '/user-reactivate-account/:token',
        element: <UserReactivateAccount />,
        pathType: pathType.open,
        name: 'User Reactivate Account',
    },
    userVerifyEmail: {
        path: '/user-verify-email/:token',
        element: <UserVerifyEmail />,
        pathType: pathType.open,
        name: 'User Verify Email',
        guardianPanelDark: true,
    },
    userRenewAccess: {
        path: '/user-renew-access',
        element: <UserRenewAccess />,
        pathType: pathType.open,
        name: 'User Renew Access',
        guardianPanelDark: true,
    },
    empty: {
        path: '*',
        element: <ErrorPageLayout type="404" />,
        pathType: pathType.open,
        name: 'Error',
        guardianPanelDark: true,
    },
};
export const manpowerPaths = {
    default: {
        path: '/',
        element: <Navigate to="/manpower-wizard" />,
        pathType: pathType.open,
        name: 'Guardian',
    },
    admin: {
        path: '/admin/',
        element: <Admin />,
        pathType: pathType.closed,
        accountTypes: ['admin'],
        name: 'Guardian',
    },
    adminScenarios: {
        path: '/admin/scenarios',
        element: <ManageScenarios />,
        pathType: pathType.closed,
        accountTypes: ['admin'],
        name: 'Guardian',
    },
    adminModels: {
        path: '/admin/models',
        element: <ManageModels />,
        pathType: pathType.closed,
        accountTypes: ['admin'],
        name: 'Guardian',
    },
    wizard: {
        path: '/manpower-wizard/*',
        element: <Wizard />,
        pathType: pathType.closed,
        accountTypes: ['user', 'admin', 'moderator'],
        name: 'Guardian',
    },
    downloadScenario: {
        path: '/review-download',
        element: <ReviewDownload />,
        pathType: pathType.closed,
        accountTypes: ['user', 'admin', 'moderator'],
        name: 'Guardian',
        guardianPanelDark: true,
    },
    downloadMissingUnits: {
        path: '/missing-units-download',
        element: <MissingUnitsDownload />,
        pathType: pathType.closed,
        accountTypes: ['admin', 'moderator'],
        name: 'Guardian',
        guardianPanelDark: true,
    },
    reportIssue: {
        path: '/report-issue',
        element: <ReportIssue />,
        pathType: pathType.closed,
        accountTypes: ['user', 'admin', 'moderator'],
        name: 'Guardian',
    },
};
