// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.register-content {
  display: flex;
  flex-direction: column;
}
.register-content input {
  font-size: 1rem;
}
.register-content .email-content {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.register-content .email-content svg {
  width: 2rem;
}
.register-content .middle-content {
  display: flex;
  margin-bottom: 1rem;
}
.register-content .middle-content svg:first-child {
  width: 2rem;
}
.register-content .bottom-content button .loading-content {
  display: flex;
}
.register-content .bottom-content button .loading-content .loading-util {
  min-height: 1rem;
}
.register-content span.red-text {
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/pages/accountServices/RegistrationForm.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACI;EACI,eAAA;AACR;AAEI;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;AAAR;AAEQ;EACI,WAAA;AAAZ;AAMI;EACI,aAAA;EACA,mBAAA;AAJR;AAMQ;EACI,WAAA;AAJZ;AAUY;EACI,aAAA;AARhB;AAUgB;EACI,gBAAA;AARpB;AAcI;EACI,UAAA;AAZR","sourcesContent":[".register-content {\n    display: flex;\n    flex-direction: column;\n\n    input {\n        font-size: 1rem;\n    }\n\n    .email-content {\n        display: flex;\n        margin-bottom: 1rem;\n        margin-top: 2rem;\n\n        svg {\n            width: 2rem;\n        }\n\n\n    }\n\n    .middle-content {\n        display: flex;\n        margin-bottom: 1rem;\n\n        svg:first-child {\n            width: 2rem;\n        }\n    }\n\n    .bottom-content {\n        button {\n            .loading-content {\n                display: flex;\n\n                .loading-util {\n                    min-height: 1rem;\n                }\n            }\n        }\n    }\n\n    span.red-text {\n        color: red;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
