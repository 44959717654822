import { ComboBox } from '@carbon/react';
import React from 'react';
import { comboBoxShouldFilterItem } from '../../../helpers';

export const ModelFilters = ({ cmdList, cmdFilter, mdsFilter, mdsList, onSelect }) => {
    return (
        <div className="model-filters">
            <FilterDropdowns
                cmdList={cmdList}
                cmdFilter={cmdFilter}
                mdsFilter={mdsFilter}
                mdsList={mdsList}
                onSelect={onSelect}
            />
        </div>
    );
};

const FilterDropdowns = ({ cmdList, cmdFilter, mdsFilter, mdsList, onSelect }) => {
    return (
        <div className="filter-dropdowns">
            <div data-testid="model-filter-dropdown-CMD">
                <Dropdown
                    dropdownName="CMD"
                    items={cmdList}
                    onSelect={(selectedItem) => {
                        onSelect({ cmd: selectedItem });
                    }}
                    selectedItem={cmdFilter}
                />
            </div>
            <div data-testid="model-filter-dropdown-MDS">
                <Dropdown
                    items={mdsList}
                    dropdownName="MDS"
                    onSelect={(selectedItem) => {
                        onSelect({
                            mds: selectedItem,
                        });
                    }}
                    selectedItem={mdsFilter}
                />
            </div>
        </div>
    );
};

const Dropdown = ({ items, dropdownName, onSelect, selectedItem }) => {
    const fieldName = {
        CMD: 'Major Command',
        MDS: 'Mission Design Series',
    };
    return (
        <div className="dropdown">
            <ComboBox
                id={`${dropdownName}-dropdown`}
                titleText={fieldName[dropdownName]}
                label=""
                items={items}
                selectedItem={selectedItem ?? ''}
                shouldFilterItem={comboBoxShouldFilterItem}
                onChange={({ selectedItem }) => {
                    onSelect(selectedItem || null);
                }}
            />
        </div>
    );
};

Dropdown.propTypes = {};

ModelFilters.propTypes = {};
