import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@carbon/react';

import { publishScenario, unpublishScenario } from '../adminApiCalls';

import { ConfirmationModal } from '../../wizard/modals/confirmationModal/ConfirmationModal';

import { useErrorNotification } from '../../../common/ErrorNotification/useErrorNotification';

const publishScenarioModalProps = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    populateScenarios: PropTypes.func.isRequired,
    action: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    scenarioName: PropTypes.string,
    scenarioId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    setFailedPublishScenario: PropTypes.func.isRequired,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof publishScenarioModalProps>>} props
 * @returns {React.ReactElement}
 */
export const PublishScenarioModal = ({
    isOpen,
    onClose,
    populateScenarios,
    action,
    onChange,
    scenarioName,
    scenarioId,
    setFailedPublishScenario,
}) => {
    const [unpublishReason, setUnpublishReason] = useState('');
    const [unpublishInvalid, setUnpublishInvalid] = useState(false);
    const { openError } = useErrorNotification();

    const callToAction = action === 'PUBLISH' ? 'Publish' : 'Unpublish';

    return (
        <ConfirmationModal
            id="publish-confirmation-modal"
            className="publish-confirmation-modal"
            open={isOpen}
            modalHeading={scenarioName ?? ''}
            modalLabel={callToAction}
            primaryButtonText={callToAction}
            secondaryButtonText="Cancel"
            onClose={onClose}
            onSubmit={async () => {
                if (action === 'PUBLISH') {
                    const { success, error } = await publishScenario({ scenarioName, scenarioId });
                    if (success) {
                        await populateScenarios();
                        onChange(scenarioId);
                        openError({
                            kind: 'success',
                            subtitle: 'Successfully published scenario',
                            title: 'Success',
                        });
                    } else {
                        const errorMsg = error.response.data.detail;
                        setFailedPublishScenario({ scenarioName, reason: errorMsg });
                        throw new Error('Could not publish');
                    }
                } else {
                    if (!unpublishReason) {
                        setUnpublishInvalid(true);
                        throw new Error('Missing required field');
                    }
                    const { success } = await unpublishScenario({ scenarioName, scenarioId }, unpublishReason);
                    if (success) {
                        await populateScenarios();
                        setUnpublishReason('');
                        onChange(scenarioId);
                        openError({
                            kind: 'error',
                            subtitle: 'Successfully unpublished scenario',
                            title: 'Success',
                        });
                    } else {
                        openError({
                            subtitle: `Could not unpublish scenario: ${scenarioName}`,
                        });
                        throw new Error('Could not unpublish');
                    }
                }
                onClose();
            }}
        >
            {action === 'PUBLISH' ? (
                <p>Are you sure you want to publish this scenario?</p>
            ) : (
                <div>
                    <p>Are you sure you want to unpublish this scenario?</p>
                    <TextInput
                        placeholder=""
                        className="mt-3"
                        labelText="Explanation"
                        id="unpublished-scenario-explanation"
                        enableCounter
                        maxCount={200}
                        value={unpublishReason}
                        invalid={unpublishInvalid}
                        invalidText="Required field"
                        onChange={(e) => {
                            setUnpublishReason(e.target.value);
                            setUnpublishInvalid(false);
                        }}
                    />
                </div>
            )}
        </ConfirmationModal>
    );
};

PublishScenarioModal.propTypes = publishScenarioModalProps;
