import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';

import { usePrevious } from '../../hooks';
import { insertScenarioApiDataAtom, scenarioIsLoadingAtom } from '../../hooks/wizardAtoms';

import { getScenario } from '../../pages/wizard/scenarioApiCalls';

/**
 * Fetches updated scenario information if scenario id changes
 * @param {undefined | string | number} scenario_id
 */
export const useUpdateScenarioOnChange = ({ scenario_id, fetchScenarioList, setList }) => {
    const navigate = useNavigate();
    const insertScenarioApiData = useSetAtom(insertScenarioApiDataAtom);
    const setScenarioIsLoading = useSetAtom(scenarioIsLoadingAtom);
    // Fetch scenario information if current scenario id changed
    const previousScenarioId = usePrevious(scenario_id);
    useEffect(() => {
        if (typeof previousScenarioId !== 'number' || previousScenarioId < 0) {
            return;
        }
        if (previousScenarioId !== scenario_id && parseInt(scenario_id, 10) > 0) {
            setScenarioIsLoading(true);
            getScenario(scenario_id).then(({ isOk, data: scenario }) => {
                setScenarioIsLoading(false);
                if (isOk) {
                    insertScenarioApiData(scenario);
                    if (parseInt(previousScenarioId, 10) > 0) {
                        // Navigate if previous scenario was valid
                        navigate('/manpower-wizard/');
                    }
                }
            });
            fetchScenarioList().then(({ scenarios }) => setList(scenarios));
        }
    }, [previousScenarioId, scenario_id, insertScenarioApiData, navigate, fetchScenarioList, setList]);
};

/**
 * store scenario id in localStorage
 * @param {undefined | string | number} scenario_id
 */
export const useSaveScenarioIdInLocalStorage = (scenario_id) => {
    useEffect(() => {
        try {
            // Update selected scenario_id in storage on currentscenario changes
            if (window.localStorage && parseInt(scenario_id, 10) > 0) {
                window.localStorage.setItem('scenario_id', `${scenario_id}`);
            }
        } catch (error) {
            console.error('could not update scenario id store');
        }
    }, [scenario_id]);
};

export const scenarioDropdownFilter = ({ inputValue, item, itemToString }) => {
    try {
        const value = inputValue.toLowerCase().trim();
        const matchedName = itemToString(item).toLowerCase().includes(value);
        const matchedStatus = item.status?.toLowerCase?.().includes(value);

        return matchedName || matchedStatus;
    } catch (error) {
        return true;
    }
};
