import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';

export const udColumn1 = ['FLYING_SQ_1', 'FLYING_SQ_2', 'FLYING_SQ_3', 'FLYING_SQ_4', 'FLYING_SQ_5', 'OSS_PAS'];
export const udColumn2 = ['FGS_PAS_1', 'FGS_PAS_2', 'FGS_PAS_3', 'FGS_PAS_4', 'FGS_PAS_5'];
export const udColumn3 = ['AMX_PAS_1', 'AMX_PAS_2', 'AMX_PAS_3', 'AMX_PAS_4', 'AMX_PAS_5'];
export const udColumn4 = ['MXS_PAS_1', 'MXS_PAS_2', 'EMS_PAS_1', 'EMS_PAS_2'];
export const udColumn5 = ['CMS_PAS_1', 'CMS_PAS_2', 'MUNS_PAS', 'MXOF_PAS'];

// Unit Details Atoms
export const unitDetailsInitialState = {
    OG_PAS: '',
    MXG_PAS: '',
    OSS_PAS: '',
    MUNS_PAS: '',
    AMX_PAS_1: '',
    AMX_PAS_2: '',
    AMX_PAS_3: '',
    AMX_PAS_4: '',
    AMX_PAS_5: '',
    FGS_PAS_1: '',
    FGS_PAS_2: '',
    FGS_PAS_3: '',
    FGS_PAS_4: '',
    FGS_PAS_5: '',
    MXS_PAS_1: '',
    MXS_PAS_2: '',
    EMS_PAS_1: '',
    EMS_PAS_2: '',
    CMS_PAS_1: '',
    CMS_PAS_2: '',
    MXOF_PAS: '',
    FLYING_SQ_1: '',
    FLYING_SQ_2: '',
    FLYING_SQ_3: '',
    FLYING_SQ_4: '',
    FLYING_SQ_5: '',
};

export const ogPasChildrenInitialState = {
    OSS_PAS: '',
    FLYING_SQ_1: '',
    FLYING_SQ_2: '',
    FLYING_SQ_3: '',
    FLYING_SQ_4: '',
    FLYING_SQ_5: '',
};

export const mxgPasChildrenInitialState = {
    MUNS_PAS: '',
    AMX_PAS_1: '',
    AMX_PAS_2: '',
    AMX_PAS_3: '',
    AMX_PAS_4: '',
    AMX_PAS_5: '',
    FGS_PAS_1: '',
    FGS_PAS_2: '',
    FGS_PAS_3: '',
    FGS_PAS_4: '',
    FGS_PAS_5: '',
    MXS_PAS_1: '',
    MXS_PAS_2: '',
    EMS_PAS_1: '',
    EMS_PAS_2: '',
    CMS_PAS_1: '',
    CMS_PAS_2: '',
    MXOF_PAS: '',
};

const unitDetailsOptionsInitialState = {
    OG_PAS_CHILDREN: {
        allOptions: [],
        options: [],
        inputs: [],
    },
    MXG_PAS_CHILDREN: {
        allOptions: [],
        options: [],
        inputs: [],
    },
    MXG_PAS: {
        allOptions: [],
        options: [],
        inputs: [],
    },
    OG_PAS: {
        allOptions: [],
        options: [],
        inputs: [],
    },
};

export function getUnitDetailsOptionType(label) {
    switch (label) {
        case 'OSS_PAS':
        case 'FLYING_SQ_1':
        case 'FLYING_SQ_2':
        case 'FLYING_SQ_3':
        case 'FLYING_SQ_4':
        case 'FLYING_SQ_5':
            return 'OG_PAS_CHILDREN';
        case 'MUNS_PAS':
        case 'AMX_PAS_1':
        case 'AMX_PAS_2':
        case 'AMX_PAS_3':
        case 'AMX_PAS_4':
        case 'AMX_PAS_5':
        case 'MXS_PAS_1':
        case 'MXS_PAS_2':
        case 'EMS_PAS_1':
        case 'EMS_PAS_2':
        case 'CMS_PAS_1':
        case 'CMS_PAS_2':
        case 'MXOF_PAS':
        case 'FGS_PAS_1':
        case 'FGS_PAS_2':
        case 'FGS_PAS_3':
        case 'FGS_PAS_4':
        case 'FGS_PAS_5':
            return 'MXG_PAS_CHILDREN';
        case 'OG_PAS':
        case 'MXG_PAS':
        default:
            return label;
    }
}

export const unitDetailsAtom = atom(unitDetailsInitialState);
export const unitDetailsOptionsAtom = atom(unitDetailsOptionsInitialState);
export const unitDetailsFiltersAtom = atom({ MDS: '', ILC: '', CMD: '' });

export const selectedMxgPecsAtom = atom([]);
export const selectedOgPecsAtom = atom([]);
export const selectedPecsAtom = atom([]);

export const resetUnitDetailsPasAtom = atom(null, (get, set, update) => {
    try {
        const unitDetailsState = get(unitDetailsAtom);
        const optionsState = get(unitDetailsOptionsAtom);
        const { OG_PAS_CHILDREN, MXG_PAS_CHILDREN } = optionsState;
        if (update === 'OG_PAS') {
            set(unitDetailsOptionsAtom, {
                ...optionsState,
                OG_PAS_CHILDREN: {
                    ...OG_PAS_CHILDREN,
                    options: OG_PAS_CHILDREN.allOptions,
                },
            });
            set(unitDetailsAtom, {
                ...unitDetailsState,
                ...ogPasChildrenInitialState,
            });
        } else if (update === 'MXG_PAS') {
            set(unitDetailsOptionsAtom, {
                ...optionsState,
                MXG_PAS_CHILDREN: {
                    ...MXG_PAS_CHILDREN,
                    options: MXG_PAS_CHILDREN.allOptions,
                },
            });
            set(unitDetailsAtom, {
                ...unitDetailsState,
                ...mxgPasChildrenInitialState,
            });
        }
    } catch (error) {
        console.error(error);
    }
});
// Update unitDetailsSelection
export const writeUnitDetailsAtom = atom(null, (get, set, update) => {
    try {
        const zoomedOption = getUnitDetailsOptionType(Object.keys(update)[0]);
        const atomToUpdateAtom = focusAtom(unitDetailsOptionsAtom, (optics) => optics.prop(zoomedOption));
        const selections = get(unitDetailsAtom);
        const newSelections = { ...selections, ...update };
        const { allOptions, inputs } = get(atomToUpdateAtom);
        const inputed = new Set();

        inputs.forEach((input) => {
            inputed.add(newSelections[input]);
        });
        const newAvailableOptions = allOptions.filter((option) => !inputed.has(option));
        set(atomToUpdateAtom, {
            allOptions,
            options: newAvailableOptions,
            inputs,
        });
        set(unitDetailsAtom, newSelections);
    } catch (error) {
        console.error(error);
    }
});

// Checks if UnitDetailsAtom is completed
export const checkUnitDetailsCompletedAtom = atom((get) => {
    const selections = get(unitDetailsAtom);
    const ogPecSelections = get(selectedOgPecsAtom);
    const mxgPecSelections = get(selectedMxgPecsAtom);

    const hasOnePecSelection = ogPecSelections.length > 0 || mxgPecSelections.length > 0;
    const ogPasFields = ['OSS_PAS', 'FLYING_SQ_1', 'FLYING_SQ_2', 'FLYING_SQ_3', 'FLYING_SQ_4', 'FLYING_SQ_5'];
    const mxgPasFields = [
        'MUNS_PAS',
        'AMX_PAS_1',
        'AMX_PAS_2',
        'AMX_PAS_3',
        'AMX_PAS_4',
        'AMX_PAS_5',
        'FGS_PAS_1',
        'FGS_PAS_2',
        'FGS_PAS_3',
        'FGS_PAS_4',
        'FGS_PAS_5',
        'MXS_PAS_1',
        'MXS_PAS_2',
        'EMS_PAS_1',
        'EMS_PAS_2',
        'CMS_PAS_1',
        'CMS_PAS_2',
        'MXOF_PAS',
    ];
    const hasOgPasUnits = ogPasFields.some((ogPasSelection) => {
        return selections[ogPasSelection] !== '';
    });

    const hasMxgPasUnits = mxgPasFields.some((mxgPasSelection) => {
        return selections[mxgPasSelection] !== '';
    });

    const ogSectionFilled = selections.OG_PAS && selections.OG_PAS.length > 0 && hasOgPasUnits && hasOnePecSelection;

    const mxgSectionFilled =
        selections.MXG_PAS && selections.MXG_PAS.length > 0 && hasMxgPasUnits && hasOnePecSelection;

    const allFiltersSelected = ogSectionFilled || mxgSectionFilled;

    const contractMXGSelected = () => {
        let hasMxgPasUnits = false;
        let contactMaintenance = false;
        for (let i = 0; i < mxgPasFields.length; i++) {
            if (selections[mxgPasFields[i]] === 'CONTRACT MAINTENANCE') {
                contactMaintenance = true;
            } else if (selections[mxgPasFields[i]] !== '') {
                hasMxgPasUnits = true;
            }
        }
        return !hasMxgPasUnits && contactMaintenance;
    };

    const amxSelected = () => {
        for (let i = 1; i < 6; i++) {
            if (selections[`AMX_PAS_${i}`] !== '') {
                return true;
            }
        }
    };

    const getFlyingSquadronsMatch = () => {
        for (let i = 1; i < 6; i++) {
            if (selections[`FLYING_SQ_${i}`] !== '') {
                if (amxSelected()) {
                    return true;
                }
                if (selections[`FGS_PAS_${i}`] === '') {
                    return false;
                }
            }
        }
        return true;
    };
    const flyingSquadronsMatch = getFlyingSquadronsMatch();

    return {
        hasOgPasUnits,
        hasMxgPasUnits,
        allFiltersSelected: !!allFiltersSelected,
        flyingSquadronsMatch,
        contractMXGSelected,
    };
});

// Insert data from API into UD Atom
export const insertDataIntoUnitDetailsAtom = atom(null, (get, set, data) => {
    const newUnitDetailsState = {};
    const savedInputs = [];
    Object.keys(data).forEach((key) => {
        savedInputs.push(key);
        const label = getUnitDetailsOptionType(key);
        if (newUnitDetailsState[label]) {
            newUnitDetailsState[label] = {
                ...newUnitDetailsState[label],
                // Keep track of number of duplicate inputs for this type
                inputs: [...newUnitDetailsState[label].inputs, key],
            };
        } else {
            newUnitDetailsState[label] = {
                allOptions: data[key],
                options: data[key],
                inputs: [key],
            };
        }
    });
    const selections = get(unitDetailsAtom);
    const inputed = new Set();

    savedInputs.forEach((input) => {
        if (selections[input]?.length > 0) {
            inputed.add(selections[input]);
        }
    });
    Object.entries(newUnitDetailsState).forEach(([key, obj]) => {
        const filteredOptions = obj.allOptions.filter((option) => !inputed.has(option));
        newUnitDetailsState[key] = {
            ...obj,
            options: filteredOptions,
        };
    });
    set(unitDetailsOptionsAtom, newUnitDetailsState);
});

export const restartUnitDetailsAtom = atom(null, (_get, set) => {
    set(unitDetailsAtom, unitDetailsInitialState);
    set(selectedOgPecsAtom, []);
    set(selectedMxgPecsAtom, []);
    set(unitDetailsOptionsAtom, unitDetailsOptionsInitialState);
    set(unitDetailsFiltersAtom, { MDS: '', ILC: '', CMD: '' });
});

export const scenarioExclusionAtom = atom({ OPS: [], MX: [] });
