import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import axios from 'axios';
import { useAtom, useSetAtom } from 'jotai';

import { resetScenarioInputsAtom, isApprovedScenarioAtom, scenarioIsLoadingAtom } from '../../../../hooks/wizardAtoms';
import { useIsMounted, useUpdateWizardStepValid } from '../../../../hooks';

import { ForceStructureInteraction } from './ForceStructureInteraction';
import { ForceStructureTable } from './ForceStructureTable';
import {
    filteredDataTableAtom,
    filteredPecAtom,
    forceStructureAdjustableAtom,
    gsFiltersAtom,
    filtersAtom,
    addMDSModalOpenAtom,
    addUnitModalOpenAtom,
    baselineMPESDataAtom,
    fsTableMessageAtom,
} from './GeneralSettingsAtoms';
import { restartUnitDetailsAtom } from '../unitDetails/UnitDetailsAtoms';
import { AddMdsModal } from '../../modals/generalSettings/AddMdsModal';
import { AddUnitModal } from '../../modals/generalSettings/AddUnitModal';
import { useSaveScenarioAtomOnUnmount } from '../../../../hooks/useSaveAtomOnUnmount';
import { Loading } from '@carbon/react';

export const GeneralSettings = () => {
    const setFilteredPec = useSetAtom(filteredPecAtom);
    const [filters] = useAtom(filtersAtom);
    const [gsFilters] = useAtom(gsFiltersAtom);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredDataTable, setFilteredDataTable] = useAtom(filteredDataTableAtom);
    const setBaselineMPESData = useSetAtom(baselineMPESDataAtom);
    const resetScenarioInputs = useSetAtom(resetScenarioInputsAtom);
    const restartUnitDetails = useSetAtom(restartUnitDetailsAtom);
    const setForceStructureAdjustment = useSetAtom(forceStructureAdjustableAtom);
    const [addMDSModalOpen, setAddMDSModalOpen] = useAtom(addMDSModalOpenAtom);
    const [addUnitModalOpen, setAddUnitModalOpen] = useAtom(addUnitModalOpenAtom);
    const [emptyMessage, setEmptyMessage] = useAtom(fsTableMessageAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const [scenarioIsLoading] = useAtom(scenarioIsLoadingAtom);

    const isMounted = useIsMounted();

    const { CMD, MDS, ILC } = gsFilters;

    useSaveScenarioAtomOnUnmount({ isValid: true, isGeneralSettings: true });

    const resetFilters = () => {
        setFilteredDataTable([]);
        setEmptyMessage('Please make a selection for MDS, ILC, and CMD.');
        restartUnitDetails();
        setForceStructureAdjustment([]);
        resetScenarioInputs();
    };

    const allFiltersSelected = useMemo(() => {
        return MDS !== '' && ILC !== '' && CMD !== '';
    }, [CMD, MDS, ILC]);

    useEffect(() => {
        if (!allFiltersSelected && !isLoading) {
            setFilteredDataTable([]);
            setEmptyMessage('Please make a selection for MDS, ILC, and CMD.');
        }
    }, [allFiltersSelected, isLoading, setEmptyMessage, setFilteredDataTable]);

    useUpdateWizardStepValid('generalSettings', allFiltersSelected);

    const populateTable = useCallback(async () => {
        try {
            if (MDS.length > 0 && ILC.length > 0 && CMD.length > 0) {
                setIsLoading(true);
                const { data } = await axios.post(`/api/fast-api/fs_status`, { mds: MDS, ilc: ILC, cmd: CMD }, null);
                const newFilteredPec = filters.filter(
                    ({ MDS_SELECT, CMD_SELECT, ILC_SELECT }) =>
                        MDS_SELECT === MDS && ILC_SELECT === ILC && CMD_SELECT === CMD
                );
                if (isMounted()) {
                    setFilteredDataTable(data);
                    setIsLoading(false);
                    setEmptyMessage('');
                    setFilteredPec(newFilteredPec);
                }
            } else {
                setFilteredDataTable([]);
            }
        } catch (error) {
            console.error(error);
            if (isMounted()) {
                setIsLoading(false);
                setEmptyMessage('No results found');
                setFilteredDataTable([]);
            }
        }
    }, [MDS, ILC, CMD, filters, setFilteredDataTable, setEmptyMessage, setFilteredPec, isMounted]);

    const fetchBaselineMPESData = useCallback(async () => {
        try {
            if (MDS.length > 0 && ILC.length > 0 && CMD.length > 0) {
                const { data } = await axios.post(
                    `/api/fast-api/fs_status/baseline`,
                    { mds: MDS, ilc: ILC, cmd: CMD },
                    null
                );
                if (isMounted()) {
                    setBaselineMPESData(data);
                }
            }
        } catch (error) {
            if (isMounted()) {
                setBaselineMPESData([]);
            }
            console.error(error);
        }
    }, [CMD, MDS, ILC, setBaselineMPESData, isMounted]);

    useEffect(() => {
        if (allFiltersSelected) {
            populateTable();
            fetchBaselineMPESData();
        }
    }, [populateTable, fetchBaselineMPESData, allFiltersSelected]);

    if (scenarioIsLoading) {
        return <Loading data-qa="loading-spinner" />;
    }

    return (
        <div className="general-settings">
            <ForceStructureInteraction resetFilters={resetFilters} disabled={isApprovedScenario} />
            <ForceStructureTable rows={filteredDataTable} isLoading={isLoading} emptyMessage={emptyMessage} />
            {addMDSModalOpen && (
                <AddMdsModal open={addMDSModalOpen} onClose={() => setAddMDSModalOpen(false)} filters={filters} />
            )}
            {addUnitModalOpen && (
                <AddUnitModal
                    open={addUnitModalOpen}
                    populateTable={populateTable}
                    onClose={() => setAddUnitModalOpen(false)}
                />
            )}
        </div>
    );
};
