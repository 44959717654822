import { useEffect, useRef } from 'react';

export const useConditionalEffect = (callback, condition) => {
    const cbRef = useRef(callback);

    cbRef.current = callback;

    useEffect(() => {
        if (condition) {
            cbRef.current();
        }
    }, [condition]);
};
