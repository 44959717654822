import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    DataTable,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHead,
    TableRow,
    SkeletonText,
    Pagination,
    TableToolbarMenu,
    Checkbox,
    //
} from '@carbon/react';

import { usePagination, useFilteredData } from './usePaginatedDatatable';

const paginatedDatatableProps = {
    isLoading: PropTypes.bool,
    id: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            header: PropTypes.string.isRequired,
        })
    ).isRequired,
    tableTitle: PropTypes.node,
    enablePagination: PropTypes.bool,
    enableSearch: PropTypes.bool,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof paginatedDatatableProps>>}
 * @returns {React.ReactElement}
 */
export const PaginatedDatatable = ({
    rows,
    headers,
    tableTitle = null,
    id = 'paginated-datatable',
    isLoading = false,
    enablePagination = false,
    enableSearch = false,
    datatableStatus = true,
    defaultPageSize = 10,
}) => {
    const totalItems = rows.length;
    const { page, pageSize, rowPositions, handlePageChange, jumpToFirstPage } = usePagination({
        initialPageSize: defaultPageSize,
    });
    const { filters, setFilters, filteredData } = useFilteredData(rows);

    const [visibleColumns, setVisibleColumns] = useState(
        headers.map((column) => column.key).reduce((a, v) => ({ ...a, [v]: true }), {})
    );

    const rowsRef = React.useRef(rows);
    rowsRef.current = rows;

    const onSearch = (e) => {
        setFilters({ ...filters, all: e.target.value.trim() });
        if (enablePagination && e.target.value.trim().length > 0) {
            jumpToFirstPage();
        }
    };
    return (
        <>
            <DataTable rows={filteredData} headers={headers} id={id} isSortable>
                {({
                    rows,
                    headers,
                    getTableProps,
                    getHeaderProps,
                    getRowProps,
                    getToolbarProps,
                    getTableContainerProps,
                }) => (
                    <TableContainer title={tableTitle} {...getTableContainerProps()}>
                        <TableToolbar {...getToolbarProps()} aria-label="data table toolbar">
                            <TableToolbarContent>
                                {enableSearch ? <TableToolbarSearch onChange={onSearch} persistent /> : null}
                                <TableToolbarMenu>
                                    {headers.map((header) => (
                                        <Checkbox
                                            labelText={header.header}
                                            checked={visibleColumns[header.key]}
                                            id={`columnSelector_${header.key}`}
                                            key={`columnSelector_${header.key}`}
                                            data-testid={`columnSelector_${header.key}`}
                                            onChange={(event) => {
                                                if (event.target.checked)
                                                    setVisibleColumns((state) => {
                                                        return { ...state, [header.key]: true };
                                                    });
                                                else
                                                    setVisibleColumns((state) => {
                                                        return { ...state, [header.key]: false };
                                                    });
                                            }}
                                        />
                                    ))}
                                </TableToolbarMenu>
                            </TableToolbarContent>
                        </TableToolbar>
                        <Table {...getTableProps()} aria-label={id}>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) =>
                                        visibleColumns[header.key] ? (
                                            <TableHeader
                                                {...getHeaderProps({
                                                    header,
                                                    key: header.key,
                                                    className: 'paginated-datatable-header-' + header.key,
                                                })}
                                            >
                                                {header.header.replaceAll('_', ' ')}
                                            </TableHeader>
                                        ) : null
                                    )}
                                </TableRow>
                            </TableHead>
                            {isLoading ? (
                                <TableBody data-testid="paginated-datatable-loading">
                                    <TableRow>
                                        {headers.map((header, idx) =>
                                            visibleColumns[header.key] ? (
                                                <TableCell key={`loading_${header}_${idx}`}>
                                                    <SkeletonText />
                                                </TableCell>
                                            ) : null
                                        )}
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {rows.slice(rowPositions.start, rowPositions.end).map((row) => (
                                        <TableRow key={row.id} {...getRowProps({ row })}>
                                            {row.cells.map((cell) =>
                                                visibleColumns[cell.info.header] ? (
                                                    <TableCell key={cell.id}>{cell.value}</TableCell>
                                                ) : null
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                )}
            </DataTable>
            {enablePagination ? (
                <Pagination
                    id="selection-pagination"
                    page={page}
                    pageSize={pageSize}
                    pageSizes={[10, 25, 50, 100, 500]}
                    totalItems={totalItems}
                    onChange={handlePageChange}
                />
            ) : null}
        </>
    );
};

PaginatedDatatable.propTypes = paginatedDatatableProps;
