import { useEffect, useState } from 'react';

/**
 *
 * @param  {...any} deps
 * @returns {number}
 */
export const useCountChanges = (...deps) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        setCount((prevCount) => prevCount + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return count;
};
