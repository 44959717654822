import { useRef, useEffect } from 'react';

/**
 * 
 * @param {string | number | null | boolean} value must be of type primitive
 * @returns {string | number | null | boolean} previous value
 */
export const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value])

    return ref.current;
}
