import React, { useCallback, useEffect, useState } from 'react';

import { ModelFilters } from './ModelFilters';
import { Accordion, AccordionItem, ListItem, Loading, UnorderedList } from '@carbon/react';
import { ModelsTable } from './ModelsTable';
import { fetchAircrewModels } from '../adminApiCalls';
import { deleteAircrewModel } from '../../wizard/aircrewApiCalls';
import { useErrorNotification } from '../../../common/ErrorNotification/useErrorNotification';

const opsModelProps = {};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof opsModelProps>>}
 * @returns {React.ReactElement}
 */

export const OpsModelTab = ({ onViewModel, activeIndex, setModelsInScenarios }) => {
    const [modelList, setModelList] = useState([]);
    const [cmd, setCmd] = useState([]);
    const [mds, setMds] = useState([]);
    const [filter, setFilter] = useState({
        cmd: null,
        mds: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const { openError } = useErrorNotification();

    const populateAircrewModels = useCallback(
        async function () {
            if (modelList.length < 1) {
                setIsLoading(true);
            }
            setButtonLoading(true);
            const { success, data, cmdList, mdsList } = await fetchAircrewModels();
            if (success) {
                setModelList(data);
                setCmd(cmdList);
                setMds(mdsList);
                setIsLoading(false);
                setButtonLoading(false);
            }
        },
        [modelList]
    );

    useEffect(() => {
        if (activeIndex === 0 && modelList.length < 1) {
            populateAircrewModels();
        }
    }, [activeIndex, modelList, populateAircrewModels]);

    const cmdFilter = filter.cmd;
    const mdsFilter = filter.mds;

    const isFiltered = (model) => {
        if (cmdFilter && model.CMD !== cmdFilter) {
            return false;
        }
        if (mdsFilter && model.MDS !== mdsFilter) {
            return false;
        }
        return true;
    };

    const updateModelList = (id) => {
        const updatedModelList = modelList.map((list) => {
            const updatedModels = list.MODELS.map((model) => {
                return {
                    ...model,
                    ARCHIVED: model.ACR_ID === id ? !model.ARCHIVED : model.ARCHIVED,
                };
            });
            return {
                ...list,
                MODELS: updatedModels,
            };
        });
        setModelList(updatedModelList);
    };

    return (
        <div className="mt-4 mb-4" data-testid="ops-model-tab-content">
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <ModelFilters
                        cmdList={cmd}
                        cmdFilter={cmdFilter}
                        mdsFilter={mdsFilter}
                        mdsList={mds}
                        onSelect={(update) => {
                            setFilter((prevFilter) => ({
                                ...prevFilter,
                                ...update,
                            }));
                        }}
                    />
                    <Accordion>
                        {modelList.map((modelData, i) =>
                            isFiltered(modelData) ? (
                                <AccordionItem
                                    key={`accordion_item_${i}`}
                                    title={
                                        <div className="accordion-title">
                                            <div>{`CMD: ${modelData.CMD}`}</div>
                                            <div>{`MDS: ${modelData.MDS}`}</div>
                                        </div>
                                    }
                                    data-testid={`accordion_item_${i}`}
                                >
                                    <ModelsTable
                                        models={modelData.MODELS}
                                        actions={[
                                            {
                                                actionText: 'View',
                                                onClick: (model) => onViewModel(model),
                                            },
                                            {
                                                actionText: 'Archive',
                                                onClick: async (model) => {
                                                    setButtonLoading(true);
                                                    const { isOk, error } = await deleteAircrewModel(
                                                        model?.ACR_ID,
                                                        true
                                                    );
                                                    if (isOk) {
                                                        updateModelList(model?.ACR_ID);
                                                        openError({
                                                            kind: 'success',
                                                            subtitle: 'Model archived successfully.',
                                                            title: 'Success',
                                                        });
                                                        setButtonLoading(false);
                                                    } else {
                                                        const scenarios = error.response.data.detail;
                                                        setModelsInScenarios(scenarios);
                                                        setButtonLoading(false);
                                                    }
                                                },
                                            },
                                            {
                                                actionText: 'Unarchive',
                                                onClick: async (model) => {
                                                    setButtonLoading(true);
                                                    const { isOk } = await deleteAircrewModel(model?.ACR_ID, false);
                                                    if (isOk) {
                                                        updateModelList(model?.ACR_ID);
                                                        openError({
                                                            kind: 'success',
                                                            subtitle: 'Model unarchived successfully.',
                                                            title: 'Success',
                                                        });
                                                        setButtonLoading(false);
                                                    } else {
                                                        openError({
                                                            subtitle: `Could not unarchive model.`,
                                                        });
                                                        setButtonLoading(false);
                                                    }
                                                },
                                            },
                                        ]}
                                        onViewModel={onViewModel}
                                        ariaLabel="Ops Model Table"
                                        buttonLoading={buttonLoading}
                                    />
                                </AccordionItem>
                            ) : null
                        )}
                    </Accordion>
                </>
            )}
        </div>
    );
};

OpsModelTab.propTypes = opsModelProps;
