/**
 * Call in useEffect to get a CSRF token. The token is then set as a default header in Axios.
 * The token is valid for as long as the backend session.
 */
export const getCSRFToken = async () => {
    try {
        const res = await global.LibAxios.get('/api/getCSRFToken');
        global.LibAxios.defaults.headers.post['X-CSRF-Token'] = res.data.CSRFToken;
    } catch (error) {
        console.error(error);
    }
};
