import { Modal } from '@carbon/react';
import './ManageScenarios.scss';
import PropTypes from 'prop-types';

export const RevokeApprovalModal = ({ scenario, open, onClose, onSubmit }) => {
    const handleSubmit = () => {
        onSubmit();
        onClose();
    };

    return (
        <Modal
            className="revoke-approval-modal"
            open={open}
            modalHeading="Revoke Approved Scenario"
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
            onRequestClose={onClose}
            onRequestSubmit={handleSubmit}
            size="sm"
            data-testid="revoke-approval-modal"
        >
            <div className="mb-2">
                Are you sure you want to revoke approval for <strong>{scenario}</strong>?
            </div>
        </Modal>
    );
};

RevokeApprovalModal.propTypes = {
    scenario: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};
