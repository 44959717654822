import { FilterableMultiSelect } from '@carbon/react';
import PropTypes from 'prop-types';

export const FilterableMultiSelectAll = ({
    id,
    keyValue,
    className,
    items,
    initialSelectedItems,
    itemToString,
    label,
    titleText,
    direction,
    sortItems,
    onChange,
    disabled = false,
}) => {
    return (
        <FilterableMultiSelect
            id={id}
            disabled={disabled}
            key={keyValue}
            className={className}
            items={items}
            initialSelectedItems={initialSelectedItems}
            itemToString={itemToString}
            label={label}
            titleText={titleText}
            direction={direction}
            sortItems={sortItems}
            onChange={onChange}
        />
    );
};

FilterableMultiSelectAll.propTypes = {
    id: PropTypes.string,
    keyValue: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    titleText: PropTypes.string,
    direction: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.object),
    initialSelectedItems: PropTypes.arrayOf(PropTypes.object),
    itemToString: PropTypes.func,
    sortItems: PropTypes.func,
    onChange: PropTypes.func,
};
