import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';

require('highcharts/modules/exporting')(Highcharts);

export const VnvChart = ({ graphData, title, asisTotal, tobeTotal }) => {
    const options = {
        title: {
            text: `${title}`,
        },
        chart: {
            type: 'scatter',
            zoomType: 'xy',
        },
        xAxis: {
            title: {
                text: `ASIS (${asisTotal})`,
            },
            labels: {
                format: '{value}',
            },
            endOnTick: true,
            showLastLabel: true,
        },
        yAxis: {
            title: {
                text: `TOBE (${tobeTotal})`,
            },
            labels: {
                format: '{value}',
            },
            tickAmount: 16,
            endOnTick: true,
            showLastLabel: true,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 2.5,
                    symbol: 'circle',
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)',
                        },
                    },
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false,
                        },
                    },
                },
                jitter: {
                    x: 0.005,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: '0.8em',
            },
            pointFormat:
                '<br/> MP_TYPE: {point.MP_TYPE} <br/> ASIS: {point.x} <br/> TOBE: {point.y} <br/> CMD: {point.CMD} <br/> ILC: {point.ILC} <br/> MDS: {point.MDS}',
        },
        series: graphData,
        accessibility: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
    };
    return (
        <section className="graph-view">
            <HighchartsReact highcharts={Highcharts} options={options} />
        </section>
    );
};

VnvChart.propTypes = {
    graphData: PropTypes.arrayOf(
        PropTypes.shape({
            asisTotal: PropTypes.number,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    ASIS: PropTypes.number,
                    CALC_TYPE: PropTypes.string,
                    CMD: PropTypes.string,
                    DELTA: PropTypes.number,
                    ILC: PropTypes.string,
                    MDS: PropTypes.string,
                    MP_TYPE: PropTypes.string,
                    NOTES: PropTypes.string,
                    PEC: PropTypes.string,
                    POS: PropTypes.string,
                    RIC: PropTypes.string,
                    SCENARIO: PropTypes.string,
                    TOBE: PropTypes.number,
                    UNIT: PropTypes.string,
                    id: PropTypes.string,
                    x: PropTypes.number,
                    y: PropTypes.number,
                })
            ),
            name: PropTypes.string,
            tobeTotal: PropTypes.number,
        })
    ),
    title: PropTypes.string,
    asisTotal: PropTypes.number,
    tobeTotal: PropTypes.number,
    delta: PropTypes.number,
};
