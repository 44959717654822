export const CARBON_THEME = {
    WHITE: 'white',
    G10: 'g10',
    G90: 'g90',
    G100: 'g100',
};

export const CUSTOM_THEME = {
    LIGHT: 'light',
    DARK: 'dark',
};
