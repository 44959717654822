import { ComposedModal, ListItem, ModalBody, ModalHeader, UnorderedList } from '@carbon/react';
import PropTypes from 'prop-types';

export const SuccessStateModal = ({ onClose, sharedScenarios = {}, userList = [], revokeScenario, isSuccess }) => {
    const findUserName = (userId) => {
        return userList.find((user) => user.id === userId)?.name;
    };

    if (revokeScenario) {
        return (
            <ComposedModal className="success-modal" open onClose={onClose} data-testid="revoke-success-modal">
                <ModalHeader label="" title="Scenarios Revoked"></ModalHeader>
                <ModalBody>
                    <div>
                        <div>
                            {isSuccess ? 'Approval revoked for ' : ' Approval could not be revoked for '}
                            <strong>{revokeScenario}</strong>.
                        </div>
                    </div>
                </ModalBody>
            </ComposedModal>
        );
    }
    return (
        <ComposedModal className="success-modal" open onClose={onClose} data-testid="share-success-modal">
            <ModalHeader label="" title="Scenarios Shared"></ModalHeader>
            <ModalBody>
                <div>
                    {sharedScenarios.success.length > 0 && (
                        <div>
                            <strong>Scenarios successfully shared:</strong>
                            <UnorderedList>
                                {sharedScenarios.success.map((scenario) => {
                                    return (
                                        <ListItem key={scenario.scenario_id}>
                                            {scenario.scenario_name} {`[${findUserName(scenario.user_id)}]`}
                                        </ListItem>
                                    );
                                })}
                            </UnorderedList>
                        </div>
                    )}
                    {sharedScenarios.failed.length > 0 && (
                        <div>
                            <strong>Approved scenario already exists:</strong>
                            <UnorderedList>
                                {sharedScenarios.failed.map((scenario) => {
                                    return (
                                        <ListItem key={scenario.scenario_id}>
                                            {scenario.scenario_name} {`[${findUserName(scenario.user_id)}]`}
                                        </ListItem>
                                    );
                                })}
                            </UnorderedList>
                        </div>
                    )}
                </div>
            </ModalBody>
        </ComposedModal>
    );
};

SuccessStateModal.propTypes = {
    isSuccess: PropTypes.bool,
    sharedScenarios: PropTypes.object,
    userList: PropTypes.arrayOf(PropTypes.object),
    revokeScenario: PropTypes.string,
    onClose: PropTypes.func,
};
