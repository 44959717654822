import { atom } from 'jotai';
import { gsFiltersAtom, initialGsFilters } from '../pages/wizard/steps/generalSettings/GeneralSettingsAtoms';
import { opsScenarioAtom } from '../pages/wizard/steps/opsEquations/OpsAtoms';
import {
    getUnitDetailsOptionType,
    selectedMxgPecsAtom,
    selectedOgPecsAtom,
    unitDetailsAtom,
} from '../pages/wizard/steps/unitDetails/UnitDetailsAtoms';
import { mxScenarioAtom } from './mxAtoms';
import {
    additionalUnitsAtom,
    createEmptyUnitRow,
    initialAdditionalDetailsState,
} from '../pages/wizard/steps/additionalUnitDetails/useAdditionalUnitDetailsState';

// Wizard Atoms
export const allInputsAtom = atom({
    generalSettings: false,
    unitDetails: false,
    additionalUnits: false,
    tfiAssociations: false,
});

/**
 * Updates allInputsAtom will preserving other inputs in atom
 * eg: setReduceAllInputs({ generalSettings: true })
 */
export const reduceAllInputsAtom = atom(
    (get) => get(allInputsAtom),
    (get, set, updatedInput) => {
        const allInputs = get(allInputsAtom);
        set(allInputsAtom, { ...allInputs, ...updatedInput });
    }
);

export const isScenarioDialogOpenAtom = atom(false);
export const customScenarioSaveAtom = atom(false);
export const scenarioIsSavingAtom = atom(false);
export const isShareScenarioOpenAtom = atom(false);

export const scenarioAtom = atom({
    scenario_id: -1,
    scenario_name: 'init',
});

export const apiScenarioAtom = atom({
    generalSettings: {},
    unitDetails: {},
});

export const isApprovedScenarioAtom = atom(false);
export const isUnpublishedScenarioAtom = atom(false);

export const aircrewHostUnitsAtom = atom([]);

export const scenarioIsLoadingAtom = atom(false);

/**
 * Write only atom for inserting data from the API into other atoms
 */
export const insertScenarioApiDataAtom = atom(null, (get, set, update) => {
    const { scenario_id, scenario_name } = get(scenarioAtom);
    // keep only the scenario_id or scenario_name
    // when updating from API data
    const updateCopy = {
        scenario_id,
        scenario_name,
        ...update,
    };

    set(
        isApprovedScenarioAtom,
        updateCopy.STATUS === 'APPROVED' || updateCopy.STATUS === 'UNPUBLISHED' || updateCopy.STATUS === 'PUBLISHED'
    );

    set(isUnpublishedScenarioAtom, updateCopy.STATUS === 'UNPUBLISHED');

    // Keep snapshot of fetched scenario data for comparison
    set(apiScenarioAtom, updateCopy);

    // Keep original just in case update does not include gsFiltersChange
    const gsFiltersLocal = get(gsFiltersAtom);
    const gsFiltersAPI = {
        MDS: update?.generalSettings?.MDS ?? gsFiltersLocal.MDS,
        CMD: update?.generalSettings?.CMD ?? gsFiltersLocal.CMD,
        ILC: update?.generalSettings?.ILC ?? gsFiltersLocal.ILC,
    };
    set(gsFiltersAtom, gsFiltersAPI);

    if (update?.unitDetails) {
        set(aircrewHostUnitsAtom, []);
        const { unitDetails } = update;
        const mxPecsAPI = unitDetails.MX_PECS ?? [];
        set(selectedMxgPecsAtom, mxPecsAPI);
        delete updateCopy.MX_PECS;

        const opsPecsAPI = unitDetails.OG_PECS ?? [];
        set(selectedOgPecsAtom, opsPecsAPI);
        delete updateCopy.OPS_PECS;

        set(unitDetailsAtom, unitDetails);
        for (const unitLabel in unitDetails) {
            const labelType = getUnitDetailsOptionType(unitLabel);
            if (labelType === 'OG_PAS_CHILDREN' && unitDetails[unitLabel]) {
                set(aircrewHostUnitsAtom, [...get(aircrewHostUnitsAtom), unitDetails[unitLabel]]);
            }
        }
    }

    if (updateCopy.additionalUnitDetails) {
        const current = get(additionalUnitsAtom);
        const selectedCMDs =
            updateCopy.additionalUnitDetails.CMD?.length > 0
                ? updateCopy.additionalUnitDetails.CMD
                : current.selectedCMDs;
        set(additionalUnitsAtom, {
            ...current,
            selectedCMDs: selectedCMDs ?? [],
            ilcs: updateCopy.additionalUnitDetails.ILC ?? [],
            ops:
                updateCopy.additionalUnitDetails.AIRCREW?.length > 0
                    ? updateCopy.additionalUnitDetails.AIRCREW.map((unit) => ({
                          ...createEmptyUnitRow('ops'),
                          unit: unit.UNIT,
                          hostUnit: unit.HOST,
                          pec: unit.PEC,
                      }))
                    : [createEmptyUnitRow('ops')],
            mx:
                updateCopy.additionalUnitDetails.MX?.length > 0
                    ? updateCopy.additionalUnitDetails.MX.map((unit) => ({
                          ...createEmptyUnitRow('mx'),
                          unit: unit.UNIT,
                          hostUnit: unit.HOST,
                          pec: unit.PEC,
                      }))
                    : [createEmptyUnitRow('mx')],
        });
    } else {
        set(additionalUnitsAtom, {
            ...initialAdditionalDetailsState,
        });
    }

    if (updateCopy.mx) {
        set(mxScenarioAtom, updateCopy.mx);
        delete updateCopy.mx;
    }

    if (updateCopy.ops) {
        set(opsScenarioAtom, updateCopy.ops);
        delete updateCopy.ops;
    }

    set(scenarioAtom, updateCopy);
});

/**
 * Gets object of app state for saving scenario data with api
 */
export const getAllOfScenarioStateAtom = atom((get) => {
    const result = {
        ...get(scenarioAtom),
        generalSettings: {
            ...get(gsFiltersAtom),
            forceStructureTables: {
                adjustment: [],
                ...get(scenarioAtom)?.generalSettings?.forceStructureTables,
            },
        },
        unitDetails: {
            ...get(unitDetailsAtom),
            MX_PECS: get(selectedMxgPecsAtom),
            OG_PECS: get(selectedOgPecsAtom),
        },
    };

    // result.additionalUnits = {}
    const additionalUnitsState = get(additionalUnitsAtom);
    function filterAdditionalUnits(additionalUnit) {
        return !!additionalUnit.unit && !!additionalUnit.hostUnit;
    }
    // const fullCmds =
    // get the list of cmds from the filled units
    const cmds = [];
    const ilcs = additionalUnitsState.ilcs || [];
    const allUnits = additionalUnitsState.allUnits;
    const userSelectedCMDs = additionalUnitsState.selectedCMDs || [];
    result.additionalUnitDetails = {
        CMD: [],
        ILC: [],
        AIRCREW: additionalUnitsState.ops.filter(filterAdditionalUnits).map((additionalUnit) => {
            if (allUnits[additionalUnit.unit]) {
                cmds.push(allUnits[additionalUnit.unit].CMD);
                ilcs.push(allUnits[additionalUnit.unit].ILC);
            }
            return {
                UNIT: additionalUnit.unit,
                HOST: additionalUnit.hostUnit,
                PEC: additionalUnit.pec,
            };
        }),
        MX: additionalUnitsState.mx.filter(filterAdditionalUnits).map((additionalUnit) => {
            if (allUnits[additionalUnit.unit]) {
                cmds.push(allUnits[additionalUnit.unit].CMD);
                ilcs.push(allUnits[additionalUnit.unit].ILC);
            }
            return {
                UNIT: additionalUnit.unit,
                HOST: additionalUnit.hostUnit,
                PEC: additionalUnit.pec,
            };
        }),
    };
    if (result.additionalUnitDetails.AIRCREW.length > 0 || result.additionalUnitDetails.MX.length > 0) {
        result.additionalUnitDetails.CMD = Array.from(
            new Set([...userSelectedCMDs.filter((cmd) => cmd !== 'SELECT ALL'), ...cmds])
        );
        result.additionalUnitDetails.ILC = Array.from(new Set([...ilcs]));
    }

    // Ensure that ops and mx are no longer included with the scenario
    if (result.ops) {
        delete result.ops;
    }
    if (result.mx) {
        delete result.mx;
    }
    return result;
});

export const resetScenarioInputsAtom = atom(null, (get, set, update) => {
    set(gsFiltersAtom, initialGsFilters);

    set(opsScenarioAtom, []);
    set(mxScenarioAtom, []);
    const { scenario_id, scenario_name } = get(scenarioAtom);
    set(scenarioAtom, { scenario_id, scenario_name });
    set(apiScenarioAtom, {
        scenario_id,
        scenario_name,
        generalSettings: {},
        unitDetails: {},
    });
    set(additionalUnitsAtom, {
        ...initialAdditionalDetailsState,
    });
});

export const hasOnePaiTypeAtom = atom(true);

export const MPESList = [2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032];
export const MPESDefault = 2025;
export const selectedMPESAtom = atom(null);
