export const InputValidator = ({ type, value }) => {
    switch (type) {
        case 'emailBasic':
        case 'emailDomain':
            return validateEmail(value, type);
        case 'name':
            return validateName(value);
        case 'password':
            return validatePassword(value);
        case 'token':
            return validateToken(value);
        case 'ip':
            return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                value
            );
        case 'date':
            return validateDate(value);
        default:
            return false;
    }
};

export const comboBoxShouldFilterItem = ({
    inputValue,
    item,
    itemToString,
}) => {
    try {
        return itemToString(item)
            .toLowerCase()
            .includes(inputValue.toLowerCase().trim());
    } catch (error) {
        return true;
    }
};

const digit = /(?=\S*\d)/;
const lowercaseLetter = /(?=\S*[a-z])/;
const uppercaseLetter = /(?=\S*[A-Z])/;
const specialCharacter = /(?=\S*[`~\-_=+[{}\]\\|;:'",<.>/?!@#$%^&*()])/;
const onlyLettersSpacesAndHyphens = /^[a-zA-Z\s-]+$/;
const onlyLettersNumbers = /^[a-zA-Z\d]+$/;
const onlyNumbers = /^[0-9]+$/;
const dateFormatRegex = {
    m: /^(0[1-9]|1[012])$/,
    d: /^(0[1-9]|[12][0-9]|3[01])$/,
    y: /\d{2}/,
    Y: /^([12]\d{3})$/,
};

const validateEmail = (email, type) => {
    const prefixCheck = /^\s*[a-zA-Z\d-_.]+$/;
    const domainCheck =
        /^(([a-zA-Z\d][a-zA-Z\d-]*[a-zA-Z\d])|([a-zA-Z]))\.[a-zA-Z\d][a-zA-Z\d-\.]*[a-zA-Z\d]\s*$/;

    let hasValidPrefix = true;
    let hasValidDomain = true;
    const hasAtSymbol = email.includes('@');
    if (email.includes('@')) {
        const prefix = email.slice(0, email.indexOf('@'));
        const domain = email.slice(email.indexOf('@') + 1);
        hasValidPrefix = prefixCheck.test(prefix);
        hasValidDomain = domainCheck.test(domain);
    }

    const basicConditions = {
        hasAtSymbol,
        hasValidPrefix,
        hasValidDomain,
    };
    const lengthMet = email.length < 80;

    const basicConditionsMet =
        Object.values(basicConditions).includes(false) === false;
    let allConditionsMet = basicConditionsMet && lengthMet;
    let conditionsReturned = {
        lengthMet,
        basicConditionsMet,
        allConditionsMet,
    };

    if (type === 'emailDomain') {
        const validEmailDomains = process.env.REACT_APP_VALID_EMAIL_DOMAINS;
        const domainApproved = validEmailDomains.includes(email.split('@')[1]);
        allConditionsMet = hasValidPrefix && domainApproved && lengthMet;

        conditionsReturned = {
            lengthMet,
            hasValidPrefix,
            domainApproved,
            allConditionsMet,
        };
    }

    return conditionsReturned;
};

const validatePassword = (password) => {
    const individualConditions = {
        hasDigit: digit.test(password),
        hasLowercaseLetter: lowercaseLetter.test(password),
        hasCapitalLetter: uppercaseLetter.test(password),
        hasSpecialCharacter: specialCharacter.test(password),
        hasAtLeastEightCharacters: password.length > 7,
    };
    const maxLengthMet = password.length < 70;
    const individualConditionsMet =
        Object.values(individualConditions).includes(false) === false;
    const allConditionsMet = individualConditionsMet && maxLengthMet;
    return {
        individualConditions,
        maxLengthMet,
        individualConditionsMet,
        allConditionsMet,
    };
};

const validateName = (name) => {
    const nameLetters = name.replaceAll(' ', '').replaceAll('-', '');
    const nameTrimmed = name.trim();

    const conditions = {
        onlyHasLettersSpacesAndHyphens: onlyLettersSpacesAndHyphens.test(name),
        hasThreeLetters: /^[a-zA-Z]{3,}$/.test(nameLetters),
        noDoubleSpaces: !nameTrimmed.includes('  '),
        noDoubleHyphens: !nameTrimmed.includes('--'),
        noSpaceHyphenCombo: !name.includes(' -') && !name.includes('- '),
        lessThanSixty: name.length < 60,
        endsInLetter: !nameTrimmed.endsWith('-'),
    };

    let allConditionsMet = true;
    let characterConditionsMet = true;
    Object.keys(conditions).forEach((condition) => {
        if (conditions[condition] === false) {
            allConditionsMet = false;
        }
        if (
            conditions[condition] === false &&
            condition !== 'hasThreeLetters' &&
            condition !== 'lessThanSixty'
        ) {
            characterConditionsMet = false;
        }
    });

    return {
        ...conditions,
        characterConditionsMet,
        allConditionsMet,
    };
};

const validateToken = (token) => {
    return {
        lessThan42: token.length < 42,
        // exactLength: token.length === 32,
        onlyNumbersLetters: onlyLettersNumbers.test(token),
    };
};

const validateDate = (value) => {
    const { dateType, lastTypedDate } = value;
    return {
        matchDateType: dateFormatRegex[dateType].test(lastTypedDate),
        onlyNumbers: onlyNumbers.test(lastTypedDate),
    };
};
