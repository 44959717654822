import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { focusAtom } from 'jotai-optics';

/**
 * Used for focusing on atoms
 * @param {JotaiAtom} mainAtom Main state object to focus on
 * @param {string} key STATIC key of object to focus on
 * @returns {[any, (state: any) => void]} returns useAtom state hook
 */
export const useFocusAtom = (mainAtom, key) => {
    return useAtom(
        focusAtom(
            mainAtom,
            useCallback((optics) => optics.prop(key), [key])
        )
    );
};
