import React, { useState } from 'react';
import { Button, ComboBox, FilterableMultiSelect } from '@carbon/react';
import { TrashCan } from '@carbon/react/icons';

import './AdditionalUnitDetails.scss';
import { MultiSelectAll } from './AdditionalUnitDetails';

export const AddUnitRow = ({
    id,
    pecs,
    initialSelectedPecs,
    onChangeMultiSelect,
    onChangeCombobox,
    onDelete,
    units,
    index,
    canDelete = false,
    disabled = false,
    type = 'ops',
    selectedUnit = '',
    hostUnit = [],
    hostUnits,
}) => {
    const [multiselectKey, setMultiselectKey] = useState(0);

    return (
        <div className="unit-details-pairing mb-3">
            <Button
                data-testid="remove-row-btn"
                className="delete-btn me-2"
                label="Remove row"
                align="right"
                kind="ghost"
                onClick={() => onDelete({ type, index })}
                disabled={!canDelete}
            >
                <TrashCan />
            </Button>
            <div className="units">
                <ComboBox
                    items={units}
                    id={`combobox_${id}`}
                    key={`combobox_${id}`}
                    titleText="Unit"
                    placeholder={units.length > 0 ? 'Please select a unit...' : 'No units'}
                    selectedItem={selectedUnit}
                    onChange={({ selectedItem }) => {
                        if (!selectedItem) {
                            setMultiselectKey((prev) => prev + 1);
                        }
                        onChangeCombobox({ type, index, unit: selectedItem, hostUnit });
                    }}
                    disabled={disabled}
                />
            </div>
            <div className="pecs">
                <FilterableMultiSelect
                    id={`multiselect_${id}`}
                    key={`multiselect_${id}_${multiselectKey}`}
                    className="mt-2 pb-3"
                    items={pecs}
                    initialSelectedItems={initialSelectedPecs}
                    itemToString={(i) => i || ''}
                    label=""
                    titleText="Connected PECS"
                    placeholder="Please select..."
                    direction="bottom"
                    onChange={({ selectedItems }) => {
                        onChangeMultiSelect({
                            //
                            unit: selectedUnit,
                            pecs: selectedItems,
                            type,
                            index,
                        });
                    }}
                    disabled={disabled}
                />
            </div>
            <div className="host-unit">
                <MultiSelectAll
                    id={`multiselect_host_unit_${id}`}
                    items={hostUnits}
                    selectedItemsInState={hostUnit}
                    label=""
                    titleText="Host Unit"
                    onChange={(selectedItems, selectAll) => {
                        onChangeCombobox({
                            type,
                            index,
                            unit: selectedUnit,
                            hostUnit: selectAll ? hostUnits : selectedItems,
                            updateCount: selectAll,
                        });
                    }}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
