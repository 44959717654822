import React, { useState, useReducer } from 'react';
import {
    TextInput,
    Checkbox,
    CheckboxGroup,
    Button,
    FileUploader,
    TextArea,
    Form,
    RadioButton,
    RadioButtonGroup,
    Dropdown,
} from '@carbon/react';

import './reportIssuesForm.scss';
import { submitReport } from '../issuesApiCalls';
import { useErrorNotification } from '../../../common/ErrorNotification/useErrorNotification';

const issueTypes = Object.freeze({
    bug: 'bug',
    calculationIssue: 'calculationIssue',
    modelIssue: 'modelIssue',
    inclusionIssue: 'inclusionIssue',
    featureRequest: 'featureRequest',
    other: 'other',
});

const initialReportIssuesState = {
    detailedDescription: '',
    cmd: 'REGAF',
    issueType: issueTypes.bug,
    scenarioName: '',
    umpPage: {
        'general-settings': false,
        'unit-details': false,
        'ops-equations': false,
        'mx-equations': false,
        'review-page': false,
        'admin-page': false,
        'other-page': false,
    },
    briefDescription: '',
    stepsToReproduce: '',
    screenshots: [],
    files: [],
    otherIssue: '',
    otherPage: '',
    urgency: 'MEDIUM',
};

const reportIssuesReducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return {
                ...state,
                ...action.payload,
            };
        case 'update-page':
            return {
                ...state,
                umpPage: {
                    ...state.umpPage,
                    ...action.payload,
                },
            };
        case 'add-file': {
            const { type, file } = action.payload;
            const files = state[type] || [];
            return {
                ...state,
                [type]: [...files, ...file],
            };
        }
        case 'delete-file': {
            const { type, fileName } = action.payload;
            let files = state[type] || [];
            files = files.filter((file) => file.name !== fileName);
            return {
                ...state,
                [type]: files,
            };
        }
        default:
            return state;
    }
};

const formStatuses = Object.freeze({
    INIT: 'init',
    LOADING: 'loading',
    ERROR: 'error',
    SUCCESS: 'success',
});

export const ReportIssuesForm = ({ onSubmit }) => {
    const [state, dispatch] = useReducer(reportIssuesReducer, initialReportIssuesState);
    const [formStatus, setFormStatus] = useState(formStatuses.INIT);
    const { openError } = useErrorNotification();

    const handleUpdatePage = (_evt, { checked, id }) => {
        dispatch({
            type: 'update-page',
            payload: { [id]: checked },
        });
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        setFormStatus(formStatuses.LOADING);
        const umpPage = Object.entries(state.umpPage).reduce((str, [page, selected]) => {
            if (selected) {
                let toBeAdded = page;
                if (page === 'other-page') {
                    toBeAdded = state.otherPage;
                }
                if (str.length > 0) {
                    str += ',' + toBeAdded;
                } else {
                    str = toBeAdded;
                }
            }
            return str;
        }, '');
        let issueType = state.issueType;
        if (state.issueType === issueTypes.other) {
            issueType = state.otherIssue;
        }
        const postBody = {
            cmd: state.cmd,
            issue_type: issueType,
            scenario_name: state.scenarioName,
            ump_page: umpPage,
            brief_desc: state.briefDescription,
            detailed_desc: state.detailedDescription,
            steps_to_reproduce: state.stepsToReproduce,
            screenshots: state.screenshots,
            files: state.files,
            urgency: state.urgency,
        };
        const { success } = await submitReport(postBody);

        if (success) {
            setFormStatus(formStatuses.SUCCESS);
            openError({ title: 'Successfully uploaded issue', kind: 'success' });
        } else {
            setFormStatus(formStatuses.ERROR);
            openError({ title: 'Failed to post issue', kind: 'error' });
        }
        onSubmit(success);
    };

    return (
        <Form className="report-issues-form" onSubmit={handleSubmit} data-testid="report-issues-form">
            {/* Describe the issue */}
            <TextInput
                className="mb-3"
                id="brief-description"
                labelText={
                    <div className="required">
                        Brief Description<span>*</span>
                    </div>
                }
                maxLength={100}
                required
                value={state.briefDescription}
                onChange={(e) => {
                    dispatch({
                        type: 'update',
                        payload: {
                            briefDescription: e.target.value,
                        },
                    });
                }}
            />

            <RadioButtonGroup
                legendText="MAJCOM"
                defaultSelected="REGAF"
                onChange={(value) => {
                    dispatch({
                        type: 'update',
                        payload: { cmd: value },
                    });
                }}
                name="majcom-radio-button-group"
                orientation="vertical"
                required
                data-testid="majcom-radio-group"
            >
                <RadioButton labelText="REGAF" value="REGAF" id="REGAF-radio" />
                <RadioButton labelText="AFR" value="AFR" id="AFR-radio" />
                <RadioButton labelText="ANG" value="ANG" id="ANG-radio" />
            </RadioButtonGroup>

            {/* Issue Type */}
            <RadioButtonGroup
                legendText="Issue Type"
                className="mt-3 fieldset-other"
                onChange={(value) => {
                    dispatch({
                        type: 'update',
                        payload: { issueType: typeof value !== 'string' ? 'other' : value },
                    });
                }}
                name="issue-type-radio-button-group"
                defaultSelected="bug"
                orientation="vertical"
                data-testid="issue-type-radio-group"
            >
                <RadioButton labelText="Bug" value={issueTypes.bug} id="bug" />
                <RadioButton labelText="Calculation Issue" value={issueTypes.calculationIssue} id="calculationIssue" />
                <RadioButton labelText="MX/OPS Model Issue" value={issueTypes.modelIssue} id="modelIssue" />
                <RadioButton labelText="Filter/Inclusion Issue" value={issueTypes.inclusionIssue} id="inclusionIssue" />
                <RadioButton labelText="Feature Request" value={issueTypes.featureRequest} id="featureRequest" />
                <div className="other-button-wrapper">
                    <RadioButton
                        name="issue-type-radio-button-group"
                        className="other-button"
                        labelText="Other"
                        value={issueTypes.other}
                        id="otherIssue"
                    />
                    <TextInput
                        id="other-issue-text"
                        data-testid="other-issue-text-input"
                        labelText=""
                        size="sm"
                        value={state.otherIssue}
                        required={state.issueType === 'other'}
                        onChange={(e) => {
                            dispatch({
                                type: 'update',
                                payload: { otherIssue: e.target.value },
                            });
                        }}
                    />
                </div>
            </RadioButtonGroup>

            {/* Scenario Name */}
            <TextInput
                id="scenario-name"
                className="mt-3"
                maxLength={45}
                labelText="Scenario Name (if applicable)"
                value={state.scenarioName}
                onChange={(e) => {
                    dispatch({
                        type: 'update',
                        payload: { scenarioName: e.target.value },
                    });
                }}
            />
            {/* UMP Page */}
            <CheckboxGroup className="mt-3" legendText="UMP Page" data-testid="ump-page-checkboxes">
                <Checkbox
                    labelText="General Settings"
                    id="general-settings"
                    checked={state.umpPage['general-settings']}
                    onChange={handleUpdatePage}
                />
                <Checkbox
                    labelText="Unit Details"
                    id="unit-details"
                    checked={state.umpPage['unit-details']}
                    onChange={handleUpdatePage}
                />
                <Checkbox
                    labelText="OPS Equations"
                    id="ops-equations"
                    checked={state.umpPage['ops-equations']}
                    onChange={handleUpdatePage}
                />
                <Checkbox
                    labelText="MX Equations"
                    id="mx-equations"
                    checked={state.umpPage['mx-equations']}
                    onChange={handleUpdatePage}
                />
                <Checkbox
                    labelText="Review Page"
                    id="review-page"
                    checked={state.umpPage['review-page']}
                    onChange={handleUpdatePage}
                />
                <Checkbox
                    labelText="Admin Page (Approve/Share Scenarios)"
                    id="admin-page"
                    checked={state.umpPage['admin-page']}
                    onChange={handleUpdatePage}
                />
                <div className="other-button-wrapper">
                    <Checkbox
                        className="other-button"
                        labelText="Other"
                        id="other-page"
                        checked={state.umpPage['other-page']}
                        onChange={handleUpdatePage}
                    />
                    <TextInput
                        data-testid="other-page-text"
                        id="other-page-text"
                        labelText=""
                        size="sm"
                        value={state.otherPage}
                        onChange={(e) => {
                            dispatch({
                                type: 'update',
                                payload: { otherPage: e.target.value },
                            });
                        }}
                    />
                </div>
            </CheckboxGroup>

            {/* Describe the issue */}
            <TextArea
                className="mt-3"
                id="issue-description"
                data-testid="issue-description-text-area"
                labelText={
                    <div className="required">
                        Describe the issue<span>*</span>
                    </div>
                }
                maxLength={400}
                required
                value={state.detailedDescription}
                onChange={(e) => {
                    dispatch({
                        type: 'update',
                        payload: {
                            detailedDescription: e.target.value,
                        },
                    });
                }}
            />

            {/* Steps To reproduce */}
            <TextArea
                className="mt-3"
                id="steps-to-reproduce"
                data-testid="steps-to-reproduce-text-area"
                labelText={
                    <div className="required">
                        Steps to Reproduce<span>*</span>
                    </div>
                }
                maxLength={400}
                value={state.stepsToReproduce}
                required
                onChange={(e) => {
                    dispatch({
                        type: 'update',
                        payload: {
                            stepsToReproduce: e.target.value,
                        },
                    });
                }}
            />

            <div className="mt-3">
                <Dropdown
                    id="urgency-dropdown"
                    data-testid="urgency-dropdown"
                    titleText={
                        <div className="required">
                            Urgency<span>*</span>
                        </div>
                    }
                    initialSelectedItem={state.urgency}
                    label=""
                    items={['LOWEST', 'LOW', 'MEDIUM', 'HIGH', 'HIGHEST']}
                    onChange={({ selectedItem }) => {
                        dispatch({
                            type: 'update',
                            payload: {
                                urgency: selectedItem,
                            },
                        });
                    }}
                />
            </div>

            {/* Screenshots */}
            <div className="cds--file__container mt-3">
                <FileUploader
                    data-testid="screenshot-uploader"
                    labelTitle="Screenshots (if applicable)"
                    labelDescription=""
                    buttonLabel="Add file"
                    buttonKind="tertiary"
                    size="md"
                    filenameStatus="edit"
                    accept={['.jpg', '.png']}
                    multiple={true}
                    iconDescription="Delete file"
                    name=""
                    onChange={(e) => {
                        dispatch({
                            type: 'add-file',
                            payload: {
                                type: 'screenshots',
                                file: e.target.files,
                            },
                        });
                    }}
                    onDelete={(e) => {
                        let fileName = '';
                        if (e.target.tagName === 'svg') {
                            fileName = e.target.parentElement.ariaLabel.split(' - ')[1];
                        } else {
                            fileName = e.target.ariaLabel.split(' - ')[1];
                        }
                        dispatch({
                            type: 'delete-file',
                            payload: {
                                type: 'screenshots',
                                fileName: fileName,
                            },
                        });
                    }}
                />
            </div>

            {/* File Upload */}
            <div className="cds--file__container">
                <FileUploader
                    data-testid="file-uploader"
                    labelTitle="File Upload (if applicable)"
                    labelDescription=""
                    buttonLabel="Add file"
                    buttonKind="tertiary"
                    size="md"
                    filenameStatus="edit"
                    accept={['.xlsx', '.zip']}
                    multiple={true}
                    iconDescription="Delete file"
                    name=""
                    onChange={(e) => {
                        dispatch({
                            type: 'add-file',
                            payload: {
                                type: 'files',
                                file: e.target.files,
                            },
                        });
                    }}
                    onDelete={(e) => {
                        let fileName = '';
                        if (e.target.tagName === 'svg') {
                            fileName = e.target.parentElement.ariaLabel.split(' - ')[1];
                        } else {
                            fileName = e.target.ariaLabel.split(' - ')[1];
                        }
                        dispatch({
                            type: 'delete-file',
                            payload: {
                                type: 'files',
                                fileName: fileName,
                            },
                        });
                    }}
                />
            </div>
            <Button className="mt-3 submit-button" type="submit" disabled={formStatus === formStatuses.LOADING}>
                Submit
            </Button>
        </Form>
    );
};
