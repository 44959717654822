// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-scenarios-content {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}
.manage-scenarios-content > .cds--tab-content {
  flex: 1 1 100%;
}
.manage-scenarios-content .cds--data-table-content {
  max-height: 400px;
}
.manage-scenarios-content .approve-scenario-modal,
.manage-scenarios-content .share-scenario-modal,
.manage-scenarios-content .revoke-approval-modal {
  min-height: 200px;
}
.manage-scenarios-content .unit-details-info {
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
}
.manage-scenarios-content .error_notification--container .failed-scenarios-list {
  margin-bottom: 4rem;
}
.manage-scenarios-content .error_notification--container .failed-scenarios-list li {
  color: var(--cds-text-inverse, #ffffff);
}
.manage-scenarios-content .back-to-admin-btn {
  position: absolute;
  right: var(--cds-layout-density-padding-inline-local);
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/ManageScenarios/ManageScenarios.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACI;EACI,cAAA;AACR;AAEI;EACI,iBAAA;AAAR;AAGI;;;EAGI,iBAAA;AADR;AAII;EACI,aAAA;EACA,SAAA;EACA,eAAA;AAFR;AAMQ;EACI,mBAAA;AAJZ;AAMY;EACI,uCAAA;AAJhB;AASI;EACI,kBAAA;EACA,qDAAA;EACA,UAAA;AAPR","sourcesContent":[".manage-scenarios-content {\n    flex: 1 1 100%;\n    display: flex;\n    flex-direction: column;\n\n    &>.cds--tab-content {\n        flex: 1 1 100%;\n    }\n\n    .cds--data-table-content {\n        max-height: 400px;\n    }\n\n    .approve-scenario-modal,\n    .share-scenario-modal,\n    .revoke-approval-modal {\n        min-height: 200px;\n    }\n\n    .unit-details-info {\n        display: flex;\n        gap: 4rem;\n        flex-wrap: wrap;\n    }\n\n    .error_notification--container {\n        .failed-scenarios-list {\n            margin-bottom: 4rem;\n\n            & li {\n                color: var(--cds-text-inverse, #ffffff);\n            }\n        }\n    }\n\n    .back-to-admin-btn {\n        position: absolute;\n        right: var(--cds-layout-density-padding-inline-local);\n        z-index: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
