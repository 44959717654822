// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeout-alert {
  z-index: 9500;
}
.timeout-alert .main-content {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/common/TimeoutAlert.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,aAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,sBAAA;AACR","sourcesContent":[".timeout-alert {\n    z-index: 9500;\n\n    .main-content {\n        padding: 2rem;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
