import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';

export const mxEquationsAtom = atom({
    l_id: '',
    wc_id: '',
    warCheck: false,
    peaceCheck: false,
    aefCheck: false,
    modelName: '',
    notes: '',
    paa: 0,
    source: '',
    poc: '',
});

export const updateMxEquationsAtom = atom(
    (get) => get(mxEquationsAtom),
    (get, set, update) => {
        const currentState = get(mxEquationsAtom);
        set(mxEquationsAtom, {
            ...currentState,
            ...update,
        });
    }
);

export const mxWorkcentersAtom = atom([]);
export const paiReqAtom = atom({});
export const hasPaiReqAtom = atom((get) => {
    return Object.keys(get(paiReqAtom)).length > 0;
});
export const editedFulltimeWorkcentersAtom = atom({
    edited: false,
    isEditable: false,
    mxId: null,
});

export const mxWorkcentersInvalidInputAtom = atom(false);
export const mxConditionalAtom = atom({
    SPA: [
        {
            OSC: 'MXAAA',
            FAC: '22M17P',
            AFSC: '2A3X5',
            PAI: 12,
            REQ: 23,
            SPA_VALUE: 4.65,
            MISSION_TYPE: 'Combat',
        },
        {
            OSC: 'MXAAA',
            FAC: '22M17P',
            AFSC: '2A3X5',
            PAI: 15,
            REQ: 38,
            SPA_VALUE: 3,
            MISSION_TYPE: 'Training',
        },
    ],
    VARIANCES: [
        {
            FAC: '31B100',
            NOTES: 'Mobility Air Force (MAF) wings with large PAA (10-14) - 1 per each aviation AFSC',
            ILC: 'MUHJ',
            IMPACT: 1,
        },
        {
            FAC: '31B100',
            NOTES: 'Mobility Air Force (MAF) wings with large PAA (10-14) - 1 per each aviation AFSC',
            ILC: 'MUHJ',
            IMPACT: 1,
        },
    ],
});

//Zooms in on pieces of mxEquationsAtom
export const l_idAtom = focusAtom(mxEquationsAtom, (optics) => optics.prop('l_id'));
export const wc_idAtom = focusAtom(mxEquationsAtom, (optics) => optics.prop('wc_id'));
export const warCheckAtom = focusAtom(mxEquationsAtom, (optics) => optics.prop('warCheck'));
export const peaceCheckAtom = focusAtom(mxEquationsAtom, (optics) => optics.prop('peaceCheck'));
export const aefCheckAtom = focusAtom(mxEquationsAtom, (optics) => optics.prop('aefCheck'));
export const modelNameAtom = focusAtom(mxEquationsAtom, (optics) => optics.prop('modelName'));
export const notesAtom = focusAtom(mxEquationsAtom, (optics) => optics.prop('notes'));
export const paaAtom = focusAtom(mxEquationsAtom, (optics) => optics.prop('paa'));
export const sourceAtom = focusAtom(mxEquationsAtom, (optics) => optics.prop('source'));
export const pocAtom = focusAtom(mxEquationsAtom, (optics) => optics.prop('poc'));

//zooms in on pieces of mxConditionalAtom
export const SPAAtom = focusAtom(mxConditionalAtom, (optics) => optics.prop('SPA'));
export const VARIANCESAtom = focusAtom(mxConditionalAtom, (optics) => optics.prop('VARIANCES'));

export function mxGetZoomedPropertyAtom(label) {
    switch (label) {
        case 'l_id':
            return l_idAtom;
        case 'wc_id':
            return wc_idAtom;
        case 'warCheck':
            return warCheckAtom;
        case 'peaceCheck':
            return peaceCheckAtom;
        case 'aefCheck':
            return aefCheckAtom;
        case 'modelName':
            return modelNameAtom;
        case 'notes':
            return notesAtom;
        case 'paa':
            return paaAtom;
        case 'SPA':
            return SPAAtom;
        case 'VARIANCES':
            return VARIANCESAtom;
        case 'source':
            return sourceAtom;
        case 'poc':
            return pocAtom;
        default:
            console.error('Incorrect label');
    }
}

export const optionBTabs = Object.freeze({
    peace: {
        text: 'Peace',
        key: 'peace',
        index: 0,
        type: 'root',
    },
    fol: {
        text: 'Split OPs Home',
        key: 'fol',
        index: 1,
        folIndex: 0,
        type: 'fol',
    },
    fol1: {
        text: 'FOL1',
        key: 'fol1',
        index: 1,
        folIndex: 1,
        type: 'fol',
    },
    fol2: {
        text: 'FOL2',
        key: 'fol2',
        index: 1,
        folIndex: 2,
        type: 'fol',
    },
    fol3: {
        text: 'FOL3',
        key: 'fol3',
        index: 1,
        folIndex: 3,
        type: 'fol',
    },
    war: {
        text: 'War',
        key: 'war',
        index: 2,
        type: 'root',
    },
});

export function getTabByIndex(index, isRootLevel) {
    if (isRootLevel) {
        switch (index) {
            case optionBTabs.peace.index:
                return optionBTabs.peace;
            case optionBTabs.fol.index:
                return optionBTabs.fol1;
            case optionBTabs.war.index:
            default:
                return optionBTabs.war;
        }
    }
    switch (index) {
        case optionBTabs.fol.folIndex:
            return optionBTabs.fol;
        case optionBTabs.fol1.folIndex:
            return optionBTabs.fol1;
        case optionBTabs.fol2.folIndex:
            return optionBTabs.fol2;
        case optionBTabs.fol3.folIndex:
        default:
            return optionBTabs.fol3;
    }
}

export const initialAdvancedEquationsState = {
    maf: 0,
    type: optionBTabs.peace.key,
};
export const advancedEquationsAtom = atom(initialAdvancedEquationsState);

export const initialOptionBTableRow = {
    A: 0,
    B1: 0,
    B2: 0,
    X2: 0,
    B3: 0,
    X3: 0,
    B4: 0,
    X4: 1,
    fac: '',
    afsc: '',
    osc: '',
    wc_title: '',
    MIN_REQ: 0,
};

export const initialOptionBTableState = {
    showSummary: false,
    tableRows: [],
};
export const optionBTableAtom = atom(initialOptionBTableState);

export const mxScenarioAtom = atom([]);
export const insertEntryMxScenarioAtom = atom(
    (get) => get(mxScenarioAtom),
    (get, set, newEntry) => {
        set(mxScenarioAtom, [...get(mxScenarioAtom), newEntry]);
    }
);
