import React, { useEffect, useState } from 'react';
import { Loading, Button } from '@carbon/react';

import { JsonToExcel } from '../../../helpers';
import { fetchUnits } from '../adminApiCalls';

/**
 *
 * @param {object} row
 * @returns
 */
const filterOutIds = ({ id, ...details }) => {
    return {
        ...details,
    };
};

export const MissingUnitsDownload = () => {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchUnitsDownload() {
            setIsLoading(true);
            const { success, missing, transit } = await fetchUnits();
            const xlsx = new JsonToExcel();
            setIsLoading(false);
            if (success) {
                xlsx.addWorksheet('Missing Units', missing.map(filterOutIds));
                xlsx.addWorksheet('Units In Transit', transit.map(filterOutIds));

                xlsx.exportFile('UMP Missing Units');
            }
        }

        fetchUnitsDownload();
    }, []);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="align-self-center">
            <div
                className="mb-3"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '30vh',
                }}
            >
                <h1 className="fw-bold mb-5" style={{ marginTop: 'auto' }}>
                    Guardian Download Page
                </h1>
                <p className="d-block pt-3 mb-4">Download completed</p>
                <Button
                    onClick={() => {
                        window.open('', '_parent', '');
                        window.close();
                    }}
                >
                    Close window
                </Button>
            </div>
        </div>
    );
};
