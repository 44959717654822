import React from 'react';
import PropTypes from 'prop-types';
import { NotebookReference } from '@carbon/react/icons';
import { Tooltip } from '@carbon/react';

const downloadUserGuidePropTypes = {
    kind: PropTypes.oneOf(['primary', 'tertiary']),
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof downloadUserGuidePropTypes>>}
 * @returns {React.ReactElement}
 */
export const DownloadUserGuide = ({ kind }) => {
    return (
        <Tooltip className="cds--icon-tooltip" label="Download UMP user guide" align="left" enterDelayMs={1000}>
            <a
                data-testid="download-user-guide-btn"
                href="/api/protected-assets/Rhombus_Unified_Manpower_Platform_User_Guide_9_03_24.pdf"
                download="Rhombus Unified Manpower Platform User Guide.pdf"
                className={`cds--btn--icon-only me-2 cds--btn cds--btn--md cds--layout--size-md ${
                    kind === 'primary' ? 'cds--btn--primary' : 'cds--btn--tertiary'
                } me-2`}
            >
                <NotebookReference />
            </a>
        </Tooltip>
    );
};

DownloadUserGuide.propTypes = downloadUserGuidePropTypes;
