import React from 'react';
import PropTypes from 'prop-types';
import { InlineNotification } from '@carbon/react';

const bannerTypes = {
    unconventional: {
        title: 'This scenario has non-conventional Unit, PEC, FAC information in MPES/UMD and Model Calculations',
        color: 'green-background',
    },
    missing: {
        title: 'This scenario has missing Unit, PEC, FAC information in MPES/UMD and Model Calculations',
        color: 'amber-background',
    },
    tfa: {
        title: 'This scenario is associated with TFA/Host-Support/CRF',
        color: 'blue-background',
    },
};

const bannerPropTypes = {
    type: PropTypes.oneOf(['unconventional', 'missing', 'tfa']).isRequired,
    className: PropTypes.string,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof bannerPropTypes>>}
 * @returns {React.ReactElement}
 */
export const Banner = ({ type, wrapperClassName = '', className = '' }) => {
    return (
        <div className={`banner ${wrapperClassName}`}>
            <InlineNotification
                title={bannerTypes[type].title}
                subtitle=""
                className={`${className} ${bannerTypes[type].color}`}
                kind="warning"
            />
        </div>
    );
};

Banner.propTypes = bannerPropTypes;
