import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Datatable, refreshTable } from '../../utils/Datatable';

const headers = [
    {
        header: 'ID', // Display on UI table header
        key: 'id', // id to link row with header
        format: (row) => {
            return JSON.stringify(row.id);
        }, // format of how the column should display
    },
    {
        key: 'hash',
        header: 'Hash',
        format: (row) => {
            return row.hash;
        },
    },
    {
        key: 'timestamp',
        header: 'Created On',
        format: (row) => {
            return row.timestamp;
        },
    },
    {
        key: 'userID',
        header: 'Created By',
        format: (row) => {
            return row.userID;
        },
    },
    {
        key: 'status',
        header: 'Status',
        format: (row) => {
            return row.status;
        },
    },
    {
        key: 'userTimestamp',
        header: 'User Timestamp',
        format: (row) => {
            return row.userTimestamp;
        },
    },
];

export const CodeIntegrityTable = () => {
    // DATATABLE PROPS
    const [, setRows] = useState([]); // raw table data
    const [formattedRows, setFormattedRows] = useState([]); // table data formatted for UI look
    const [datatableStatus, setDatatableStatus] = useState('loading');

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.post('/api/codeIntegrity');
                refreshTable(res.data, headers, setRows, setFormattedRows);
                setDatatableStatus(!(res.data.length === 0));
            } catch (error) {
                console.log(error.response.data.errorMessage);
                setDatatableStatus(false);
            }
        })();
    }, []);

    return (
        <>
            <Datatable
                headers={headers}
                rows={formattedRows}
                tableTitle="Code Integrity"
                datatableStatus={datatableStatus}
            />
        </>
    );
};
