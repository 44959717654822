import { SkeletonText, TableBody, TableCell, TableRow } from '@carbon/react';
import React from 'react';
import PropTypes from 'prop-types';

export const TableLoading = ({ headers }) => {
    return (
        <TableBody data-testid="table-loading">
            <TableRow>
                {headers.map((header, idx) => (
                    <TableCell key={`loading_${header}_${idx}`}>
                        <SkeletonText />
                    </TableCell>
                ))}
            </TableRow>
        </TableBody>
    );
};

TableLoading.propTypes = {
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
        })
    ).isRequired,
};
