import { ComboBox, Modal } from '@carbon/react';
import './modal.scss';
import { atom, useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { scenarioAtom } from '../../../../hooks/wizardAtoms';
import { useFocusAtom } from '../../../../hooks';
import {
    CMDFiltersAtom,
    ILCFiltersAtom,
    MDSFiltersAtom,
} from '../../steps/generalSettings/GeneralSettingsAtoms';
import { comboBoxShouldFilterItem } from '../../../../helpers';
import axios from 'axios';

const addUnitModalStateAtom = atom({});

export const AddUnitModal = ({ open, onClose, populateTable }) => {
    const [invalid, setInvalid] = useState({
        CMD: false,
        ILC: false,
        MDS: false,
        UNIT: false,
        MP_TYPE: false,
    });
    const [units, setUnits] = useState([]);
    const [CMDFilters] = useAtom(CMDFiltersAtom);
    const [MDSFilters] = useAtom(MDSFiltersAtom);
    const [ILCFilters] = useAtom(ILCFiltersAtom);
    const [addUnitModalState, setAddUnitModalState] = useAtom(
        addUnitModalStateAtom
    );
    const [scenario_id] = useFocusAtom(scenarioAtom, 'scenario_id');

    const CMD = addUnitModalState['CMD'];

    const isValid =
        addUnitModalState['CMD'] &&
        addUnitModalState['ILC'] &&
        addUnitModalState['MDS'] &&
        addUnitModalState['MP_TYPE'] &&
        addUnitModalState['UNIT'];

    const handleCloseModal = () => {
        setAddUnitModalState({});
        onClose();
    };

    const handleSubmit = () => {
        if (
            !addUnitModalState['CMD'] ||
            !addUnitModalState['ILC'] ||
            !addUnitModalState['MDS'] ||
            !addUnitModalState['MP_TYPE'] ||
            !addUnitModalState['UNIT']
        ) {
            setInvalid({
                CMD: !addUnitModalState['CMD'] ? true : false,
                ILC: !addUnitModalState['ILC'] ? true : false,
                MDS: !addUnitModalState['MDS'] ? true : false,
                MP_TYPE: !addUnitModalState['MP_TYPE'] ? true : false,
                UNIT:
                    addUnitModalState['CMD'] && !addUnitModalState['UNIT']
                        ? true
                        : false,
            });
            return;
        }
        createUnit(scenario_id, addUnitModalState);
        handleCloseModal();
    };

    const createUnit = async (scenario_id, input) => {
        if (isValid) {
            try {
                const { res } = await axios.post(
                    `/api/fast-api/filters/create_unit`,
                    {
                        SCENARIO_ID: scenario_id,
                        CMD: input.CMD,
                        ILC: input.ILC,
                        MDS: input.MDS,
                        UNIT: input.UNIT,
                        MP_TYPE: input.MP_TYPE,
                    }
                );
                return res;
            } catch (error) {
                console.error(error);
            } finally {
                populateTable();
            }
        }
    };

    const fetchUnits = useCallback(async () => {
        if (CMD) {
            try {
                const { data } = await axios.post(
                    `/api/fast-api/filters/pas_unit`,
                    {
                        CMD,
                    }
                );
                if (data) {
                    setUnits(data);
                }
                return data;
            } catch (error) {
                console.error(error);
            }
        }
    }, [CMD]);

    useEffect(() => {
        fetchUnits();
    }, [fetchUnits]);

    return (
        <Modal
            open={open}
            modalHeading="Add Unit"
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
            onRequestClose={handleCloseModal}
            onRequestSubmit={handleSubmit}
        >
            <div className="row">
                <div className="col-4" data-qa="aum-CMD-dropdown-select">
                    <ComboBox
                        id={`CMD-dropdown`}
                        titleText={'CMD'}
                        items={CMDFilters}
                        selectedItem={addUnitModalState['CMD'] ?? ''}
                        invalid={invalid['CMD']}
                        invalidText="Please select a CMD."
                        shouldFilterItem={comboBoxShouldFilterItem}
                        onChange={({ selectedItem }) => {
                            setInvalid({
                                ...invalid,
                                CMD: false,
                            });
                            setAddUnitModalState({
                                ...addUnitModalState,
                                CMD: selectedItem,
                            });
                        }}
                    />
                </div>
                <div className="col-4" data-qa="aum-ILC-dropdown-select">
                    <ComboBox
                        id={`ILC-dropdown`}
                        titleText={'ILC'}
                        items={ILCFilters}
                        invalid={invalid['ILC']}
                        invalidText="Please select a ILC."
                        selectedItem={addUnitModalState['ILC'] ?? ''}
                        shouldFilterItem={comboBoxShouldFilterItem}
                        onChange={({ selectedItem }) => {
                            setInvalid({
                                ...invalid,
                                ILC: false,
                            });
                            setAddUnitModalState({
                                ...addUnitModalState,
                                ILC: selectedItem,
                            });
                        }}
                    />
                </div>
                <div className="col-4" data-qa="aum-MDS-dropdown-select">
                    <ComboBox
                        id={`MDS-dropdown`}
                        titleText={'MDS'}
                        items={MDSFilters}
                        invalid={invalid['MDS']}
                        invalidText="Please select a MDS."
                        selectedItem={addUnitModalState['MDS'] ?? ''}
                        shouldFilterItem={comboBoxShouldFilterItem}
                        onChange={({ selectedItem }) => {
                            setInvalid({
                                ...invalid,
                                MDS: false,
                            });
                            setAddUnitModalState({
                                ...addUnitModalState,
                                MDS: selectedItem,
                            });
                        }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-4" data-qa="aum-MP-dropdown-select">
                    <ComboBox
                        id={`MP-type`}
                        titleText={'MP Type'}
                        label=""
                        items={['MX', 'OPS']}
                        invalid={invalid['MP_TYPE']}
                        invalidText="Please select a MP type."
                        selectedItem={addUnitModalState['MP_TYPE'] ?? ''}
                        shouldFilterItem={comboBoxShouldFilterItem}
                        onChange={({ selectedItem }) => {
                            setInvalid({
                                ...invalid,
                                MP_TYPE: false,
                            });
                            setAddUnitModalState({
                                ...addUnitModalState,
                                MP_TYPE: selectedItem,
                            });
                        }}
                    />
                </div>
                <div className="col-5" data-qa="aum-UNIT-dropdown-select">
                    <ComboBox
                        className="unit-dropdown"
                        id={`unit-dropdown`}
                        disabled={!addUnitModalState['CMD']}
                        titleText={'Unit'}
                        items={units}
                        invalid={invalid['UNIT']}
                        invalidText="Please select a Unit."
                        selectedItem={addUnitModalState['UNIT'] ?? ''}
                        shouldFilterItem={comboBoxShouldFilterItem}
                        onChange={({ selectedItem }) => {
                            setInvalid({
                                ...invalid,
                                UNIT: false,
                            });
                            setAddUnitModalState({
                                ...addUnitModalState,
                                UNIT: selectedItem,
                            });
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};
