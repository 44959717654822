// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-models-content .back-to-admin-btn {
  position: absolute;
  right: var(--cds-layout-density-padding-inline-local);
  z-index: 1;
}
.manage-models-content .filter-dropdowns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.manage-models-content .filter-dropdowns > div {
  padding-right: 2rem;
  width: 100%;
}
.manage-models-content .cds--accordion__wrapper {
  background-color: var(--cds-background);
}
.manage-models-content .cds--accordion__wrapper .cds--accordion__content {
  padding-inline-end: 1rem !important;
}
.manage-models-content .accordion-title {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin: 1rem 0;
  align-items: center;
}
.manage-models-content .accordion-title > div {
  font-size: 1.1rem;
  height: fit-content;
}
.manage-models-content .table-row-model-name {
  display: flex;
  align-items: center;
  height: inherit;
  text-wrap: nowrap;
}
.manage-models-content .table-row-model-view {
  height: fit-content;
  min-height: fit-content;
}
.manage-models-content .archive-success-notification {
  position: fixed;
  top: 10%;
  right: 50%;
  left: 50%;
  z-index: 9999;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/ManageModels/ManageModels.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,qDAAA;EACA,UAAA;AAAR;AAGI;EACI,aAAA;EACA,8BAAA;AADR;AAGQ;EACI,mBAAA;EACA,WAAA;AADZ;AAKI;EACI,uCAAA;AAHR;AAKQ;EACI,mCAAA;AAHZ;AAOI;EACI,aAAA;EACA,kCAAA;EACA,WAAA;EACA,cAAA;EACA,mBAAA;AALR;AAOQ;EACI,iBAAA;EACA,mBAAA;AALZ;AASI;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AAPR;AAUI;EACI,mBAAA;EACA,uBAAA;AARR;AAWI;EACI,eAAA;EACA,QAAA;EACA,UAAA;EACA,SAAA;EACA,aAAA;AATR","sourcesContent":[".manage-models-content {\n    .back-to-admin-btn {\n        position: absolute;\n        right: var(--cds-layout-density-padding-inline-local);\n        z-index: 1;\n    }\n\n    .filter-dropdowns {\n        display: grid;\n        grid-template-columns: 1fr 1fr;\n\n        &>div {\n            padding-right: 2rem;\n            width: 100%;\n        }\n    }\n\n    .cds--accordion__wrapper {\n        background-color: var(--cds-background);\n\n        .cds--accordion__content {\n            padding-inline-end: 1rem !important;\n        }\n    }\n\n    .accordion-title {\n        display: grid;\n        grid-template-columns: 1fr 1fr 1fr;\n        width: 100%;\n        margin: 1.0rem 0;\n        align-items: center;\n\n        &>div {\n            font-size: 1.1rem;\n            height: fit-content;\n        }\n    }\n\n    .table-row-model-name {\n        display: flex;\n        align-items: center;\n        height: inherit;\n        text-wrap: nowrap;\n    }\n\n    .table-row-model-view {\n        height: fit-content;\n        min-height: fit-content;\n    }\n\n    .archive-success-notification {\n        position: fixed;\n        top: 10%;\n        right: 50%;\n        left: 50%;\n        z-index: 9999;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
