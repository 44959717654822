import React, { useState } from 'react';
import { TextInput } from '@carbon/react';
import { InputValidator } from '../../helpers';

export const TextField = ({ textInput = {}, type = 'text', setState = () => {}, validation = false }) => {
    const [invalidText, setInvalidText] = useState();

    const handleChange = (e) => {
        const typedText = e.target.value;
        if (validation === true) {
            const conditions = InputValidator({ type: type, value: typedText });
            switch (type) {
                case 'name':
                    if (typedText.length < 70) {
                        if (!conditions.lessThanSixty) {
                            setInvalidText('Use less than 60 characters to write your name.');
                        } else if (!conditions.characterConditionsMet) {
                            setInvalidText('You can use letters, single hyphens and single spaces.');
                        } else if (!conditions.hasThreeLetters) {
                            setInvalidText('Enter your name using at least 3 letters.');
                        }

                        const valid = conditions.allConditionsMet || typedText === '';
                        setState({
                            value: typedText,
                            invalid: !valid,
                        });
                    }
                    break;
                case 'token':
                    if (conditions.lessThan42) {
                        if (conditions.onlyNumbersLetters === false) {
                            setInvalidText('Your token should only consist of letters and numbers.');
                        }
                        const conditionsMet = conditions.onlyNumbersLetters;
                        const valid = conditionsMet || typedText === '';
                        setState({
                            value: typedText,
                            invalid: !valid,
                        });
                    }
                    break;
                default:
            }
        } else {
            setState({ value: e.target.value });
        }
    };

    const textInputProps = {
        invalidText,
        labelText: 'Name',
        id: `${type}${Math.random().toString()}`,
        // hideLabel: true,
        onChange: (e) => handleChange(e),
        ...textInput,
    };

    return <TextInput {...textInputProps} />;
};
