import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { OneTimeTokenLayout } from '../../utils';
import axios from 'axios';

export const AdminActivateAccount = () => {
    const params = useParams();

    const [token, setToken] = useState({ value: '' });
    const [response, setResponse] = useState('');

    useEffect(() => {
        (async () => {
            await global.GetCSRFToken();
            if (params.token && params.token !== ':token') {
                setToken({
                    value: params.token,
                    invalid: false,
                });
            }
            try {
                const res = await axios.post(
                    '/api/adminActivateAccount',
                    {
                        adminActivationToken: params.token,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
                setResponse(res.data.message);
            } catch (err) {
                setResponse(err.response.data.errorMessage);
            }
        })();
    }, []);

    return (
        <OneTimeTokenLayout
            type="adminActivation"
            homeButton="true"
            children={response}
        />
    );
};
