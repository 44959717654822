import React, { useReducer, useState, useMemo } from 'react';
import { ButtonUtil, IconButtonUtil, PostResponseNotification, SelectUtil, TextField } from '../../../../utils';
import { TrashCan } from '@carbon/icons-react';
import { selectionList } from './selectionList';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const getEmptyRequirementContribution = () => {
    const initRequirementContribution = {
        unit: 'placeholder-item',
        wc: 'placeholder-item',
        off: 0,
        enl: 0,
        civ: 0,
        total: 0,
        pec: 'placeholder-item',
    };

    return {
        ...initRequirementContribution,
        rowId: Math.ceil(Math.random() * 1000000000000),
    };
};

const formatRequirementContribution = (data) => {
    return data.map((datum) => {
        return {
            ...datum,
            rowId: Math.ceil(Math.random() * 1000000000000),
        };
    });
};

const emptyRequirementContribution = [getEmptyRequirementContribution()];

function requirementContributionReducer(state, action) {
    switch (action.type) {
        case 'modify':
            const { rowId, columnName, newValue } = action;
            return state.map((reqContributionState) => {
                const { rowId: stateRowId } = reqContributionState;
                if (stateRowId === rowId) {
                    return {
                        ...reqContributionState,
                        [columnName]: newValue,
                    };
                }
                return reqContributionState;
            });
        case 'add':
            const newReqContribution = getEmptyRequirementContribution();
            return [...state, newReqContribution];
        case 'delete':
            const { deleteRowNumber } = action;
            const newState = state.filter(({ rowId }) => {
                return rowId !== deleteRowNumber;
            });
            return newState;
        default:
            throw new Error('Wrong reducer action type provided');
    }
}

export function RequirementContributions({ setIsCompleteTfiContributionInput, data }) {
    const formattedReqContribution = formatRequirementContribution(data);
    const initialRequirementContribution = data.length > 0 ? formattedReqContribution : emptyRequirementContribution;
    const [reqContributions, dispatchReqContributions] = useReducer(
        requirementContributionReducer,
        initialRequirementContribution
    );
    const [showAddErrorReqContribution, setShowAddErrorReqContribution] = useState(false);
    const [showDeleteErrorReqContribution, setShowDeleteErrorReqContribution] = useState(false);

    const handleDeleteReqContribution = (deleteRowNumber) => {
        setShowAddErrorReqContribution(false);
        setShowDeleteErrorReqContribution(false);
        if (reqContributions.length <= 1) {
            setShowDeleteErrorReqContribution(true);
        } else {
            dispatchReqContributions({ type: 'delete', deleteRowNumber });
        }
    };

    const hasMissingRequiredContributionsField = useMemo(() => {
        const requiredFields = ['unit', 'wc', 'pec'];
        const oneRowIsMissingField = reqContributions
            .map((reqContribution) => {
                const isMissingField = requiredFields.some(
                    (requiredField) => reqContribution[requiredField] === 'placeholder-item'
                );
                return isMissingField;
            })
            .some((fieldMissingBoolean) => fieldMissingBoolean);
        return oneRowIsMissingField;
    }, [reqContributions]);

    const handleCloseAddReqContributionNotification = () => {
        setShowAddErrorReqContribution(false);
    };

    const handleCloseDeleteReqContributionNotification = () => {
        setShowDeleteErrorReqContribution(false);
    };

    const handleAddReqContribution = (e) => {
        e.preventDefault();
        setShowAddErrorReqContribution(false);
        setShowDeleteErrorReqContribution(false);
        if (hasMissingRequiredContributionsField) {
            setShowAddErrorReqContribution(true);
        } else {
            dispatchReqContributions({ type: 'add' });
        }
    };

    useEffect(() => {
        setIsCompleteTfiContributionInput(!hasMissingRequiredContributionsField);
    }, [hasMissingRequiredContributionsField, setIsCompleteTfiContributionInput]);

    return (
        <div id="requirement-contribution-section" data-testid="requirement-contribution-section">
            <div className="mb-2">
                <h5>Requirement Contribution</h5>
            </div>
            {showDeleteErrorReqContribution && (
                <PostResponseNotification
                    data={{
                        status: 'error',
                        errorMessage: 'Cannot delete. Required at least one.',
                    }}
                    inlineNotification={{
                        hideCloseButton: false,
                        onCloseButtonClick: handleCloseDeleteReqContributionNotification,
                    }}
                />
            )}
            {showAddErrorReqContribution && (
                <PostResponseNotification
                    data={{
                        status: 'error',
                        errorMessage: 'Cannot add. Required parameters should have values.',
                    }}
                    inlineNotification={{
                        hideCloseButton: false,
                        onCloseButtonClick: handleCloseAddReqContributionNotification,
                    }}
                />
            )}
            {reqContributions.map((reqContribution, rowNumber) => {
                const hideLabel = rowNumber > 0 ? true : false;
                const { rowId } = reqContribution;
                return (
                    <div key={rowId} className="d-flex" data-testid={`requirement-contribution-row-${rowNumber}`}>
                        <SelectUtil
                            select={{
                                hideLabel,
                                labelText: 'Primary Unit',
                                size: 'sm',
                                defaultValue: reqContribution['unit'],
                                value: undefined,
                                className: 'mb-3',
                                onChange: (e) =>
                                    dispatchReqContributions({
                                        type: 'modify',
                                        rowId,
                                        columnName: 'unit',
                                        newValue: e.target.value,
                                    }),
                            }}
                            options={selectionList.unitOptions}
                        />
                        <SelectUtil
                            select={{
                                hideLabel,
                                labelText: 'Worker Center',
                                size: 'sm',
                                defaultValue: reqContribution['wc'],
                                value: undefined,
                                className: 'mb-3',
                                onChange: (e) =>
                                    dispatchReqContributions({
                                        type: 'modify',
                                        rowId,
                                        columnName: 'wc',
                                        newValue: e.target.value,
                                    }),
                            }}
                            options={selectionList.workCenterOptions}
                        />
                        <TextField
                            textInput={{
                                hideLabel,
                                type: 'number',
                                labelText: 'OFF',
                                size: 'sm',
                                defaultValue: reqContribution['off'],
                                onChange: (e) =>
                                    dispatchReqContributions({
                                        type: 'modify',
                                        rowId,
                                        columnName: 'off',
                                        newValue: e.target.value,
                                    }),
                            }}
                        />
                        <TextField
                            textInput={{
                                hideLabel,
                                type: 'number',
                                labelText: 'ENL',
                                size: 'sm',
                                defaultValue: reqContribution['enl'],
                                onChange: (e) =>
                                    dispatchReqContributions({
                                        type: 'modify',
                                        rowId,
                                        columnName: 'enl',
                                        newValue: e.target.value,
                                    }),
                            }}
                        />
                        <TextField
                            textInput={{
                                hideLabel,
                                type: 'number',
                                labelText: 'CIV',
                                size: 'sm',
                                defaultValue: reqContribution['civ'],
                                onChange: (e) =>
                                    dispatchReqContributions({
                                        type: 'modify',
                                        rowId,
                                        columnName: 'civ',
                                        newValue: e.target.value,
                                    }),
                            }}
                        />
                        <TextField
                            textInput={{
                                hideLabel,
                                type: 'number',
                                labelText: 'TOTAL',
                                size: 'sm',
                                defaultValue: reqContribution['total'],
                                onChange: (e) =>
                                    dispatchReqContributions({
                                        type: 'modify',
                                        rowId,
                                        columnName: 'total',
                                        newValue: e.target.value,
                                    }),
                            }}
                        />
                        <SelectUtil
                            select={{
                                hideLabel,
                                labelText: 'PEC',
                                size: 'sm',
                                defaultValue: reqContribution['pec'],
                                value: undefined,
                                className: 'mb-3',
                                onChange: (e) =>
                                    dispatchReqContributions({
                                        type: 'modify',
                                        rowId,
                                        columnName: 'pec',
                                        newValue: e.target.value,
                                    }),
                            }}
                            options={selectionList.pecOptions}
                        />
                        <IconButtonUtil
                            iconButton={{
                                children: <TrashCan />,
                                label: 'Delete',
                                size: 'sm',
                                kind: 'tertiary',
                                className: `p-2 ${hideLabel ? '' : 'mt-4'}`,
                                onClick: () => handleDeleteReqContribution(rowId),
                            }}
                        />
                    </div>
                );
            })}
            <div className="d-flex justify-content-end">
                <ButtonUtil
                    button={{
                        children: `Add Row +`,
                        kind: 'tertiary',
                        size: 'sm',
                        className: 'mb-3 ml-4',
                        onClick: handleAddReqContribution,
                    }}
                />
            </div>
        </div>
    );
}

RequirementContributions.propTypes = {
    setIsCompleteTfiContributionInput: PropTypes.func,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            civ: PropTypes.number,
            enl: PropTypes.number,
            off: PropTypes.number,
            pec: PropTypes.string,
            total: PropTypes.number,
            unit: PropTypes.string,
            wc: PropTypes.string,
        })
    ),
};
