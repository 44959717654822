import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@carbon/react';

import { cloneNewScenarioFromOld } from '../adminApiCalls';

import { ConfirmationModal } from '../../wizard/modals/confirmationModal/ConfirmationModal';

const cloneScenarioModalProps = {
    isOpen: PropTypes.bool,
    originalName: PropTypes.string,
    originalId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

/**
 * @type {React.FC<PropTypes.InferProps<typeof cloneScenarioModalProps>>} props
 * @returns {React.ReactElement}
 */
export const CloneScenarioModal = ({ isOpen, originalName, originalId, onClose, onSubmit }) => {
    const [newScenarioName, setNewScenarioName] = useState('');
    const [cloneIsInvalid, setCloneIsInvalid] = useState(false);

    return (
        <ConfirmationModal
            data-testid="clone-confirmation-modal"
            className="publish-confirmation-modal"
            open={isOpen}
            modalHeading={originalName}
            modalLabel="Create Scenario"
            primaryButtonText="Create Scenario"
            secondaryButtonText="Cancel"
            onClose={onClose}
            onSubmit={async () => {
                if (newScenarioName && originalId) {
                    const { isOk, scenarioId: clonedScenarioId } = await cloneNewScenarioFromOld({
                        scenarioId: originalId,
                        newScenarioName,
                    });
                    if (isOk) {
                        onSubmit({ scenarioId: clonedScenarioId, scenarioName: newScenarioName });
                        return;
                    }
                }
                setCloneIsInvalid(true);
                throw new Error('Could not create');
            }}
        >
            <p className="mb-3">Do you want to recreate this scenario?</p>
            <TextInput
                placeholder=""
                labelText="Scenario name"
                id="clone-unpublished-scenario"
                value={newScenarioName}
                invalid={cloneIsInvalid}
                invalidText={newScenarioName.length === 0 ? 'Required field' : 'Duplicate name'}
                onChange={(e) => {
                    setNewScenarioName(e.target.value ?? '');
                    setCloneIsInvalid(false);
                }}
            />
        </ConfirmationModal>
    );
};

CloneScenarioModal.propTypes = cloneScenarioModalProps;
