export const checkIfInputIsInvalid = (val, cellHeader) => {
    switch (cellHeader) {
        case 'FAC':
            return !validateFAC(val);
        case 'OSC':
            return !validateOSC(val);
        case 'AFSC':
            return !validateAFSC(val);
        case 'WC_TITLE':
        case 'FAC_TITLE':
            return !validateTitle(val);
        default:
            return false;
    }
};

export const checkAllInputIsValid = (workcenter) => {
    return (
        validateFAC(workcenter.FAC) &&
        validateAFSC(workcenter.AFSC) &&
        validateOSC(workcenter.OSC) &&
        (validateTitle(workcenter.WC_TITLE) || validateTitle(workcenter.FAC_TITLE))
    );
};

export const validateFAC = (val) => {
    const alphaNumeric = /^(\w)*$/;

    if (val && (val.length > 20 || !alphaNumeric.test(val))) {
        return false;
    }
    return true;
};

export const validateOSC = (val) => {
    const alphaNumeric = /^(\w)*$/;

    if (val && (val.length > 10 || !alphaNumeric.test(val))) {
        return false;
    }
    return true;
};

export const validateAFSC = (val) => {
    const alphaNumeric = /^(\w)*$/;

    if (val && (val.length > 6 || !alphaNumeric.test(val))) {
        return false;
    }
    return true;
};

export const validateTitle = (val) => {
    if (val && val.length > 200) {
        return false;
    }
    return true;
};

export const invalidTextBasedOnColumn = {
    FAC: 'Max length is 20 characters. Alphanumeric only',
    OSC: 'Max length is 10 characters. Alphanumeric only',
    AFSC: 'Max length is 6 characters. Alphanumeric only',
    WC_TITLE: 'Max length is 200 characters',
    FAC_TITLE: 'Max length is 200 characters',
};
