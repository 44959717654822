import axios from 'axios';

/**
 * @typedef {Object} GeneralSettingsScenario
 * @property {string} CMD
 * @property {string} MDS
 * @property {string} ILC
 * @property {{
 *  AR: number; BAI: number; PDAI: number; PMAI: number; PTAI: number;
 *  UNIT: string;
 * }} forceStructureTable
 */

/**
 * @typedef {{ [unitDetailsInput]: string }} UnitDetailsScenario
 */

/**
 * @typedef {{
 *  NTI: number;
 *  PEC: string;
 *  RIC: string;
 *  UNIT: string;
 *  ACR_ID: string | number;
 *  MX_UNIT: string;
 *  PAI_QTY: number;
 *  PAI_TYPE: string;
 * }[]} UnitModelScenario
 */

/**
 * @typedef {Object} Scenario
 * @property {(string|number)} scenario_id
 * @property {string} scenario_name
 * @property {string} LAST_MODIFIED
 * @property {?GeneralSettingsScenario} generalSettings
 * @property {?UnitDetailsScenario} unitDetails
 * @property {?UnitModelScenario} ops
 * @property {?UnitModelScenario} mx
 */

/**
 * Fetches Scenario detail data
 * @param {number} scenarioId
 * @returns {Promise<{
 *  isOk: boolean;
 *  data: Omit<Scenario, 'scenario_id'|'scenario_name'>;
 *  error: null | any;
 * }>}
 */
export const getScenario = async (scenario_id = 1) => {
    try {
        await global.GetCSRFToken();
        const response = await axios.post(`/api/fast-api/scenario/load/${scenario_id}`, null);

        if (response.data[0] === 200) {
            return {
                isOk: true,
                data: {
                    ...response.data[1],
                    LAST_MODIFIED: new Date(response.data[1].LAST_MODIFIED).toLocaleString('en-US'),
                },
                error: null,
            };
        }
        return {
            isOk: false,
            data: {
                ...response.data[1],
                LAST_MODIFIED: new Date(response.data[1].LAST_MODIFIED).toLocaleString('en-US'),
            },
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            data: {},
            error,
        };
    }
};

const scenarioStatusPriority = (status) => {
    switch (status) {
        case 'PENDING APPROVAL':
            return 3;
        case 'APPROVED':
            return 2;
        case 'PUBLISHED':
            return 1;
        case 'UNPUBLISHED':
            return 0;
        default:
            return -1;
    }
};

/**
 * Fetches list of all scenarios that belong to the current user
 * @returns {Promise<{
 *      isOk: boolean;
 *      scenarios: Omit<Scenario, 'generalSettings'|'unitDetails'|'ops'|'mx'>[];
 *      error: null | any;
 * }>}
 */
export const getAllScenarios = async () => {
    try {
        await global.GetCSRFToken();
        const { data } = await axios.post(`/api/fast-api/scenario/list`, null);
        return {
            isOk: true,
            error: null,
            scenarios: data
                .map(({ SCENARIO_ID, SCENARIO_NAME, LAST_MODIFIED, STATUS }) => ({
                    id: `${SCENARIO_ID}`,
                    scenario_id: SCENARIO_ID,
                    scenario_name: SCENARIO_NAME,
                    status: STATUS,
                    LAST_MODIFIED: new Date(LAST_MODIFIED).toLocaleString('en-US'),
                }))
                .sort((a, b) => {
                    const diff = scenarioStatusPriority(b.status) - scenarioStatusPriority(a.status);
                    if (diff === 0) {
                        return new Date(b.LAST_MODIFIED).getTime() - new Date(a.LAST_MODIFIED).getTime();
                    }
                    return diff;
                }),
        };
    } catch (error) {
        const { response = {} } = error;
        // status 404: no scenarios
        if (response.status === 404) {
            return {
                isOk: true,
                scenarios: [],
                error,
            };
        }
        return {
            isOk: false,
            scenarios: [],
            error,
        };
    }
};

/**
 * @param {string|number} scenarioId
 * @returns
 */
export const deleteScenario = async (scenarioId) => {
    try {
        await axios.post(`/api/fast-api/scenario/delete/${scenarioId}`, null, {
            params: {
                scenario_id: scenarioId,
            },
        });
        return {
            isOk: true,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            error,
        };
    }
};

/**
 *
 * @param {string|number} scenarioId
 * @param {string|number} userId
 * @param {string} additionalText
 * @returns
 */
export const shareScenario = async (scenarioId, userId, additionalText) => {
    try {
        const { status } = await axios.post(
            `/api/fast-api/scenario/share/${scenarioId}`,
            { additionalText },
            {
                params: {
                    share_user_id: userId,
                },
            }
        );

        if (status === 200) {
            return {
                isOk: true,
                success: true,
                error: null,
            };
        }
        return {
            isOk: true,
            success: false,
            error: null,
        };
    } catch (error) {
        if (typeof error?.response?.data?.detail === 'string') {
            if (error.response.data.detail.includes('already exists')) {
                return {
                    isOk: false,
                    success: false,
                    error: {
                        title: 'Could not share',
                        message: `${error.response.data.detail} Please rename scenario`,
                    },
                };
            }
        }
        return {
            isOk: false,
            success: false,
            error: {
                title: 'Could not share',
                message: error?.response?.data?.detail ?? '',
            },
        };
    }
};

/**
 *
 * @param {{scenario_id: number | string, scenario_name: string }} data
 * @param {number | string} user_id
 * @param {any} params
 * @returns {Promise<{ data: [number, Scenario] }>}
 */
export const saveScenario = async (data, params = null) => {
    // Required fields for API
    data.scenario_id = data.scenario_id ?? 1;

    return axios.post(`/api/fast-api/scenario/save/${data.scenario_id}`, data, params);
};

/**
 *
 * @param {{scenario_name: string, [key: string]: any}} newScenario
 * @param {number} scenario_id
 * @returns {Promise<{ data: [number, Scenario] }>}
 */
export const createScenario = async (newScenario, scenario_id = 1) => {
    const reqBody = {
        ...newScenario,
    };
    return axios.post('/api/fast-api/scenario/create', reqBody, { params: { scenario_id } });
};

/**
 *
 * @param {number} scenarioId
 * @param {{ acr_id: number; ft_mx_id: number; mil_mx_id: number; eqn_id: { OFF: number[], ENL: number[]}  }} data
 * @returns {Promise<{ data: [number, Scenario] }>}
 */
export const updateModelId = async (scenario_id, data) => {
    return await axios.post(`/api/fast-api/scenario/update_model_id/${scenario_id}`, data);
};

/**
 *
 * @param {number | string} scenarioId
 * @returns {Promise<{
 *      isOk: boolean,
 *      data: {
 *          generalSettings: {[key: string]: any, id: string}[]
 *          opsTobe: {[key: string]: any, id: string}[]
 *          opsAsis: {[key: string]: any, id: string}[]
 *          mxTobe: {[key: string]: any, id: string}[]
 *          mxAsis: {[key: string]: any, id: string}[]
 *          unitDetails: {
 *              OPS_DETAILS: {[key: string]: any, id: string}[],
 *              OPS_GP: string,
 *              MX_DETAILS: {[key: string]: any, id: string}[],
 *              MX_GP: string,
 *          }
 *          addUnitDetails: {
 *              CMD: string[],
 *              ILC: string[],
 *              OPS: {[key: string]: any, id: string}[],
 *              MX: {[key: string]: any, id: string}[],
 *          }
 *      },
 *      error: null | any,
 * }>}
 */
export const getScenarioReview = async (scenarioId) => {
    try {
        function addIdxAsId(detail, idx) {
            return {
                ...detail,
                id: `${idx}`,
            };
        }
        const { data } = await axios.post(`/api/fast-api/scenario/review/${scenarioId}`, null);
        return {
            isOk: true,
            data: {
                generalSettings: data.GENERAL_SETTINGS.map(addIdxAsId),
                unitDetails: {
                    ...data.UNIT_DETAILS,
                    OPS_DETAILS: data.UNIT_DETAILS.OPS_DETAILS.map(addIdxAsId),
                    MX_DETAILS: data.UNIT_DETAILS.MX_DETAILS.map(addIdxAsId),
                },
                addUnitDetails: {
                    CMD: data.ADD_UNIT_DETAILS.CMD || [],
                    ILC: data.ADD_UNIT_DETAILS.ILC || [],
                    OPS: (data.ADD_UNIT_DETAILS.OPS || []).map(addIdxAsId),
                    MX: (data.ADD_UNIT_DETAILS.MX || []).map(addIdxAsId),
                },
                opsTobe: data.OPS_TOBE.map(addIdxAsId),
                mxTobe: data.MX_TOBE.map(addIdxAsId),
                opsAsis: data.OPS_ASIS.map(addIdxAsId),
                mxAsis: data.MX_ASIS.map(addIdxAsId),
            },
            error: null,
        };
    } catch (error) {
        console.error(error);
        return {
            isOk: false,
            data: {
                generalSettings: [],
                unitDetails: {
                    OPS_DETAILS: [],
                    MX_DETAILS: [],
                },
                opsTobe: [],
                mxTobe: [],
                opsAsis: [],
                mxAsis: [],
            },
            error: error,
        };
    }
};

/**
 *
 * @param {{cmd: string; ilc: string; pec: string[]; mp_type: 'AIRCREW', 'MX'; scenario_id: number}} params
 * @returns
 */
export const fetchFacMsiApiDropdowns = async (params = {}) => {
    const { cmd, ilc, pec, mp_type, scenario_id } = params;
    const reqBody = {
        pec,
        cmd,
        ilc,
        mp_type,
    };
    try {
        const { data } = await axios.post('/api/fast-api/scenario/fac_msi_api_dropdowns', reqBody, {
            params: {
                scenario_id,
            },
        });
        return {
            isOk: true,
            error: null,
            data,
        };
    } catch (error) {
        return {
            isOk: false,
            data: null,
            error,
        };
    }
};
