// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-content {
  display: flex;
  flex-direction: column;
}
.login-content .input-group-text {
  color: var(--bs-white);
}
.login-content input {
  font-size: 1rem;
}
.login-content .email-content {
  display: flex;
  margin-bottom: 1rem;
  margin-top: 2rem;
}
.login-content .email-content svg {
  width: 2rem;
}
.login-content .password-content {
  display: flex;
}
.login-content .password-content svg {
  width: 2rem;
}
.login-content .terms {
  color: var(--cds-text-secondary, #525252);
}
.login-content #link-content {
  gap: 15rem;
  align-items: center;
  margin-top: 2rem;
}
.login-content #link-content a {
  margin-left: 0;
}
.login-content #link-content .loading-content .loading-util {
  min-height: 1rem;
}
.login-content #link-content button {
  padding-right: 0;
  width: 25%;
}
.login-content #link-content button .button-content {
  display: flex;
}
.login-content #link-content button .button-content .text-content {
  padding-right: 5vw;
}`, "",{"version":3,"sources":["webpack://./src/pages/accountServices/LoginForm.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ;AACI;EACI,sBAAA;AACR;AAEI;EACI,eAAA;AAAR;AAGI;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;AADR;AAGQ;EACI,WAAA;AADZ;AAMI;EACI,aAAA;AAJR;AAMQ;EACI,WAAA;AAJZ;AASI;EACI,yCAAA;AAPR;AAUI;EACI,UAAA;EACA,mBAAA;EACA,gBAAA;AARR;AAUQ;EACI,cAAA;AARZ;AAaY;EACI,gBAAA;AAXhB;AAeQ;EACI,gBAAA;EACA,UAAA;AAbZ;AAeY;EACI,aAAA;AAbhB;AAegB;EACI,kBAAA;AAbpB","sourcesContent":[".login-content {\n    display: flex;\n    flex-direction: column;\n\n    .input-group-text {\n        color: var(--bs-white);\n    }\n\n    input {\n        font-size: 1rem;\n    }\n\n    .email-content {\n        display: flex;\n        margin-bottom: 1rem;\n        margin-top: 2rem;\n\n        svg {\n            width: 2rem;\n        }\n\n    }\n\n    .password-content {\n        display: flex;\n\n        svg {\n            width: 2rem;\n        }\n\n    }\n\n    .terms {\n        color: var(--cds-text-secondary, #525252)\n    }\n\n    #link-content {\n        gap: 15rem;\n        align-items: center;\n        margin-top: 2rem;\n\n        a {\n            margin-left: 0;\n        }\n\n        .loading-content {\n\n            .loading-util {\n                min-height: 1rem;\n            }\n        }\n\n        button {\n            padding-right: 0;\n            width: 25%;\n\n            .button-content {\n                display: flex;\n\n                .text-content {\n                    padding-right: 5vw;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
