import { useEffect, useRef, useCallback } from 'react';

/**
 * @returns {() => boolean} callback function for if the component is mounted
 */
export const useIsMounted = () => {
    const isMountedRef = useRef(true);

    useEffect(() => {
        isMountedRef.current = true;
        return () => (isMountedRef.current = false);
    }, []);

    return useCallback(() => {
        return isMountedRef.current;
    }, []);
};
