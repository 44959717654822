import { useEffect, useRef } from 'react';

/**
 * Hook that calls a function when the component unmounts
 * @param {function} fn
 */
export const useUnmount = (fn) => {
    const fnRef = useRef(fn);

    // update unmount function with each render
    fnRef.current = fn;

    useEffect(() => {
        // uses a mutable ref to keep the useEffect from firing on each render
        return () => fnRef.current();
    }, []);
};
