import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import axios from 'axios';

import App from '../App';

import { isLoggedInAtom } from '../utils/PrivateRoute';

export const useHandleLogout = () => {
    const setIsLoggedIn = useSetAtom(isLoggedInAtom);

    const handleLogout = useCallback(async () => {
        try {
            await axios.post('/api/logout');
            setIsLoggedIn(false);
        } catch (error) {
            console.error('Logout failed:', error);
            window.location.reload();
        }
    }, [setIsLoggedIn]);

    return handleLogout;
};
