import React from 'react';
import axios from 'axios';
import { Header, HeaderMenu, HeaderNavigation } from '@carbon/react';
import { Logout } from '@carbon/icons-react';
import { useNavigate } from 'react-router-dom';

import { LinkUtil, ButtonUtil } from '.';
import { paths, demoPaths } from '../config';

import './Navbar.scss';

export const Navbar = ({ header }) => {
    let navigate = useNavigate();

    const demoKeys = Object.keys(demoPaths);

    const pageKeys = Object.keys(paths).filter((path) => path !== 'empty');

    const logout = () => {
        (async () => {
            try {
                await axios.post(
                    '/api/logout',
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                        credentials: 'include',
                    }
                );
            } catch (err) {
                console.log('failed to log out');
            }
            navigate('/');
        })();
    };

    return (
        <Header
            aria-label="Rhombus Power Basecode"
            className="nav-header"
            {...header}
        >
            <LinkUtil
                pathKey="accountServices"
                link={{
                    className: 'logo-link',
                    children: (
                        <div>
                            <img
                                src="/assets/Logos/rhombusLogo.png"
                                alt="Logo"
                                className="mx-2 logo-img"
                            />
                            <span className="logo-text">Guardian</span>
                        </div>
                    ),
                }}
            />
            <DemoNavigation demoKeys={demoKeys} />
            <PageNavigation pageKeys={pageKeys} />
            <div className="ms-2">
                <ButtonUtil
                    button={{
                        kind: 'tertiary',
                        renderIcon: Logout,
                        children: 'Logout',
                        onClick: () => logout(),
                    }}
                />
            </div>
        </Header>
    );
};

const DemoNavigation = ({ demoKeys }) => (
    <HeaderNavigation aria-label="Rhombus Power Inc." className="demo-nav">
        <HeaderMenu aria-label="COMPONENTS" menuLinkName="COMPONENTS">
            {demoKeys.map((demoKey) => (
                <LinkUtil
                    key={demoKey}
                    link={{
                        className: 'cds--header__menu-item',
                        children: (
                            <span className="cds--text-truncate--end">
                                {demoPaths[demoKey]['name']}
                            </span>
                        ),
                    }}
                    pathKey={demoKey}
                />
            ))}
        </HeaderMenu>
    </HeaderNavigation>
);

const PageNavigation = ({ pageKeys }) => (
    <HeaderNavigation aria-label="Rhombus Power Inc." className="page-nav">
        {pageKeys.map((pageKey) => (
            <LinkUtil
                key={pageKey}
                link={{
                    className: 'cds--header__menu-item',
                    children: (
                        <span
                            className="cds--text-truncate--end"
                            style={{ width: 120 }}
                        >
                            {paths[pageKey]['name']}
                        </span>
                    ),
                    style: { textAlign: 'center', width: '100%' },
                }}
                pathKey={pageKey}
            />
        ))}
    </HeaderNavigation>
);
