// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ump-flow > div {
  width: max-content;
}
.ump-flow .cds--modal-content {
  min-height: 0;
  height: 100%;
  width: min(90vw, 2072px);
  aspect-ratio: 2072/973;
  padding: 0;
  overflow: hidden;
  max-width: max-content;
  max-height: max-content;
  margin: 0 auto;
  mask-image: none;
}
.ump-flow .ump-flow-content {
  width: 100%;
  height: 100%;
}
.ump-flow .ump-flow-content > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media screen and (max-width: 671px) {
  .ump-flow .cds--modal-content {
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/wizard/modals/umpFlow/UmpFlow.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;AAAR;AAGI;EACI,aAAA;EACA,YAAA;EACA,wBAAA;EACA,sBAAA;EACA,UAAA;EACA,gBAAA;EACA,sBAAA;EACA,uBAAA;EACA,cAAA;EACA,gBAAA;AADR;AAII;EACI,WAAA;EACA,YAAA;AAFR;AAKI;EACI,WAAA;EACA,YAAA;EACA,mBAAA;AAHR;AAMI;EACI;IACI,YAAA;EAJV;AACF","sourcesContent":[".ump-flow {\n    &>div {\n        width: max-content;\n    }\n\n    .cds--modal-content {\n        min-height: 0;\n        height: 100%;\n        width: min(90vw, 2072px);\n        aspect-ratio: 2072/973;\n        padding: 0;\n        overflow: hidden;\n        max-width: max-content;\n        max-height: max-content;\n        margin: 0 auto;\n        mask-image: none;\n    }\n\n    .ump-flow-content {\n        width: 100%;\n        height: 100%;\n    }\n\n    .ump-flow-content>img {\n        width: 100%;\n        height: 100%;\n        object-fit: contain;\n    }\n\n    @media screen and (max-width: 671px) {\n        .cds--modal-content {\n            width: 100vw;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
