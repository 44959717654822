function isStringOfLength(str) {
    return typeof str === 'string' && str.length > 0;
}

function isCrewValid(crew) {
    if (!isStringOfLength(crew.AFSC) || !isStringOfLength(crew.CREW_POSITION)) {
        return false;
    }
    if (
        isNaN(crew.CREW_RATIO) ||
        isNaN(crew.CREW_COMP_OFF) ||
        isNaN(crew.CREW_COMP_ENL)
    ) {
        return false;
    }
    if (crew.CREW_RATIO < 0) {
        return false;
    }
    if (crew.CREW_COMP_OFF === 0 && crew.CREW_COMP_ENL === 0) {
        return false;
    }
    if(!(typeof crew.FAC === 'string' && crew.FAC.length === 6)) {
        return false;
    }
    return true;
}

export function validateUpdateModelState(model) {
    return Object.entries(model).every(([key, value]) => {
        if (key === 'composition') {
            return value.every(isCrewValid);
        }
        if (typeof value === 'string') {
            if (key === 'NOTES') {
                // Notes can be empty
                return true;
            }
            return value.length > 0;
        }
        if (typeof value === 'number') {
            return true;
        }
        return true;
    });
}
