import React, { useState } from 'react';
import { PasswordInput } from '@carbon/react';
import { InputValidator } from '../../helpers';

export const PasswordField = ({ 
    passwordInput={}, 
    type='password', 
    validation=false, 
    duplicate='', 
    original='',
    setState=()=>{}, 
    setDuplicate=()=>{} 
}) => {
    const [invalidText, setInvalidText] = useState('Match the above password.')

    const handleChange = (e) => {
        const typedPass = e.target.value
        if (validation) {
            if (typedPass.length < 80) {
                switch (type){
                    case 'password': 
                        const {
                            individualConditions,
                            maxLengthMet, 
                            individualConditionsMet, 
                            allConditionsMet
                        } = 
                            InputValidator({ 
                                type: 'password', 
                                value: e.target.value, 
                            })
                        ;

                        let invalid = false
                        if (typedPass !== '' && allConditionsMet === false) {invalid = true}
                        setState({ 
                            value: typedPass, 
                            invalid: invalid
                        })

                        let requirementsMissing= []
                        for (const req of Object.keys(individualConditions)) {
                            if (individualConditions[req]===false){
                                let reqName
                                switch (req) {
                                    case 'hasDigit':
                                        reqName = 'one number'
                                        break 
                                    case 'hasLowercaseLetter':
                                        reqName = 'one lowercase letter'
                                        break
                                    case 'hasCapitalLetter':
                                        reqName = 'one capital letter'
                                        break  
                                    case 'hasSpecialCharacter':
                                        reqName = 'one special character'
                                        break 
                                    case 'hasAtLeastEightCharacters':
                                        reqName = 'eight characters in total'
                                        break 
                                    default:
                                }
                                requirementsMissing.push(reqName)
                            }
                        }
    
                        const firstReqs = requirementsMissing.slice(0,-1).join(', ')
                        const lastReq = requirementsMissing.at(-1)
                        const reqs = 
                            requirementsMissing.length === 1 
                                ? lastReq
                                : `${firstReqs} and ${lastReq}`
                        if (maxLengthMet === false) {
                            setInvalidText('Use less than 70 characters for your password.')
                        }
                        else if (individualConditionsMet === false) {
                            setInvalidText(`Use at least ${reqs}.`)
                        }
                        let duplicateIsInvalid = false
                        if (duplicate !=='' && typedPass !== duplicate) {
                            duplicateIsInvalid = true
                        }
                        setDuplicate({
                            value: duplicate, 
                            invalid: duplicateIsInvalid
                        })
                        break
    
                    case 'duplicate': 
                        const duplicateSameAsOriginal = e.target.value === original
                        let duplicateInvalid = 
                            (
                                !duplicateSameAsOriginal ||
                                typedPass.length > 69
                            ) &&
                            typedPass !== ''
                        ;
                        setState({ 
                            value: typedPass, 
                            invalid: duplicateInvalid
                        })
                        if (typedPass.length > 69) {
                            setInvalidText('Use less than 70 characters to match your password.')
                        }
                        else if (!duplicateSameAsOriginal){
                            setInvalidText('Match the above password.')
                        }
                        break
    
                    default : 
                }
            }
        }
        else {
            setState({ value: typedPass })
        }
    }

    const passwordInputProps = {
        labelText: 'Password', 
        id: `${type}${Math.random().toString()}`,
        invalidText: invalidText,
        ...passwordInput, 
        onChange: (e) => {
            if (typeof passwordInput.onChange === 'function') {
                passwordInput.onChange()
            }
            handleChange(e)
        },
        invalid: validation ? passwordInput.invalid : false,
    }

    return  <PasswordInput {...passwordInputProps} /> 
}