import React, { useEffect } from 'react';
import { Modal, TextInput, Dropdown, Button, ContentSwitcher, Switch, NumberInput } from '@carbon/react';
import { TrashCan, AddAlt } from '@carbon/react/icons';

import { useWlfEquationsState } from './wlfEquationsState';
import { editFulltimeEquation } from '../../../aircrewApiCalls';

const views = Object.freeze({
    searchCriteria: 'SEARCH CRITERIA',
    customValue: 'CUSTOM VALUE',
});

export const WlfEquations = ({ open, onClose, onSubmit, eqnId, ilc, scenarioId, disabled }) => {
    const {
        updateInput,
        updateRootInput,
        wlfEquation,
        addWlfQueryRow,
        deleteRow,
        reset,
        // toggleIlc,
    } = useWlfEquationsState(eqnId);
    const [view, setView] = React.useState(wlfEquation.wlfType || views.searchCriteria);

    const numberOfInputs = wlfEquation.inputs?.length ?? 0;
    const readOnly = wlfEquation.isBaseline;

    useEffect(() => {
        if (numberOfInputs === 0 && open) {
            addWlfQueryRow();
        }
    }, [open, numberOfInputs, addWlfQueryRow]);

    const handleToggleView = ({ name }) => {
        setView(name);
    };

    const handleSubmit = async () => {
        const inputs = [];
        wlfEquation.inputs?.forEach((input) => {
            if (input.field.length > 0 && input.criteria.length > 0 && input.search.length > 0) {
                inputs.push(input);
            }
        });
        const customValue = `${wlfEquation.customValue ?? ''}`;
        const wlf = {};
        if (view.toUpperCase() === 'SEARCH CRITERIA') {
            wlf.QUERY = inputs.map(({ field, criteria, search }) => ({
                FIELD: field,
                CRITERIA: criteria,
                REGEX: search,
            }));
        } else if (view.toUpperCase() === 'CUSTOM VALUE') {
            wlf.VALUE = customValue;
        }
        const wlfEquationBody = {
            eqn_id: eqnId,
            value: customValue,
            fac: wlfEquation.fac,
            osc: wlfEquation.osc,
            afsc: wlfEquation.afsc,
            iaf: wlfEquation.iaf,
            ilc: wlfEquation.ilcSpecific ?? 'ALL',
            constant: wlfEquation.constant || 0,
            coefficient: wlfEquation.coefficient || 0,
            maf: wlfEquation.maf || null,
            aircrew_type: wlfEquation.aircrewType,
            wlf,
        };

        if (wlfEquation.cmd) {
            wlfEquationBody.cmd = wlfEquation.cmd;
        }

        const editResult = await editFulltimeEquation(scenarioId, wlfEquationBody);

        onSubmit(wlfEquationBody, editResult);
    };

    const handleClose = () => {
        reset(eqnId);
        onClose();
    };

    return (
        <Modal
            modalHeading={`${
                wlfEquation.aircrewType === 'OFF' ? 'Officer' : 'Enlisted'
            }: Workload Factor Search Criteria`}
            data-testid="wlf-equations-modal"
            open={open}
            passiveModal={readOnly}
            onRequestClose={handleClose}
            onRequestSubmit={handleSubmit}
            primaryButtonText={readOnly ? 'Close' : 'Apply Changes'}
            secondaryButtonText="Cancel"
        >
            <div className="d-flex justify-content-between">
                <ContentSwitcher
                    style={{
                        minWidth: 'fit-content',
                        width: 'fit-content',
                        marginRight: 'auto',
                    }}
                    selectedIndex={view === views.searchCriteria ? 0 : 1}
                    onChange={handleToggleView}
                >
                    <Switch
                        text="Search Criteria"
                        name={views.searchCriteria}
                        style={{
                            minWidth: 'fit-content',
                            width: 'fit-content',
                        }}
                    />
                    <Switch
                        text="Custom Value"
                        name={views.customValue}
                        style={{
                            minWidth: 'fit-content',
                            width: 'fit-content',
                        }}
                    />
                </ContentSwitcher>
            </div>
            {view === views.searchCriteria ? (
                <>
                    {wlfEquation.inputs?.map((input, index) => (
                        <WlfEquationRow
                            key={input.id}
                            index={index}
                            readOnly={readOnly}
                            onChange={updateInput}
                            field={input.field}
                            criteria={input.criteria}
                            search={input.search}
                            onAdd={addWlfQueryRow}
                            onDelete={deleteRow}
                            showDeleteButton={wlfEquation.inputs.length - 1 === index && index !== 0}
                        />
                    ))}
                </>
            ) : (
                <div>
                    <NumberInput
                        className="row"
                        id="custom-value-input"
                        disabled={disabled}
                        min={0}
                        max={Number.MAX_SAFE_INTEGER}
                        value={wlfEquation.customValue}
                        readOnly={readOnly}
                        step={1}
                        label="Custom WLF Value"
                        invalidText="Number is not valid"
                        onChange={(_e, { value }) => {
                            let numValue = value;
                            if (isNaN(value)) {
                                numValue = 0;
                            }
                            updateRootInput({
                                inputType: 'customValue',
                                value: numValue,
                            });
                        }}
                    />
                </div>
            )}
        </Modal>
    );
};

const WlfEquationRow = ({ index, onChange, field, criteria, search, onAdd, onDelete, showDeleteButton, readOnly }) => {
    return (
        <div className="row" data-testid="wlf-equations-row">
            <div className="col-md-3" data-testid="wlf-equations-field">
                <Dropdown
                    id={`wlf-row-field-${index}`}
                    titleText="Field"
                    label=""
                    items={['AFS', 'API', 'FAC', 'MNT', 'MSI', 'OSC', 'RIC']}
                    selectedItem={field}
                    readOnly={readOnly}
                    onChange={({ selectedItem }) => {
                        onChange({
                            input: 'field',
                            value: selectedItem,
                            index,
                        });
                    }}
                />
            </div>
            <div className="col-md-3" data-testid="wlf-equations-criteria">
                <Dropdown
                    id={`wlf-row-criteria-${index}`}
                    label=""
                    titleText="Criteria"
                    items={['INCLUDE', 'EXCLUDE']}
                    selectedItem={criteria}
                    readOnly={readOnly}
                    onChange={({ selectedItem }) => {
                        onChange({
                            input: 'criteria',
                            value: selectedItem,
                            index,
                        });
                    }}
                />
            </div>
            <div className="col-md-3">
                <TextInput
                    id={`wlf-row-search-${index}`}
                    data-testid="wlf-equations-search"
                    labelText="Search"
                    value={search ?? ''}
                    readOnly={readOnly}
                    onChange={(e) => {
                        onChange({
                            input: 'search',
                            value: e.target.value,
                            index,
                        });
                    }}
                    placeholder="value1|value2"
                />
            </div>
            <div className="col-md-1 mt-auto">
                {index === 0 && !readOnly && (
                    <Button
                        id="wlf-equations-add-row-btn"
                        onClick={onAdd}
                        renderIcon={AddAlt}
                        kind="ghost"
                        size="lg"
                        iconDescription="Add field"
                        data-testid="add-field-button"
                    />
                )}
                {showDeleteButton && !readOnly && (
                    <Button
                        id="wlf-equations-delete-row-btn"
                        onClick={onDelete}
                        renderIcon={TrashCan}
                        kind="ghost"
                        size="lg"
                        iconDescription="Trash"
                        data-testid="delete-button"
                    />
                )}
            </div>
        </div>
    );
};
