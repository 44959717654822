import axios from 'axios';

export const fetchFiltersCombined = async (params = {}) => {
    await global.GetCSRFToken();
    return await axios.post(
        `/api/fast-api/filters/general_settings`,
        null,
        params
    );
};
