import { useEffect } from 'react';

// Sum quantity for rows for equations table on mount

export const useSelectAllRows = ({ isLoading, rowsRef, rows, selectAllRows }) => {
    const isCurrent = rowsRef.current === rows;
    useEffect(() => {
        if (!isLoading && !isCurrent) {
            selectAllRows(true);
            rowsRef.current = rows;
        }
    }, [isLoading, isCurrent, selectAllRows, rowsRef, rows]);
};
