import React from 'react';
import PropTypes from 'prop-types';
import { TextInput, TableCell, Dropdown, Button } from '@carbon/react';
import { TrashCan } from '@carbon/react/icons';
import { MxValidationTextInput } from '../../mxFormGroups/mxInputs/MxValidationTextInput';

const formulaTableCellTypes = {
    value: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    dataType: PropTypes.oneOf(['number', 'positive-number', 'string', 'conditional', 'boolean', 'readonly', 'edit']),
    colName: PropTypes.string.isRequired,
    rowIdx: PropTypes.number.isRequired,
    onOpenConditionalEqns: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showRequiredFields: PropTypes.bool,
    disabled: PropTypes.bool,
};

const optionalFields = new Set(['osc']);
const isInvalidPositiveNumber = (value) => {
    return isNaN(value) || value === '' || value <= 0;
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof formulaTableCellTypes>>}
 * @returns {React.ReactElement}
 */
export const FormulaTableCell = ({
    value,
    id,
    onChange,
    dataType,
    colName,
    rowIdx,
    onOpenConditionalEqns,
    onDelete,
    showRequiredFields = false,
    disabled,
}) => {
    const [showWarning, setShowWarning] = React.useState(false);
    switch (dataType) {
        case 'string':
            return (
                <TableCell className="pai-input">
                    <MxValidationTextInput
                        id={id}
                        testId="formula-table-cell-text"
                        value={value}
                        disabled={disabled}
                        cellHeader={colName.toUpperCase()}
                        invalid={!optionalFields.has(colName) && showRequiredFields && !value}
                        isRequired={!optionalFields.has(colName)}
                        onChange={(e) => {
                            onChange(e.target.value, rowIdx, colName);
                        }}
                    />
                </TableCell>
            );
        case 'number':
            return (
                <TableCell className="pai-input">
                    <TextInput
                        id={id}
                        data-testid="formula-table-cell-number"
                        labelText=""
                        type="number"
                        disabled={disabled}
                        min={0}
                        max={Infinity}
                        value={value}
                        warn={showWarning}
                        warnText="Enter a valid number"
                        invalid={showRequiredFields && isNaN(parseFloat(value))}
                        invalidText="Field is required"
                        onBlur={() => {
                            if (isNaN(value) || value === '') {
                                setShowWarning(true);
                            }
                        }}
                        onChange={(e) => {
                            onChange(e.target.value, rowIdx, colName);
                            setShowWarning(false);
                        }}
                    />
                </TableCell>
            );
        case 'positive-number': {
            const isInvalid = showRequiredFields && isNaN(parseFloat(value));
            return (
                <TableCell className="pai-input">
                    <TextInput
                        id={id}
                        data-testid="formula-table-cell-number"
                        labelText=""
                        type="number"
                        disabled={disabled}
                        min={0}
                        max={Infinity}
                        value={value}
                        warn={showWarning || (showRequiredFields && isInvalidPositiveNumber(Number(value)))}
                        warnText="Enter a valid positive non-zero number"
                        invalid={isInvalid}
                        invalidText="Field is required"
                        onBlur={() => {
                            if (isInvalidPositiveNumber(value)) {
                                setShowWarning(true);
                            }
                        }}
                        onChange={(e) => {
                            onChange(e.target.value, rowIdx, colName);
                            setShowWarning(false);
                        }}
                    />
                </TableCell>
            );
        }
        case 'conditional':
            return (
                <TableCell className="pai-input">
                    <Button
                        id={id}
                        data-testid="formula-table-cell-conditional"
                        kind="ghost"
                        label=""
                        onClick={() => {
                            onOpenConditionalEqns({
                                rowIdx,
                                id,
                            });
                            setShowWarning(false);
                        }}
                    >
                        {value && value?.length > 0 ? 'Yes' : 'No'}
                    </Button>
                </TableCell>
            );
        case 'edit':
            return (
                <TableCell className="pai-input">
                    <Button
                        id={id}
                        className="editNote"
                        renderIcon={TrashCan}
                        kind="ghost"
                        size="sm"
                        iconDescription="Trash"
                        data-testid="formula-table-cell-delete-button"
                        disabled={disabled}
                        onClick={() => {
                            onDelete(rowIdx);
                        }}
                    />
                </TableCell>
            );
        default:
            return <TableCell key={id}>{value}</TableCell>;
    }
};

FormulaTableCell.propTypes = formulaTableCellTypes;
