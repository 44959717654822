import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHead,
    TableRow,
    Tooltip,
    SkeletonText,
    Pagination,
    Button,
    //
} from '@carbon/react';
import { useAtom } from 'jotai';
import { tooltipsAtom } from './ReviewAtoms';

const reviewTableProps = {
    isLoading: PropTypes.bool,
    id: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            header: PropTypes.string.isRequired,
        })
    ).isRequired,
    enablePagination: PropTypes.bool,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof reviewTableProps>>}
 * @returns {React.ReactElement}
 */
export const ReviewTable = ({ rows, headers, id = 'review-table', isLoading = false, enablePagination = true }) => {
    const [paginatedRows, setPaginatedRows] = useState(rows);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [tooltips] = useAtom(tooltipsAtom);
    const totalItems = rows.length;

    const rowsRef = React.useRef(rows);
    rowsRef.current = rows;

    React.useEffect(() => {
        setPaginatedRows(rowsRef.current.slice(0, pageSize));
    }, [rows.length, pageSize]);

    const handlePageSizeChange = (update) => {
        setPage(update.page ?? 0);
        setPageSize(update.pageSize ?? 0);
        const start = update.pageSize * (update.page - 1);
        setPaginatedRows(rowsRef.current.slice(start, start + update.pageSize));
    };
    return (
        <>
            <DataTable rows={paginatedRows} headers={headers} id={id}>
                {({ rows, headers, getHeaderProps, getTableProps }) => (
                    <Table {...getTableProps()} aria-label={id}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header) => (
                                    <TableHeader
                                        {...getHeaderProps({
                                            header,
                                            key: header.key,
                                            className: 'review-table-header-' + header.key,
                                        })}
                                    >
                                        {header.header.replaceAll('_', ' ')}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        {isLoading ? (
                            <TableBody data-testid="review-table-loading">
                                <TableRow>
                                    {headers.map((header, idx) => (
                                        <TableCell key={`loading_${header}_${idx}`}>
                                            <SkeletonText />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {rows.map((row, rowIdx) => (
                                    <TableRow key={row.id}>
                                        {row.cells.map((cell) => {
                                            switch (cell.info.header) {
                                                case 'PEC':
                                                    return (
                                                        <TableCell key={cell.id}>
                                                            <Tooltip align="right" label={tooltips.PEC[cell.value]}>
                                                                <Button
                                                                    kind="ghost"
                                                                    size="sm"
                                                                    style={{ alignItems: 'center' }}
                                                                >
                                                                    {cell.value}
                                                                </Button>
                                                            </Tooltip>
                                                        </TableCell>
                                                    );
                                                case 'FAC':
                                                    return (
                                                        <TableCell key={cell.id}>
                                                            <Tooltip align="right" label={tooltips.FAC[cell.value]}>
                                                                <Button
                                                                    kind="ghost"
                                                                    size="sm"
                                                                    style={{ alignItems: 'center' }}
                                                                >
                                                                    {cell.value}
                                                                </Button>
                                                            </Tooltip>
                                                        </TableCell>
                                                    );

                                                case 'RIC':
                                                    return (
                                                        <TableCell key={cell.id}>
                                                            <Tooltip align="right" label={tooltips.RIC[cell.value]}>
                                                                <Button
                                                                    kind="ghost"
                                                                    size="sm"
                                                                    style={{ alignItems: 'center' }}
                                                                >
                                                                    {cell.value}
                                                                </Button>
                                                            </Tooltip>
                                                        </TableCell>
                                                    );

                                                default:
                                                    return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                            }
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </Table>
                )}
            </DataTable>
            {enablePagination ? (
                <Pagination
                    id={id}
                    page={page}
                    pageSize={pageSize}
                    pageSizes={[10, 20, 30, 40, 50]}
                    totalItems={totalItems}
                    onChange={handlePageSizeChange}
                />
            ) : null}
        </>
    );
};

ReviewTable.propTypes = reviewTableProps;
