import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    DataTable,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Tooltip,
} from '@carbon/react';
import { CheckmarkFilled } from '@carbon/react/icons';
import { useFetchAllUsers } from '../../wizard/modals/shareScenario/useFetchAllUsers';

const publishTableProps = {
    scenarios: PropTypes.arrayOf(
        PropTypes.shape({
            SCENARIO_NAME: PropTypes.string.isRequired,
            SCENARIO_ID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            id: PropTypes.string.isRequired,
        }).isRequired
    ).isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func.isRequired,
            actionText: PropTypes.oneOf(['Publish', 'Unpublish', 'Create']).isRequired,
        }).isRequired
    ),
    tableDescription: PropTypes.string.isRequired,
    onReviewScenario: PropTypes.func.isRequired,
    tableHeader: PropTypes.string.isRequired,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
        }).isRequired
    ),
    ariaLabel: PropTypes.string,
    onUnpublishExplanation: PropTypes.func,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof publishTableProps>>}
 * @returns {React.ReactElement}
 */
export const PublishTable = ({
    tableDescription,
    scenarios,
    onReviewScenario,
    tableHeader,
    ariaLabel,
    highlightScenarioId,
    onUnpublishExplanation = null,
    actions = [],
    headers = [
        { header: '', key: 'ICON' },
        { header: 'Scenario Name', key: 'SCENARIO_NAME' },
        { header: '', key: 'action' },
    ],
}) => {
    const { allUsers } = useFetchAllUsers();
    return (
        <div className="mt-4 mb-4 publish-table">
            <DataTable rows={scenarios} headers={headers}>
                {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getToolbarProps,
                    getBatchActionProps,
                    onInputChange,
                    getTableProps,
                    getTableContainerProps,
                }) => {
                    const batchActionProps = {
                        ...getBatchActionProps(),
                    };
                    return (
                        <TableContainer title={tableHeader} {...getTableContainerProps()}>
                            <div className="publish-table-description">
                                <p className="me-4">{tableDescription}</p>
                                <TableToolbar {...getToolbarProps()}>
                                    <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                                        <TableToolbarSearch
                                            tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                            onChange={onInputChange}
                                        />
                                    </TableToolbarContent>
                                </TableToolbar>
                            </div>
                            <Table {...getTableProps()} aria-label={ariaLabel}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {rows.map((row, index) => (
                                        <TableRow
                                            className={row.id === highlightScenarioId ? 'highlight' : ''}
                                            {...getRowProps({ row })}
                                        >
                                            {row.cells.map((cell) => {
                                                switch (cell.info.header) {
                                                    case 'ICON':
                                                        return (
                                                            <TableCell className="table-row-icon" key={cell.id}>
                                                                {cell.value ? (
                                                                    <Tooltip
                                                                        align="right"
                                                                        label="Scenario has been published to whatif"
                                                                    >
                                                                        <CheckmarkFilled />
                                                                    </Tooltip>
                                                                ) : (
                                                                    <div />
                                                                )}
                                                            </TableCell>
                                                        );
                                                    case 'SCENARIO_NAME':
                                                        return (
                                                            <TableCell key={cell.id}>
                                                                <div className="table-row-scenario-name">
                                                                    <div>
                                                                        {' '}
                                                                        {cell.value}
                                                                        {` [`}
                                                                        <Button
                                                                            className="table-row-scenario-review"
                                                                            onClick={() => {
                                                                                onReviewScenario(
                                                                                    scenarios.find(
                                                                                        (scenario) =>
                                                                                            scenario.id === row.id
                                                                                    )
                                                                                );
                                                                            }}
                                                                            kind="ghost"
                                                                            size="sm"
                                                                            style={{ padding: 0 }}
                                                                        >
                                                                            Review
                                                                        </Button>
                                                                        {`]`}
                                                                    </div>
                                                                    {typeof onUnpublishExplanation === 'function' ? (
                                                                        <div>
                                                                            {` [`}
                                                                            <Button
                                                                                className="table-row-scenario-review"
                                                                                onClick={() => {
                                                                                    onUnpublishExplanation(
                                                                                        scenarios.find(
                                                                                            (scenario) =>
                                                                                                scenario.id === row.id
                                                                                        )
                                                                                    );
                                                                                }}
                                                                                kind="ghost"
                                                                                size="sm"
                                                                                style={{ padding: 0 }}
                                                                            >
                                                                                Reasoning
                                                                            </Button>
                                                                            {`]`}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    case 'PUBLISHED_BY':
                                                    case 'UNPUBLISHED_BY':
                                                        return (
                                                            <TableCell
                                                                className="table-row-user"
                                                                key={cell.id}
                                                                style={{ width: '25%' }}
                                                            >
                                                                {
                                                                    allUsers?.find(
                                                                        (user) => user.id === parseInt(cell.value)
                                                                    )?.name
                                                                }
                                                            </TableCell>
                                                        );
                                                    case 'DATE_PUBLISHED':
                                                    case 'DATE_UNPUBLISHED':
                                                        return (
                                                            <TableCell
                                                                className="table-row-date"
                                                                key={cell.id}
                                                                style={{ width: '25%' }}
                                                            >
                                                                {new Date(cell.value).toLocaleString('en-US')}
                                                            </TableCell>
                                                        );
                                                    case 'action':
                                                        return (
                                                            <TableCell key={cell.id} style={{ width: '15%' }}>
                                                                <div
                                                                    className="d-flex ms-auto me-3"
                                                                    style={{
                                                                        width: 'fit-content',
                                                                        gap: '0.5rem',
                                                                    }}
                                                                >
                                                                    {actions.map(({ onClick, actionText }) => (
                                                                        <Button
                                                                            key={actionText}
                                                                            className="pe-4"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                onClick(
                                                                                    row.id,
                                                                                    scenarios.find(
                                                                                        (scenario) =>
                                                                                            scenario.id === row.id
                                                                                    )?.SCENARIO_NAME
                                                                                );
                                                                            }}
                                                                            type="button"
                                                                        >
                                                                            {actionText}
                                                                        </Button>
                                                                    ))}
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    default:
                                                        return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                                }
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );
                }}
            </DataTable>
        </div>
    );
};

PublishTable.propTypes = publishTableProps;
