import {
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    ComboBox,
    DataTable,
    TableContainer,
} from '@carbon/react';
import React from 'react';
import { comboBoxShouldFilterItem } from '../../../helpers';

const cmdSummaryHeaders = [
    { header: 'CMD', key: 'title' },
    { header: 'Total Published', key: 'count' },
];
const mdsSummaryHeaders = [
    { header: 'MDS', key: 'title' },
    { header: 'Total Published', key: 'count' },
];

export const PublishFilters = ({
    cmdSummary,
    mdsSummary,
    cmdList,
    cmdFilter,
    ilcList,
    ilcFilter,
    mdsFilter,
    mdsList,
    onSelect,
    totalCount,
}) => {
    const showTotals = !!cmdSummary && !!mdsSummary;
    return (
        <div className="publish-filters">
            {showTotals ? (
                <>
                    <h4>Total Published Scenarios: {totalCount}</h4>
                    <div className="tables">
                        <PublishSummaryTable
                            title="Major Command"
                            rows={cmdSummary ?? []}
                            headers={cmdSummaryHeaders}
                        />
                        <PublishSummaryTable
                            title="Mission Design Series"
                            rows={mdsSummary ?? []}
                            headers={mdsSummaryHeaders}
                        />
                    </div>
                </>
            ) : null}
            <FilterDropdowns
                cmdList={cmdList}
                cmdFilter={cmdFilter}
                ilcList={ilcList}
                ilcFilter={ilcFilter}
                mdsFilter={mdsFilter}
                mdsList={mdsList}
                onSelect={onSelect}
            />
        </div>
    );
};

const PublishSummaryTable = ({ rows, headers, title }) => {
    return (
        <DataTable stickyHeader rows={rows} headers={headers}>
            {({ rows, headers, getHeaderProps, getRowProps, getTableProps, getTableContainerProps }) => (
                <TableContainer title={title} {...getTableContainerProps()}>
                    <Table {...getTableProps()} aria-label={title + ' Table'}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header) => (
                                    <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {rows.map((row) => (
                                <TableRow {...getRowProps({ row })}>
                                    {row.cells.map((cell) => (
                                        <TableCell key={cell.id}>{cell.value}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </DataTable>
    );
};

const FilterDropdowns = ({ cmdList, cmdFilter, ilcList, ilcFilter, mdsFilter, mdsList, onSelect }) => {
    return (
        <div className="filter-dropdowns">
            <div data-testid="publish-filter-dropdown-CMD">
                <Dropdown
                    dropdownName="CMD"
                    items={cmdList}
                    onSelect={(selectedItem) => {
                        onSelect({ cmd: selectedItem });
                    }}
                    selectedItem={cmdFilter}
                />
            </div>
            <div data-testid="publish-filter-dropdown-ILC">
                <Dropdown
                    items={ilcList}
                    dropdownName="ILC"
                    onSelect={(selectedItem) => {
                        onSelect({ ilc: selectedItem });
                    }}
                    selectedItem={ilcFilter}
                />
            </div>
            <div data-testid="publish-filter-dropdown-MDS">
                <Dropdown
                    items={mdsList}
                    dropdownName="MDS"
                    onSelect={(selectedItem) => {
                        onSelect({
                            mds: selectedItem,
                        });
                    }}
                    selectedItem={mdsFilter}
                />
            </div>
        </div>
    );
};

const Dropdown = ({ items, dropdownName, onSelect, selectedItem }) => {
    const fieldName = {
        CMD: 'Major Command',
        MDS: 'Mission Design Series',
        ILC: 'Installation Location Name',
    };
    return (
        <div className="dropdown">
            <ComboBox
                id={`${dropdownName}-dropdown`}
                titleText={fieldName[dropdownName]}
                label=""
                items={items}
                selectedItem={selectedItem ?? ''}
                shouldFilterItem={comboBoxShouldFilterItem}
                onChange={({ selectedItem }) => {
                    onSelect(selectedItem || null);
                }}
            />
        </div>
    );
};

Dropdown.propTypes = {};

PublishFilters.propTypes = {};
