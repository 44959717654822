import React, { useEffect, useState } from 'react';
import { Accordion, AccordionItem, Link, TabPanel } from '@carbon/react';

import { fetchUnits } from '../adminApiCalls';

import { MissingUnitsTable } from './MissingUnitsTable';
import { PublishFilters } from '../PublishScenarioTab/PublishFilters';

const headers = [
    { header: 'CMD', key: 'CMD_TITLE', width: '25%' },
    { header: 'ILC', key: 'ILC_TITLE', width: '25%' },
    { header: 'MDS', key: 'MDS_TITLE', width: '25%' },
    { header: 'OPS Unit', key: 'OPS_UNIT', width: '15%' },
    { header: 'PAI Type', key: 'PAI_TYPE', width: '10%' },
];

/**
 * @typedef {Object} command
 * @property {string} CMD
 * @property {string} MDS
 * @property {string} ILC
 * @property {{ id: string, PAI_TYPE: string, OPS_UNIT: string }[]} MISSING
 * @property {{ id: string, PAI_TYPE: string, OPS_UNIT: string }[]} 'IN TRANSIT'
 */

export const MissingUnitsTab = () => {
    const [missing, setMissing] = useState(/** @type {command[]} */ ([]));
    const [transit, setTransit] = useState(/** @type {command[]} */ ([]));
    const [filter, setFilter] = useState({
        cmd: null,
        mds: null,
        ilc: null,
    });
    const [cmd, setCmd] = useState(/** @type {string[]} */ ([]));
    const [mds, setMds] = useState(/** @type {string[]} */ ([]));
    const [ilc, setIlc] = useState(/** @type {string[]} */ ([]));

    useEffect(() => {
        async function getUnits() {
            const { success, cmdList, mdsList, ilcList, missing, transit } = await fetchUnits();
            if (success) {
                setCmd(cmdList);
                setMds(mdsList);
                setIlc(ilcList);
                setMissing(missing);
                setTransit(transit);
            }
        }

        getUnits();
    }, []);

    const cmdFilter = filter.cmd;
    const ilcFilter = filter.ilc;
    const mdsFilter = filter.mds;

    let filteredMissing = missing;
    let filteredTransit = transit;
    if (cmdFilter) {
        const filter = cmdFilter.split(' - ').shift();
        filteredMissing = filteredMissing.filter((command) => command.CMD === filter);
        filteredTransit = filteredTransit.filter((command) => command.CMD === filter);
    }
    if (ilcFilter) {
        const filter = ilcFilter.split(' - ').pop();
        filteredMissing = filteredMissing.filter((command) => command.ILC === filter);
        filteredTransit = filteredTransit.filter((command) => command.ILC === filter);
    }
    if (mdsFilter) {
        const filter = mdsFilter.split(' - ').shift();
        filteredMissing = filteredMissing.filter((command) => command.MDS === filter);
        filteredTransit = filteredTransit.filter((command) => command.MDS === filter);
    }

    return (
        <TabPanel className="publish-scenario-tab" aria-label="missing-units-tab">
            <PublishFilters
                cmdList={cmd}
                cmdFilter={cmdFilter}
                ilcList={ilc}
                ilcFilter={ilcFilter}
                mdsFilter={mdsFilter}
                mdsList={mds}
                onSelect={(update) => {
                    setFilter((prevFilter) => ({
                        ...prevFilter,
                        ...update,
                    }));
                }}
            />
            <MissingUnitsTable
                tableDescription="Units with no scenario in UMP"
                units={filteredMissing}
                tableHeader="Missing Units"
                ariaLabel="Missing Units Table"
                headers={headers}
                cmdFilter={cmdFilter}
                ilcFilter={ilcFilter}
                mdsFilter={mdsFilter}
            />
            <MissingUnitsTable
                tableDescription="Units with scenario in transition to MP Whatif"
                units={filteredTransit}
                tableHeader="In Transit"
                ariaLabel="Approved Scenarios Table"
                headers={headers}
                cmdFilter={cmdFilter}
                ilcFilter={ilcFilter}
                mdsFilter={mdsFilter}
            />
            <Link
                className="mt-2 ms-auto p-3 pe-5 d-block cds--btn cds--btn--primary"
                href={`/missing-units-download`}
                target="_blank"
                style={{ color: 'white', textDecoration: 'none' }}
                data-testid="missing-units-download"
            >
                <div className="pe-3">Download</div>
            </Link>
        </TabPanel>
    );
};
