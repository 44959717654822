import React, { useEffect, useState } from 'react';
import { SelectUtil } from '../../../../utils';
import { selectionList } from './selectionList';
import PropTypes from 'prop-types';

export const AssociatedCommandSection = ({ setIsCompleteAssocInput, data }) => {
    const [associatedCmd, setAssociatedCmd] = useState(data.cmd);
    const [associatedLocation, setAssociatedLocation] = useState(data.ilc);
    const [associatedOpPec, setAssociatedOpPec] = useState(data.op_pec);
    const [associatedMxPec, setAssociatedMxPec] = useState(data.mx_pec);

    useEffect(() => {
        const isCompleteHostInput =
            !associatedCmd.invalid &&
            !associatedLocation.invalid &&
            !associatedOpPec.invalid &&
            !associatedMxPec.invalid;
        setIsCompleteAssocInput(isCompleteHostInput);
    }, [
        setIsCompleteAssocInput,
        associatedCmd.invalid,
        associatedLocation.invalid,
        associatedOpPec.invalid,
        associatedMxPec.invalid,
    ]);

    return (
        <div id="tfi-associated-command-section" className="bordered mb-3">
            <div className="mb-2">
                <h5>Associated Command</h5>
            </div>
            <div className="d-flex">
                <SelectUtil
                    select={{
                        hideLabel: false,
                        size: 'sm',
                        labelText: 'CMD',
                        className: 'mb-3',
                        value: undefined,
                        defaultValue: associatedCmd,
                    }}
                    options={selectionList.cmdOptions}
                    setState={setAssociatedCmd}
                />
                <SelectUtil
                    select={{
                        hideLabel: false,
                        size: 'sm',
                        labelText: 'Location',
                        className: 'mb-3',
                        value: undefined,
                        defaultValue: associatedLocation,
                    }}
                    options={selectionList.ilcOptions}
                    setState={setAssociatedLocation}
                />
                <SelectUtil
                    select={{
                        hideLabel: false,
                        size: 'sm',
                        labelText: 'OP PEC',
                        className: 'mb-3',
                        value: undefined,
                        defaultValue: associatedOpPec,
                    }}
                    options={selectionList.pecOptions}
                    setState={setAssociatedOpPec}
                />
                <SelectUtil
                    select={{
                        hideLabel: false,
                        size: 'sm',
                        labelText: 'MX PEC',
                        className: 'mb-3',
                        value: undefined,
                        defaultValue: associatedMxPec,
                    }}
                    options={selectionList.pecOptions}
                    setState={setAssociatedMxPec}
                />
            </div>
        </div>
    );
};

AssociatedCommandSection.propTypes = {
    setIsCompleteAssocInput: PropTypes.func,
    data: PropTypes.object,
};
