import {
    Button,
    DataTable,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
} from '@carbon/react';
import PropTypes from 'prop-types';
import { userListDataAtom } from '../../wizard/modals/shareScenario/useFetchAllUsers';
import { useAtom } from 'jotai';

const modelsTableProps = {
    models: PropTypes.arrayOf(PropTypes.object).isRequired,
    ariaLabel: PropTypes.string,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func.isRequired,
            actionText: PropTypes.oneOf(['View', 'Archive', 'Unarchive']).isRequired,
        }).isRequired
    ),
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
        }).isRequired
    ),
    buttonLoading: PropTypes.bool,
};

export const ModelsTable = ({
    models,
    ariaLabel,
    actions = [],
    headers = [
        { header: 'Model Name', key: 'MODEL_NAME' },
        { header: 'Source', key: 'SOURCE' },
        { header: 'Created Date', key: 'CREATED_DATE' },
        { header: 'Updated Date', key: 'UPDATED_DATE' },
        { header: 'Created By', key: 'USER_ID' },
        { header: '', key: 'action' },
    ],
    buttonLoading = false,
}) => {
    const [userListData] = useAtom(userListDataAtom);

    return (
        <DataTable rows={models} headers={headers}>
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getToolbarProps,
                getBatchActionProps,
                onInputChange,
                getTableProps,
                getTableContainerProps,
            }) => {
                const batchActionProps = {
                    ...getBatchActionProps(),
                };
                return (
                    <TableContainer {...getTableContainerProps()}>
                        <div className="publish-table-description">
                            <TableToolbar {...getToolbarProps()}>
                                <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                                    <TableToolbarSearch
                                        tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                        onChange={onInputChange}
                                    />
                                </TableToolbarContent>
                            </TableToolbar>
                        </div>
                        <Table {...getTableProps()} aria-label={ariaLabel}>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow {...getRowProps({ row })}>
                                        {row.cells.map((cell) => {
                                            switch (cell.info.header) {
                                                case 'MODEL_NAME':
                                                    return (
                                                        <TableCell key={cell.id}>
                                                            <div className="table-row-model-name">{cell.value}</div>
                                                        </TableCell>
                                                    );
                                                case 'USER_ID':
                                                    return (
                                                        <TableCell
                                                            className="table-row-user"
                                                            key={cell.id}
                                                            style={{ width: '15%' }}
                                                        >
                                                            {
                                                                userListData.allUsers?.find(
                                                                    (user) => user.id === parseInt(cell.value)
                                                                )?.name
                                                            }
                                                        </TableCell>
                                                    );
                                                case 'CREATED_DATE':
                                                case 'UPDATED_DATE':
                                                    return (
                                                        <TableCell
                                                            className="table-row-date"
                                                            key={cell.id}
                                                            style={{ width: '15%' }}
                                                        >
                                                            {new Date(cell.value).toLocaleString('en-US')}
                                                        </TableCell>
                                                    );
                                                case 'action':
                                                    const currentModel = models.find((model) => model.id === row.id);
                                                    const isArchived =
                                                        currentModel?.ARCHIVED === 1 || currentModel?.ARCHIVED === true;
                                                    const filteredActions = actions.filter((action) => {
                                                        if (isArchived) {
                                                            return action.actionText !== 'Archive';
                                                        } else {
                                                            return action.actionText !== 'Unarchive';
                                                        }
                                                    });

                                                    return (
                                                        <TableCell key={cell.id} style={{ width: '15%' }}>
                                                            <div
                                                                className="d-flex ms-auto me-3"
                                                                style={{ width: 'fit-content', gap: '0.5rem' }}
                                                            >
                                                                {filteredActions.map(({ onClick, actionText }) =>
                                                                    actionText === 'View' ? (
                                                                        <Button
                                                                            key={actionText}
                                                                            className="pe-4"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                onClick(
                                                                                    models.find(
                                                                                        (model) => model.id === row.id
                                                                                    )
                                                                                );
                                                                            }}
                                                                            type="button"
                                                                        >
                                                                            {actionText}
                                                                        </Button>
                                                                    ) : (
                                                                        <Button
                                                                            key={actionText}
                                                                            className="pe-4"
                                                                            size="sm"
                                                                            onClick={() => {
                                                                                onClick(
                                                                                    models.find(
                                                                                        (model) => model.id === row.id
                                                                                    )
                                                                                );
                                                                            }}
                                                                            type="button"
                                                                            disabled={buttonLoading}
                                                                        >
                                                                            {actionText}
                                                                        </Button>
                                                                    )
                                                                )}
                                                            </div>
                                                        </TableCell>
                                                    );
                                                default:
                                                    return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                            }
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                );
            }}
        </DataTable>
    );
};

ModelsTable.propTypes = modelsTableProps;
