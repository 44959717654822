import { Button } from '@carbon/react';
import { useCallback, useState } from 'react';
import { useFocusAtom } from '../../../../hooks';
import { scenarioAtom } from '../../../../hooks/wizardAtoms';
import PropTypes from 'prop-types';
import { fetchTempOpsAsis } from '../../aircrewApiCalls';
import { fetchTempMxAsis } from '../../maintenanceApiCalls';
import { getRowExclusions } from './helpers/getRowExclusions';
import { ApplyFiltersModal } from '../../modals/applyFilters/ApplyFiltersModal';
import { FilterableMultiSelectAll } from '../../../../common/FilterableMultiSelectAll/FilterableMultiSelectAll';

export const FacMsiApiDropdowns = ({
    dropdownItems,
    selectedFilters,
    mpType,
    setSelectedFilters,
    isLoading = false,
    setAsisRows,
    setExclusions,
    setIsLoading,
    disabled,
}) => {
    const [scenario_id] = useFocusAtom(scenarioAtom, 'scenario_id');
    const [applyFiltersModalOpen, setApplyFiltersModalOpen] = useState(false);
    const [count, setCount] = useState(0);

    const handleFetchTempAsis = useCallback(
        async (filterData) => {
            let tempRows = [];
            if (mpType === 'AIRCREW') {
                setIsLoading(true);
                const { data, isOk } = await fetchTempOpsAsis({
                    filterData: { api: filterData.API, fac: filterData.FAC, msi: filterData.MSI },
                    scenario_id,
                });
                if (isOk) {
                    tempRows = data;
                    setIsLoading(false);
                }
            } else {
                setIsLoading(true);
                const { data, isOk } = await fetchTempMxAsis({
                    filterData: { api: filterData.API, fac: filterData.FAC, msi: filterData.MSI },
                    scenario_id,
                });
                if (isOk) {
                    tempRows = data;
                    setIsLoading(false);
                }
            }
            const exclusions = getRowExclusions(tempRows);
            setAsisRows(tempRows);
            setExclusions(exclusions);
        },
        [mpType, scenario_id, setAsisRows, setExclusions, setIsLoading]
    );

    const handleApplyFilters = useCallback(() => {
        const filterData = {
            API: selectedFilters.API.map((item) => item.VALUE),
            FAC: selectedFilters.FAC.map((item) => item.VALUE),
            MSI: selectedFilters.MSI.map((item) => item.VALUE),
        };
        handleFetchTempAsis(filterData);
    }, [selectedFilters, handleFetchTempAsis]);

    const sortItems = (items) => {
        return items.sort((a, b) => {
            if (a.VALUE === 'SELECT ALL') {
                return -1;
            }
            if (b.VALUE === 'SELECT ALL') {
                return 1;
            }
            return a.VALUE.localeCompare(b.VALUE);
        });
    };

    if (isLoading) {
        return <></>;
    }

    return (
        <div className="d-flex">
            {applyFiltersModalOpen && (
                <ApplyFiltersModal
                    open={applyFiltersModalOpen}
                    onClose={() => setApplyFiltersModalOpen(false)}
                    onSubmit={handleApplyFilters}
                />
            )}
            <div className="mx-2" style={{ width: '180px' }}>
                <FilterableMultiSelectAll
                    label=""
                    id="fac-multiselect"
                    keyValue={`fac-multiselect-${count}`}
                    disabled={disabled}
                    titleText="FAC"
                    items={[{ VALUE: 'SELECT ALL', CHECKED: false }, ...dropdownItems.FAC]}
                    initialSelectedItems={selectedFilters.FAC}
                    itemToString={(item) => (item ? item.VALUE : '')}
                    sortItems={(items) => sortItems(items)}
                    onChange={({ selectedItems }) => {
                        const hasSelectAll = selectedItems.find((item) => item.VALUE === 'SELECT ALL');
                        if (hasSelectAll) {
                            setCount(count + 1);
                        }
                        setSelectedFilters({
                            API: selectedFilters.API,
                            MSI: selectedFilters.MSI,
                            FAC: hasSelectAll ? dropdownItems.FAC : selectedItems,
                        });
                    }}
                />
            </div>
            {mpType === 'MX' && (
                <div className="mx-2" style={{ width: '180px' }}>
                    <FilterableMultiSelectAll
                        label=""
                        id="msi-multiselect"
                        keyValue={`msi-multiselect-${count}`}
                        disabled={disabled}
                        titleText="MSI"
                        items={[{ VALUE: 'SELECT ALL', CHECKED: false }, ...dropdownItems.MSI]}
                        initialSelectedItems={selectedFilters.MSI}
                        itemToString={(item) => (item ? item.VALUE : '')}
                        sortItems={(items) => sortItems(items)}
                        onChange={({ selectedItems }) => {
                            const hasSelectAll = selectedItems.find((item) => item.VALUE === 'SELECT ALL');
                            if (hasSelectAll) {
                                setCount(count + 1);
                            }
                            setSelectedFilters({
                                API: selectedFilters.API,
                                FAC: selectedFilters.FAC,
                                MSI: hasSelectAll ? dropdownItems.MSI : selectedItems,
                            });
                        }}
                    />
                </div>
            )}
            {mpType === 'AIRCREW' && (
                <div className="mx-2" style={{ width: '180px' }}>
                    <FilterableMultiSelectAll
                        label=""
                        id="api-multiselect"
                        keyValue={`api-multiselect-${count}`}
                        disabled={disabled}
                        titleText="API"
                        items={[{ VALUE: 'SELECT ALL', CHECKED: false }, ...dropdownItems.API]}
                        initialSelectedItems={selectedFilters.API}
                        itemToString={(item) => (item ? item.VALUE : '')}
                        sortItems={(items) => sortItems(items)}
                        onChange={({ selectedItems }) => {
                            const hasSelectAll = selectedItems.find((item) => item.VALUE === 'SELECT ALL');
                            if (hasSelectAll) {
                                setCount(count + 1);
                            }
                            setSelectedFilters({
                                FAC: selectedFilters.FAC,
                                MSI: selectedFilters.MSI,
                                API: hasSelectAll ? dropdownItems.API : selectedItems,
                            });
                        }}
                    />
                </div>
            )}
            <div className="d-flex align-items-end">
                <Button
                    kind="tertiary"
                    size="md"
                    disabled={disabled}
                    onClick={() => setApplyFiltersModalOpen(true)}
                    data-testid="apply-filters-button"
                >
                    Apply Filters
                </Button>
            </div>
        </div>
    );
};

FacMsiApiDropdowns.propTypes = {
    dropdownItems: PropTypes.object,
    selectedFilters: PropTypes.object,
    mpType: PropTypes.string,
    setSelectedFilters: PropTypes.func,
    isLoading: PropTypes.bool,
    setAsisRows: PropTypes.func,
    setExclusions: PropTypes.func,
    setIsLoading: PropTypes.func,
    disabled: PropTypes.bool,
};
