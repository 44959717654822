import { useCallback } from 'react';
import { useAtom } from 'jotai';

import { scenarioIsSavingAtom } from './wizardAtoms';
import { saveScenario } from '../pages/wizard/scenarioApiCalls';

export const useSaveScenario = () => {
    const [isSaving] = useAtom(scenarioIsSavingAtom);

    const saveScenarioCallback = useCallback(async (updatedScenario) => {
        try {
            const { data } = await saveScenario(updatedScenario);
            return data;
        } catch (error) {
            console.error(error);
        }
    }, []);

    return {
        isSaving,
        saveScenarioCallback,
    };
};
