import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    TextInput,
    Dropdown,
    TableExpandedRow,
    TableSelectRow,
    TableExpandRow,
    TableCell,
} from '@carbon/react';
import { AddFilled, Edit, TrashCan, Information, Replicate } from '@carbon/react/icons';

import { fulltimeEquationSelectionsAtom, showWlfConstants, useWlfEquationsState } from './wlfEquationsState';
import { useFocusAtom } from '../../../../../hooks';

export const FulltimeEquationRow = ({
    onOpenClick,
    pos,
    aircrewType,
    open,
    eqnId,
    showFulltimeEquation,
    ILC,
    getRowProps,
    getSelectionProps,
    onDeleteModel,
    headers,
    row,
    onDuplicateRow,
    disabled,
}) => {
    const { addWlfQueryRow, wlfEquation, updateRootInput, reset } = useWlfEquationsState(eqnId);

    const [selections = {}, setSelections] = useFocusAtom(fulltimeEquationSelectionsAtom, aircrewType);
    const [invalidIAF, setInvalidIAF] = useState(false);
    useEffect(() => {
        if (!open) {
            reset(eqnId);
        }
    }, [open, reset, eqnId]);

    const wlfValue = wlfEquation.value;
    const hasWlf = typeof wlfValue === 'number';
    const readOnly = wlfEquation.isBaseline;

    const getDisplayCell = ({ inputType, cell, alreadyExpanded, updateExpanded }) => {
        switch (inputType) {
            case 'ilcSpecific':
                return (
                    <TableCell key={cell.id}>
                        <div
                            onKeyDownCapture={(e) => {
                                if (!alreadyExpanded && !readOnly) {
                                    if (e.key === ' ' || e.key === 'Enter') {
                                        updateExpanded(e);
                                    }
                                }
                            }}
                            onClickCapture={(e) => {
                                if (!alreadyExpanded && !readOnly) {
                                    updateExpanded(e);
                                }
                            }}
                        >
                            <Dropdown
                                id={`wlf-eqn-ilc-dropdown-${ILC}`}
                                data-testid="wlf-eqn-ilc-dropdown"
                                titleText=""
                                label=""
                                aria-label="ILC"
                                className="me-2 flex-shrink-0"
                                items={['ALL', ILC]}
                                readOnly={readOnly}
                                selectedItem={cell.value ?? ''}
                                onChange={({ selectedItem }) => {
                                    updateRootInput({
                                        value: selectedItem,
                                        inputType: 'ilcSpecific',
                                    });
                                }}
                            />
                        </div>
                    </TableCell>
                );
            case 'edit': {
                if (wlfEquation.isBaseline) {
                    return <TableCell key={cell.id}>{null}</TableCell>;
                }
                return (
                    <TableCell key={cell.id}>
                        <div className="action-buttons">
                            <Button
                                kind="ghost"
                                size="sm"
                                disabled={disabled}
                                renderIcon={TrashCan}
                                iconDescription="Trash Can"
                                onClick={() => {
                                    onDeleteModel(wlfEquation);
                                }}
                                data-testid="delete-fulltime-model"
                            />
                        </div>
                    </TableCell>
                );
            }
            case 'copy': {
                if (wlfEquation.isBaseline) {
                    const { fac, afsc, osc, iaf, maf, wlf, coefficient, constant } = wlfEquation;
                    return (
                        <TableCell key={cell.id}>
                            <div className="action-buttons">
                                <Button
                                    renderIcon={Replicate}
                                    kind="ghost"
                                    size="sm"
                                    disabled={disabled}
                                    iconDescription="Clone"
                                    onClick={() =>
                                        onDuplicateRow(aircrewType, {
                                            fac,
                                            afsc,
                                            osc,
                                            iaf,
                                            maf,
                                            wlf,
                                            coefficient,
                                            constant,
                                        })
                                    }
                                    aria-label="clone model"
                                />
                            </div>
                        </TableCell>
                    );
                } else {
                    return <TableCell key={cell.id}>{null}</TableCell>;
                }
            }
            case 'fac':
            case 'osc':
            case 'afsc': {
                return (
                    <TableCell key={cell.id}>
                        <TextInput
                            id={`wlf-input-${pos}-${inputType}`}
                            data-testid={`wlf-equations-${inputType}`}
                            disabled={disabled}
                            labelText=""
                            invalidText="Field required"
                            invalid={wlfEquation[`${inputType}Error`]}
                            readOnly={readOnly}
                            value={wlfEquation[inputType] ?? ''}
                            onChange={(e) => {
                                updateRootInput({
                                    value: e.target.value,
                                    inputType,
                                });
                            }}
                        />
                    </TableCell>
                );
            }
            case 'iaf': {
                return (
                    <TableCell key={cell.id}>
                        <TextInput
                            id={`wlf-input-${pos}-${inputType}`}
                            data-testid={`wlf-equations-${inputType}`}
                            disabled={disabled}
                            labelText=""
                            invalidText="Valid number required"
                            invalid={wlfEquation[`${inputType}Error`] || invalidIAF}
                            readOnly={readOnly}
                            value={wlfEquation[inputType] ?? ''}
                            type="number"
                            onBlur={() => {
                                if (isNaN(parseFloat(wlfEquation[inputType]))) {
                                    setInvalidIAF(true);
                                }
                            }}
                            onChange={(e) => {
                                setInvalidIAF(false);
                                updateRootInput({
                                    value: e.target.value,
                                    inputType,
                                });
                            }}
                        />
                    </TableCell>
                );
            }
            default:
                return (
                    <TableCell key={cell.id} id={cell.id}>
                        {cell.value}
                    </TableCell>
                );
        }
    };
    const { isExpanded, onExpand, ...rowProps } = getRowProps({ row });

    return (
        <>
            <TableExpandRow
                {...rowProps}
                isExpanded={isExpanded}
                onExpand={(...args) => {
                    return onExpand(...args);
                }}
                data-testid="fulltime-equation-root"
            >
                <TableSelectRow
                    {...getSelectionProps({ row })}
                    onChange={(value) => {
                        setSelections({ ...selections, [row.id]: value });
                    }}
                    checked={selections[row.id] ?? false}
                    disabled={disabled}
                />
                {row.cells.map((cell) =>
                    getDisplayCell({
                        inputType: cell.info.header,
                        cell,
                        alreadyExpanded: isExpanded,
                        updateExpanded: onExpand,
                    })
                )}
            </TableExpandRow>
            <TableExpandedRow colSpan={headers.length + 3} className="" data-testid="fulltime-equation-expanded">
                <div className="p-4">
                    <div className="align-items-end align-self-end d-flex">
                        <strong className="text-nowrap d-block">
                            y =&nbsp;
                            {showFulltimeEquation === showWlfConstants.FULL ? <>&#40;&nbsp;</> : null}
                        </strong>
                        <TextInput
                            id="wlf-equations-constant"
                            labelText="Constant"
                            data-testid="wlf-equations-constant"
                            type="number"
                            value={wlfEquation.constant ?? ''}
                            placeholder="0"
                            invalidText="Number is not valid"
                            invalid={wlfEquation.constantError}
                            readOnly={readOnly}
                            onChange={(e) => {
                                updateRootInput({
                                    value: e.target.value,
                                    inputType: 'constant',
                                });
                            }}
                        />
                        <strong className="text-nowrap">&nbsp;&#43;&nbsp;</strong>
                        <TextInput
                            id="wlf-equations-coefficient"
                            labelText="Coefficient"
                            data-testid="wlf-equations-coefficient"
                            type="number"
                            value={wlfEquation.coefficient ?? ''}
                            placeholder="0"
                            invalidText="Number is not valid"
                            invalid={wlfEquation.coefficientError}
                            readOnly={readOnly}
                            onChange={(e) => {
                                updateRootInput({
                                    value: e.target.value,
                                    inputType: 'coefficient',
                                });
                            }}
                        />
                        {hasWlf ? (
                            <>
                                <div className="wlf-value">
                                    <p className="ms-2">WLF</p>

                                    <strong className="text-nowrap d-block">&nbsp;&#42;&nbsp;{wlfValue}</strong>
                                </div>
                                {wlfEquation.isBaseline ? (
                                    <Button
                                        kind="ghost"
                                        size="sm"
                                        renderIcon={Information}
                                        iconDescription="Show details"
                                        onClick={() => onOpenClick(eqnId)}
                                        data-testid="expand-workload-factor"
                                    />
                                ) : (
                                    <Button
                                        kind="ghost"
                                        size="sm"
                                        renderIcon={Edit}
                                        iconDescription="Pencil"
                                        onClick={() => onOpenClick(eqnId)}
                                        data-testid="edit-workload-factor"
                                    />
                                )}
                            </>
                        ) : (
                            <div className="wlf-value">
                                <p className="ms-3">WLF</p>
                                <Button
                                    kind="ghost"
                                    size="sm"
                                    renderIcon={AddFilled}
                                    iconDescription="Plus sign"
                                    onClick={() => onOpenClick(eqnId)}
                                    data-testid="add-workload-factor"
                                />
                            </div>
                        )}
                        <strong className="d-block">&nbsp;</strong>
                        {showFulltimeEquation === showWlfConstants.FULL ? (
                            <>
                                <strong className="text-nowrap d-block">&#41;&nbsp;&#47;&nbsp;</strong>
                                <TextInput
                                    id="wlf-equations-maf"
                                    className="flex-shrink-1"
                                    labelText="MAF"
                                    data-testid="wlf-equations-maf"
                                    type="number"
                                    value={wlfEquation.maf ?? ''}
                                    invalidText="Number is not valid"
                                    invalid={wlfEquation.mafError}
                                    placeholder="1"
                                    readOnly={readOnly}
                                    min={1}
                                    onChange={(e) => {
                                        updateRootInput({
                                            value: e.target.value,
                                            inputType: 'maf',
                                        });
                                    }}
                                />
                            </>
                        ) : null}
                    </div>
                </div>
            </TableExpandedRow>
        </>
    );
};
FulltimeEquationRow.propTypes = {
    onOpenClick: PropTypes.func.isRequired,
    pos: PropTypes.string,
    aircrewType: PropTypes.string,
    open: PropTypes.bool,
    eqnId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showFulltimeEquation: PropTypes.oneOf(Object.values(showWlfConstants)),
    ILC: PropTypes.string,
    getRowProps: PropTypes.func.isRequired,
    getSelectionProps: PropTypes.func.isRequired,
    onDeleteModel: PropTypes.func.isRequired,
    headers: PropTypes.array.isRequired,
    row: PropTypes.any,
    onDuplicateRow: PropTypes.func,
    disabled: PropTypes.bool,
};
