import React, { useEffect, useState } from 'react';
import { DataTableSkeleton, Modal, TextInput } from '@carbon/react';
import PropTypes from 'prop-types';
import { fetchAircrewModelData } from '../../wizard/aircrewApiCalls';

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof OpsModelInfoModalProps>>}
 * @returns {React.ReactElement}
 */
export const OpsModelInfoModal = ({ open, onClose, model }) => {
    const [modelInfo, setModelInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchModelData(acrId) {
            try {
                setIsLoading(true);
                const { data } = await fetchAircrewModelData(acrId);
                setModelInfo(data);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
        if (model) {
            fetchModelData(model.ACR_ID);
        }
    }, [model]);

    return (
        <Modal
            passiveModal
            open={open}
            size="lg"
            className="ops-model-info-modal"
            onRequestClose={onClose}
            modalLabel={model.MODEL_NAME}
            data-testid="ops-model-info-modal"
            key={`${model.ACR_ID} - ${model.MODEL_NAME}`}
        >
            {isLoading ? (
                <DataTableSkeleton headers={[]} />
            ) : (
                modelInfo.length > 0 &&
                modelInfo[0]?.CHILDREN.map((model, i) => {
                    if (i === 0) {
                        return (
                            <>
                                <div className="row" key={`${i}`}>
                                    <div className="col-md-2">
                                        <TextInput
                                            id="model-info-mds"
                                            labelText="MDS"
                                            value={model.MDS || ''}
                                            data-testid="model-info-mds"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <TextInput
                                            id="model-info-cmd"
                                            labelText="CMD"
                                            value={model.CMD || ''}
                                            data-testid="model-info-cmd"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <TextInput
                                            id="model-info-model-name"
                                            labelText="Model Name"
                                            value={model.MODEL_NAME || ''}
                                            data-testid="model-info-model-name"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <TextInput
                                            id="model-info-source"
                                            labelText="Source"
                                            value={model.SOURCE || ''}
                                            data-testid="model-info-source"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <TextInput
                                            id="model-info-poc"
                                            labelText="POC"
                                            value={model.POC || ''}
                                            data-testid="model-info-poc"
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-2">
                                        <TextInput
                                            id="model-info-notes"
                                            labelText="Notes"
                                            value={model.NOTES || ''}
                                            data-testid="model-info-notes"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <TextInput
                                            id="model-info-crew-position"
                                            labelText="Aircrew Position"
                                            value={model.CREW_POSITION || ''}
                                            data-testid="model-info-crew-positio"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <TextInput
                                            id="model-info-crew-ratio"
                                            labelText="Aircrew Ratio"
                                            value={model.CREW_RATIO}
                                            data-testid="model-info-crew-ratio"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <TextInput
                                            id="model-info-crew-comp-enl"
                                            labelText="Crew Complement ENL"
                                            value={model.CREW_COMP_ENL}
                                            data-testid="model-info-crew-comp-enl"
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <TextInput
                                            id="model-info-crew-comp-off"
                                            labelText="Crew Complement OFF"
                                            value={model.CREW_COMP_OFF}
                                            data-testid="model-info-crew-comp-off"
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </>
                        );
                    } else {
                        return (
                            <div className="row" key={`${i}`}>
                                <div className="col-md-2">
                                    <TextInput
                                        id="model-info-notes"
                                        labelText="Notes"
                                        value={model.NOTES || ''}
                                        data-testid="model-info-notes"
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <TextInput
                                        id="model-info-crew-position"
                                        labelText="Aircrew Position"
                                        value={model.CREW_POSITION || ''}
                                        data-testid="model-info-crew-positio"
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <TextInput
                                        id="model-info-crew-ratio"
                                        labelText="Aircrew Ratio"
                                        value={model.CREW_RATIO}
                                        data-testid="model-info-crew-ratio"
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <TextInput
                                        id="model-info-crew-comp-enl"
                                        labelText="Crew Complement ENL"
                                        value={model.CREW_COMP_ENL}
                                        data-testid="model-info-crew-comp-enl"
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <TextInput
                                        id="model-info-crew-comp-off"
                                        labelText="Crew Complement OFF"
                                        value={model.CREW_COMP_OFF}
                                        data-testid="model-info-crew-comp-off"
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        );
                    }
                })
            )}
        </Modal>
    );
};

const OpsModelInfoModalProps = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    model: PropTypes.object.isRequired,
};

OpsModelInfoModal.propTypes = OpsModelInfoModalProps;
