import { useState } from 'react';
import PropTypes from 'prop-types';
import { NumberInput, Button, Popover, PopoverContent } from '@carbon/react';
import { Edit } from '@carbon/react/icons';

export const MxToolTip = ({ currentValue, activeWorkcenters, col, handleColDelete, setActiveWorkcenters }) => {
    const [inputValue, setInputValue] = useState(currentValue);
    const [openPopover, setOpenPopover] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);

    const handleEditCol = (colName, updatedPaiValue) => {
        if (Object.hasOwn(activeWorkcenters[0], `PAI_${updatedPaiValue}_RQMT`) || inputValue === '') {
            setIsInvalid(true);
            return;
        }
        const headerParts = col.split('_');
        const updatedColName = `${headerParts[0]}_${updatedPaiValue}_${headerParts[2]}`;
        const newTableData = [];
        activeWorkcenters.forEach((elem) => {
            let newElem = { ...elem };
            newElem[updatedColName] = newElem[colName];
            delete newElem[colName];
            newTableData.push(newElem);
        });
        setActiveWorkcenters(newTableData);
    };

    const invalidText = inputValue === '' ? 'Please enter a PAI value.' : 'PAI column already exists';

    return (
        <Popover
            open={openPopover}
            align="bottom-right"
            autoAlign={false}
            className="ps-3"
            highContrast
            tabIndex={0}
            istabtip="true"
            onMouseEnter={() => setOpenPopover(true)}
            onMouseLeave={() => {
                setOpenPopover(false);
            }}
            role="mxtooltipPopover"
        >
            <div>
                <Button renderIcon={Edit} kind="ghost" size="sm" iconDescription="Pencil" />
            </div>
            <PopoverContent className="p-3">
                <NumberInput
                    id="number-input"
                    className="pai-input pb-3"
                    size="md"
                    min={0}
                    max={100}
                    value={inputValue}
                    invalid={isInvalid}
                    aria-label="update pai requirement"
                    invalidText={isInvalid ? invalidText : ''}
                    onChange={(event, { value }) => {
                        setIsInvalid(false);
                        setInputValue(value);
                    }}
                    onKeyDown={(e) => {
                        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
                    }}
                />
                <Button
                    size="sm"
                    className="cds--btn cds--btn--ghost"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleEditCol(col, inputValue);
                    }}
                    aria-label="save PAI requirement column"
                >
                    Save
                </Button>
                <Button
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenPopover(false);
                        handleColDelete(col);
                    }}
                    size="sm"
                    title="Delete PAI Requirement Column"
                    className="cds--btn cds--btn--danger--ghost"
                    aria-label="delete PAI requirement column"
                >
                    Delete
                </Button>
            </PopoverContent>
        </Popover>
    );
};

MxToolTip.propTypes = {
    currentValue: PropTypes.string,
    activeWorkcenters: PropTypes.arrayOf(PropTypes.object),
    col: PropTypes.string.isRequired,
    handleColDelete: PropTypes.func.isRequired,
    setActiveWorkcenters: PropTypes.func.isRequired,
};
