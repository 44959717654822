export const handleRowsSelected = ({ isSelected, parentId, cellId, setRowsSelected }) => {
    let newRowsSelected = null;
    if (!isSelected) {
        setRowsSelected((previousRowsSelected) => {
            newRowsSelected = {
                ...previousRowsSelected,
                [parentId]: previousRowsSelected[parentId].filter((selectedId) => {
                    return !cellId.includes(selectedId);
                }),
            };
            return newRowsSelected;
        });
    } else {
        setRowsSelected((previousRowsSelected) => {
            newRowsSelected = {
                ...previousRowsSelected,
                [parentId]: previousRowsSelected[parentId]
                    ? [...new Set(previousRowsSelected[parentId].concat(cellId))]
                    : [...cellId],
            };
            return newRowsSelected;
        });
    }
    return newRowsSelected;
};
