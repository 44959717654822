import React from 'react';
import { ComboBox } from '@carbon/react';
import { comboBoxShouldFilterItem } from '../../../../../../helpers';

export const MxDropdown = ({ labelText, value, onValueChange = () => {}, items, disabled }) => {
    return (
        <div className="MxDropdown" data-testid="mx-dropdown">
            <ComboBox
                titleText={labelText}
                label={labelText}
                id={`MxDropdown_${labelText}`}
                items={items}
                selectedItem={value ?? ''}
                onChange={({ selectedItem }) => {
                    onValueChange(selectedItem || null);
                }}
                disabled={disabled}
                shouldFilterItem={comboBoxShouldFilterItem}
            />
        </div>
    );
};
