import { Pagination } from '@carbon/react';
import PropTypes from 'prop-types';

export const TablePagination = ({ id, page, pageSize, totalItems, onPageSizeChange }) => {
    const handlePageSizeChange = (pageInfo) => {
        onPageSizeChange({
            page: pageInfo.page,
            pageSize: pageInfo.pageSize,
        });
        localStorage.setItem(
            `pagination-${id}`,
            JSON.stringify({
                page: pageInfo.page,
                pageSize: pageInfo.pageSize,
            })
        );
    };

    return (
        <Pagination
            id={id}
            page={page}
            pageSize={pageSize}
            pageSizes={[10, 20, 30, 40, 50]}
            totalItems={totalItems}
            onChange={handlePageSizeChange}
        />
    );
};

TablePagination.propTypes = {
    id: PropTypes.string,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    onPageSizeChange: PropTypes.func.isRequired,
};
