import React from 'react';
import {
    DataTable,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TextInput,
} from '@carbon/react';
import PropTypes from 'prop-types';

const tableHeaders = [
    {
        header: 'ILC',
        key: 'ILC',
    },
    {
        header: 'FAC',
        key: 'FAC',
    },
    {
        header: 'AFSC',
        key: 'AFSC',
    },
    {
        header: 'OSC',
        key: 'OSC',
    },
    {
        header: 'IAF',
        key: 'IAF',
    },
    {
        header: 'POSITION',
        key: 'POSITION',
    },
];

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof FulltimeEquationsInfoModalProps>>}
 * @returns {React.ReactElement}
 */
export const FulltimeEquationsInfoModal = ({ open, onClose, model }) => {
    return (
        <Modal
            passiveModal
            open={open}
            size="lg"
            className="fte-info-modal"
            onRequestClose={onClose}
            modalLabel={`Fulltime Equation: ${model.AIRCREW_TYPE === 'ENL' ? 'Enlisted' : 'Officer'}`}
            data-testid="fulltime-equations-info-modal"
        >
            <DataTable rows={[model]} headers={tableHeaders}>
                {({ rows, headers, getHeaderProps, getRowProps, getTableProps, getTableContainerProps }) => {
                    return (
                        <TableContainer {...getTableContainerProps()}>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({ header })}>{header.header}</TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {rows.map((row) => (
                                        <TableRow {...getRowProps({ row })}>
                                            {row.cells.map((cell) => (
                                                <TableCell key={cell.id}>{cell.value}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );
                }}
            </DataTable>
            <div className="p-4">
                <div className="align-items-end align-self-end d-flex">
                    <strong className="text-nowrap d-block">y =&nbsp;</strong>
                    <TextInput
                        id="wlf-equations-constant"
                        labelText="Constant"
                        data-testid="wlf-equations-constant"
                        value={model.CONSTANT || 0}
                        readOnly={true}
                    />
                    <strong className="text-nowrap">&nbsp;&#43;&nbsp;</strong>
                    <TextInput
                        id="wlf-equations-coefficient"
                        labelText="Coefficient"
                        data-testid="wlf-equations-coefficient"
                        value={model.COEFFICIENT || 0}
                        readOnly={true}
                    />
                    <>
                        <div
                            className="wlf-value"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignSelf: 'stretch',
                                justifyContent: 'space-between',
                            }}
                        >
                            <p className="mb-2" style={{ fontSize: '9.75px', color: 'rgb(82, 82, 82)' }}>
                                WLF
                            </p>
                            <strong className="text-nowrap d-block">&nbsp;&#42;&nbsp;{model.WLF_VALUE || 0}</strong>
                        </div>
                    </>
                    <strong className="d-block">&nbsp;</strong>
                    <>
                        <strong className="text-nowrap d-block">&#41;&nbsp;&#47;&nbsp;</strong>
                        <TextInput
                            id="wlf-equations-maf"
                            className="flex-shrink-1"
                            labelText="MAF"
                            data-testid="wlf-equations-maf"
                            value={model.MAF || 0}
                            readOnly={true}
                        />
                    </>
                </div>
            </div>
        </Modal>
    );
};

const FulltimeEquationsInfoModalProps = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    model: PropTypes.object.isRequired,
};

FulltimeEquationsInfoModal.propTypes = FulltimeEquationsInfoModalProps;
