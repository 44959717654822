import React from 'react';
import { FilterableMultiSelect } from '@carbon/react';

import { UDDropdownSelect } from './UDDropdownSelect';
import { useCountChanges } from '../../../../hooks';
import { useAtom, useSetAtom } from 'jotai';
import { resetUnitDetailsPasAtom } from './UnitDetailsAtoms';
import PropTypes from 'prop-types';
import { isApprovedScenarioAtom } from '../../../../hooks/wizardAtoms';

export const UDDropdowns = ({
    column1,
    column2,
    column3,
    column4,
    column5,
    disableMXOF_PAS,
    opsSelectItems,
    mxSelectItems,
    setSelectedMxgPecs,
    selectedMxgPecs,
    setSelectedOgPecs,
    selectedOgPecs,
    hasSelectedRequiredOgPas,
    hasSelectedRequiredMxgPas,
}) => {
    const count = useCountChanges(hasSelectedRequiredOgPas, hasSelectedRequiredMxgPas);
    const resetUnitDetailsPas = useSetAtom(resetUnitDetailsPasAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);

    return (
        <section className="UD-dropdowns" data-qa="uddropdowns-container">
            <div className="row">
                <div className="col-sm-4">
                    <UDDropdownSelect
                        titleText="OG/WING PAS"
                        labelText="OG_PAS"
                        resetUnitDetailsPas={resetUnitDetailsPas}
                    />
                    <div className="row ps-4">
                        <div className="col-12 bordered">
                            {column1.map((labelText) => (
                                <UDDropdownSelect
                                    key={`og_pas_${labelText}`}
                                    className="position-relative"
                                    labelText={labelText}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-sm-8">
                    <UDDropdownSelect
                        titleText="MXG/WING PAS"
                        labelText="MXG_PAS"
                        resetUnitDetailsPas={resetUnitDetailsPas}
                    />
                    <div className="row ps-4">
                        <div className="col-3 bordered">
                            {column2.map((labelText) => (
                                <UDDropdownSelect
                                    key={`mxg_pas_${labelText}`}
                                    className="position-relative"
                                    labelText={labelText}
                                />
                            ))}
                        </div>
                        <div className="col-3 bordered">
                            {column3.map((labelText) => (
                                <UDDropdownSelect
                                    key={`mxg_pas_column3${labelText}`}
                                    className="position-relative"
                                    labelText={labelText}
                                    disableMXOF_PAS={disableMXOF_PAS}
                                />
                            ))}
                        </div>
                        <div className={`col-3 ${column4.length === 5 ? 'bordered' : 'short-bordered'}`}>
                            {column4.map((labelText) => (
                                <UDDropdownSelect
                                    key={`mxg_pas_column4${labelText}`}
                                    className="position-relative"
                                    labelText={labelText}
                                    disableMXOF_PAS={disableMXOF_PAS}
                                />
                            ))}
                        </div>
                        <div className={`col-3 ${column5.length === 5 ? 'bordered' : 'short-bordered'}`}>
                            {column5.map((labelText) => (
                                <UDDropdownSelect
                                    key={`mxg_pas_column5${labelText}`}
                                    className="position-relative"
                                    labelText={labelText}
                                    disableMXOF_PAS={disableMXOF_PAS}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4" data-testid="uddropdowns-og-pec">
                    <FilterableMultiSelect
                        id="uddropdown_og_pec"
                        key={`og_pec_multiselect_${count}`}
                        className="pb-3"
                        items={opsSelectItems}
                        initialSelectedItems={selectedOgPecs}
                        itemToString={(i) => (i ? i : '')}
                        label=""
                        titleText="OG PEC"
                        placeholder="Please Select..."
                        direction="top"
                        onChange={({ selectedItems }) => {
                            if (isApprovedScenario) {
                                return;
                            } else {
                                setSelectedOgPecs(selectedItems);
                            }
                        }}
                        disabled={!hasSelectedRequiredOgPas}
                    />
                </div>
                <div className="col-sm-8">
                    <FilterableMultiSelect
                        id="uddropdown_mxg_pec"
                        key={`mxg_pec_multiselect_${count}`}
                        className="pb-3"
                        items={mxSelectItems}
                        initialSelectedItems={selectedMxgPecs}
                        itemToString={(i) => (i ? i : '')}
                        label=""
                        titleText="MXG PEC"
                        placeholder="Please Select..."
                        direction="top"
                        onChange={({ selectedItems }) => {
                            if (isApprovedScenario) {
                                return;
                            } else {
                                setSelectedMxgPecs(selectedItems);
                            }
                        }}
                        disabled={!hasSelectedRequiredMxgPas}
                    />
                </div>
            </div>
        </section>
    );
};

UDDropdowns.propTypes = {
    column1: PropTypes.arrayOf(PropTypes.string).isRequired,
    column2: PropTypes.arrayOf(PropTypes.string).isRequired,
    column3: PropTypes.arrayOf(PropTypes.string).isRequired,
    column4: PropTypes.arrayOf(PropTypes.string).isRequired,
    column5: PropTypes.arrayOf(PropTypes.string).isRequired,
    disableMXOF_PAS: PropTypes.bool,
    opsSelectItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    mxSelectItems: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedMxgPecs: PropTypes.func.isRequired,
    selectedMxgPecs: PropTypes.arrayOf(PropTypes.string).isRequired,
    setSelectedOgPecs: PropTypes.func.isRequired,
    selectedOgPecs: PropTypes.arrayOf(PropTypes.string).isRequired,
    hasSelectedRequiredOgPas: PropTypes.bool,
    hasSelectedRequiredMxgPas: PropTypes.bool,
};
