import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    DataTable,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    SkeletonText,
} from '@carbon/react';
import { Add } from '@carbon/react/icons';
import { useSetAtom } from 'jotai';
import { FormulaTableCell } from './FormulaTableCell';
import { useFormulaTable } from './useFormulaTable';
import { isConditionalEqnsOpenAtom, useConditionalEqns } from './useConditionalEqns';

const formulaTableTypes = {
    isLoading: PropTypes.bool,
    showRequiredFields: PropTypes.bool,
    colHeaders: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
};

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof formulaTableTypes>>}
 * @returns {React.ReactElement}
 */
export const FormulaTable = ({ colHeaders, isLoading = false, showRequiredFields = false, disabled }) => {
    const setIsConditionalEqnsOpen = useSetAtom(isConditionalEqnsOpenAtom);
    const { insertConditionalEqn } = useConditionalEqns({});

    const { handleUpdateRow, handleAddRow, handleDeleteRow, optionB } = useFormulaTable();

    const handleOpenConditionalEqns = ({ rowIdx, id }) => {
        setIsConditionalEqnsOpen({
            conditionalEqn: optionB.tableRows[rowIdx].conditionalEqns ?? null,
            rowIdx,
        });
        if (Array.isArray(optionB.tableRows[rowIdx].conditionalEqns)) {
            insertConditionalEqn(optionB.tableRows[rowIdx].conditionalEqns);
        }
    };

    return (
        <div className="formula-table-container">
            <div className="hot-table mt-3" data-testid="formula-table-hot-table">
                <div className="me-auto d-flex align-items-center">
                    <h5 className="title">Workcenters</h5>
                    <Button
                        className="edit-workcenters-button"
                        size="md"
                        kind="ghost"
                        iconDescription="Add Workcenter"
                        disabled={disabled}
                        hasIconOnly={true}
                        renderIcon={Add}
                        onClick={handleAddRow}
                        data-testid="add-workcenter-btn"
                    />
                </div>
                <DataTable
                    rows={optionB.tableRows}
                    headers={colHeaders}
                    id="datatable-conditiona-equation"
                    data-testid="active-workcenters-datatable"
                >
                    {({ rows, headers, getHeaderProps, getRowProps, getTableProps }) => (
                        <Table {...getTableProps()}>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableHeader col={header.key} key={header.key} {...getHeaderProps({ header })}>
                                            {header.header.replaceAll('_', ' ')}
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {isLoading ? (
                                <TableBody>
                                    {Array.from(Array(10)).map((r, idx) => (
                                        <TableRow
                                            key={`loading_row_${idx}`}
                                            id={`loading_${idx}`}
                                            data-testid="loading-row"
                                        >
                                            {headers.map((header, idx) => (
                                                <TableCell key={`loading_${header}_${idx}`}>
                                                    <SkeletonText />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {rows.map((row, rowIdx) => (
                                        <React.Fragment key={row.id}>
                                            <TableRow {...getRowProps({ row })} data-testid="formula-table-row">
                                                {row.cells.map((cell, colIdx) => (
                                                    <FormulaTableCell
                                                        key={cell.id}
                                                        value={cell.value}
                                                        id={cell.id}
                                                        disabled={disabled}
                                                        dataType={colHeaders[colIdx].dataType}
                                                        onChange={handleUpdateRow}
                                                        colName={colHeaders[colIdx].key}
                                                        rowIdx={rowIdx}
                                                        onOpenConditionalEqns={handleOpenConditionalEqns}
                                                        onDelete={handleDeleteRow}
                                                        showRequiredFields={showRequiredFields}
                                                    />
                                                ))}
                                            </TableRow>
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    )}
                </DataTable>
            </div>
        </div>
    );
};

FormulaTable.propTypes = formulaTableTypes;
