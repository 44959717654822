import { useFocusAtom } from './useFocusAtom';
import { scenarioAtom } from './wizardAtoms';

export const useTFABanner = () => {
    const [tfaBanner] = useFocusAtom(scenarioAtom, 'additionalUnitDetails');
    if (!tfaBanner) {
        return false;
    }
    return (
        // tfaBanner.CMD?.length > 0 ||
        // tfaBanner.ILC?.length > 0 ||
        tfaBanner.AIRCREW?.length > 0 || tfaBanner.MX?.length > 0
    );
};
