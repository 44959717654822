import React from 'react';
import { Modal } from '@carbon/react';
import './modal.scss';
import PropTypes from 'prop-types';

export const CalculateTobeModal = ({ open, onClose, onSubmit }) => {
    const handleModal = () => {
        onClose();
        onSubmit();
    };

    return (
        <Modal
            className="calculate-tobe-modal"
            open={open}
            onRequestClose={onClose}
            onRequestSubmit={handleModal}
            modalHeading="Calculate TOBE"
            primaryButtonText="Calculate"
            secondaryButtonText="Cancel"
            size="xs"
            data-testid="calculate-tobe-modal"
        >
            The changes made to the ASIS will be saved and the TOBE will be calculated again accordingly.
        </Modal>
    );
};

CalculateTobeModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};
