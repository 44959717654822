import {
    Button,
    DataTable,
    TabPanel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableSelectAll,
    TableSelectRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
} from '@carbon/react';
import PropTypes from 'prop-types';

export const ShareScenarioTab = ({
    users,
    approvedScenarios,
    onScenarioSelect,
    hasSelections,
    onUserSelect,
    onModalOpen,
}) => {
    return (
        <TabPanel aria-label="share-approved-scenarios-tab">
            <div className="d-flex">
                <div style={{ width: '50%' }}>
                    <DataTable rows={approvedScenarios} headers={[{ header: 'Scenario Name', key: 'scenario_name' }]}>
                        {({
                            rows,
                            headers,
                            getHeaderProps,
                            getRowProps,
                            getSelectionProps,
                            getToolbarProps,
                            getBatchActionProps,
                            onInputChange,
                            getTableProps,
                            getTableContainerProps,
                        }) => {
                            const batchActionProps = {
                                ...getBatchActionProps(),
                            };
                            return (
                                <TableContainer
                                    title="Share Approved Scenarios"
                                    description="Please select scenarios to share with other users."
                                    {...getTableContainerProps()}
                                >
                                    <TableToolbar {...getToolbarProps()}>
                                        <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                                            <TableToolbarSearch
                                                tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                                onChange={onInputChange}
                                            />
                                        </TableToolbarContent>
                                    </TableToolbar>
                                    <Table {...getTableProps()} aria-label="Share Approved Scenarios Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableSelectAll
                                                    {...getSelectionProps({
                                                        onClick: (e) => {
                                                            onScenarioSelect(
                                                                e.target.checked ? rows.map((row) => row.id) : []
                                                            );
                                                        },
                                                    })}
                                                />
                                                {headers.map((header, i) => (
                                                    <TableHeader
                                                        key={i}
                                                        {...getHeaderProps({
                                                            header,
                                                        })}
                                                    >
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, i) => (
                                                <TableRow
                                                    key={i}
                                                    {...getRowProps({
                                                        row,
                                                    })}
                                                >
                                                    <TableSelectRow
                                                        {...getSelectionProps({
                                                            row,
                                                            onClick: (e) => {
                                                                if (e.target.checked) {
                                                                    onScenarioSelect((prev) => [...prev, row.id]);
                                                                } else {
                                                                    onScenarioSelect((prev) =>
                                                                        prev.filter((scenario) => scenario !== row.id)
                                                                    );
                                                                }
                                                            },
                                                        })}
                                                    />
                                                    {row.cells.map((cell) => (
                                                        <TableCell key={cell.id}>{cell.value}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            );
                        }}
                    </DataTable>
                </div>

                <div className="mx-2" style={{ width: '50%' }}>
                    <DataTable
                        rows={users.map((user) => ({ ...user, id: `${user.id}` }))}
                        headers={[
                            { header: 'User', key: 'name' },
                            { header: 'Email', key: 'email' },
                        ]}
                    >
                        {({
                            rows,
                            headers,
                            getHeaderProps,
                            getRowProps,
                            getSelectionProps,
                            getToolbarProps,
                            getBatchActionProps,
                            onInputChange,
                            getTableProps,
                            getTableContainerProps,
                        }) => {
                            const batchActionProps = {
                                ...getBatchActionProps(),
                            };
                            return (
                                <TableContainer
                                    title="Users to share scenarios with"
                                    description="Please select users to share scenarios with."
                                    {...getTableContainerProps()}
                                >
                                    <TableToolbar {...getToolbarProps()}>
                                        <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                                            <TableToolbarSearch
                                                tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                                onChange={onInputChange}
                                            />
                                        </TableToolbarContent>
                                    </TableToolbar>
                                    <Table {...getTableProps()} aria-label="Users Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableSelectAll
                                                    {...getSelectionProps({
                                                        onClick: (e) => {
                                                            onUserSelect(
                                                                e.target.checked ? rows.map((row) => row.id) : []
                                                            );
                                                        },
                                                    })}
                                                />
                                                {headers.map((header, i) => (
                                                    <TableHeader
                                                        key={i}
                                                        {...getHeaderProps({
                                                            header,
                                                        })}
                                                    >
                                                        {header.header}
                                                    </TableHeader>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, i) => (
                                                <TableRow
                                                    key={i}
                                                    {...getRowProps({
                                                        row,
                                                    })}
                                                >
                                                    <TableSelectRow
                                                        {...getSelectionProps({
                                                            row,
                                                            onClick: (e) => {
                                                                if (e.target.checked) {
                                                                    onUserSelect((prev) => [...prev, row.id]);
                                                                } else {
                                                                    onUserSelect((prev) =>
                                                                        prev.filter((user) => user !== row.id)
                                                                    );
                                                                }
                                                            },
                                                        })}
                                                    />
                                                    {row.cells.map((cell) => (
                                                        <TableCell key={cell.id}>{cell.value}</TableCell>
                                                    ))}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            );
                        }}
                    </DataTable>
                </div>
            </div>
            <div className="d-flex align-items-end justify-content-end mt-2">
                <Button disabled={!hasSelections} onClick={onModalOpen}>
                    Share Scenarios
                </Button>
            </div>
        </TabPanel>
    );
};

ShareScenarioTab.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object),
    approvedScenarios: PropTypes.arrayOf(PropTypes.object),
    hasSelections: PropTypes.bool,
    onModalOpen: PropTypes.func,
    onScenarioSelect: PropTypes.func,
    onUserSelect: PropTypes.func,
};
