import React, { useMemo, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate, useRoutes } from 'react-router-dom';
import { useAtom, useSetAtom } from 'jotai';
import { Button, ProgressIndicator, ProgressStep, Tag, Theme } from '@carbon/react';
import { ChevronLeft, ChevronRight } from '@carbon/icons-react';
import { useQuery } from '@tanstack/react-query';

import { Navigation, PageHeader } from '../../common';
import { useCurrentStep } from '../../hooks';
import { allInputsAtom, selectedMPESAtom, MPESList, MPESDefault } from '../../hooks/wizardAtoms';
import { StepHeader, stepOrder } from './steps';
import {
    filtersAtom,
    ILCFiltersAtom,
    MDSFiltersAtom,
    CMDFiltersAtom,
    hasOnePaiTypeAtom,
} from './steps/generalSettings/GeneralSettingsAtoms';
import { fetchFiltersCombined } from '../../httpRequests/filtersCombined';
import { TimeoutAlert } from '../../common/TimeoutAlert';
import { SaveSessionModal, saveSessionModalOpenAtom } from './modals/saveSession/SaveSessionModal';
import { CARBON_THEME, CUSTOM_THEME } from './constants';

import './Wizard.scss';
import { ReleaseNotes, releaseNotesOpenAtom } from './modals/releaseNotes/ReleaseNotes';
import { SelectMPESModal } from './modals/selectMPESModal/SelectMPESModal';

export const WizardContext = React.createContext();

const downloadReviewPagePath = '/manpower-wizard/review-download';

export const Wizard = () => {
    const [carbonTheme, setCarbonTheme] = useState(
        localStorage.getItem('theme') === CUSTOM_THEME.DARK ? CARBON_THEME.G90 : CARBON_THEME.WHITE
    );
    const [customTheme, setCustomTheme] = useState(
        localStorage.getItem('theme') === CUSTOM_THEME.DARK ? CUSTOM_THEME.DARK : CUSTOM_THEME.LIGHT
    );
    const [filters, setFilters] = useAtom(filtersAtom);
    const setILCFilters = useSetAtom(ILCFiltersAtom);
    const setMDSFilters = useSetAtom(MDSFiltersAtom);
    const setCMDFilters = useSetAtom(CMDFiltersAtom);
    const [allInputs] = useAtom(allInputsAtom);
    const [saveSessionOpen, setSaveSessionOpen] = useAtom(saveSessionModalOpenAtom);
    const [hasOnePaiType] = useAtom(hasOnePaiTypeAtom);
    const [menuCollapse, setMenuCollapse] = useState(false);
    const [buttonClicked, setButtonClicked] = useState();
    const [openReleaseNotes, setOpenReleaseNotes] = useAtom(releaseNotesOpenAtom);
    const [openMpes, setOpenMpes] = useState(false);
    const [selectedMPES, setSelectedMPES] = useAtom(selectedMPESAtom);

    const navigate = useNavigate();

    const changeTheme = () => {
        // Local storage or custom theme
        const prevStorageTheme = localStorage.getItem('theme');
        const newStorageTheme = prevStorageTheme === CUSTOM_THEME.DARK ? CUSTOM_THEME.LIGHT : CUSTOM_THEME.DARK;

        // Carbon Theme
        const newCarbonTheme = prevStorageTheme === CUSTOM_THEME.DARK ? CARBON_THEME.WHITE : CARBON_THEME.G90;

        localStorage.setItem('theme', newStorageTheme);
        setCarbonTheme(newCarbonTheme);
        setCustomTheme(newStorageTheme);
    };

    const fetchData = async (prefix, path, params = {}) => {
        // check filters or fs_status router in fastapi/src/api/routers for prefix and path
        try {
            const response = await axios.post(`/api/fast-api/${prefix}/${path}`, null, { params: params });
            setFilters({ data: response.data });
        } catch (error) {
            console.error(error);
        }
    };

    const fetchFilters = useCallback(async (filterType, updateFilter) => {
        await global.GetCSRFToken();
        try {
            const { data } = await axios.post(`/api/fast-api/filters/${filterType}`, null);
            updateFilter(data);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetchFilters('ilc', setILCFilters);
        fetchFilters('cmd', setCMDFilters);
        fetchFilters('mds', setMDSFilters);
    }, [fetchFilters, setCMDFilters, setILCFilters, setMDSFilters]);

    useQuery({
        queryKey: ['filters_combined', filters],
        queryFn: fetchFiltersCombined,
        onSuccess: (response) => {
            setFilters(response.data);
        },
        enabled: filters.length <= 0,
        onError: (error) => {
            console.error(error);
        },
    });

    const navigateNext = () => {
        const nextStep = stepOrder
            .sort((a, b) => a.index - b.index)
            .filter((step, index) => {
                return index > currentStep.index && step.skip !== true;
            })
            .at(0);

        if (!currentStep.isLast) {
            navigate(`/manpower-wizard/${nextStep.path}`);
        }
    };

    const navigatePrevious = () => {
        const prevStep = stepOrder
            .sort((a, b) => a.index - b.index)
            .filter((step, index) => {
                return index < currentStep.index && step.skip !== true;
            })
            .at(-1);

        if (!currentStep.isFirst) {
            navigate(`/manpower-wizard/${prevStep.path}`);
        }
    };

    const pathOrder = stepOrder.map((step) => step.path);
    const currentStep = useCurrentStep(pathOrder);
    const stepElement = useRoutes(stepOrder);

    useEffect(() => {
        // redirect if invalid path
        if (!stepOrder[currentStep.index]?.path) {
            navigate('/manpower-wizard/');
        }
    }, [stepOrder[currentStep.index]?.path]);

    const isValid = useMemo(() => {
        switch (currentStep.slug) {
            case '':
                return allInputs.generalSettings && hasOnePaiType;
            case 'unit-details':
                return allInputs.unitDetails;
            case 'additional-unit-details':
                return allInputs.additionalUnits;
            case 'ops':
            case 'mx':
                return true;
            default:
                return false;
        }
    }, [allInputs, currentStep.slug, hasOnePaiType]);

    const renderWizard = () => {
        const isOpsMx = currentStep.slug === 'ops' || currentStep.slug === 'mx';
        return (
            <>
                <StepHeader
                    title={stepOrder[currentStep.index]?.header || stepOrder[currentStep.index]?.title}
                    subtitle={stepOrder[currentStep.index]?.subtitle}
                />
                <div className="wizard-step-content" style={{ paddingTop: isOpsMx ? 0 : '' }}>
                    {stepElement}
                </div>
            </>
        );
    };

    const handleProgressChange = (index) => {
        const path = stepOrder[index]?.path;
        navigate(`/manpower-wizard/${path}`);
    };

    const handleSaveSessionSubmit = () => {
        if (buttonClicked === 'previous') {
            navigatePrevious();
        } else if (buttonClicked === 'next') {
            navigateNext();
        } else {
            handleProgressChange(buttonClicked);
        }
    };

    return (
        <Theme theme={carbonTheme}>
            {openReleaseNotes && <ReleaseNotes />}
            <div className="wizard">
                <Navigation />
                <PageHeader changeTheme={changeTheme} customTheme={customTheme} />
                <div className="wizard-content">
                    <div
                        className="wizard-nav position-relative"
                        style={{ width: menuCollapse ? '8rem' : '17rem' }}
                        data-testid="wizard-side-nav"
                    >
                        <ProgressIndicator
                            vertical
                            currentIndex={currentStep.index}
                            onChange={(index) => {
                                setSaveSessionOpen(true);
                                setButtonClicked(index);
                            }}
                        >
                            {stepOrder.map((step, index) => (
                                <ProgressStep
                                    key={index}
                                    label={menuCollapse ? step.icon : step.title}
                                    title={step.title}
                                    disabled={index === 0 ? false : step.skip || !hasOnePaiType}
                                />
                            ))}
                        </ProgressIndicator>
                        <Button
                            className="collapse-button"
                            kind="ghost"
                            iconDescription={menuCollapse ? 'Open Menu' : 'Collapse Menu'}
                            hasIconOnly
                            renderIcon={menuCollapse ? ChevronRight : ChevronLeft}
                            onClick={() => setMenuCollapse(!menuCollapse)}
                            data-testid="wizard-collapse-button"
                        />
                        <div
                            className="d-flex"
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                right: 0,
                                flexWrap: 'wrap',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Tag
                                as="button"
                                onClick={() => setOpenMpes(true)}
                                style={{ width: 'fit-content' }}
                                size="lg"
                                type="teal"
                            >
                                {`MPES: FY ${selectedMPES || MPESDefault}`}
                            </Tag>
                            <Tag as="button" onClick={() => setOpenReleaseNotes(true)} size="lg" type="green">
                                {`V${process.env.REACT_APP_VERSION ?? '0.0.0'}`}
                            </Tag>
                        </div>
                    </div>
                    <WizardContext.Provider
                        value={{
                            fetchData,
                            navigateNext,
                            navigatePrevious,
                        }}
                    >
                        <div className={currentStep.slug + '-step wizard-form-container'}>
                            {renderWizard()}
                            <footer className="button-container" data-testid="wizard-footer">
                                {!currentStep.isFirst && (
                                    <Button
                                        kind="secondary"
                                        onClick={() => {
                                            setSaveSessionOpen(true);
                                            setButtonClicked('previous');
                                        }}
                                        disabled={currentStep.isFirst}
                                    >
                                        Previous
                                    </Button>
                                )}
                                {!currentStep.isLast && (
                                    <Button
                                        onClick={() => {
                                            setSaveSessionOpen(true);
                                            setButtonClicked('next');
                                        }}
                                        disabled={!isValid}
                                        className="next-btn"
                                    >
                                        Next
                                    </Button>
                                )}
                            </footer>
                        </div>
                    </WizardContext.Provider>
                </div>
            </div>
            <TimeoutAlert />
            {saveSessionOpen && (
                <SaveSessionModal
                    open={saveSessionOpen}
                    onClose={() => {
                        setSaveSessionOpen(false);
                        setButtonClicked();
                    }}
                    onSubmit={() => {
                        handleSaveSessionSubmit();
                        setSaveSessionOpen(false);
                        setSelectedMPES(MPESDefault);
                    }}
                />
            )}
            {openMpes && <SelectMPESModal open={openMpes} onClose={() => setOpenMpes(false)} />}
        </Theme>
    );
};
